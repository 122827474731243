<template>
  <div v-easyadmin="easyadminId" class="w-100" :class="[contentType, columnClasses, paddingY]" payment-form :style="theme">
    <div class="payment-form" v-if="step===PAYMENT_STEP.CHOOSE_AMOUNT || (step===PAYMENT_STEP.STRIPE_PAYMENT && !showStripe)">
      <div class="payment-form-content p-3 themed-item">
        <div class="form-group amount-preselection" v-if="content.permit_free_amount">
          <label for="amount" class="h4 text-center d-block mb-2">{{ft('ecommerce.amount')}}</label>
          <div class="d-flex justify-content-between mb-3 amount-preselection-container" v-if="amountPreselection.length > 0">
            <button 
              @click.prevent="updateAmount(amount)"
              v-for="(amount, idx) in amountPreselection" 
              :key="idx" 
              type="button"
              class="btn btn-light btn-amount-preselection">
              € {{ amount }}
            </button>
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text py-0">€</span>
            </div>
            <input @keydown="isValid" step="1" class="amount-input" type="number" placeholder="10" v-model="amount" required>
          </div>
        </div>
        <div class="row">
          <div class="col-12 py-4">
            <h4 class="text-center">{{ft('ecommerce.select_payment')}}</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="btn-group btn-group w-100" role="group">
              <button 
                @click.prevent="handleClickPaymentMethod(PAYMENT_STEP.STRIPE_PAYMENT)"
                class="purchase-btn btn btn-primary"
                :disabled="amount<=0"
              >
                <i class="fa-lg fal fa-credit-card pr-2"></i>{{ft('ecommerce.button.credit_card')}}
              </button>
              <button :disabled="amount<=0" class="purchase-btn btn satispay-button">
                <img
                  src="../../../../../assets/img/satispay-logo.png"
                  @click.prevent="handleClickPaymentMethod(PAYMENT_STEP.SATISPAY_PAYMENT)"
                >
              </button>
              <button :disabled="amount<=0" @click.prevent="createOrder" class="purchase-btn btn paypal-button">
                <div class="paypal-button-label-container">
                  <img src="../../../../../assets/img/paypal-logo-pp.png">
                  <img src="../../../../../assets/img/paypal-logo-text.png">
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <payment-stripe 
      :amount="amount"
      :product-name="content.name"
      :redirect-url="content.payment_redirect_url"
      v-if="step===PAYMENT_STEP.STRIPE_PAYMENT"
      v-show="showStripe"
      @payment-stripe:back="handleBack"
      @payment-stripe:ready="handleStripeReady"
      @payment-stripe:error="handleError"
    ></payment-stripe>
    <payment-satispay
      :amount="amount"
      :product-name="content.name"
      v-if="step===PAYMENT_STEP.SATISPAY_PAYMENT"
      @payment-satispay:back="handleBack"
    ></payment-satispay>

    <div class="modal fade" tabindex="-2" id="errorModal" role="dialog" aria-labelledby="errorModal" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ft('ecommerce.modal_error.title')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ errorText }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ft('ecommerce.button.close')}}</button>
          </div>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { get } from 'lodash';
import PaymentSatispay from './payment-satispay.vue';
import PaymentStripe from './payment-stripe.vue';

const PAYMENT_STEP = {
  CHOOSE_AMOUNT: 1,
  STRIPE_PAYMENT: 2,
  SATISPAY_PAYMENT: 3,
}

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  components: { PaymentSatispay, PaymentStripe },
  data: function() {
    return {
      PAYMENT_STEP,
      step: PAYMENT_STEP.CHOOSE_AMOUNT,
      currency: null,
      amount: this.content.amount,
      loading: false,
      errorText: "",
      showStripe: false,
      paypal: null
    };
  },
  computed: {
    amountPreselection() {
      return this.content.amount_preselection ? this.content.amount_preselection.split(',') : [];
    },
    columnWidth() {
      const size = get(this, ["content", "column_width"], 8);
      return parseInt(size);
    },
    columnClasses() {
      return `col-lg-${this.columnWidth}`;
    },
  },
  methods: {
    createOrder() {
      Fandom.emit("show-loader");
      Fandom.ajax({
        url: "/api/v5/paypal/create_order",
        method: "POST",
        data: {
          purchased_content: this.content.name,
          amount: this.amount
        },
        success: (order) => {
          Fandom.emit("hide-loader");
          const approveLink = order.links.find(link => link.rel === "approve")
          return window.location.href = approveLink.href;
        }
      })
    },
    updateAmount(amount) {
      this.amount = amount;
    },
    isValid(event) {
      const intRx = /\d/;
			if ((event.key.length > 1) || ( (event.key === "-") && (!event.currentTarget.value.length) ) || intRx.test(event.key)) {
        return;
      }
			event.preventDefault();
		},
    handleClickPaymentMethod(method) {
      if (method === PAYMENT_STEP.STRIPE_PAYMENT) {
        Fandom.emit("show-loader");
      }
      this.step = method
    },
    handleBack() {
      this.step = PAYMENT_STEP.CHOOSE_AMOUNT;
      this.showStripe = false;
    },
    handleStripeReady() {
      Fandom.emit("hide-loader");
      this.showStripe = true;
    },
    handleError(event) {
      this.errorText = event.error;
      $('#errorModal').modal('show');
    }
  }
}
</script>

<style lang="scss" scoped>
[payment-form] {
  .payment-form {
    align-self: center;

    &-content {
      border: 2px solid $black;
    }

    label {
      margin-bottom: 0;
      -webkit-font-smoothing: antialiased;
    }
    
    input {
      line-height: 20px;
      padding: 12px
    }

    .amount-input {
      flex: 1;
    }

    .amount-preselection {
      &-container {
        flex-wrap: wrap;
        gap: 10px;

        .btn-amount-preselection {
          flex: 1;
          min-width: 70px;
        }
      }
    }

    .btn-group {
      gap: 10px;

      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
      }

      .purchase-btn {
        color: #ffffff;
        border-radius: 4px;
        display: block;
        transition: all 0.2s ease;
        box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
        width: 100%;
        margin-left: 0;

        &:hover {
          filter: contrast(115%);
        }

        &:disabled {
          opacity: 0.5;
          cursor: default;
        }
      }

      .satispay-button {
        cursor: pointer;
        background-color: #f94c43;
        
        img {
          height: 1.375rem;
          max-height: 1.375rem;
        }
      }

      .paypal-button {
        background: #009cde;
        color: #fff;

        &-label-container {
          height: 1.375rem;
          max-height: 1.375rem;

          img {
            vertical-align: top;
            height: 100%;
            text-align: left;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .btn-amount-preselection {
      padding: 0.75rem 0.75rem;
    }
  }
}
</style>