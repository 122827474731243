import { render, staticRenderFns } from "./embed-youtube.vue?vue&type=template&id=d66e9cce&scoped=true"
import script from "./embed-youtube.vue?vue&type=script&lang=js"
export * from "./embed-youtube.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d66e9cce",
  null
  
)

export default component.exports