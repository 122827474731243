import { difference, intersection } from "lodash";

function getLiveInteraction() {
  let cmp = this;
  Fandom.ajax({
    method: "POST",
    url: "/api/v5/live_events/get_active_interaction",
    data: {content_name: cmp.content.name, content_id: cmp.content.id},
    success: (data) => {

      data.live_interactions.reverse();

      let iteractionIdsAlreadyInPage = []
      if (cmp.liveInteractions) {
        iteractionIdsAlreadyInPage = cmp.liveInteractions.map((interactionObj) => {
          return interactionObj.interaction.id;
        })
      }

      let polledInteractionIds = []
      data.live_interactions.forEach((liveInteractionInfo) => {
        polledInteractionIds.push(liveInteractionInfo[0])
      });

      let liveInteractionIdToRemove = difference(iteractionIdsAlreadyInPage, polledInteractionIds);
      if (liveInteractionIdToRemove.length > 0) {
        liveInteractionIdToRemove.forEach((interactionId) => {
          Vue.delete(cmp.pageModel.name_to_content_user_info[cmp.content.name].id_to_interaction, interactionId);
        });
      }

      // Detect possible new polled interactions
      let newInteractionsInPageIds = difference(polledInteractionIds, iteractionIdsAlreadyInPage);
      if (newInteractionsInPageIds.length > 0) {
        console.log("newInteractionsInPage", newInteractionsInPageIds);
        Fandom.emit('newInteractionsInPage', { 
          containerContentId: cmp.containerContent.id,
          interactionsStatusChangedIds: newInteractionsInPageIds
        });
      }

      // Detect possible status change in polled interactions
      let interactionsWithPossibleStatusChangeIds = intersection(polledInteractionIds, iteractionIdsAlreadyInPage);
      let interactionsWithStatusChangeIds = [];
      interactionsWithPossibleStatusChangeIds.forEach((id) => {
        let oldInteractionUserInfo = cmp.pageModel.name_to_content_user_info[cmp.content.name].id_to_interaction[id];
        data.live_interactions.forEach((liveInteractionInfo) => {
          if((liveInteractionInfo[0] == id) && (liveInteractionInfo[2].updated_at != oldInteractionUserInfo.updated_at)){
            return interactionsWithStatusChangeIds.push(id);
          }
        });
      });
      if(interactionsWithStatusChangeIds.length > 0) {
        console.log("newInteractionResultsInPage", interactionsWithStatusChangeIds);
        Fandom.emit('newInteractionResultsInPage', {
          containerContentId: cmp.containerContent.id,
          interactionsStatusChangedIds: interactionsWithStatusChangeIds
        });
      }

       // Update name_to_content_user_info interaction's map
      data.live_interactions.forEach((liveInteractionInfo) => {
        Vue.set(cmp.pageModel.name_to_content_user_info[cmp.content.name].id_to_interaction, liveInteractionInfo[0], liveInteractionInfo[2]);
      });

      // Update interactions
      Vue.set(cmp, "liveInteractions", data.live_interactions.map((liveInteractionInfo) => {
        return liveInteractionInfo[1];
      }));

      //Update live events extra fields with fresh values
      Object.entries(data.live_extra_fields).forEach(([key, value]) => {
        delete value["$id"];
        Vue.set(cmp.content, key, value);
      });

    }
  });  
}

const liveEventsMixin = {
  data: function() {
    return {
      liveInteractions: this.initLiveInteractions() // Interactions must be reversed.
    };
  },
  methods: {
    getLiveInteraction,
    initLiveInteractions() {
      if (this.content.live_interactions) {
        return (Fandom.deepClone(this.content.live_interactions)).reverse();
      } else {
        return [];
      }
    }
  },
};

export {
  liveEventsMixin
}
