<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { audiEventsBasicMixin } from "../../modules/audi_events_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, audiEventsBasicMixin],
  mounted() {
    this.registerAnimation();
  },
  computed: {
    rowClasses() {
      const classes = [];
      const columnCount = (this.content.column_count || 3);
      classes.push(`row-cols-md-${columnCount}`);
      return classes;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" class="inview_animate" v-if="isContentVisibleToUser()" list-launch-tiles-component :id="content.name">
    <div class="container px-sm-0 py-2 py-sm-0" :id="content.stripe_id">
      <div class="row inset row-cols-1 row-cols-sm-2 mx-sm-n2" :class="rowClasses">
        <tile
          class="col launch-tile position-static" alignment-class="justify-content-between" :spacing="true"
          v-for="(item, index) in content.items" :key="`item-${index}`"
          :content="item" :opt-container-content="content"
        >
        </tile>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.launch-tile {
  min-height: 35rem;
}
</style>
