<template>
  <div v-easyadmin="easyadminId" :class="contentType" class="quiz container pt-3 h-100" player-quiz-component>
    <div class="row quiz-header">
      <div class="col-12">
        <countdown
          v-if="countdown && isSimple && isQuizStarted"
          class="position-absolute"
          v-model="countdown"
          :empty-color="emptyColor"
          :full-color="fullColor"
          :circle-size="32"
          :interval-audio="countdownAudio"
          @finished="handleCountdownEnd"
        ></countdown>
        <div v-else class="text-uppercase small font-weight-bold text-center mb-4">{{ content.subtitle }}</div>
      </div>
    </div>
    <div class="row quiz-body">
      <div v-if="questions.length===0" class="col-12 h-100">
        <div class="h-100 d-flex justify-content-center align-items-center flex-column py-3">
          <div class="placeholder-image rounded-circle d-flex justify-content-center align-items-center">
            <i class="fa-3x fas fa-comments-question-check"></i>
          </div>
          <h4 class="placeholder-text pt-3 mb-0">{{ ft(`globals.live_shopping.empty_quiz_title`) }}</h4>
          <p class="placeholder-text">{{ ft(`globals.live_shopping.empty_quiz_subtitle`) }}</p>
        </div>
      </div>
      <div v-else-if="!isQuizStarted && isCarousel" class="col-12">
        <div class="d-flex justify-content-center align-items-center flex-column h-100">
          <img v-if="content.logo" class="quiz__logo mb-4 rounded" :alt="content.logo.alt" :src="content.logo.url">
          <h2 class="quiz__title text-center" v-if="content.title">{{content.title}}</h2>
          <p class="quiz__description text-center mb-4" v-if="content.description" v-html="content.description"></p>
          <button class="btn btn-dark rounded font-weight-bold" @click="startQuiz">{{ content.button_text }}</button>
        </div>
      </div>
      <div v-else class="col-12 d-flex flex-column align-items-center justify-content-center">
        <div class="flickity-carousel w-100" ref="flickity-carousel">
          <component
            v-for="question in questions"
            :key="`question-carousel${question.interaction.id}`"
            class="my-3 flickity-carousel-item"
            :is="question.layout"
            :content="question"
            :opt-container-content="containerContent"
            @user-interaction-updated="handleUserAnswer"
            @simple-form-sent="handleUserAnswer"
          ></component>
          <div v-if="isCarousel" class="flickity-carousel-item">
            <div class="quiz-result d-flex justify-content-center align-items-center flex-column h-100">
              <template v-if="content.end_icon">
                <img v-if="content.end_icon.url" :src="expandUrl(content.end_icon.url)" :alt="content.end_icon.alt" class="quiz__logo pb-4"/>
                <i v-else :class="content.end_icon" class="text-warning pb-4"></i>
              </template>
              <h2 class="quiz__title text-center">{{ content.end_title || ft("page_live_event.versus.feedback_title")}}</h2>
              <p v-if="allVersus" class="text-uppercase small quiz__description">{{ ft("page_live_event.waiting_game.result", { ca: correctAnswers, tot: questions.length }) }}</p>
              <ol class="quiz-result__dots px-0 py-3 m-0">
                <li 
                  v-for="(q, index) in questions"
                  :key="`dot-${index}`"
                  class="dot"
                  :class="q.status"
                ></li>
              </ol>
              <p class="mb-0 pb-0 pt-3 quiz__description text-center" v-if="content.end_description" v-html="content.end_description"></p>
            </div>
          </div>
        </div>
        <ol v-if="slider && isCarousel" :class="{'d-none': slider.selectedIndex === questions.length}" class="flickity-page-dots custom-flickity-page-dots">
          <li 
            v-for="(q, index) in questions"
            :key="`dot-carousel-${index}`"
            class="dot"
            :class="[q.status, {'is-selected': slider.selectedIndex === index}]"
          ></li>
        </ol>
      </div>
    </div>
  </div> 
</template>

<script>
import Flickity from "flickity";
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { isInteractionDone, isAnswerCorrect, getUserInteractionAnswerId } from 'src/modules/interactions_module.js';
import { QUIZ_TIMEOUT_TO_NEXT_QUESTION, QUIZ_TYPE } from 'src/modules/utils/constants';
import { showQuiz, hideQuiz } from  'src/modules/player/animations';
import { differenceBy, debounce, isUndefined } from 'lodash-es';

const ANIMATION_TARGETS = [
  // versus element
  '.is-selected .question-card .question-title',
  '.is-selected .question-card .answer',
  // simple form element
  '.is-selected[simple-form-component].flickity-carousel-item [text-section-component]',
  '.is-selected[simple-form-component].flickity-carousel-item [generic-form-component] .form-group',
  '.is-selected[simple-form-component].flickity-carousel-item [generic-form-component] .row',
  '.is-selected[simple-form-component].flickity-carousel-item [generic-form-component] p', // textseparator
  '.is-selected[simple-form-component].flickity-carousel-item [generic-form-component] button',
];

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  props: {
    countdownAudio: {
      type: [Object, HTMLAudioElement],
      required: false
    }
  },
  data: function() {
    return {
      isQuizStarted: false,
      slider: null,
      countdown: null,
      emptyColor: undefined,
      fullColor: undefined
    }
  },
  mounted() {
    if (this.questions.length>0) {
      this.init();
      window.addEventListener('resize', debounce(() => {
        if (this.slider) {
          this.slider.reposition();
          this.slider.resize();
        }
      }, 500));
    }
  },
  methods: {
    expandUrl: Fandom.expandUrl,
    init() {
      if (this.isSimple) {
        // Force quiz to start immediately
        this.startQuiz();
      } else {
        const answeredQuestions = this.questions.reduce((acc, q) => {
          return acc + (q.isDoneByUser ? 1 : 0);
        }, 0);

        if (answeredQuestions > 0) {
          const index = answeredQuestions === this.questions.length ? answeredQuestions : this.questions.findIndex(q => !q.isDoneByUser);
          this.startQuiz(index);
        }
      }
    },
    startQuiz(initialIndex=0) {
      this.isQuizStarted = true;
      const slideConfig =  {
        draggable: false,
        initialIndex,
        prevNextButtons: false,
        pageDots: false,
        contain: true,
        cellAlign: "center",
        wrapAround: false,
        groupCells: false,
        adaptiveHeight: true
      }

      Vue.nextTick(() => {
        this.slider = new Flickity(this.$refs["flickity-carousel"], slideConfig);
        showQuiz(ANIMATION_TARGETS);
        this.$emit('slider-ready', this.slider);
      })

      // only one question active
      const activeQuestion = this.questions[0];
      if (!(activeQuestion?.votingClosed ?? true)) {
        this.countdown = activeQuestion.countdown ?? null;
      }
    },
    handleCountdownEnd(timeout = QUIZ_TIMEOUT_TO_NEXT_QUESTION) {
      const currentQuestion = this.questions[this.slider.selectedIndex];
      if (typeof currentQuestion === "undefined") {
        return;
      }
      Vue.set(currentQuestion, "valid_to", Date.now());
      if (this.slider.selectedIndex < this.slider.slides.length-1) {
        setTimeout(() => {
          const nextQuestion = this.questions[this.slider.selectedIndex+1];
          const hideAnimation = hideQuiz(ANIMATION_TARGETS);
          hideAnimation.finished.then(()=>{
            this.slider.next(false, true);
            this.slider.resize();
            showQuiz(ANIMATION_TARGETS);
          });
          hideAnimation.play();

          if (this.content.with_countdown && nextQuestion?.countdown) {
            this.resetCountdown(nextQuestion.countdown);
          }
        }, timeout);
      }
    },
    resetCountdown(newCountdown) {
      this.countdown = newCountdown;
      this.emptyColor = undefined;
      this.fullColor = undefined;
    },
    resetSlider() {
      this.isQuizStarted = false;
      this.slider?.destroy();
      this.slider = null;
    },
    handleUserAnswer(e) {
      if (this.countdown === null || this.countdown === 0) {
        const timeout = isUndefined(e) ? 0 : QUIZ_TIMEOUT_TO_NEXT_QUESTION;
        this.handleCountdownEnd(timeout);
      }
    },
    isVersusWithCorrect(question) {
      return question.interaction?.type === 'Versus' && question.has_correct_answer;
    }
  },
  computed: {
    validQuestions() {
      return (this.content?.questions?.filter(q => q.valid) ?? []);
    },
    quizType() {
      return this.validQuestions.length > 1 ? QUIZ_TYPE.CAROUSEL : QUIZ_TYPE.SIMPLE;
    },
    isCarousel() {
      return this.quizType === QUIZ_TYPE.CAROUSEL;
    },
    isSimple() {
      return this.quizType === QUIZ_TYPE.SIMPLE;
    },
    allVersus() {
      return this.questions.filter(this.isVersusWithCorrect).length === this.questions.length;
    },
    questions() {
      return this.validQuestions
        .map(q => {
          let votingClosed = false;
          if (q.valid_to && new Date() > new Date(q.valid_to)) {
            votingClosed = true;
          }

          let status = "no-status";
          const hasCorrect = q.has_correct_answer ?? false;
          const isDoneByUser = isInteractionDone(this.containerContent, q.interaction);
          if (isDoneByUser && hasCorrect) {
            const answerId = getUserInteractionAnswerId(this.containerContent, q.interaction);
            status = isAnswerCorrect(this.containerContent, q.interaction, answerId) ? "correct" : "wrong";
          }

          return {
            ...q,
            votingClosed,
            isDoneByUser,
            status
          };
        });
    },
    correctAnswers() {
      return this.questions.reduce((acc, q) => {
        return acc + (q.isDoneByUser && q.status === "correct" ? 1 : 0);
      }, 0);
    }
  },
  watch: {
    countdown(newVal) {
      if (newVal===3) {
        this.emptyColor = "#D82830";
        this.fullColor = "#A71929";
      }
    },
    questions(newVal, oldVal) {
      if (newVal.length>0 && differenceBy(newVal, oldVal, '$id').length > 0) {
        this.resetSlider();
        Vue.nextTick(()=>this.init());
      } else if (newVal.length===0) {
        this.resetSlider();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
[player-quiz-component] {
  .rounded {
    border-radius: $player-border-radius !important;
  }

  &.quiz {
    display: flex;
    flex-direction: column;

    .quiz-body {
      flex: 1;

      .quiz__logo {
        width: 7rem;
      }

      .quiz__title {
        color: #10111D;
      }

      .quiz__description {
        color: #7B7C83;
      }

      .placeholder {
        &-image {
          width: 9rem;
          height: 9rem;
          color: var(--gray);
          background: var(--gray-light);

          @include media-breakpoint-down(md) {
            width: 6rem;
            height: 6rem;

            i {
              font-size: 2em;
            }
          }

          i {
            opacity: 0.75;
          }
        }

        &-text {
          color: var(--gray);
          text-align: center;
        }
      }

      .flickity-carousel-item {
        width: 100%;

        ::v-deep .question-card {
          background-color: $white !important;

          .answer {
            border: 1px solid var(--player-grey) !important;
          }

          .question-title, .answer {
            opacity: 0;
          }
        }

        ::v-deep [generic-form-component] {
          form {
            padding-bottom: 1rem;

            textarea {
              resize: none;
            }

            .progress {
              display: none;
            }
          }
        }

        ::v-deep [form-feedback-component] {
          .text-success {
            display: none;
          }
        }

        .quiz-result {
          &__dots {
            list-style: none;
            line-height: 1;

            .dot {
              display: inline-block;
              width: .5rem;
              height: .5rem;
              margin: 0 .5rem;
              border-radius: 50%;
              background-color: $player-grey;

              &.correct {
                background-color: $correct-color;
              }

              &.wrong {
                background-color: $wrong-color;
              }
            }
          }
        }
      }

      ::v-deep .flickity-page-dots {
        position: relative;
        bottom: 0;

        .dot {
          pointer-events: none;

          &.correct {
            background-color: $correct-color;
            opacity: 1;
          }

          &.wrong {
            background-color: $wrong-color;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>