const PROVIDERS = {
  HTML5: 'html5',
  VIMEO: 'vimeo',
  YOUTUBE: 'youtube',
  IVS: 'ivs',
  SVP: 'svp'
}

const HTML5_STATUS = {
  PLAYING: 'playing',
  PAUSE: 'pause',
  ENDED: 'ended',
  ERROR: 'error',
  WAITING: 'waiting',
  LIVEEVENTENDED: 'liveeventended',
  LIVEEVENTSTARTED: 'liveeventstarted',
  LIVESTREAMOFFLINE: 'livestreamoffline',
  LIVESTREAMONLINE: 'livestreamonline',
  TIMEUPDATE: 'timeupdate',
  DURATIONCHANGE: 'durationchange',
  BUFFERSTART: 'bufferstart'
}

const PLAYER_STATUS = {
  BUFFERING: 'buffering',
  READY: 'ready',
  UNSTARTED: 'unstarted',
  PLAYING: 'playing',
  PAUSED: 'paused',
  ENDED: 'ended',
  DURATION_CHANGED: 'duration_changed',
  NOT_AVAILABLE: 'not_available',
  LIVESTREAMOFFLINE: 'livestreamoffline',
}

const PRESETS_TYPE = {
  DEFAULT: 'default',
  INTERACTION: 'interaction',
  LIVESHOPPING: 'live-shopping',
  LIVEEVENT: 'live-event',
  ONDEMAND_SHOPPING: 'ondemand-shopping',
}

const PIP_STATE = {
  ACTIVE: 'picture-in-picture',
  INACTIVE: 'inline',
};

const RATIO = {
  VERTICAL: '9by16',
  HORIZONTAL: '16by9',
}

const PLAYER_CONTENT = {
  EMPTY: 'empty',
  CHAPTERS: 'chapters',
  PRODUCTS: 'products',
  COMMENTS: 'comments',
  WISHLIST: 'wishlist',
  INFO: 'info',
  QUIZ: 'quiz'
}

const AUTO_QUALITY = { name: 'auto', id: 'auto' };

/**
 * PLAYER OVERVIDEO INTERACTIONS
 */
const INTERACTION_TYPES = {
  INTERACTION: 'interaction', 
  LIST_INTERACTIONS: 'list-interactions'
};

/**
 * PLAYER EMBEDDED
 */
const IFRAME_SOURCE = 'fandom-embed';
const LIBRARY_SOURCE = 'fandom-embed';

// Actions used in parent-iframe communication
const ACTIONS = {
  PLAYER: {
    READY: 'ready',
    CLOSE: 'close',
    BUY: 'buy',
    ADD_TO_WISHLIST: 'addToWishlist',
    REMOVE_FROM_WISHLIST: 'removeFromWishlist',
  },
  LIBRARY: {
    SET_USER: 'setUser'
  }
}

export {
  PROVIDERS,
  HTML5_STATUS,
  PLAYER_STATUS,
  PRESETS_TYPE,
  PIP_STATE,
  RATIO,
  PLAYER_CONTENT,
  AUTO_QUALITY,
  INTERACTION_TYPES,
  IFRAME_SOURCE,
  LIBRARY_SOURCE,
  ACTIONS
}