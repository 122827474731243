<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { commentsMixin } from 'src/modules/comments_module.js';
import { addPolling, removePolling } from 'src/modules/polling_module.js';
import { flow, get, difference, uniq, flattenDeep } from "lodash";

//
const retrieveUserIds = interactions => interactions.map(comments => comments.map(comment => comment.user_id));
const usersReducer = flow([retrieveUserIds, flattenDeep, uniq]);

export default {
  mixins: [fandomBasicMixin, commentsMixin],
  data() {
    return {
      // comment
      childComments: [],
      usersData: {},
      childIdToName: {},
      commentsLoaded: false,
      activeIndex: 0
    };
  },
  computed: {
    interactionComments() {
      return this.childComments.map(
        childComment => childComment.comments_info.last_comments.filter(comment => comment.approved !== false)
      ).filter(comments => comments.length > 0);
    },
    totalComments() {
      return this.interactionComments.map(comments => comments.length).reduce((length, total) => length + total, 0);
    },
    availableComments() {
      return this.commentsLoaded ? this.totalComments > 0 : false;
    }
  },
  methods: {
    setActive(index) {
      this.activeIndex = index;
    },
    getContentName(comments) {
      const id = comments[0].content_cache_id;
      return this.childIdToName[id];
    },
    getUserField(id, field) {
      return get(this.usersData, [id, field], "");
    },
    updateUsersData() {
      const usersToLoad = difference(usersReducer(this.interactionComments), Object.keys(this.usersData));
      if (usersToLoad.length > 0) {
        Fandom.ajax({
          method: "POST",
          url: "/api/v5/get_users_info_api",
          data: {
            users_ids: usersToLoad,
            extra_fields_to_keep: ["society"]
          },
          success: (data) => {
            data.forEach(user => {
              this.usersData[user.id] = user
            });
          }
        });
      }
    }
  },
  mounted() {
    this.content.children.forEach(child => {
      const content = this.pageModel.name_to_content[child];
      if (Fandom.exists(content.interaction)) {
        const comments = globalState.pageModel.name_to_content_user_info[content.name].id_to_interaction[content.interaction.id];
        this.childComments.push(comments);
        this.childIdToName[content.id] = content.name;
        this.updatePollingInfo(content.id, content.name, content.interaction.id, comments.comments_info.last_comment_datetime);
      }
    });
    if (this.childComments.length > 0) {
      addPolling("commentsPolling", () => {
        this.pollingNewComments("all", () => {
          this.updateUsersData();
          this.commentsLoaded = true;
        });
      });
    }
  }
}
</script>

<template>
  <div class="container pt-5 mh-75vh" v-easyadmin="easyadminId" :class="contentType" comments-admin-component>
    <div class="row">
      <div class="col-12">
        <h1>{{ft("comments_admin.title")}}</h1>
      </div>
      <div class="col-12" v-if="!availableComments">
        <h2>{{ft("comments_admin.no_comments")}}</h2>
      </div>
      <div class="col-12" v-else>
        <ul class="nav nav-tabs">
          <li class="nav-item" v-for="(comments, index) in interactionComments" :key="`tab-${index}`">
            <a class="nav-link" href="javascript:void(0)" :class="{'active': index === activeIndex}" @click="setActive(index)">
              {{ft("comments_admin.comments_content", {content: getContentName(comments)})}}
            </a>
          </li>
        </ul>
        <div
          class="_content w-100"
          v-easyadmin="getContentName(comments)"
          v-for="(comments, index) in interactionComments"
          :class="{'d-none': index !== activeIndex}"
          :key="`table-${index}`"
          >
          <table class="table border-top-0">
            <thead>
              <tr>
                <th scope="col" class="border-top-0">id</th>
                <th scope="col" class="border-top-0">{{ft("comments_admin.first_name")}}</th>
                <th scope="col" class="border-top-0">{{ft("comments_admin.last_name")}}</th>
                <th scope="col" class="border-top-0">{{ft("comments_admin.society")}}</th>
                <th scope="col" class="border-top-0">{{ft("comments_admin.text")}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(comment, commentIndex) in comments" :key="`comment-${index}-${commentIndex}`">
                <th scope="row">{{comment.id}}</th>
                <td>{{getUserField(comment.user_id, "first_name")}}</td>
                <td>{{getUserField(comment.user_id, "last_name")}}</td>
                <td>{{getUserField(comment.user_id, "society")}}</td>
                <td>{{comment.text}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
