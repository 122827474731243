<template>
  <div like-interaction-component class="like-interaction-container"></div>
</template>

<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { ActionCableComponentsMixin } from 'src/modules/websocket_module.js';
import { interactionMixin } from 'src/modules/interactions_module.js';
import { shuffle, throttle } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export default {
  mixins: [fandomBasicMixin, ActionCableComponentsMixin, interactionMixin],
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  data: function() {
    return {
      flows: ["flowOne", "flowTwo", "flowThree"],
      likes: this.value,
      likeSent: 0
    };
  },
  mounted() {
    if (this.cid === '') {
      this.cid = uuidv4();
    }
    this.initSubscriptions();
  },
  methods: {
    initSubscriptions() {
      this.subscribe(
        { channel: "PublicChannel", room: `${this.containerContent.name}|${this.content.interaction.id}`, lang: globalState.lang },
        {
          received: (receivedData) => {
            if (receivedData.cid !== this.cid) {
              Array.from({ length: (receivedData.counter || 1) }, () => {
               this.showHeart();
              });
            }
          }
        }
      );
    },
    sendLikeInteraction() {
      this.likeSent++;
      this.updateLikeTr();
    },
    updateLikeTr: throttle(
      function() {
        const params = {
          repeatable: this.content.repeatable || false,
          counter: this.likeSent
        }
        this.updateLikeInteraction(this.containerContent, this.content, this.content.interaction, params);
        this.likeSent = 0;
      }, 1000, {trailing: true}
    ),
    showHeart(fromUser=false) {
      const randomID = Math.floor((Math.random() * 100) + 1);
      const duration = (Math.random() * (1.6 - 1.2) + 1.2).toFixed(1);

      const classes = `heart part-${randomID} ${fromUser ? 'text-white' : ''}`;
      $(`<div class="${classes}"><i class="fa fa-heart"></i></div>`)
        .appendTo(".like-interaction-container")
        .css({
          animation: `${shuffle(this.flows)[0]} ${duration}s linear`
        });
      
      $(`.part-${randomID}`).show();
      setTimeout(function() {
        $(`.part-${randomID}`).remove();
      }, duration * 900)
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal > 0 && newVal > oldVal) {
        this.likes = newVal;
        this.sendLikeInteraction();
        this.showHeart(true);
      }
    }
  }
};
</script>

<style lang="scss">
[like-interaction-component] {
  pointer-events: none;
  width: 1.5rem;
  height: 40vh;
  position: absolute;
  bottom: 0;
  right: 0;

  .heart {
    opacity: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
    color: $player-red-color;
    font-size: 1.5rem;
  }

  @keyframes flowOne {
    0% {
      opacity: 0;
      bottom: 0;
      left: 14%
    }
    40% { opacity: .8; }
    50% {
      opacity: 1;
      left: 0;
    }
    60% { opacity: .2; }
    80% { bottom: 80%; }
    100% {
      opacity: 0;
      bottom: 100%;
      left: 18%
    }
  }

  @keyframes flowTwo {
    0% {
      opacity: 0;
      bottom: 0;
      left: 0;
    }
    40% { opacity: .8; }
    50% {
      opacity: 1;
      left: 11%
    }
    60% { opacity: .2; }
    80% { bottom: 60%; }
    100% {
      opacity: 0;
      bottom: 80%;
      left: 0;
    }
  }

  @keyframes flowThree {
    0% {
      opacity: 0;
      bottom: 0;
      left: 0;
    }
    40% { opacity: .8; }
    50% {
      opacity: 1;
      left: 30%
    }
    60% { opacity: .2; }
    80% { bottom: 70%; }
    100% {
      opacity: 0;
      bottom: 90%;
      left: 0;
    }
  }
}

</style>
