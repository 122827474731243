<template>
  <div class="player-progress">
    <div 
      class="player-progress-container d-flex"
      role="slider"
      aria-valuemin="0"
    >
      <span class="time pr-2">{{ (currentTime/TIME_INTERVAL) | toTime }}</span>
      <div ref="progressList" class="player-progress-list h-100 flex-grow-1" @click="seekTo">
        <vue-slider
          ref="slider"
          class="p-0 w-100"
          process-class="bg-primary"
          :speed="0.1"
          :height="4"
          :dot-size="12"
          :tooltip="false"
          v-model="currentTime"
          :max="duration*TIME_INTERVAL"
          :piecewise="true"
          :piecewise-filter="filterMarks"
          :stop-propagation="true"
          @drag-start="handleDragStart"
          @drag-end="handleDragEnd"
        >
          <template #piecewise="piecewiseProps">
            <component @go-to-mark="handleGoToMark" v-bind="getOptions(piecewiseProps)"></component>
          </template>
        </vue-slider>
      </div>
      <span class="time pl-2">{{ duration | toTime }}</span>
    </div>
  </div>
</template>

<script>
import { PLAYER_STATUS, INTERACTION_TYPES } from 'src/modules/player/constants';
import { toTime } from 'src/modules/utils/time';
import vueSlider from 'vue-slider-component';
import {MarkInteraction} from 'player';

const TIME_INTERVAL = 10;

export default {
  name: 'progress-bar',
  components: {
    vueSlider,
    MarkInteraction
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      required: true
    },
    status: {
      type: String,
      required: true,
      validator: (value) => {
        return Object.values(PLAYER_STATUS).includes(value.toLowerCase())
      }
    },
    marks: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      TIME_INTERVAL,
      currentTime: 0,
      intervalID: null
    }
  },
  mounted() {
    this.currentTime = parseInt(this.value*TIME_INTERVAL);
    const resizeObserver = new ResizeObserver(() => {
      // trigger resize event to update vue-slider width
      window.dispatchEvent(new Event('resize'));
    });
    resizeObserver.observe(this.$refs.progressList);

    if (this.status === PLAYER_STATUS.PLAYING) {
      this.startProgress();
    }
  },
  methods: {
    getOptions(piecewiseProps) {
      const mark = this.getMark(piecewiseProps);
      if (typeof mark === "undefined") {
        return {};
      }

      const isInteraction = Object.values(INTERACTION_TYPES).includes(mark.type);
      return {
        is: isInteraction ? "mark-interaction" : "span",
        value: mark,
        class: ['vue-slider-piecewise-dot',{'vue-slider-piecewise-dot-active': piecewiseProps.active}, {'default': !isInteraction}]
      }
    },
    getMark(piecewiseProps) {
      return this.marks.find(m => this.calcMarkSecond(m) === piecewiseProps.label);
    },
    filterMarks(mark) {
      return this.marks.findIndex(m => this.calcMarkSecond(m) == mark.index)!==-1;
    },
    calcMarkSecond(mark) {
      return parseInt((mark.seconds==="end" ? this.duration : mark.seconds)*TIME_INTERVAL);
    },
    startProgress() {
      if (this.intervalID) {
        clearInterval(this.intervalID);
      }

      this.intervalID = setInterval(() => {
        if (this.currentTime < this.duration*TIME_INTERVAL) {
          this.currentTime = Math.min(this.currentTime+1,this.duration*TIME_INTERVAL);
          this.$emit('input', this.currentTime/TIME_INTERVAL)
        }
      }, 1000/TIME_INTERVAL);
    },
    handleDragStart() {
      this.$emit("dragStart");
    },
    handleDragEnd() {
      this.$emit("seekTo", this.currentTime/TIME_INTERVAL);
    },
    handleGoToMark(mark) {
      this.$emit("seekTo", mark.seconds-0.2);
    },
    seekTo(event) {
      // Calc the estimeted time to seek the video
      const progressListEl = this.$refs.progressList;
      const seekBarClientRect = progressListEl.getBoundingClientRect();
      const seekBarWidth = parseFloat(seekBarClientRect.width);
      const newPosition = event.clientX - seekBarClientRect.left;
      const progress = (newPosition / seekBarWidth) * 100;
      
      this.$emit("seekTo", this.duration*progress/100);
    }
  },
  filters: {
    toTime
  },
  watch: {
    status(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      if (newVal === PLAYER_STATUS.PAUSED || newVal === PLAYER_STATUS.BUFFERING && this.intervalID>0) {
        clearInterval(this.intervalID);
      } else if (newVal === PLAYER_STATUS.PLAYING) {
        this.startProgress();
      }
    },
    value(newVal, oldVal) {
      if (parseInt(newVal) !== parseInt(oldVal)) {
        this.currentTime = Math.min(this.duration*TIME_INTERVAL, parseInt(newVal*TIME_INTERVAL));
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$main-class: "player-progress";

.#{$main-class} {
  position: absolute;
  bottom: var(--player-control-bar-height);
  width: 100%;

  &-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;
    background: transparent;

    .time {
      font-weight: 400;
      font-size: .65rem;
      line-height: 5px;
      mix-blend-mode: normal;
      opacity: .75;
      min-width: 43px;
      color: $white;
    }

    .#{$main-class}-list {
      position: relative;
      cursor: pointer;

      ::v-deep {
        .vue-slider {
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 2px;

          &-piecewise-dot {
            visibility: visible !important;
            border-radius: 2px;
            height: 8px;

            &.default {
              background: $white;
            }
          }
        }
      }
    }
  }
}
</style>