// Constants
const userActiveCheckerTime = 60000;
const sendFandomAnalyticsEventTime = 30000;
const userEvents = ["mouseup", "mousedown", "mousemove", "click", "scroll"];

// Variables
let isUserActive = true;
let forceKeepAlive = false;
let userActiveCheckerTimeout = null;
let sendFandomAnalyticsEventInterval = null;

function isPWA() {
  const isInWebAppiOS = (window.navigator.standalone === true);
  const isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches);
  return isInWebAppiOS || isInWebAppChrome;
}

function isGoogleGlobalSiteTag() {
  return window.fandom_analytics_type == "global_site_tag";
}

function isGoogleTagManager() {
  return window.fandom_analytics_type == "google_tag_manager";
}

function isGoogleAnalytics() {
  return window.fandom_analytics_type == "google_analytics";
}

function sendFandomAnalyticsEvent(category, action, label) {
  console.log("%cfandom analytics\n\tcategory: " + category + "\n\taction: " + action + "\n\tlabel: " + label + "\n\tpwa: " + isPWA(), "color: #aaaaaa");

  if (isGoogleGlobalSiteTag()) {
    const params = {
      "event_category": category,
      "event_label": label,
      "pwa": isPWA()
    }
    window.gtag("event", action, params);
  } else if (isGoogleTagManager()) {
    window.dataLayer.push({
      "event": "gaEvent",
      "eventCategory": category,
      "eventAction": action,
      "eventLabel": label
    });
  } else if (isGoogleAnalytics()) {
    window.ga("send", "event", category, action, label);
  }
}

// WARNING: invoke startCheckerInterval in document ready
function startCheckerInterval() {
  userEvents.forEach((event) => document.addEventListener(event, userDidAnAction));
  // Check if the user did an action in the last userActiveCheckerTime minutes
  userActiveCheckerTimeout = setTimeout(setUserAsInactive, userActiveCheckerTime);
  // Send an event each sendFandomAnalyticsEventTime if the user is active
  const sessionIsAlive = () => {
    if (isUserActive || forceKeepAlive) {
      sendFandomAnalyticsEvent("session_keep_alive", "user_action", "session_keep_alive");
    }
  };
  sendFandomAnalyticsEventInterval = setInterval(sessionIsAlive, sendFandomAnalyticsEventTime);
}

function userDidAnAction() {
  isUserActive = true;
  clearTimeout(userActiveCheckerTimeout);
  userActiveCheckerTimeout = setTimeout(setUserAsInactive, userActiveCheckerTime);
}

function setForceKeepAlive(value) {
  forceKeepAlive = value;
}

function setUserAsInactive() {
  isUserActive = false;
  clearTimeout(userActiveCheckerTimeout);
}

function sendCookieAccepted() {
  if (isGoogleTagManager()) {
    window.dataLayer.push({ event: "userCookieAccepted" });
  }
}

const fandomAnalyticsStartedMixin = {
  methods: {
    sendFandomAnalyticsEvent
  },
  mounted() {
    if (isGoogleTagManager()) {
      window.dataLayer.push({"event": "fandomInit"});
    }

    if (isGoogleGlobalSiteTag()) {
      window.gtag('event', 'pwa_event', {'pwa': isPWA() ? 'true' : 'false'});
    }
    startCheckerInterval();
  }
};

export {
  setForceKeepAlive,
  fandomAnalyticsStartedMixin,
  sendFandomAnalyticsEvent,
  sendCookieAccepted,
  isPWA
};
