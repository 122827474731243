const VISEMES_MAP = {
  'ready-player-me': {
    's': 'viseme_SS',
    'a': 'viseme_aa',
    'o': 'viseme_O',
    'sil': 'viseme_sil',
    'p': 'viseme_PP',
    'e': 'viseme_E',
    't': 'viseme_DD',
    'f': 'viseme_TH',
    'u': 'viseme_U',
    'i': 'viseme_I',
    'k': 'viseme_kk',
    'r': 'viseme_RR',
    // RHUBARB VISEMES MAPPINGS
    'A': "viseme_PP",
    'B': "viseme_kk",
    'C': "viseme_I",
    'D': "viseme_AA",
    'E': "viseme_O",
    'F': "viseme_U",
    'G': "viseme_FF",
    'H': "viseme_TH",
    'X': "viseme_sil"
  },
  'avatar-sdk': {
    's': 'SS',
    'a': 'aa',
    'o': 'oh',
    'sil': 'sil',
    'p': 'PP',
    'e': 'E',
    't': 'DD',
    'f': 'TH',
    'u': 'ou',
    'i': 'ih',
    'k': 'kk',
    'r': 'RR',
    // RHUBARB VISEMES MAPPINGS
    'A': "PP",
    'B': "kk",
    'C': "ih",
    'D': "aa",
    'E': "oh",
    'F': "ou",
    'G': "FF",
    'H': "TH",
    'X': "sil"
  }
}

export { VISEMES_MAP }