<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { SOCIAL_AUTH } from 'src/modules/utils/constants';

export default {
  mixins: [fandomBasicMixin],
  data: function() {
    return {
      SOCIAL_AUTH
    }
  },
  mounted() {
    Fandom.onEvent("interaction-unauthorized", (data) => {
      if (data.type == 'registration') {
        $(this.$refs["modal-authentication-required"]).modal("show");
      }
    });
  },
  methods: {
    hasSocialAuth(social) {
      return this.globalState.pageModel?.authentications?.[social] ?? false;
    }
  },
  computed: {
    hasAuth() {
      const auth = this.globalState.pageModel?.authentications ?? {};
      return Object.values(auth).filter(item => item).length;
    },
    facebookAuthUrl() {
      return this.applyContextToUrl(`/auth/facebook_${this.globalState.pageModel.tenant.id}`);
    },
    googleAuthUrl() {
      return this.applyContextToUrl(`/auth/google_oauth2_${this.globalState.pageModel.tenant.id}`);
    }
  }
}
</script>

<template>
  <div ref="modal-authentication-required" class="modal fade" tabindex="-1" role="dialog" modal-authentication-required-component>
    <div class="modal-dialog" role="document">
      <div class="modal-content rounded">
        <div class="modal-header border-bottom-0 pb-0">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center p-4">
          <h5 class="modal-title h2 mb-3">{{ft("globals.register.join_now")}}</h5>
          <p class="text-gray">{{ft("globals.register.join_now_desc")}}</p>

          <div v-if="hasAuth" class="my-4 d-flex flex-column align-items-center">
            <a v-if="hasSocialAuth(SOCIAL_AUTH.FACEBOOK)" :href="facebookAuthUrl" class="btn-facebook mb-3">
              <div class="btn-facebook__logo-container">
                <i class="fab fa-facebook"></i>
              </div>
              <div class="btn-facebook__text-container">
                <span>{{ ft("globals.register.connect_with_facebook") }}</span>
              </div>
            </a>
            <a v-if="hasSocialAuth(SOCIAL_AUTH.GOOGLE)" :href="googleAuthUrl" class="btn-google">
              <div class="btn-google__logo-container">
                <svg viewBox="0 0 46 46" class="google-svg-icon">
                  <path class="gs-1" d="M31.64 23.2c0-.63-.06-1.25-.16-1.84H23v3.48h4.84a4.14 4.14 0 0 1-1.8 2.72v2.26h2.92a8.78 8.78 0 0 0 2.68-6.62z"/>
                  <path class="gs-2" d="M23 32a8.6 8.6 0 0 0 5.96-2.18l-2.91-2.26a5.4 5.4 0 0 1-8.09-2.85h-3v2.33A9 9 0 0 0 23 32z"/>
                  <path class="gs-3" d="M17.96 24.71a5.41 5.41 0 0 1 0-3.42v-2.33h-3a9 9 0 0 0 0 8.08l3-2.33z"/>
                  <path class="gs-4" d="M23 17.58c1.32 0 2.5.45 3.44 1.35l2.58-2.59a9 9 0 0 0-14.06 2.61l3 2.34A5.36 5.36 0 0 1 23 17.58z"/>
                  <path d="M14 14h18v18H14V14z"/>
                </svg>
              </div>
              <div class="btn-google__text-container">
                <span>{{ ft("globals.register.connect_with_google") }}</span>
              </div>
            </a>
          </div>
          
          <div v-if="hasAuth" class="small text-gray">{{ ft("globals.register.or") }}</div>

          <a role="button" :href="`${applyContextToUrl('/users/sign_up')}`" class="rounded btn btn-dark my-2">{{ft("globals.login.sign_up")}}</a>
          <a role="button" :href="`${applyContextToUrl('/users/sign_in')}`" class="rounded btn btn-outline-dark my-2">{{ft("globals.login.login")}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[modal-authentication-required-component] {
  z-index: 1091;

  $google-red: #EA4335;
  $google-blue: #4285F4;
  $google-green: #34A853;
  $google-orange: #FBBC05;
  $facebook-blue: #1877F2;

  .text-gray {
    color: var(--gray);
  }

  .rounded {
    border-radius: $player-border-radius !important;
  }

  .modal-title {
    line-height: 2.6rem;
  }

  .modal-body {
    @include media-breakpoint-up(md) {
      width: 60%;
      margin: auto;
    }
  }

  .google-svg-icon {
    fill: none;
    fill-rule: evenodd;
  }

  .gs-1 {fill: $google-blue;}
  .gs-2 {fill: $google-green;}
  .gs-3 {fill: $google-orange;}
  .gs-4 {fill: $google-red;}

  .btn-google {
    color: rgba($black, .54);
    border: 1px solid #ccc;
    background-color: $white;

    .btn-google_logo-container {
      border-radius: .25rem;
    }

    .google-svg-icon {
      border-radius: .25rem;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $google-blue;
      border-color: $google-blue;
      color: $white;

      .btn-google__logo-container {
        background-color: $white;
      }

      .google-svg-icon {
        background-color: $white;
      }

      .gs-1 {
        fill: darken($google-blue, 25%);
      }
    }
  }

  .btn-facebook,
  .btn-google {
    padding-right: 0;
    width: 15.875rem;
    height: 2.5rem;
    font-family: Roboto, Arial, sans-serif;
    overflow: hidden;
    display: flex;
    border-radius: $player-border-radius !important;
    text-decoration: none !important;

    .btn-facebook__logo-container {
      height: 1.5rem;
      margin: .5rem;
      width: 1.5rem;
      display: block;
    }

    .btn-google__logo-container {
      &:hover {
        background-color: $white;
      }
      svg {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    .btn-facebook__text-container,
    .btn-google__text-container {
      width: 13.4375rem;
      padding: .5rem;
      font-size: 1rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .btn-facebook {
    background-color: $facebook-blue;
    border-color: $facebook-blue;

    &:hover,
    &:focus,
    &:active {
      fill: $facebook-blue;
    }
  }
}
</style>