<template>
  <div ai-speech class="position-relative d-flex justify-content-center align-items-center" :class="{'recording': isRecording}">
    <av-media 
      v-show="isRecording"
      class="canvas-container"
      :frequ-lnum="frequLnum"
      :frequ-line-cap="true"
      :line-width="lineWidth"
      :line-color="lineColor"
      :canv-width="canvWidth"
      :canv-height="canvHeight"
      :type="type"
      :radius="radius"
      frequ-direction="mo"
      :media="stream"
    />
    <div class="icon-container ml-3">
      <slot v-if="showMicrophone" name="icon-content" :toggle-record="toggleRecord" :is-recording="isRecording">
        <i v-if="showMicrophone && canRecord" @click="toggleRecord" class="fa fa-md fa-microphone" :class="isRecording?'is-recording fa-solid':'fa-thin'"></i>
      </slot>
    </div>
    <div v-if="isRecording && label" :class="labelClass" class="label text-uppercase font-weight-bold">{{label}}</div>
  </div>
</template>

<script>
import AvMedia from 'vue-audio-visual/src/components/AvMedia';
import anime from 'animejs/lib/anime.es.js';

const VOICE_MIN_DECIBELS = -40;
const VOICE_MAX_DECIBELS = 0;
const DELAY_BETWEEN_DIALOGS = 3000;

export default {
  components: {
    AvMedia
  },
  props: {
    immediate: {
      type: Boolean,
      default: false
    },
    showMicrophone: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: "frequ"
    },
    canvWidth: {
      type: Number
    },
    canvHeight: {
      type: Number
    },
    radius: {
      type: Number,
      default: 100
    },
    lineColor: {
      type: String,
      default: "black"
    },
    lineWidth: {
      type: Number,
      default: 0.5
    },
    frequLnum: {
      type: Number,
      default: 30
    },
    minDecibel: {
      type: Number,
      default: VOICE_MIN_DECIBELS
    },
    label: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: 'text-white'
    },
    audio: {
      type: Object,
      default: null
    },
    enableVoiceRecognition: {
      type: Boolean,
      default: true
    },
    startRecording: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      isRecording: false,
      chunks: [],
      stream: null,
      analisys: {
        time: null,
        startTime: undefined,
        lastDetectedTime: undefined,
        soundDetected: false,
        analyser: null
      },
      domainData: [],
      audioEl: null
    }
  },
  mounted() {
    this.initAudio();
    if (this.immediate) {
      this.toggleRecord();
    }

    $(document).on("keypress", this.handleSpacePress);
  },
  beforeDestroy() {
    $(document).off("keypress", this.handleSpacePress);
  },
  methods: {
    initAudio() {
      if (this.audio?.url) {
        this.audioEl = new Audio(this.audio.url);
        this.audio.volume = 0;
        this.audio.currentTime = 0;
        this.audio.volume = 1;
      }
    },
    resetAnalisys() {
      const time = new Date();
      this.analisys = {
        time,
        startTime: time.getTime(),
        lastDetectedTime: time.getTime(),
        soundDetected: false,
        analyser: null
      }
    },
    handleSpacePress(e) {
      if (e.charCode == 32 && this.isRecording) {
        this.toggleRecord();
      }
    },
    toggleRecord() {
      this.audioEl?.play();
      this.isRecording ? this.stopRecord() : this.startRecord();
    },
    startRecord() {
      navigator.mediaDevices
        .getUserMedia({audio: true})
        .then((stream) => {
          this.stream = stream;
          this.mediaRecorder = new MediaRecorder(this.stream);
          this.mediaRecorder.ondataavailable = e => this.chunks.push(e.data);
          this.mediaRecorder.onstop = () => this.handleStop();
          this.mediaRecorder.start();
          this.isRecording = true;
          this.animateIcon();
          if (this.enableVoiceRecognition) {
            this.startAnalisys();
          }
          this.$emit('start-recording');
        })
        .catch((err) => {
          console.error(`The following getUserMedia error occurred: ${err}`);
        });
    },
    stopRecord() {
      this.mediaRecorder.stop();
      this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
    },
    handleStop() {
      this.isRecording = false;
      const blob = new Blob(this.chunks, { type: "audio/mp4" });
      this.chunks = [];
      this.$emit('stop-recording', blob);
    },
    startAnalisys() {
      this.resetAnalisys();
      const audioContext = new AudioContext();
      const audioStreamSource = audioContext.createMediaStreamSource(this.stream);
      this.analisys.analyser = audioContext.createAnalyser();
      this.analisys.analyser.maxDecibels = VOICE_MAX_DECIBELS;
      this.analisys.analyser.minDecibels = this.minDecibel;
      audioStreamSource.connect(this.analisys.analyser);
      this.domainData = new Uint8Array(this.analisys.analyser.frequencyBinCount);

      window.requestAnimationFrame(() => this.detectSound());
    },
    detectSound() {
      if (!this.isRecording) {
        return;
      }

      this.analisys.time = new Date();
      const currentTime = this.analisys.time.getTime();
      
      // check if no sound detected from last detection time
      //console.log("%ccurrentTime - lastDetectedTime:", "background-color: cyan;", currentTime - this.analisys.lastDetectedTime);
      if (this.analisys.soundDetected && currentTime > this.analisys.lastDetectedTime + DELAY_BETWEEN_DIALOGS) {
        this.stopRecord();
        return;
      }
      
      //check for detection:
      this.analisys.analyser.getByteFrequencyData(this.domainData);
      for (let i = 0; i < this.analisys.analyser.frequencyBinCount; i++) {
        if (this.domainData[i] > 0) {
          this.analisys.soundDetected = true;
          this.analisys.time = new Date();
          this.analisys.lastDetectedTime = this.analisys.time.getTime();
        }
      }

      window.requestAnimationFrame(() => this.detectSound());
    },
    animateIcon() {
      anime.timeline({autoplay: true})
        .add({
          targets: '.fa-microphone.is-recording',
          opacity: [0, 1],
          easing: 'easeInOutCubic',
          duration: 600,
          begin: ()=> {
            anime({
              targets: '.fa-microphone.is-recording',
              color: ['rgba(233, 27, 59, 0)', 'rgba(233, 27, 59, 1)'],
              easing: 'easeOutCubic',
              duration: 900,
              loop: true,
              direction: 'alternate'
            });
          }
        })
    }
  },
  computed: {
    canRecord() {
      return navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
    }
  },
  watch: {
    startRecording(newVal, oldVal) {
      if (newVal!=oldVal) {
        this.toggleRecord();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
[ai-speech] {
  .icon-container {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    border-radius: 50%;
  }

  .fa-microphone {
    cursor: pointer;
    
    &.is-recording {
      -webkit-text-stroke: .5px $primary;
      color: transparent;
    }
  }
}
</style>