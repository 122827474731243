<template>
  <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
    <div
      v-if="currentOvervideoInteraction"
      :style="oiStyle"
      class="overvideo-interaction themed-item bg-cover-center d-flex align-items-center justify-content-center position-absolute-center"
    >
      <component
        class="w-100"
        :is="currentOvervideoInteraction.layout"
        :content="currentOvervideoInteraction"
        :opt-container-content="containerContent"
        :show-restart-btn="true"
        inherit-theme-type="dark"
        @interaction-done="handleInteractionDone"
        @interaction-handler="handleInteraction"
        @video-player-action-handler="handleInteraction"
      ></component>
    </div>
  </transition>
</template>

<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module';
import { isInteractionDone } from 'src/modules/interactions_module';
import { PLAYER_STATUS, INTERACTION_TYPES } from 'src/modules/player/constants';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  props: {
    interactions: {
      type: Array,
      required: true
    },
    time: {
      type: Number,
      required: true
    },
    status: {
      type: String,
      default: PLAYER_STATUS.PAUSED
    },
    interactionSuspenseAudio: {
      type: Object,
      required: false
    }
  },
  data: function() {
    return {
      currentOvervideoInteraction: null,
      suspenseAudio: null 
    }
  },
  beforeDestroy() {
    this.suspenseAudio?.pause();
  },
  methods: {
    initInteraction(interaction) {
      this.preloadAssets();

      this.currentOvervideoInteraction = interaction;
      if (this.currentOvervideoInteraction.prevent_pause) {
        const timeout = parseFloat(this.currentOvervideoInteraction.max_seconds_to_interact || 3) * 1000;
        setTimeout(()=>this.currentOvervideoInteraction=null, timeout);
      } else if (this.currentOvervideoInteraction.seconds === "end") {
        this.suspenseAudio?.play();
      } else {
        this.$emit('toggle-play');
        this.suspenseAudio?.play();

        if (this.currentOvervideoInteraction.type === INTERACTION_TYPES.INTERACTION) {
          if (isInteractionDone(this.containerContent, this.currentOvervideoInteraction.interaction)) {
            setTimeout(this.handleInteractionDone, 3000);
          }
        }
      }
    },
    preloadAssets() {
      if (this.interactionSuspenseAudio?.url && this.suspenseAudio===null) {
        this.suspenseAudio = new Audio(this.interactionSuspenseAudio.url);
        this.suspenseAudio.loop = true;
        this.suspenseAudio.play();
        this.suspenseAudio.pause();
      }
    },
    handleInteractionDone() {
      if (this.currentOvervideoInteraction.seconds != "end" || !this.currentOvervideoInteraction.seconds) {
        this.$emit('interaction-done', {
          seconds: !this.currentOvervideoInteraction.prevent_pause ? parseInt(this.currentOvervideoInteraction.seconds)+0.1 : null,
          togglePlay: !this.currentOvervideoInteraction.prevent_pause
        });
        this.suspenseAudio?.pause();
        this.currentOvervideoInteraction = null;
      }
    },
    handleInteraction(data) {
      if (["reset", "restart", "seekTo"].includes(data.action)) {
        this.suspenseAudio?.pause();
        const togglePlay = !this.currentOvervideoInteraction.prevent_pause;
        const seconds = data.action === "seekTo" ? data.value : 0;

        this.currentOvervideoInteraction = null;
        this.$emit('interaction-done', {seconds, togglePlay});
      }
    },
    parseFloat(str,val) {
      str = str.toString();
      str = str.slice(0, (str.indexOf(".")) + val + 1); 
      return Number(str);   
    }
  },
  computed: {
    oiStyle() {
      if (this.currentOvervideoInteraction) {
        const textColor = this.currentOvervideoInteraction.text_color ??  "var(--light)";
        return {
          "background-image": this.getContentResponsiveImageURL('background_image', this.currentOvervideoInteraction),
          "--theme-background": this.currentOvervideoInteraction.background_color ??  "var(--dark)",
          "--theme-color": textColor,
          "--theme-border-color": this.currentOvervideoInteraction.border_color ?? textColor
        };
      }

      return {};
    }
  },
  watch: {
    time(newVal) {
      if (newVal && this.interactions.length>0 && this.status === PLAYER_STATUS.PLAYING) {
        const oi = this.interactions.find(i => this.parseFloat(newVal,1) == i.seconds);
        typeof oi !== 'undefined' && this.initInteraction(oi);
      }
    },
    status(newVal) {
      if (newVal === PLAYER_STATUS.ENDED) {
        const endOI = this.interactions.slice(-1)[0] ?? {};
        if (endOI?.seconds === "end") {
          this.initInteraction(endOI);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>