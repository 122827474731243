<template>
  <div>
    <div :id="stripeSnippetId" stripe-v3-snippet></div>
    <form @submit.prevent="handleSubmit" class="payment-form">
      <div class="payment-form-content p-3">
        <div class="h4 text-center">{{ft('ecommerce.amount_recap')}} €{{amount}}</div>
        <div class="form-group">
          <label for="email">{{ft('ecommerce.email')}}</label>
          <input type="email" pattern=".+@.+\..+" class="StripeElement w-100" placeholder="jenny.rosen@example.com" v-model="billingDetails.email" required>
        </div>
        <div class="form-group">
          <label for="name">{{ft('ecommerce.name')}}</label>
          <input type="text" class="StripeElement w-100" placeholder="Jenny Rosen" v-model="billingDetails.name" required>
        </div>

        <div class="d-flex justify-content-between" v-if="canManageContents">
          <div>
            <a href="#" data-toggle="modal" data-target="#pay-demo"><i class="fal fa-question"></i></a>
          </div>
        </div>

        <div id="payment-element" class="mb-4">
          <!--Stripe.js injects the Payment Element-->
        </div>

        <button class="purchase-btn btn btn-primary mt-3" :disabled="loading">
          <i v-show="loading" class="fa-spin fal fa-circle-notch mr-2"></i>
          <span>{{ft('ecommerce.purchase')}}</span>
        </button>

        <button @click.prevent="$emit('payment-stripe:back')" class="purchase-btn btn-light text-black btn btn-secondary mt-3" :disabled="loading">
          <span>{{ft('ecommerce.button.back')}}</span>
        </button>
      </div>
    </form>
    <div class="modal fade" id="pay-demo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">DEMO</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-4">4242424242424242</div>
              <div class="col-8">Succeeds and immediately processes the payment.</div>
            </div>
            <div class="row">
              <div class="col-4">4000002500003155</div>
              <div class="col-8">Requires authentication. Stripe will trigger a modal asking for the customer to authenticate.</div>
            </div>
            <div class="row">
              <div class="col-4">4000000000009995</div>
              <div class="col-8">Always fails with a decline code of insufficient_funds.</div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import stripeMixin from 'src/modules/stripe_module.js';
import postscribe from "postscribe";

export default {
  mixins: [fandomBasicMixin, stripeMixin],
  props: {
    amount: {
      type: [String, Number],
      required: true
    },
    productName: {
      type: String,
      required: true
    },
    redirectUrl: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      stripeSnippetId: "stripe-v3",
      elements: null,
      stripe: null,
      clientSecret: null,
      currency: null,
      extraBillingDetails: {},
      billingDetails: {
        name: "",
        email: ""
      },
      loading: false,
      errorText: "",
      paymentUUID: null
    };
  },
  mounted() {
    postscribe(`#${this.stripeSnippetId}`, "<script src=\"https:\/\/js.stripe.com\/v3\/\"><\/script>", {
      done: this.init
    });
  },
  methods: {
    init() {
      const publicKey = this.getBusinessNamePublicKey();
      this.stripe = Stripe(publicKey);
      this.paymentIntent();
    },
    paymentIntent(update=false, async=true) {
      this.loading = true;
      Fandom.ajax({
        url: "/api/v5/stripe/create_payment_intent",
        method: "GET",
        async,
        data: {
          purchased_content: this.productName,
          amount: this.amount,
          extra_billing_details: this.extraBillingDetails,
          receipt_email: this.billingDetails.email,
          receipt_name: this.billingDetails.name,
        },
        success: (data) => {
          if (!update) {
            this.setupElements(data);
          }
          this.paymentUUID = data.paymentUUID;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      });
    },
    handleSubmit() {
      Fandom.emit("show-loader");
      // Needed to show loader
      setTimeout(()=>{
        this.paymentIntent(true, false);
        this.confirmPayment();
      },100)
    },
    async confirmPayment() {
      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: `${window.location.origin}${this.redirectUrl.url}?payment_uuid=${this.paymentUUID}`,
          payment_method_data: {
            billing_details: this.billingDetails
          }
        }
      });

      this.loading = false;
      Fandom.emit("hide-loader");
      this.errorText = error.type === "card_error" || error.type === "validation_error" ? error.message : this.ft(`ecommerce.modal_error.default_message`);
      this.$emit('payment-stripe:error', {error: this.errorText});
    },
    setupElements(data) {
      this.currency = data.currency;
      this.clientSecret = data.clientSecret;

      const appearance = {
        theme: 'stripe',
        variables: {
          colorPrimary: '#32325d',
          colorBackground: '#ffffff',
          colorText: '#30313d',
          colorTextPlaceholder: '#aab7c4',
          colorDanger: '#df1b41',
          fontFamily: 'Mabry, sans-serif',
          spacingUnit: '4px',
          spacingGridRow: '16px',
          borderRadius: '4px',
          fontSizeBase: '18px',
        },
        rules: {
          '.Input': {
            borderColor: 'rgb(118, 118, 118)'
          },
          '.Label': {
            fontSize: '18px',
            fontWeight: '300',
            color: '#000'
          }
        }
      };
      
      this.elements = this.stripe.elements({ appearance, clientSecret: this.clientSecret });
      const paymentElement = this.elements.create("payment");
      paymentElement.on('ready', event => {
        this.$emit('payment-stripe:ready');
      });
      paymentElement.mount("#payment-element");
    }
  },
  computed: {
    canManageContents() {
      return this.globalState.allowedToSeeEasyadminShortcuts;
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-form {
  align-self: center;

  &-content {
    background-color: var(--theme-background);
    color: var(--theme-color);
    border: 2px solid $black;
  }

  label {
    margin-bottom: 0;
    -webkit-font-smoothing: antialiased;
  }
  
  input {
    line-height: 20px;
    padding: 12px
  }

  .amount-input {
    flex: 1;
  }

  .amount-preselection {
    &-container {
      flex-wrap: wrap;
      gap: 10px;

      .btn-amount-preselection {
        flex: 1;
        min-width: 70px;
      }
    }
  }

  .purchase-btn {
    color: #ffffff;
    border-radius: 4px;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;

    &:hover {
      filter: contrast(115%);
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
</style>