<script>
export default {
  data() {
    return {
      content: {},
      sender: null
    }
  },
  mounted() {
    Vue.nextTick(() => {
      Fandom.onEvent('show-confirm-interaction-vote-list', (data) => {
        Vue.set(this, 'content', data.content);
        Vue.set(this, 'sender', data.sender);
        $("#modal-confirm-interaction-vote-list").modal("show");
      });
    });
  },
  methods: {
    emitConfirmation() {
      Fandom.emit('confirm-interaction-vote-list', this.sender);
      $("#modal-confirm-interaction-vote-list").modal("hide");
    },
    closeModal() {
      $("#modal-confirm-interaction-vote-list").modal("hide");
    }
  }
}
</script>

<template>
  <div id="modal-confirm-interaction-vote-list" class="modal fade" tabindex="-1" role="dialog" modal-confirm-interaction-vote-list-component>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body py-0 px-1">
          <div class="d-flex flex-column justify-content-center align-items-center text-center py-5 px-3">
            <h3 v-if="content.title">{{content.title}}</h3>
            <p class="w-100" v-if="content.description">{{content.description}}</p>
            <div class="d-flex justify-content-around align-items-center flex-wrap">
              <button class="btn mx-2 mb-2" @click="emitConfirmation()" :class="content.confirm_button_class || 'btn-primary'">{{content.confirm_button_text}}</button>
              <button class="btn mx-2 mb-2" @click="closeModal()" :class="content.discard_button_class || 'btn-outline-dark'">{{content.discard_button_text}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>