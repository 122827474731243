<script>
import { get } from "lodash";
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  computed: {
    paddingY() {
      const paddingY = get(this, ["content", "padding_y"], 4);
      return [
        `py-${paddingY}`
      ];
    }
  }
};
</script>

<template>
  <div 
    v-easyadmin="easyadminId"
    :id="content.stripe_id"
    v-if="isContentVisibleToUser()"
    :class="[contentType]"
    :style="theme"
    class="themed-item"
    list-keypoints-v3-component
  >
    <div class="container" :class="!(content.padding_y || content.padding_top || content.padding_bottom) ? 'inset py-4' : paddingY">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <stripe-header :content="content" align="left" class="px-0 mb-3"></stripe-header>
          <table class="w-100" border="0">
            <tr v-for="(keypoint, index) in content.keypoints" :key="`tr-${index}`">
              <td class="number-cell">
                <div class="number position-relative pt-2 text-right">
                  {{keypoint.number}}
                  <div class="dot themed-item"></div>
                </div>
              </td>
              <td class="divider"></td>
              <td class="description-cell">
                <div class="h4 pl-4 ml-2 mb-0 font-weight-normal" v-if="keypoint.title">
                  {{keypoint.title}}
                </div>
                <p v-html="keypoint.description" class="text-black pl-4 ml-2 description" v-if="keypoint.description"></p>
              </td>
            </tr>
          </table>
          <div class="disclaimer mt-4" v-html="content.disclaimer"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[list-keypoints-v3-component] {
  
  .py-large {
    padding-top: 5rem;
    padding-bottom: 5rem;

    @include media-breakpoint-down(sm) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  .divider {
    width: 2px;
    background-color: $red;
  }

  td {
    border: none;
  }

  .number-cell {
    vertical-align: baseline;
  }

  .description-cell {
    padding-top: 1rem;
    width: 100%;
    max-width: 46.875rem;
  }

  .dot {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    right: -10px;
    background-color: var(--background-color);
    position: absolute;
    top: 1rem;
    border: 2px solid $red;
  }


  .number {
    font-size: 1.25rem;
    padding-right: 3rem;
    line-height: 2.25rem;
    font-weight: 700;
    white-space: nowrap;
  }

  .description {
    font-size: 14px;
  }

  .disclaimer {
    font-size: $font-size-xs;
  }
}
</style>
