<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { interactionMixin } from "src/modules/interactions_module.js";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  methods: {
    downloadInteractionHandler(item) {
      let attachment = item.attachment_column;
      this.updateDownloadInteraction(this.containerContent, this.content, item.interaction, null, attachment.url);
    }
  }
};
</script>

<template>
  <div class="row mx-0 justify-content-center bg-light py-4" course-download-additional-resources-component>
    <div class="col-12 col-lg-8">
      <h3 class="h2" v-if="content.additional_resources.title">{{content.additional_resources.title}}</h3>
      <div class="table-responsive">
        <table class="table table-bordered mb-5">
          <tbody>
            <tr v-for="item in content.additional_resources.table">
              <td v-if="item.text_column_1" class="p-4 cell" v-html="item.text_column_1"></td>
              <td v-if="item.text_column_2" class="p-4 cell" v-html="item.text_column_2"></td>
              <td v-if="item.attachment_column" align="middle" class="p-4 attachment-column cell">
                <component
                  :is="item.interaction ? 'span' : 'a'"
                  :href="item.interaction ? 'javascript:void(0)' : item.attachment_column.url"
                  :target="item.interaction ? '_self' :  '_blank'"
                  class="cursor-pointer text-dark"
                  @click="item.interaction ? downloadInteractionHandler(item) : null"
                >
                  <i class="fal fa-download"></i>
                </component>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
	</div>
</template>

<style lang="scss">
</style>