<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  props: {
    successMessage: String,
    errorMessage: String,
    success: Boolean,
    errors: Array
  }
};
</script>

<template>
  <div form-feedback-component>
    <div class="alert alert-success" role="alert" v-if="success && successMessage">
      {{successMessage}}
    </div>
    <div class="alert alert-danger" role="alert" v-if="!success && errorMessage">
      {{errorMessage}}
    </div>
    <div class="alert alert-danger" role="alert" v-if="errors && errors.length > 0">
      <p>{{ft("globals.form.errors_compiling_module")}}</p>
      <ul class="feedback-error-list mb-0">
        <li v-for="message in errors" v-html="message"></li>
      </ul>
    </div>
  </div>
</template>