<template>
  <div :class="{'mt-2': refComment && refComment.id}" comment-add-component>
    <div class="form-container">
      <div class="d-flex">
        <div ref="emojiPickerWrapper" class="emoji-picker-wrapper" v-show="showEmojiPicker"></div>
        <div v-if="showEmoji" class="emoji-button position-relative d-none d-md-block">
          <button @click.stop="toggleEmojiPicker" class="btn emoji d-flex align-items-center justify-content-start w-100">
            <i class="fal fa-smile"></i>
          </button>
        </div>
        <form @submit.prevent="tryToAddComment" class="position-relative" :class="{'active': focused}">
          <div v-if="refComment" class="small reply-to-comment d-flex align-items-center justify-content-between">
            <div class="reply-content">
              <span class="font-weight-bold">{{ refComment.username }}</span>
              <span>{{ refComment.text }}</span>
            </div>
            <i class="fal fa-times mx-1 cursor-pointer" @click="$emit('clear-reply-to')"></i>
          </div>
          <textarea
            ref="textarea"
            v-model="text"
            @focus="focused=true"
            @blur="focused=false"
            @keyup="resizeArea"
            @keydown="commentAdded=false" 
            @keydown.enter.prevent="tryToAddComment"
            :class="textAreaClass"
            class="w-100 p-2 new-comment-area"
            :placeholder="ft('comments.add_comment.placeholder')"
          />
          <button
            type="submit"
            class="btn mx-2 d-flex align-items-center justify-content-center"
            :disabled="commentIsLoading || !text">
              <i :class="submitIconClass" class="mr-1"></i>
          </button>
        </form>
      </div>
      <p v-if="showFeedback && commentAdded"><small class="text-muted">{{ft('comments.add_comment.feedback')}}</small></p>
    </div>
  </div>
</template>

<script>
import { updateContentUserInfo } from 'src/modules/interactions_module.js';
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { sendFandomAnalyticsEvent }  from 'src/modules/fandom_analytics_module.js';
import { browser } from 'src/modules/player/utils';
import { generateToken } from 'src/modules/recaptcha_v3_module.js';

import 'emoji-mart/dist/browser.js';

export default {
  mixins: [fandomBasicMixin],
  props: {
    refComment: {
      type: Object,
      default: () => {
        return null
      }
    },
    textAreaClass: {
      type: [Array, Object, String],
      required: false,
      default: "border-0"
    },
    showSubmitButton: {
      type: Boolean,
      default: true
    },
    submitIconClass: {
      type: String,
      default: "fal fa-paper-plane"
    },
    showFeedback: {
      type: Boolean,
      default: true
    },
    showEmoji: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      text: "",
      commentIsLoading: false,
      commentAdded: false,
      focused: false,
      emojiPicker: null,
      perLine: 9,
      showEmojiPicker: false
    };
  },
  mounted() {
    this.setDefaultText();
    if (this.showEmoji) {
      this.initEmojiPicker();
    }
  },
  methods: {
    initEmojiPicker() {
      this.emojiPicker = new EmojiMart.Picker({ 
        perLine: this.perLine,
        previewPosition: 'none',
        onEmojiSelect: (data) => this.text+=data.native,
        onClickOutside: () => this.showEmojiPicker ? this.toggleEmojiPicker() : ''
      })
      this.$refs.emojiPickerWrapper.appendChild(this.emojiPicker);

      const resizeObserver = new ResizeObserver((entries) => {
        const el = entries[0];
        if (!el || typeof this.emojiPicker.component === "undefined") {
          return;
        }

        const width = el.contentRect.width;
        if (width>200 && width<250) {
          this.perLine = 5;
        } else if (width>250 && width<300) {
          this.perLine = 7;
        } else if (width>300) {
          this.perLine = 9;
        }

        if (this.emojiPicker.component.props.perLine !== this.perLine) {
          this.emojiPicker.component.props.perLine = this.perLine;
          this.emojiPicker.component.forceUpdate();
        }
      });
      resizeObserver.observe($('.form-container')[0]);
    },
    setDefaultText() {
      this.text = "";
      Vue.nextTick(() => this.resizeArea())
    },
    tryToAddComment() {
      this.commentAdded = false;
      if (!this.commentIsLoading && this.text) {
        if (Fandom.isUserRegistered()) {
          this.addComment();
        } else {
          if (globalState.pageModel.aux.recaptcha_enterprise) {
            generateToken((token) => {
              this.addComment(token);
            }, "comment")
          } else {
            console.warn("recaptcha_enterprise has not been configured. Required by anonymous navigation.");
          }
        }
      } else {
        console.warn("Action stopped. Submit in progress or the text is empty.");
      }
    },
    addComment(token = null) {
      this.commentIsLoading = true;
      const data = {
        content_cache_id: this.containerContent.id,
        interaction_id: this.content.interaction.id,
        text: this.text,
        recaptcha_enterprise_token: token
      }

      if (this.refComment && this.refComment.id) {
        data.ref_comment_id = this.refComment.id;
      }

      Fandom.ajax({
        url: "/api/v5/comments/add",
        data: data,
        success: data => {
          this.commentIsLoading = false;
          if (data.errors) {
            alert(data.errors);
          } else {
            this.setDefaultText();
            this.commentAdded = true;
            
            // global event
            Fandom.emit("comment-added", {
              content_cache_id: this.containerContent.id,
              interaction_id: this.content.interaction.id
            });
            // local event
            this.$emit("collapse");
            this.$emit('clear-reply-to');

            updateContentUserInfo(this.containerContent, this.content.interaction, data);
            sendFandomAnalyticsEvent("Comment", "Send", `content_${this.containerContent.id}_interaction_${this.content.interaction.id}`);
          }
        }
      });
    },
    resizeArea() {
      if (!(this.isMobile && browser.isTouch)) {
        const textarea = this.$refs.textarea;
        textarea.style.height = "var(--comment-area-height)";
        textarea.style.height = `${textarea.scrollHeight || 40}px`;
      }
    },
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    }
  },
  computed: {
    isMobile() {
      return globalState.viewport.sm || globalState.viewport.xs;
    }
  }
}
</script>

<style lang="scss" scoped>
[comment-add-component] {
  --comment-area-height: 2.5rem;

  .emoji-picker-wrapper {
    position: absolute;
    bottom: calc(2rem + 60px);;
    z-index: 1;
  }

  .emoji-button {
    width: 35px;

    .btn {
      box-shadow: none;

      i {
        font-size: 1.3rem;
      }
    }
  }

  .reply-content {
    word-break: break-all;
  }

  .new-comment-area {
    color: var(--theme-color);
    overflow: auto;
    outline: none;
    background: rgba(238, 238, 238, 0.6);
    backdrop-filter: blur(10px);
    border-radius: $font-size-sm;
    font-size: $font-size-sm;
    padding-right: 2.5rem !important;
    height: var(--comment-area-height);
    resize: none;

    &::placeholder {
      color: var(--theme-color) !important;
    }
  }

  .btn {
    position: absolute;
    right: 0;
    bottom: .375rem;
    padding: 0;
    border: 0;

    i {
      color: var(--theme-color);
      height: 2.5rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
