<template>
  <transition enter-active-class="animated fadeInDown faster" leave-active-class="animated fadeOutUp faster">
    <interaction-feedback-alert v-if="active" :content="content" :opt-container-content="containerContent" :interaction="content.interaction"></interaction-feedback-alert>
  </transition>
</template>

<script>
import interactionFeedbackAlert from './interaction-feedback-alert';

export default {
  components: { interactionFeedbackAlert },
  data: function() {
    return {
      content: null,
      containerContent: null,
      active: false
    };
  },
  mounted() {
    Fandom.onEvent("interaction-feedback-phase", (data) => {
      if (["Trivia", "Versus", "Vote"].indexOf(data.content.interaction.type) >= 0) {
        this.content = data.content;
        this.containerContent = data.containerContent;
        this.active = data.value;
      }
    });
  }
};
</script>