import VueCountdown from '@chenfengyuan/vue-countdown';
import moment from 'moment';
import { get } from 'lodash-es';

export default {
  components: {
    VueCountdown
  },
  data: function() {
    return {
      countdownEnded: false
    }
  },
  methods: {
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        props[key] = value < 10 ? `0${value}` : value;
      });

      return props;
    },
    hasActiveFlashsale(product) {
      return get(product, "flashsale_start", false) && 
              get(product, "flashsale_end", false) && 
              moment().isBetween(product.flashsale_start, product.flashsale_end)
    },
    flashsaleDuration(product) {
      const now = moment();
      const startDate = moment(product.flashsale_start);
      const toSub = startDate.isAfter(now) ? startDate : now;
      return moment(product.flashsale_end).diff(toSub);
    }
  }
}