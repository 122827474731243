<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import Intersect from 'vue-intersect';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  components: { Intersect },
  data() {
    return {
      showBanner: false
    }
  },
  methods: {
    enterHandler(el) {
      const bannerHeight = $(el).outerHeight();
      $("body").css("padding-bottom", `${bannerHeight}px`);
    },
    leaveHander(el) {
      // Warning: default body padding bottom must be 0 for the code to be correct. 
      // Otherwise it is necessary to restore the previous one.
      $("body").css("padding-bottom", `0px`);
    }
  },
  computed: {}
};
</script>

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentType" stripe-event-heading-component>
    <intersect @leave="showBanner = true" @enter="showBanner = false">
      <stripe-one-col-media :button-ids="true" :content="content" :opt-container-content="containerContent"/>
    </intersect>
    <transition enter-active-class="animated slideInUp" leave-active-class="animated slideOutDown" @after-enter="enterHandler($event)" @after-leave="leaveHander($event)">
      <stripe-button v-if="showBanner" :content="content" :opt-container-content="optContainerContent"/>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
[stripe-event-heading-component] {
  ::v-deep [stripe-button-component] {
    z-index: $zindex-sticky - 1;
  }
}
</style>