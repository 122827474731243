<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { videoBasicMixin, videoInteractiveMixin } from "src/modules/kaltura_module.js";

export default {
  mixins: [fandomBasicMixin, videoBasicMixin, videoInteractiveMixin],
  data: function () {
    return {
      videoContent: this.content.play
    };
  },
  watch: {
    videoPlayer: {
      handler(newValue, previousValue) {
        this.$emit("video-player-handler", newValue);
      },
      deep: true
    },
    overvideoInteraction(newValue, previousValue) {
      this.$emit("overvideo-interaction-handler", newValue);
    }
  },
  methods: {
    unsetOvervideoInteraction() {
      this.overvideoInteraction = null;
    }
  }
};
</script>

<template>
	<div embed-kaltura-component>
		<div :id="videoContentId"></div>
	</div>
</template>

<style lang="scss" scoped>
</style>