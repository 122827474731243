import { showSidebarDetail, back, ANIMATION_STATUS } from 'src/modules/player/animations';
import { PLAYER_CONTENT } from 'src/modules/player/constants';

export default {
  data: function() {
    return {
      animationStatus: ANIMATION_STATUS.NOT_STARTED,
      navigationLevel: 0,
    }
  },
  methods: {
    handleGoToDetails(contentName) {
      this.activeDetailContentName = contentName;
      Vue.nextTick(() => {
        this.animationStatus=ANIMATION_STATUS.STARTED;
        const animation = showSidebarDetail('.right-container__content', '.right-container__detail');
        animation.finished.then(() => {this.animationStatus=ANIMATION_STATUS.FINISHED; this.navigationLevel=2;});
        animation.play();
      })
    },
    handleBack(immediate=false) {
      let animation = back('.right-container__detail', '.right-container__content');
      animation.finished.then(() => {
        this.activeDetailContentName=null;
      });
      immediate ? animation.seek(animation.duration) : animation.play();
      this.navigationLevel=1;
    },
    checkNavigation(newContent) {
      if (newContent === PLAYER_CONTENT.EMPTY) {
        this.handleBack(true);
        this.navigationLevel = 0;
      } else if (this.navigationLevel === 2) {
        this.handleBack();
        this.navigationLevel = 1;
      } else {
        this.navigationLevel = 1;
      }
    },
    checkDetailNavigation(newContent) {
      if (this.navigationLevel===1 && this.activeDetailContentName !== newContent) {
        this.handleGoToDetails(newContent);
      }
    }
  }
}