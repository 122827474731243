<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import Flickity from "flickity-fade";
import { get, merge } from "lodash";
import Intersect from 'vue-intersect';


export default {
  mixins: [fandomBasicMixin],
  components: {
  	Intersect
  },
  data() {
    return {
    	currentSlide: null,
    	renderComponent: true
    };
  },
  computed: {
  	anchorId() {
    	return `anchor-${this.easyadminId.replace("|", "_")}`
    }
  },
  methods: {
    scrollToBottom: Fandom.scrollToBottom,
    getContentURL: Fandom.getContentURL,
    merge: merge,
    // hack to force re-render of a component and permit initial interection observe
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
  },
  mounted() {

    Vue.nextTick(() => {
      const config = {
        draggable: ">1",
        prevNextButtons: false,
        wrapAround: true,
        pageDots: ">1",
        contain: true,
        cellAlign: 'left',
        autoPlay: 3000 // 3s
      }

      const slider = new Flickity(this.$refs["flickity-carousel"], config);

      slider.on("resize", () => {
        $(this.$refs["flickity-carousel"]).toggleClass('is-single-slide', (slider.slides.length < 2));
      });
      $(this.$refs["flickity-carousel"]).toggleClass('is-single-slide', (slider.slides.length < 2));

      slider.on("dragStart", (event) => {
        event.preventDefault();
        $(this.$refs["flickity-carousel"]).find('.flickity-carousel-item').css('pointer-events', 'none');
      });

      slider.on("dragEnd", (event) => {
        event.preventDefault();
        $(this.$refs["flickity-carousel"]).find('.flickity-carousel-item').css('pointer-events', 'all');
      });

      this.forceRerender();

    });
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" :id="content.name" class="inview_animate" stripe-carousel-fullscreen-component>
    <div class="flickity-carousel" ref="flickity-carousel">
      <div class="flickity-carousel-item w-100" v-for="(item, index) in content.children" :child_name="item" :key="`carousel-slide-${index}`">
        <a :href="getContentURL(getContent(item))" class="deco-none">
          <stripe-one-col-media 
            :content="merge(getContent(item), { 'hide_next_arrow': true })"
            :key="`flickity-item-${item}`"
            v-if="renderComponent"
          ></stripe-one-col-media>
        </a>
      </div>
    </div>
    <div v-if="!content.hide_next_arrow" class="next-container d-flex align-items-center justify-content-center py-3 w-100">
    	<div @click="scrollToBottom(anchorId, 80)"><i class="fal fa-chevron-down animated infinite pulse"></i></div>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-carousel-fullscreen-component] {
  position: relative;

  .is-single-slide .flickity-page-dots {
    display: none;
  }

  .flickity-carousel {
    position: relative;
  }

  .next-container {
		font-size: 1.5rem;
		position: absolute;
		bottom: 0;
		color: $white;
	}

  .flickity-page-dots {
    bottom: 4rem;

    .dot {
      background-color: $white;
      border: 1px solid $white;
    }
  }

  @include media-breakpoint-down(xs) {
    .flickity-prev-next-button,
    .flickity-prev-next-button {
      display: none;
    }
  }
}
</style>
