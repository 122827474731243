<template>
  <div class="h-100" products-list>
    <div class="container border-bottom py-3 py-md-4 px-medium px-md-double w-100">
      <div class="row">
        <div class="col-10 col-md-12">
          <p class="small fs-xs mb-2">{{ title }}<span class="pl-3 text-muted text-capitalize">{{ startDate }}</span></p>
          <h4 class="mb-0">{{ subtitle }}</h4>
        </div>
      </div>
      <div v-if="description" class="row">
        <div class="col-12 col-lg-10 pt-3">
          <p class="text-muted small mb-0" v-html="description"></p>
        </div>
      </div>
    </div>
    <div class="list-container hide-scrollbar">
      <div v-if="emptyProducts" class="h-100 d-flex justify-content-center align-items-center flex-column">
        <div class="placeholder-image rounded-circle d-flex justify-content-center align-items-center">
          <i :class="listType === PLAYER_CONTENT.PRODUCTS ? 'fa-shopping-bag' : 'fa-bookmark'" class="fa-3x fas"></i>
        </div>
        <h4 class="placeholder-text text-center pt-3 mb-0">{{ ft(`globals.live_shopping.empty_${listType}`) }}</h4>
      </div>
      <div v-else class="container px-medium px-md-double">
        <div class="row">
          <div class="col-12">
            <div 
              v-for="(product, index) in products"
              :key="`chapter-${index}`"
              class="card border-bottom"
              :class="{'flashsale': !product.showPreFlashsale && !product.countdownEnded && product.hasActiveFlashsale}"
            >
              <div @click="goToProductDetails(product)" class="card-body px-0">
                <div class="card-body__image rounded">
                  <div v-if="!product.showPreFlashsale && !product.countdownEnded && product.hasActiveFlashsale" class="flashsale-icon rounded-circle position-absolute m-1">
                    <i class="fas fa-bolt text-white"></i>
                  </div>
                  <img class="img-fluid" :src="product.image.url" :alt="product.image.alt">
                </div>
                <div class="card-body__content px-3 position-relative">
                  <div class="flashsale-text pb-2" v-if="!product.showPreFlashsale && !product.countdownEnded && product.hasActiveFlashsale">
                    <span class="text-uppercase">{{ ft("globals.live_shopping.flash_sale") }}</span>
                    <vue-countdown @end="handleCountdownEnd(index)" :time="product.flashsaleDuration" :transform="transform">
                      <template #default="{minutes, seconds}"><span class="countdown rounded-pill text-white">{{minutes}}:{{seconds}}</span></template>
                    </vue-countdown>
                  </div>
                  <p class="card-title h5 mb-0">{{product.title}}</p>
                  <p v-if="product.price || product.discount_price" class="card-text mb-0">
                    <span :class="product.discount_price ? 'text-player-grey line-through' : 'main-info font-weight-bold'">{{ product.price }}</span>
                    <span v-if="product.discount_price" class="pl-2 font-weight-bold main-info">{{ product.discount_price }}</span>
                  </p>
                </div>
                <div class="card-body__actions">
                  <i v-if="product.favourite" @click.stop.prevent="toggleWishlist(product)" :class="product.isFavourite ? 'fas' : 'fal'" class="fa-bookmark"></i>
                  <i class="fal fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { interactionMixin } from "src/modules/interactions_module.js";
import flashsaleMixin from 'src/modules/flashsale_module.js';
import { favouriteInteractionMixin } from 'src/modules/favourite_interaction_module.js';
import { PLAYER_CONTENT } from 'src/modules/player/constants';

import moment from 'moment';
import { isEqual } from 'lodash-es';

export default {
  mixins: [fandomBasicMixin, flashsaleMixin, favouriteInteractionMixin, interactionMixin],
  props: {
    list: {
      type: Array,
      required: true,
      default: () => []
    },
    title: {type: String},
    subtitle: {type: String},
    description: {type: String},
    listType: {
      type: String,
      default: PLAYER_CONTENT.PRODUCTS,
      validator: (value) => {
        return Object.values(PLAYER_CONTENT).includes(value)
      }
    }
  },
  data: function() {
    return {
      PLAYER_CONTENT,
      productList: [...this.list]
    }
  },
  mounted() {
    this.getOrCreateContainerContentList("live_shopping_event");
  },
  methods: {
    goToProductDetails(product) {
      this.$emit('go-to-product', product.name);
    },
    toggleWishlist(product) {
      this.updateFavouriteInteraction(product, product.favourite, product.favourite.interaction, this.containerContentFavouriteListId);
    },
    handleCountdownEnd(index) {
      Vue.set(this.productList[index], "countdownEnded", true);
    },
  },
  computed: {
    products() {
      const mappedProducts = this.productList.map(product => {
        return {
          ...product,
          isFavourite: product?.favourite?.interaction ? this.isFavouriteInListId(product, product.favourite.interaction, this.containerContentFavouriteListId) : false,
          hasActiveFlashsale: this.hasActiveFlashsale(product),
          flashsaleDuration: this.flashsaleDuration(product),
          countdownEnded: false
        }
      });

      if (this.listType === PLAYER_CONTENT.WISHLIST) {
        return mappedProducts.filter(item => item.isFavourite);
      }

      return mappedProducts;
    },
    emptyProducts() {
      return this.products.length === 0;
    },
    startDate() {
      moment.locale(this.globalState.lang ?? 'en');
      return this.content.start_time ? moment(this.content.start_time).format("D MMMM - HH:mm") : '';
    }
  },
  watch: {
    list(newVal, oldVal) {
      if (newVal && !isEqual(newVal, oldVal)) {
        this.productList = [...newVal]
      }
    }
  }
};
</script>

<style lang="scss" scoped>
[products-list] {
  display: flex;
  flex-direction: column;

  .list-container {
    overflow-y: scroll;
    flex: 1;

    @include media-breakpoint-down(md) {
      overflow-y: visible;
    }
  }
  
  .placeholder {
    &-image {
      width: 9rem;
      height: 9rem;
      color: var(--gray);
      background: var(--gray-light);

      @include media-breakpoint-down(md) {
        width: 6rem;
        height: 6rem;

        i {
          font-size: 2em;
        }
      }

      i {
        opacity: 0.75;
      }
    }

    &-text {
      color: var(--gray);
    }
  }

  .card {
    border: 0;
    cursor: pointer;

    &-body {
      display: flex;

      &__image {
        width: 7rem;
        min-width: 7rem;
        height: auto;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
          width: 4rem;
          min-width: 4rem;
        }

        img {
          border-radius: .875rem;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex: 1;
        width: calc(100% - 7rem - 12px);

        @include media-breakpoint-down(sm) {
          width: calc(100% - 4rem - 12px);
        }

        .card-title {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          @include media-breakpoint-down(sm) {
            font-size: 1rem !important;
          }
        }

        .card-text {
          .line-through {
            text-decoration: line-through;
          }
        }
      }

      &__actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    &.flashsale {
      .card-body {
        &__image {
          .flashsale-icon {
            width: 1.25rem;
            height: 1.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .5rem;
            background-color: $player-red-color;
          }

          img {
            border: 1px solid $player-red-color;
          }
        }

        .main-info {
          color: $player-red-color;
        }

        .flashsale-text {
          font-size: .5rem;
          font-weight: 600;
          color: $player-red-color;

          .countdown {
            padding: 2px .25rem;
            background-color: $player-red-color;
          }
        }
      }
    }
  }
}
</style>