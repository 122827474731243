<script>
  import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
  import { ActionCableComponentsMixin } from 'src/modules/websocket_module.js';
  import { interactionMixin } from 'src/modules/interactions_module.js';
  
  export default {
    mixins: [fandomBasicMixin, interactionMixin, ActionCableComponentsMixin],
    data() {
      return {
        expired: false,
        expireInterval: null
      }
    },
    mounted() {
      if (!!this.content.valid_to) {
        this.setExpireInterval();
      }
      this.initSubscriptions();
    },
    watch: {
      "content.valid_to"() {
        this.expired = false;
        clearInterval(this.expireInterval);
        this.setExpireInterval();
      }
    },
    computed: {
      done() {
        return this.isInteractionDone(this.containerContent, this.content.interaction);
      }
    },
    methods: {
      setExpireInterval() {
        const validTo = new Date(this.content.valid_to);
        Vue.set(this, 'expireInterval', setInterval(() => {
          if (validTo < new Date()) {
            this.expired = true;
            clearInterval(this.expireInterval);
          }
        }, 1000));
      },
      initSubscriptions() {
        this.subscribe(
          { channel: "PublicChannel", room: `${this.containerContent.name}|${this.content.interaction.id}`, lang: globalState.lang },
          { 
            connected: () => { this.$emit("websocket-connected", this.content) },
            received: (data) => { this.updateIdToAnswer(this.containerContent, this.content.interaction, receivedData) }
          }
        );
      }
    }
  };
  </script>
  
  <template>
    <div v-easyadmin="easyadminId" :class="contentType" class="pt-3" v-if="isContentVisibleToUser()" interaction-wrapper>
      <slot :expired="expired" :done="done"></slot>
    </div>
  </template>
  
  <style lang="scss">
  [interaction-wrapper] {
  
  }
  </style>