<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  computed: {
  },
  methods: {
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" class="p-md-4 p-2" page-live-event-notice-component>
    <div class="notice-container p-3 pr-5 mx-auto">
      <a class="notice-close text-white" @click="$emit('clearnotice');" href="javascript: void(0)">
        <i class="fal fa-times"></i>
      </a>
      <p class="mb-0 font-weight-bold" v-if="content.title">{{content.title}}</p>
      <p class="mb-0" v-if="content.description"><small>{{content.description}}</small></p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[page-live-event-notice-component] {
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  right: 0;
  z-index: 1;

  .notice-container {
    background-color: rgba($black, 0.75);
    color: $white !important;
    width: 75%;
    position: relative;

    .notice-close {
      position: absolute;
      right: 1.25rem;
    }

    &:after {
      content: "";
      background-color: $white;
      width: 5px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    .notice-container {
      width: 100%;
    }
  }
}
</style>
