const toTime = (duration) => {
  if (typeof duration !== 'number') {
    return '';
  };
  
  const hrs = Math.floor(duration / 3600);
  const mins = Math.floor((duration % 3600) / 60);
  const secs = Math.floor(duration % 60);

  let ret = "";
  if (hrs > 0) {
    ret = `${hrs}:`;
  }

  return `${ret}${(mins < 10 ? "0" : "")}${mins}:${(secs < 10 ? "0" : "")}${secs}`;
}

export {
  toTime
}