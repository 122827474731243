<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      marketingConsentValue: false,
      loading: false,
      active: false
    }
  },
  mounted() {
    Vue.nextTick(() => { 
      const activation = get(globalState, ["pageModel", "show_marketing_consent"], false);
      this.toggleActivation(activation);
    });
  },
  computed: {
    formUrl() {
      return Fandom.applyContextToUrl("/api/v1/change_marketing_consent");
    }
  },
  methods: {
    toggleActivation(value) {
      this.active = value;
    },
    changeMarketingConsent(marketingConsentValue) {
      if (!this.loading) {
        this.loading = true;
        Fandom.ajax({
          async: true,
          type: "POST",
          url: this.formUrl,
          data: {
            content_cache_id: globalState.pageModel.name_to_content[globalState.pageModel.main_content_name].id,
            value: marketingConsentValue
          },
          success: (data) => {
            Vue.nextTick(() => { 
              this.toggleActivation(false); 
            });
          },
          error: (jqXHR, textStatus, errorThrown) => {
            alert("Oops something went wrong. Please try again later");
          }
        }, 
        () => {
          this.loading = false;
        });
      }
    }
  }
}
</script>

<template>
  <transition enter-active-class="animated faster fadeInUp" leave-active-class="animated faster fadeOut">
    <div v-if="active" stripe-marketing-consent-component>
      <div class="m-3 bg-white border">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-auto order-sm-2 py-2 text-right"><i class="fal fa-times fa-2x cursor-pointer" @click="changeMarketingConsent(false)"></i></div>
            <div class="col p-4">
              <div class="row">
                <div class="col-12">
                  <h5 class="h3">{{ft("marketing_consent.title")}}</h5>
                  <p class="font-weight-bold mb-1">{{ft("marketing_consent.description")}}</p>
                </div>
                <div class="col-12">
                  <form ref="form" class="d-flex flex-column" @submit.prevent="changeMarketingConsent(marketingConsentValue)" :action="formUrl" method="post">
                    <p v-html="ft('marketing_consent.label')"></p>
                    <div class="form-group">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="marketing-consent-input" v-model="marketingConsentValue">
                        <label class="custom-control-label" for="marketing-consent-input">{{ft("marketing_consent.input")}}</label>
                      </div>
                    </div>
                    <div class="row mx-n1">
                      <div class="col-sm-auto px-1 mb-sm-0 mb-2">
                        <button type="submit" class="btn btn-black">Conferma e chiudi</button>
                      </div>
                      <div class="col-sm-auto px-1">
                        <button type="button" class="btn btn-outline-black" @click="changeMarketingConsent(false)">Annulla</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
[stripe-marketing-consent-component] {
  position: fixed;
  top: 0;
  right: 0;
  width: 750px;
  max-width: 100%;
  z-index: 1050;

  p > a {
    text-decoration: underline !important;
    color: $black !important;
    font-weight: normal;
  }

  p {
    font-size: 1rem;
  }

  form p, form label {
    font-size: 0.85rem;
  }

  .custom-control-label {
    padding-left: 5px;
    padding-top: 2px;
  }

  .custom-control-label::before,
  .custom-control-label::after {
    border-radius: 0;
    width: 1.5rem;
    height: 1.5rem;
    top: 0rem;
  }

  @include media-breakpoint-down(xs) {
    button {
      width: 100%;
    }
  }
}
</style>