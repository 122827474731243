<script>
import { fandomRootMixin } from 'src/modules/fandom_mixins_module.js';
import { interactionMixin } from 'src/modules/interactions_module.js';
import { favouriteInteractionMixin} from 'src/modules/favourite_interaction_module.js';

export default {
  mixins: [fandomRootMixin, interactionMixin, favouriteInteractionMixin],
  data: function() {
    return {
      errorMessages: [],
      newListName: "",
      selectedFavouriteLists: {}
    };
  },
  mounted: function() {
    $('#favourite-interaction-modal').on('show..bs.modal', (e) => {
      let successCallback = (data) => {
        for(let listId of data.content_list_ids) {
          Vue.set(this.selectedFavouriteLists, listId, true);
        }
      };
      if (this.containerContent) {
        this.getFavouriteLists(() => {this.getContentLists(this.containerContent.id, successCallback);});
      }
    });

    $('#favourite-interaction-modal').on('hidden.bs.modal', (e) => {
      this.errorMessages = [];
      this.newListName = "";
      Vue.set(this, "selectedFavouriteLists", {});
      Vue.set(globalState.requiredModals.favourite, "contentInfo", null);
    })

  },
  computed: {
    containerContent: function() {
      if(globalState.requiredModals.favourite.containerContent) {
        return globalState.requiredModals.favourite.containerContent;
      }
    },
    interactionRecord: function() {
      if (globalState.requiredModals.favourite.interactionRecord) {
        return globalState.requiredModals.favourite.interactionRecord;
      }
    },
    interaction: function() {
      if (globalState.requiredModals.favourite.interaction) {
        return globalState.requiredModals.favourite.interaction;
      }
    },
    textBlocks: function() {
      let favouriteModalLayout =  this.retrieveFromGlobalState("favourite_modal", false)
      if (favouriteModalLayout) {
        let textBlock1 = favouriteModalLayout["text_block_1"] ? favouriteModalLayout["text_block_1"] : "";
        let textBlock2 = favouriteModalLayout["text_block_2"] ? favouriteModalLayout["text_block_2"] : "";
        return { textBlock1: textBlock1, textBlock2: textBlock2 };
      } 
    },
  },
  methods: {
    retrieveFromGlobalState: Fandom.retrieveFromGlobalState,
    getPrimaryButtonClass: Fandom.getPrimaryButtonClass,
    addFavouriteList(newListName) {
      this.errorMessages = [];
      let successCallback = (data) => {
        if (data.success) {
          this.newListName = "";
          Vue.set(globalState.pageModel.favouriteLists, data.favourite_list.id, data.favourite_list.name);
        } else {
          this.errorMessages = data.errors;
        }
      }
      this.createFavouriteList(newListName, successCallback);
    },
  }
};
</script>

<template>
  <div id="favourite-interaction-modal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title h5">{{ft("favourite.list_lists")}}: {{containerContent.title}}</div>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row justify-content-center">
            <div v-if="globalState.pageModel.favouriteLists" class="col-12">
              <ul class="mb-0 p-0">
                <li v-for="(listName, listId) in globalState.pageModel.favouriteLists" :key="listId" class="d-flex align-items-center my-2">
                  <div class="pr-3">
                    <i @click="updateFavouriteInteraction(containerContent 
                    , interactionRecord, interaction, listId)"  :class="[selectedFavouriteLists[listId] ? 'fas' : 'far']" class="fa-star cursor-pointer"></i>
                  </div>
                  <div v-if="listName">{{listName}}</div>
                  <div v-else="listName">{{ft("globals.favourite_default_list")}}</div>
                </li>
              </ul>
            </div>
            <div v-if="errorMessages.length > 0" class="col-12">
              <div v-for="errorMessage in errorMessages" class="alert alert-danger mb-0">{{errorMessage}}</div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="form-inline d-flex align-items-stretch">
            <input type="text" class="px-2 form-control" name="listName" v-model="newListName"/>
            <button class="btn btn-primary ml-3" @click="addFavouriteList(newListName)">{{ft("favourite.create_list_title")}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>