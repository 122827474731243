<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { interactionMixin } from 'src/modules/interactions_module.js';
import {InteractionLike} from 'interaction';

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  components: { InteractionLike },
  props: {
    fullscreen: {
      type: Boolean,
      default: false
    },
    showControls: {
      type: Boolean,
      default: false
    },
    videoPlayer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      autoQuality: true,
      showQualities: false
    };
  },
  computed: {
    muted() {
      return this.videoPlayer.muted;
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
    pipEnabled() {
      return this.content.play.pip || false;
    }
  }
};
</script>

<template>
  <div embed-video-control-bar-component>
    <interaction-like
      v-if="likeInteraction"
      class="position-relative px-2"
      :likes="totalLikeCount"
      :cid="cid"
      :content="likeInteraction"
      :opt-container-content="containerContent"
    ></interaction-like>
    <transition enter-active-class="animated slideInUp" leave-active-class="animated slideOutDown">
      <div class="video-controls" v-show="showControls">
        <div class="position-absolute-center d-flex justify-content-between align-items-stretch text-white px-3">
          <div class="d-flex align-items-center">
            <i class="fal fa-play cursor-pointer" @click="videoPlayer._play()" v-if="videoPlayer._isPaused()"></i>
            <i class="fal fa-pause cursor-pointer" @click="videoPlayer._pause()" v-else></i>
            <div class="volume-controls d-flex align-items-center" @click="videoPlayer._toggleMute()">
              <i class="fal fa-volume cursor-pointer ml-3" v-if="!videoPlayer.muted"></i>
              <i class="fal fa-volume-mute cursor-pointer ml-3" v-else></i>
            </div>
            <div class="form-group ml-3 mb-0 d-none d-sm-block" v-if="!isSafari">
              <input
                min="0"
                max="100"
                type="range"
                @change="videoPlayer._changeVolume"
                :value="videoPlayer._getVolume()"
                class="form-control-range cursor-pointer"
              >
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div v-if="likeInteraction" class="cursor-pointer d-flex align-items-center position-relative px-2">
              <i @click="sendLike" class="fal fa-heart h3 mb-0 font-weight-normal"></i>
            </div>
            <div v-if="!content.disable_fullscreen" class="fullscreen-controls px-2 cursor-pointer" @click="$emit('toggle-fullscreen')">
              <i class="fal" :class="fullscreen ? 'fa-compress' : 'fa-expand'"></i>
            </div>
            <div v-if="pipEnabled" class="px-2 cursor-pointer" @click="$emit('toggle-pip')">
              <i class="fal fa-bring-forward"></i>
            </div>
            <div
              class="quality-controls d-flex align-items-center"
              :class="{'active': showQualities}"
              @click="showQualities = !showQualities"
            >
              <div class="d-flex align-items-center cursor-pointer position-relative px-2">
                <i class="fal fa-cog"></i>
                <div class="qualities-list py-2 px-3">
                  <div
                    class="d-flex align-items-center"
                    v-for="quality in videoPlayer.availableQualities"
                    :key="quality.name"
                    @click="() => {
                      videoPlayer._setQuality(quality);
                      autoQuality = false;
                    }"
                  >
                    <div class="dot-container text-primary mr-2">
                      <i class="fas fa-dot-circle" v-if="videoPlayer._getQuality() === quality.name && !autoQuality"></i>
                    </div>
                    {{quality.name}}
                  </div>
                  <div
                    class="d-flex align-items-center"
                    @click="() => {
                      videoPlayer._setQuality();
                      autoQuality = true;
                    }"
                  >
                    <div class="dot-container text-primary mr-2">
                      <i class="fas fa-dot-circle" v-if="autoQuality"></i>
                    </div>
                    auto
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
  [embed-video-control-bar-component] {
    ::v-deep i {
      font-size: 1.5rem;
    }

    .video-controls {
      height: $video-controlbar-height;
      background-color: rgba(0, 0, 0, .5);
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
    }

    .fullscreen-controls i {
      position: relative;
      top: 2px;
    }

    .quality-controls.active .qualities-list {
      display: block;
    }

    .qualities-list {
      display: none;
      right: 0;
      width: 7rem;
      position: absolute;
      bottom: 100%;
      background-color: rgba(0, 0, 0, .75);

      .dot-container {
        width: 1rem;
        i { font-size: 1rem !important; }
      }
    }
  }
</style>