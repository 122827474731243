<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  computed: {
    interaction() {
      return get(this, ["content", "interaction"], {});
    },
    shareProviders() {
      return get(this, ["content", "providers"], "").split(",");
    },
  },
  methods: {
    getProviderIcon: Fandom.getProviderIcon,
    providerIcon(provider) {
      return `#${provider}-icon`;
    },
    startShare(provider) {
      // We must copy the value to the clipboard here because if we go too deep in the call stack
      // we lose the origin by a click event and so we can't copy anything to the clipboard
      if (["link"].includes(provider)) {
        Fandom.copyToClipboard(window.location.href);
      }
      this.updateShareInteraction(this.containerContent, this.content, this.interaction, provider);
    }
  }
};
</script>

<template>
  <div
    class="container-fluid bg-black d-flex justify-content-end py-4 flex-wrap"
    :class="contentType"
    v-easyadmin="easyadminId"
    interaction-share-component
  >
    <div v-if="content.title" class="mr-4 d-none d-md-block">{{content.title}}</div>
    <div
      class="cursor-pointer mr-4"
      v-for="(provider, index) in shareProviders"
      :key="index"
      @click="startShare(provider)"
    >
      <svg class="share-icon" v-if="['email', 'link'].includes(provider)">
        <use :xlink:href="providerIcon(provider)"></use>
      </svg>
      <i v-else class="fab fa-lg" :class="getProviderIcon(provider)"></i>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[interaction-share-component] {
  color: $white;

  .share-icon {
    stroke: $white;
    width: 1.5rem;
    height: 1.5rem;
  }
}
</style>