// deprecated 20210511
const commentsMixin = {
  data: function() {
    return {
    };
  },
  methods: {
  }
};

export {
  commentsMixin,
}