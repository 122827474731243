<template>
  <div v-easyadmin="easyadminId" :class="[contentType, `themed-${theme}`, `theme-${layout}`]" comments-component>
    <slot name="new-comment" :opt-container-content="containerContent" :content="content" v-if="isValid">
      <div 
        v-if="isBanned" 
        class="ban-banner alert alert-warning"
      >{{ft("globals.live_shopping.banned")}}</div>
      <comment-add
        v-else
        :opt-container-content="containerContent"
        :content="content"
        :showFeedback="showFeedback"
        :allowAnonymousComments="allowAnonymousComments"
        :ref-comment="replyTo"
        @clear-reply-to="replyTo=null"
      ></comment-add>
    </slot>
    <TransitionGroup @before-enter="beforeEnter" @after-enter="afterEnter" ref="commentsList" tag="div" name="list" :class="{'list--no-animate': isLoading}" class="comments-list my-3 h-100">
      <div class="comment-container" v-for="(comment, index) in sortedComments" :key="comment.id">
        <intersect 
          root-margin="50% 0px 0px 0px"
          v-if="index == sortedComments.length-1 && sortedComments.length > 0 && hasMore"
          @enter="enterIntersect"
        >
          <div class="py-3"></div>
        </intersect>
        <comment class="pr-3" 
          :comment="comment"
          :parent-comment="getParentComment(comment)"
          :reply="reply && isValid"
          :opt-container-content="containerContent" 
          :content="content"
          :show-date="showDate"
          @reply-to-comment="hadleReply">
        </comment>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { merge, throttle } from "lodash";
import { ActionCableComponentsMixin } from "src/modules/websocket_module.js";
import Comment from "./comment";
import CommentAdd from "./comment-add";
import Intersect from "vue-intersect";
import moment from 'moment';
import { browser } from 'src/modules/player/utils';

if (!("scrollBehavior" in document.documentElement.style)) {
  import("scroll-behavior-polyfill");
}

const THEME_TYPE = {
  DEFAULT: 'default', 
  LIVE: 'live'
}

const LIMIT = 50;

export default {
  mixins: [fandomBasicMixin, ActionCableComponentsMixin],
  components: {
    Intersect,
    CommentAdd,
    Comment
  },
  props: {
    reply: {
      type: Boolean,
      default: true
    },
    isBanned: {
      type: Boolean,
      default: false
    },
    showMoreButton: {
      type: Boolean,
      default: true
    },
    layout: {
      type: String,
      default: "default",
      validator: (value) => {
        return Object.values(THEME_TYPE).includes(value)
      }
    },
    showFeedback: {
      type: Boolean,
      default: true
    },
    allowAnonymousComments: {
      type: Boolean,
      default: true
    },
    showDate: {
      type: Boolean,
      default: true
    },
    theme: {
      type: String,
      default: "light"
    }
  },
  data: function() {
    return {
      replyTo: null,
      comments: [],
      hasMore: false,
      parentComments: [],
      isLoading: false
    }
  },
  mounted() {
    if (this.showComments) {
      Fandom.onEvent(`${this._uid}`, ({room, status, receivedData}) => {
        if (room !== this.contentName) {return;}
        if (status === 'connected') {
          this.comments = [];
          this.hasMore = true;
          this.loadMore();
        } else {
          console.log('received', receivedData);
          this.receiveData(receivedData);
        };
      });

      this.subscribeWithEvent();
    }
  },
  watch: {
    showComments(newValue) {
      newValue ? this.subscribeWithEvent() : this.destroySubscriptions();
    }
  },
  methods: {
    receiveData(receivedData) {
      const comment = receivedData.comment;
      const parent_comment = receivedData.parent_comment;
      if (parent_comment?.id ?? false) {
        const index = this.parentComments.findIndex(item => parent_comment.id === item.id)
        if (index === -1) {
          this.parentComments.push(parent_comment);
        }
      }
      if (comment.approved) {
        if (receivedData.id_to_user) {
          merge(globalState.pageModel.id_to_user, globalState.pageModel.id_to_user, receivedData.id_to_user);
        }
        this.setComments([comment]);
      } else {
        this.removeComment(comment);
      }
    },
    destroySubscriptions() {
      this.deleteSubscriptions();
      this.comments = []
      this.hasMore = false;
    },
    beforeEnter() {
      if (browser.isIos && browser.isTouch) {
        const list = $('.comments-list')[0];
        list.classList.add("overflow-hidden");
      }
    },
    afterEnter() {
      if (browser.isIos && browser.isTouch) {
        const list = $('.comments-list')[0];
        list.classList.remove("overflow-hidden");
      }
    },
    setComments(comments, hasMore=null) {
      Vue.set(this, "comments", [...comments, ...this.comments]);
      if (hasMore != null) {
        this.hasMore = hasMore;
      }
    },
    removeComment(comment) {
      const filteredComments = this.comments.filter(el => el.id != comment.id && el.ref_comment_id != comment.id);
      Vue.set(this, 'comments', filteredComments)
    },
    enterIntersect() {
      this.loadMore();
    },
    loadMore: throttle(
      function() {
        this.isLoading = true;
        Fandom.ajax({
          method: "POST",
          url: "/api/v5/comments/append",
          data: {
            shown_comment_ids: this.comments.map(comment => comment.id),
            users_ids: Object.keys(globalState.pageModel.id_to_user),
            content_cache_id: this.containerContent.id,
            interaction_id: this.content.interaction.id,
            limit: LIMIT
          },
          success: (data) => this.handleNewComments(data)
        });
      }, 500
    ),
    handleNewComments(data) {
      if (data.id_to_user) {
        merge(globalState.pageModel.id_to_user, globalState.pageModel.id_to_user, data.id_to_user);
      }
      this.setComments(data.comments, data.has_more);
      if (data.parent_comments?.length > 0) {
        Vue.set(this, 'parentComments', this.parentComments.concat(data.parent_comments))
      }

      Vue.nextTick(() => setTimeout(()=>this.isLoading = false, 1000))
    },
    getParentComment(comment) {
      const parent = this.parentComments.find((item) => comment.ref_comment_id === item.id);
      return typeof parent !== "undefined" ? parent : null;
    },
    hadleReply(comment) {
      this.replyTo = comment;
    }
  },
  computed: {
    showComments() {
      return this?.content?.publish_comments ?? false;
    },
    sortedComments() {
      return this.comments
        .sort((a,b) => {
          const dateA = moment(a.created_at);
          return dateA.isAfter(b.created_at) ? -1 : (dateA.isBefore(b.created_at) ? 1 : 0);
        });
    },
    isValid() {
      if (this.content.valid_to) {
        const validTo = new Date(this.content.valid_to);
        return validTo > new Date()
      }

      return true;
    }
  }
};
</script>

<style lang="scss">
[comments-component] {
  .list-enter-active, .list-leave-active {
    transition: all .5s;
  }
  .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
  }
  .list-move {
    transition: transform .5s;
  }

  .list--no-animate {
    .comment-container {
      transition: none !important;
    }
  }

  &.theme-live {
    --scrollbar-width: 0px;
    --mask-height: 1rem;
    --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
    --mask-image-scrollbar: linear-gradient(black, black);
    --mask-size-scrollbar: var(--scrollbar-width) 100%;
    --mask-image-content: linear-gradient(to bottom, transparent, black var(--mask-height), black calc(100% - var(--mask-height)), transparent);
    height: 100%;

    .comments-list {
      // https://pqina.nl/blog/fade-out-overflow-using-css-mask-image/
      overflow-y: scroll;
      flex-wrap: nowrap;
      mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
      mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
      mask-position: 0 0, 100% 0;
      mask-repeat: no-repeat, no-repeat;
      display: flex;
      flex-direction: column-reverse;
      -ms-overflow-style: none;
      scrollbar-width: none;
      
      &::-webkit-scrollbar { display: none; }

      @include media-breakpoint-down(md) {
        height: 30vh;
      }

      .comment-container {
        flex: none;

        &:last-child {
          margin-top: 1rem;
        }
      }
    }
  }

  &.themed-light {
    [comment-add-component] {
      .new-comment-area {
        border: 1px solid !important;
      }
    }

    [comment] {
      .comment-preview {
        &__name, &__text {
          text-shadow: none;
        }
      }
    }
  }

  &.themed-dark {
    .new-comment-area {
      border: 0 !important;
    }
  }
}
</style>
