<script>
import { fandomTranslate } from "src/modules/common_module";

export default {
  data: function () {
    return {
      cookieName: "audi_events_cookies_accepted",
      cookiesAccepted: false,
    };
  },
  computed: {
    areCookiesAccepted() {
      let cookies = document.cookie.split("; ");
      let response = false;
      cookies.forEach((cookie) => {
        let cookieKey = cookie.split("=")[0];
        if (cookieKey == this.cookieName) {
          response = true;
        }
      });
      return response || this.cookiesAccepted;
    }
  },
  methods: {
    ft: fandomTranslate,
    setCookiesAccepted() {
      document.cookie = `${this.cookieName}=true; path=/;`;
      this.cookiesAccepted = true;
    }
  }
};
</script>

<template>
  <div class="row w-100 mx-0 pt-4 pb-3 pt-sm-5 pb-sm-5 justify-content-center" v-if="!areCookiesAccepted" cookies-banner-component>
    <div class="col-12 col-sm-10 row justify-content-between align-items-center">
      <div class="col-12 col-md-7">
        <p class="mb-0" v-html="ft('globals.cookies_banner')"></p>
      </div>
      <div class="col-12 col-md-5 d-flex justify-content-center mt-3 mt-md-0">
        <button class="btn btn-outline-dark px-5" @click="setCookiesAccepted()">
          {{ft("globals.ok")}}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[cookies-banner-component] {
  background-color: rgba($white, .9);
  z-index: 1090;
  position: fixed;
  bottom: 0%;

  p {
    font-size: .75rem;
  }
}
</style>
