<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import postscribe from "postscribe";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  props: {
    notices: {
      type: Array,
      default: () => []
    },
    timer: {
      type: [Number, String]
    },
  },
  mounted: function() {
    Vue.nextTick(() => {
      this.initFullscreen();

      if (this.content.snippet) {
        postscribe(`#${this.snippetId}`, this.content.snippet);
      } 
    });
  },
  data: function() {
    return {
      embed: {
        fullscreen: false,
        unsupportedFullscreenClass: ""
      },
      reloadEmbed: false
    }
  },
  watch: {
    'content.play.media_code'(_) {
      this.reload();
    },
    'content.snippet'(_) {
      this.reload();
    },
    'content.embed_media'(_) {
      this.reload();
    }
  },
  methods: {
    reload() {
      this.reloadEmbed = true;
      Vue.nextTick(() => {
        this.reloadEmbed = false;
        if (this.content.snippet) {
          Vue.nextTick(() => {
            console.log(document.getElementById(`${this.snippetId}`));
            postscribe(`#${this.snippetId}`, this.content.snippet);  
          })
        }
      })
    },
    isIOsMobile() {
      return /iPad/i.test(navigator.userAgent);
    },
    initFullscreen() {
      const target = $(this.$refs.embed)[0];
      target.addEventListener("fullscreenchange", this.onFullscreenChange);
      target.addEventListener("webkitfullscreenchange", this.onFullscreenChange);
      target.addEventListener("mozfullscreenchange", this.onFullscreenChange);
    },
    onFullscreenChange() {
      this.embed.fullscreen = Fandom.exists(document.fullscreenElement) || 
                              Fandom.exists(document.mozFullScreenElement) || 
                              Fandom.exists(document.webkitFullscreenElement);
      this.$emit('fullscreen-handler', this.embed.fullscreen);
    },
    toggleFullscreen() {
      const target = $(this.$refs.embed)[0];
      if (this.embed.fullscreen) {
        if (document.requestFullscreen) {
          document.exitFullscreen();
        } else if (document.mozFullScreen) { /* Firefox */
          document.mozCancelFullScreen();
        } else if (target.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          document.webkitExitFullscreen();
        } else {
          // unsupported
          this.embed.unsupportedFullscreenClass = "";
          this.embed.fullscreen = false;
        }
      } else {
        if (target.requestFullscreen) {
          target.requestFullscreen();
        } else if (target.mozRequestFullScreen) { /* Firefox */
          target.mozRequestFullScreen();
        } else if (target.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          target.webkitRequestFullscreen();
        } else {
          // unsupported
          this.embed.unsupportedFullscreenClass = "unsupported-fullscreen"
          this.embed.fullscreen = true;
        }
      }
    }
  },
  computed: {
    snippetId() {
      return `#embed-advanced-${this.contentId.toString().replace("|", "-")}`;
    },
    currentBreakpoint() {
      for (let breakpoint in globalState.viewport) {
        if (globalState.viewport[breakpoint] == true) {
          return breakpoint;
        }
      }
    }
  }
};
</script>

<template>
  <div :style="theme" embed-advanced-new-component>
    <div :id="snippetId" v-if="content.snippet && !reloadEmbed"></div>
    <div ref="embed" class="d-flex align-items-center bg-black position-relative flex-wrap" :class="[embed.unsupportedFullscreenClass, {'position-absolute-center': embed.fullscreen}]" >
      <slot></slot>
      <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
        <div class="timer d-flex justify-content-center align-items-center position-absolute" v-if="timer">
          <div class="h1 mb-0">{{timer}}</div>
        </div>
      </transition>
      <div v-if="content.embed_media && !reloadEmbed" v-html="content.embed_media" class="w-100" :class="{'embed_content': embed.fullscreen}"></div>
      <div v-else-if="exists(content.play)" class="w-100" :class="{'embed_content': embed.fullscreen}">
        <component
          v-if="!reloadEmbed"
          class="embed-responsive embed-responsive-16by9" 
          :is="`embed-${content.play.media_type}`"  
          :content="content" 
          :opt-container-content="containerContent"
          @video-player-handler="$emit('video-player-handler', arguments[0])">
        </component>  
      </div>
      <div v-else-if="content.image" class="bg-cover-center ar-16by9 w-100" :style="{'background-image': `url(${content.image.url})`}">
      </div>
      <a v-if="embed.fullscreen && !isIOsMobile()" href="javascript: void(0)" @click="toggleFullscreen()" class="embed-fullscreen__close-icon p-3 text-white">
        <i class="fal fa-times"></i>
      </a>
      <div class="embed_notification d-flex-inline flex-column" :class="{'embed_notification--ipad': embed.fullscreen && isIOsMobile()}">
        <transition-group
          :enter-active-class="'animated fadeInDown'"
          :leave-active-class="'animated fadeOutUp'"
        >
          <button
            v-for="notice in notices"
            type="button"
            class="notification d-flex mb-2 w-100"
            @click="notice.callback()"
            :key="notice.id"
          >
            <i :class="notice.icon" class="mr-2"></i>
            <div class="text-left">
              <div class="font-weight-bold">{{notice.message}}</div>
              <small>{{notice.description}}</small>
            </div>
          </button>
        </transition-group>
      </div>
    </div>
    <div v-if="(content.embed_media || content.play) && !content.disable_fullscreen" class="w-100 bg-black embed-fullscreen__controls py-2 px-3 d-block-ie-flex align-items-center">
      <a href="javascript: void(0)" @click="toggleFullscreen()" class="text-uppercase text-white font-weight-bold d-flex align-items-center justify-content-end">
        fullscreen<i class="ml-2 fal fa-expand-wide"></i>
      </a>
    </div>
  </div>
</template>


<style lang="scss">
[embed-advanced-new-component] {

  .embed-fullscreen__close-icon {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 2rem;
  }
  
  .unsupported-fullscreen {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 1090;
    background-color: $black;
  }

  .embed_content {
    width: 100vw;
  }

  .embed_notification {
    position: absolute;
    left: 1rem;
    top: 1rem;
    
    .notification { 
      padding: 0.75rem 1rem;
      background-color: var(--theme-background);
      color: var(--theme-color);
      border-radius: 0;
      border-width: 0;
      &:focus {outline:0;}

      i {
        font-size: 1.25rem;
        margin-top: 1px;
      }
    }
  }

  .timer {
    top: 1.5rem;
    z-index: 1;
    right: 1.5rem;
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
    border: 2px solid var(--theme-color);
    background-color: var(--theme-background);

    @include media-breakpoint-down(sm) {
      width: 4rem;
      height: 4rem;
    }
  }

  @include media-breakpoint-down(md) {

    .embed_notification {
      &.embed_notification--ipad {
        top: 5rem !important;
      }
    } 
  }

  @media (min-aspect-ratio: 16/9) {
    .embed_content {
      height: 100vh;
      width: calc((16 / 9) * 100vh) !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
}
</style>