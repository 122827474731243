<template>
  <div class="list-group">
    <a
      v-for="quality in qualitiesWAuto"
      :key="quality.name"
      @click="handleQualityClick(quality)" 
      class="list-group-item list-group-item-action p-0 m-0 small"
      :class="{'active': quality.name===selectedQuality.name}"
    >
      <i class="fal" :class="{'fa-check': quality.name===selectedQuality.name}"></i>
      <span class="pl-2 text-capitalize">{{ quality.name }}</span>
    </a>
  </div>
</template>

<script>
import { isEmpty, isEqual } from 'lodash-es';
import { AUTO_QUALITY } from 'src/modules/player/constants';

export default {
  props: {
    value: {
      type: Object
    },
    qualities: {
      type: Array,
      default: () => {
        return [AUTO_QUALITY]
      }
    }
  },
  data: function() {
    return {
      selectedQuality: {}
    }
  },
  mounted() {
    this.selectedQuality = !isEmpty(this.value) ? 
      this.value :
      this.qualities.filter((q) => q.name===AUTO_QUALITY.name)
  },
  methods: {
    handleQualityClick(quality) {
      this.$emit('input', quality);
    }
  },
  computed: {
    qualitiesWAuto() {
      return [...this.qualities, AUTO_QUALITY];
    }
  },
  watch: {
    value(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        Vue.set(this, "selectedQuality", newVal);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-group {
  right: 0;
  max-width: 250px;
  min-width: 170px;
  position: absolute;
  bottom: 100%;
  background-color: rgba(0, 0, 0, .75);

  &-item {
    height: 2.2rem;
    background-color: transparent;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    &:hover {
      background-color: rgba(255,255,255,.1);
      color: $white;
    }

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 2.2rem;
    }

    &.active {
      border: none;
      background-color: transparent;
    }
  }
}
</style>