<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import Flickity from "flickity";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data: function () {
    return { 
      slider: null
    };
  },
  mounted() {
    Vue.nextTick(() => {
      this.mountCarousel();
    });
  },
  methods: {
    mountCarousel() {
      const centeredTileSkins = ["1","4"];
      const slideConfig =  {
        draggable: "> 1",
        prevNextButtons: true,
        contain: true,
        pageDots: false,
        wrapAround: (centeredTileSkins.indexOf(this.skin) >= 0 ? true : false),
        cellAlign: (centeredTileSkins.indexOf(this.skin) >= 0 ? 'center' : 'left'),
        groupCells: '75%',
        arrowShape: "M47.89,1,.78,48.11a2.68,2.68,0,0,0,0,3.78L47.89,99a2.67,2.67,0,0,0,3.79,0l1.58-1.57a2.67,2.67,0,0,0,0-3.79L13.42,53.79h83.9A2.67,2.67,0,0,0,100,51.12V48.88a2.67,2.67,0,0,0-2.68-2.67H13.42L53.26,6.36a2.67,2.67,0,0,0,0-3.79L51.68,1A2.67,2.67,0,0,0,47.89,1Z"
      }

      this.slider = new Flickity(this.$refs["flickity-carousel"], slideConfig);

      this.slider.on("dragStart", () => {
        $(this.$refs["flickity-carousel"]).find('.flickity-carousel-item').css('pointer-events', 'none');
      });

      this.slider.on("dragEnd", () => {
        $(this.$refs["flickity-carousel"]).find('.flickity-carousel-item').css('pointer-events', 'all');
      });
    }
  },
  computed: {
    skin() {
      return this.content.skin || "1";
    },
    skinSet() {
      const classes = [`stripe-carousel__skin-${this.skin}`];
      return classes;
    },
    contentStyle() {
      return {
        "background-color": this.content.background_color,
        "color": this.content.color
      };
    },
    aspectRatioClass() {
      let aspectRatio = this.content.aspect_ratio;
      if (aspectRatio) {
        // legacy management
        return aspectRatio.startsWith("ar-") ? aspectRatio : `ar-${aspectRatio}`
      } else {
        return "ar-16by9";
      }
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" :style="[contentStyle, theme]" class="inview_animate py-4" stripe-carousel-component>
    <stripe-header :content="content"></stripe-header>
    <div class="w-100">
      <div class="flickity-carousel" ref="flickity-carousel" :class="skinSet">
        <div class="flickity-carousel-item" v-for="(child, index) in content.children" :key="`carousel-slide-${index}`" :child_name="child">
          <div class="w-100" :class="aspectRatioClass">
            <component 
              :is="getContent(child).layout" 
              :content="getContent(child)"
            ></component>
          </div>
        </div>
        <div class="flickity-carousel-item" v-if="!!content.view_more_link">
          <div class="w-100" :class="aspectRatioClass">
            <a
              class="view-more-link position-absolute-center d-flex flex-column justify-content-center align-items-center"
              :href="content.view_more_link.url"
              :target="getTarget(content.view_more_link)"
            >
              <i class="fal fa-5x fa-plus-circle"></i>
              <div class="mt-4">{{content.view_more_link.title}}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-carousel-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  position: relative;

  .flickity-viewport {
    margin-bottom: 1rem;
    margin-top: .25rem;
  }

  .flickity-viewport:focus, .flickity-carousel:focus {
    outline: none !important;
  }

  .flickity-button.flickity-prev-next-button {
    z-index: 2;
  }

  .flickity-button.flickity-prev-next-button:disabled {
    display: none;
  }

  .flickity-button-icon {
    transform: scale(.65);
  }

  .flickity-carousel-item {
    padding-left: .5rem;
    padding-right: .5rem;
    margin-bottom: .75rem; // Prevent shadow overflow

    > div {
      position: relative;
    }
  }

  .view-more-link {
    background-color: $gray-900;
    color: $gray-700;

    & > i {
      transition: transform;
      transition-duration: 3s;
      transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    &:hover > i {
      transform: scale(1.15);
    }
  }

  .stripe-carousel__skin-1 .flickity-carousel-item {
    width: 60%;
  }

  .stripe-carousel__skin-2 .flickity-carousel-item {
    width: 20%;
  }

  .stripe-carousel__skin-3 .flickity-carousel-item {
    width: calc(100% / 3.15);
  }

  .stripe-carousel__skin-4 .flickity-carousel-item {
    width: 80%;
  }

  .stripe-carousel__skin-4 {
    .flickity-viewport::before, .flickity-viewport::after {
      content: '';
      height: 100%;
      position: absolute;
      width: 6.25rem;
      background-color: #dc3545;
      z-index: 1;
    }

    .flickity-viewport::before {
      background: rgb(0,0,0);
      background: linear-gradient(to left, rgba(256,256,256, 0), $dark);
    }

    .flickity-viewport::after {
      right: 0;
      background: rgb(0,0,0);
      background: linear-gradient(to right, rgba(256,256,256, 0), rgba($dark, .95));
    }
  }

  @include media-breakpoint-down(lg) {

    .stripe-carousel__skin-3 .flickity-carousel-item {
      width: calc(100% / 2.15);
    }

    .stripe-carousel__skin-2 .flickity-carousel-item {
      width: 25%;
    }
  }

  @include media-breakpoint-down(sm) {

    .flickity-carousel-item {
      padding-left: .25rem;
      padding-right: .25rem;
    }

    .stripe-carousel__skin-1 .flickity-carousel-item, .stripe-carousel__skin-3 .flickity-carousel-item {
      width: 90%;
    }

    .stripe-carousel__skin-2 .flickity-carousel-item {
      width: 75%;
    }

    .stripe-carousel__skin-4 .flickity-carousel-item {
      width: 100%;
    }
  }
}
</style>
