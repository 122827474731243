<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { get, cloneDeep } from 'lodash';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      chapter: null,
      unitIndex: 0,
      videoPlayer: null
    }
  },
  mounted() {
    Vue.set(this, 'chapter', this.interactiveContent.chapters[0].chapters[0]);
  },
  watch: {
    "videoPlayer.firstPlay"(newVal, oldVal) {
      if (newVal) {
        const userTrackingContent = get(this.chapter, ['play', "user_tracking_content"], false);
        if (userTrackingContent) {
          Fandom.ajax({
            type: "POST",
            url: Fandom.applyContextToUrl("/api/v1/event_mkt_tracking"),
            data: {
              content_cache_id: this.containerContent.id,
              interaction_id: this.chapter.play.interaction.id
            },
            success: (data) => {
              console.log("%cfandom tracking\n\tfandom_action: " + (data.fandom_action || "none") + "\n\tmyaudi_action: " + (data.myaudi_action || "none"), "color: #aaaaaa");
            }
          });
        }
      }
    },
    'videoPlayer.end'(newVal) {
      const lastUnitIndex = this.interactiveContent.chapters.length - 1
      const lastChapterId = this.interactiveContent.chapters[lastUnitIndex].chapters[this.interactiveContent.chapters[lastUnitIndex].chapters.length - 1].$id;
      if (!!newVal && !(this.unitIndex == lastUnitIndex && this.chapter.$id == lastChapterId)) {
        const outerIndex = this.unitIndex;
        const currentUnit = this.interactiveContent.chapters[outerIndex];
        const innerIndex = currentUnit.chapters.findIndex(el => el.$id === this.chapter.$id);
        Vue.set(this, 'chapter', null);
        Vue.nextTick(() => {
          if (currentUnit.chapters.length === innerIndex + 1 && this.interactiveContent.chapters.length > (outerIndex + 1)) {
            Vue.set(this, 'chapter', this.interactiveContent.chapters[outerIndex + 1].chapters[0]);
            Vue.set(this, 'unitIndex', outerIndex + 1);
          } else if (currentUnit.chapters.length > innerIndex + 1) {
            Vue.set(this, 'chapter', this.interactiveContent.chapters[outerIndex].chapters[innerIndex + 1]);
            Vue.set(this, 'unitIndex', outerIndex);
          }
        })
      }
    }
  },
  methods: {
    getRecordOrContent: Fandom.getRecordOrContent,
    loadChapter(data) {
      this.chapter = null
      Vue.nextTick(() => {
        this.chapter = data.chapter,
        this.unitIndex = data.unitIndex
      });
    },
    handleVideoPlayer(player) {
      Vue.set(this, 'videoPlayer', player);
    }
  },
  computed: {
    currentBreakpoint() {
      for (let breakpoint in globalState.viewport) {
        if (globalState.viewport[breakpoint] == true) {
          return breakpoint;
        }
      }
    },
    liveInteractions() {
      return get(this.interactiveContent, ["live_interactions"], []);
    },
    interactiveContent() {
      return this.getRecordOrContent(this.content.interactive_content);
    },
    interactiveContentContainer() {
      return typeof this.content.interactive_content == 'string' ? this.interactiveContent : this.containerContent;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :style="theme" v-if="isContentVisibleToUser()" :class="contentType" page-on-demand-event-component>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md">
          <div class="row justify-content-center">
            <div class="col-12 px-0 bg-light" v-if="!!chapter && !!chapter.play">
              <embed-advanced
                :class="{'container-lg px-0': !interactiveContent}"
                :content="chapter"
                :opt-container-content="interactiveContentContainer"
                @video-player-handler="handleVideoPlayer"
              ></embed-advanced>
            </div>
            <div v-if="['xs','sm','md'].includes(currentBreakpoint) && interactiveContent" class="col-12 d-block d-lg-none px-0">
              <page-live-event-interactive-sidebar
                :content="interactiveContent"
                :opt-container-content="interactiveContentContainer"
                :active-chapter="chapter"
                :unit-index="unitIndex"
                activeTab="chapters"
                @load-chapter="loadChapter"
              ></page-live-event-interactive-sidebar>
            </div>
            <div class="col-12 px-0" v-if="!!chapter">
              <component :is="stripe.layout" :content="stripe" v-for="(stripe) in chapter.stripes" :key="stripe.$id" :opt-container-content="interactiveContentContainer"></component>
            </div>
            <div class="col-12 px-0" v-if="!!content.stripes">
              <component :is="stripe.layout" :content="stripe" v-for="(stripe) in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
            </div>
          </div>
        </div>
        <div v-if="['lg','xl'].includes(currentBreakpoint) && interactiveContent"  class="col-lg-auto d-none d-lg-block px-0 on-demand-event-interactive-sidebar">
          <page-live-event-interactive-sidebar
            :content="interactiveContent"
            :opt-container-content="interactiveContentContainer"
            :active-chapter="chapter"
            :unit-index="unitIndex"
            activeTab="chapters"
            @load-chapter="loadChapter"
          ></page-live-event-interactive-sidebar>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
[page-on-demand-event-component] {
  color: var(--theme-color);
  background-color: var(--theme-background);

  .on-demand-event-interactive-sidebar {
    width: 26.5rem;
  }
}
</style>