<template>
  <div ai-speech-smile-component class="position-relative">
    <canvas class="webgl"></canvas>
    <div v-if="content.face_overlay_image && !debug" class="overlay-texture" :style="{'background-image': `url('${content.face_overlay_image.url}')` }"></div>
    <div v-if="debug" class="position-fixed d-flex justify-content-center align-items-center w-100 p-4" style="bottom: 0; z-index: 1000;">
      <button class="btn btn-light btn-sm mx-2" @click="playEmotion('happy')" :disabled="threejs.animations.emotion.playing">Happy</button>
      <button class="btn btn-light btn-sm mx-2" @click="playEmotion('sad')" :disabled="threejs.animations.emotion.playing">Sad</button>
    </div>
  </div>
</template>

<script>
import { throttle } from 'lodash';
import * as THREE from 'three';
import anime from 'animejs/lib/anime.es.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

// https://github.com/mdn/webaudio-examples/blob/main/voice-change-o-matic/scripts/app.js#L108-L193
export default {
  props: {
    audioUrl: {
      type: String,
      required: false
    },
    emotion: {
      type: String,
      default: 'neutral',
      validator: (value) => {
        return ['neutral', 'happy', 'sad'].includes(value)
      }
    },
    content: {
      type: Object,
      default: () => { return {}; }
    }
  },
  data: function() {
    return {
      mediaStreamSource: null,
      drawAnimationFrame: null,
      freq: 0,
      angle: 0,
      volume: {
        currentValue: 0,
        toValue: null,
        animationMs: 100
      },
      threejs: {
        scene: null,
        camera: null,
        controls: null,
        renderer: null,
        effectComposer: null,
        unrealBloomPass: null,
        SMAAPass: null,
        face: {
          width: 1,
          idleWidth: 0.65,
          height: 0.1,
          idleHeight: 0.02,
          depth: 0.1,
          segments: 100,
          mesh: null,
          color: this.content.face_color || '#ffffff',
          minBloomStrength: 0.5
        },
        sizes: {
          width: window.innerWidth,
          height: window.innerHeight
        },
        clock: null,
        lastTime: null,
        animations: {
          emotion: {
            progress: 0,
            type: null,
            playing: false
          },
          idle: {
            progress: 1,
            playing: false
          }
        },
        backgroundColor: this.content.face_background_color || '#000000'
      }
    }
  },
  mounted() {
    this.threejsInit();
    if (this.audioUrl) {
      this.playIdle('reverse');
    }
  },
  beforeDestroy() {
    this.mediaStreamSource?.stop();
  },
  methods: {
    // THREEJS CODE
    threejsInit(){
      const canvas = document.querySelector('canvas.webgl');

      this.createScene();
      this.createCamera();
      this.createFace();
      this.createRenderer(canvas);
      this.createBloomPass();
      this.createSMAAPass();
      this.createClock();
      if (this.debug) { this.createControls(canvas); }

      window.addEventListener('resize', this.initResizeListener);
      this.initResizeListener();

      this.tick();
    },
    createScene() {
      this.threejs.scene = new THREE.Scene();
      this.threejs.scene.background = new THREE.Color(this.threejs.backgroundColor);
    },
    addToScene(object) {
      this.threejs.scene.add(object);
    },
    createCamera() {
      const size = 1;
      const aspectRatio = this.threejs.sizes.width / this.threejs.sizes.height;
      this.threejs.camera = new THREE.OrthographicCamera(- size * aspectRatio, size * aspectRatio, size, - size, 0.01, 100);

      this.threejs.camera.position.set(0, 0, 10);
      this.threejs.camera.zoom = this.scale;

      this.addToScene(this.threejs.camera);
    },
    createFace() {
      let material = null;
      const geometry = new THREE.BoxGeometry(this.threejs.face.width, this.threejs.face.height, this.threejs.face.depth, this.threejs.face.segments, 1, 1);
      
      this.threejs.face.originalGeometry = geometry;
      if (this.debug) {
        material = new THREE.MeshNormalMaterial();
      } else {
        material = new THREE.MeshBasicMaterial({ color: new THREE.Color(this.threejs.face.color) });
      }
      this.threejs.face.mesh = new THREE.Mesh(geometry, material);

      this.addToScene(this.threejs.face.mesh);
    },
    createRenderer(canvas) {
      this.threejs.renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true,
        alpha: true
      });

      this.threejs.renderer.setClearColor(0xffffff, 0);
      this.threejs.renderer.setSize(this.threejs.sizes.width, this.threejs.sizes.height);
      this.threejs.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

      this.threejs.effectComposer = new EffectComposer(this.threejs.renderer);
      this.threejs.effectComposer.setSize(this.threejs.sizes.width, this.threejs.sizes.height);
      this.threejs.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

      const renderPass = new RenderPass(this.threejs.scene, this.threejs.camera);
      this.threejs.effectComposer.addPass(renderPass);
    },
    createBloomPass() {
      this.threejs.unrealBloomPass = new UnrealBloomPass();
      this.threejs.unrealBloomPass.enabled = true;
      this.threejs.unrealBloomPass.strength = 0;
      this.threejs.unrealBloomPass.radius = 1;
      this.threejs.unrealBloomPass.threshold = 0.4;
      this.threejs.effectComposer.addPass(this.threejs.unrealBloomPass);
    },
    createSMAAPass() {
      this.threejs.SMAAPass = new SMAAPass();
      this.threejs.effectComposer.addPass(this.threejs.SMAAPass);
    },
    createClock() {
      this.threejs.clock = new THREE.Clock();
    },
    createControls(canvas) {
      this.threejs.controls = new OrbitControls(this.threejs.camera, canvas);
      this.threejs.controls.enableDamping = true;
    },
    initResizeListener() {
      this.threejs.sizes.width = window.innerWidth;
      this.threejs.sizes.height = window.innerHeight;

      const size = 1;
      const aspectRatio = this.threejs.sizes.width / this.threejs.sizes.height;

      this.threejs.camera.left = - size * aspectRatio;
      this.threejs.camera.right = size * aspectRatio;
      this.threejs.camera.top = size;
      this.threejs.camera.bottom =  - size;
      this.threejs.camera.zoom = this.scale;

      this.threejs.camera.updateProjectionMatrix();

      this.threejs.renderer.setSize(this.threejs.sizes.width, this.threejs.sizes.height);
      this.threejs.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

      this.threejs.effectComposer.setSize(this.threejs.sizes.width, this.threejs.sizes.height);
      this.threejs.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    },
    updateFaceGeometry() {
      let geometry = null;

      if (this.threejs.animations.idle.progress > 0) {
        const height = Math.max(this.threejs.face.height * (1 - this.threejs.animations.idle.progress), this.threejs.face.idleHeight);
        geometry = new THREE.BoxGeometry(this.threejs.face.idleWidth, height, this.threejs.face.depth, this.threejs.face.segments, 1, 1);
      } else {
        const width = Math.max(this.volume.currentValue * 5, this.threejs.face.idleWidth);
        geometry = new THREE.BoxGeometry(width, this.threejs.face.height, this.threejs.face.depth, this.threejs.face.segments, 1, 1);
      }
      
      const vPos = geometry.attributes.position.array;
      
      if (this.threejs.animations.emotion.playing) {
        let angle = 0;

        switch (this.threejs.animations.emotion.type) {
          case 'happy':
            angle = this.threejs.animations.emotion.progress;
            break;
          case 'sad':
            angle = - this.threejs.animations.emotion.progress;
            break;
        }

        if (angle != 0) {
          for (let i = 0; i < vPos.length; i += 3) {
            const x = vPos[i];
            const y = vPos[i + 1];
            const z = vPos[i + 2];

            const theta = x * angle;
            const sinTheta = Math.sin(theta);
            const cosTheta = Math.cos(theta);

            vPos[i] = -(y - 1.0 / angle) * sinTheta;
            vPos[i + 1] = (y - 1.0 / angle) * cosTheta + 1.0 / angle;
            vPos[i + 2] = z;
          }
        }
      }

      this.threejs.face.mesh.geometry.attributes.position.array = vPos;
      this.threejs.face.mesh.geometry.attributes.position.needsUpdate = true;
    },
    tick() {
      const elapsedTime = this.threejs.clock.getElapsedTime();
      const deltaTime = elapsedTime - this.threejs.lastTime;
      if (deltaTime >= (this.volume.animationMs / 1000)) {
        this.threejs.lastTime = elapsedTime;
        this.volume.toValue = this.volumeRaw;
        this.animateVolume();
      }

      if (this.threejs.animations.idle.progress > 0) {
        this.threejs.unrealBloomPass.strength = this.threejs.face.minBloomStrength * (1 - this.threejs.animations.idle.progress);
      } else {
        this.threejs.unrealBloomPass.strength = this.threejs.face.minBloomStrength + (this.volume.currentValue / 2);
      }

      this.updateFaceGeometry();

      if (this.debug) { this.threejs.controls.update(); }

      this.threejs.effectComposer.render();
      window.requestAnimationFrame(this.tick);
    },
    animateVolume(){
      anime({
        targets: this.volume,
        currentValue: this.volume.toValue,
        easing: 'linear',
        duration: this.volume.animationMs
      });
    },
    playEmotion(type){
      if (['happy', 'sad'].includes(type)) {
        this.threejs.animations.emotion.type = type;
        this.threejs.animations.emotion.playing = true;
        anime({
          targets: this.threejs.animations.emotion,
          progress: [
            { value: 1, duration: 500, delay: 1000 },
            { value: 0, duration: 500, delay: 3000 }
          ],
          easing: 'linear',
          complete: () => {
            this.threejs.animations.emotion.playing = false;
            this.threejs.animations.emotion.type = null;
          }
        });
      }
    },
    playIdle(type){
      this.threejs.animations.idle.playing = true;
      if (type == 'reverse') {
        anime({
          targets: this.threejs.animations.idle,
          progress: 0,
          duration: 600,
          delay: 1000,
          easing: 'easeOutQuart',
          complete: () => {
            this.threejs.animations.idle.playing = false;
            this.mediaStreamSource?.stop();
            this.togglePlayback();
          }
        });
      } else {
        anime({
          targets: this.threejs.animations.idle,
          progress: 1,
          duration: 600,
          easing: 'linear',
          complete: () => {
            this.threejs.animations.idle.playing = false;
          }
        });
      }
    },
    // END THREEJS CODE
    throttle: throttle((value) => {
      return value;
    }, 100),
    togglePlayback() {
      let request = new XMLHttpRequest();
      request.open('GET', this.audioUrl, true);
      request.responseType = 'arraybuffer';
      request.onload = () => {
        const audioContext = new AudioContext();
        audioContext.decodeAudioData(request.response, (buffer) => {
          this.mediaStreamSource = audioContext.createBufferSource();
          this.mediaStreamSource.buffer = buffer;
          this.mediaStreamSource.start();
          this.mediaStreamSource.onended = () => this.$emit('ended');
          this.$emit('playing', buffer.duration);
          const analyser = this.connect(audioContext);
          this.updatePitch(analyser);
        });
      }
      request.send();  
    },
    connect(audioContext) {
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 256;
      analyser.minDecibels = -60;
      analyser.maxDecibels = -10;
      this.mediaStreamSource.connect(analyser);
      analyser.connect(audioContext.destination);
      return analyser;
    },
    updatePitch(analyser) {
      if (this.drawAnimationFrame)
        window.cancelAnimationFrame(this.drawAnimationFrame);

      let bufSize = analyser.fftSize;
      let buf = new Uint8Array(bufSize);      

      const draw = () => {
        this.drawAnimationFrame = requestAnimationFrame(draw);
        analyser.getByteFrequencyData(buf);
        
        let height = 0;
        let size = 0;

        for (let i = 0; i < bufSize; i++) {
          height += buf[i];
          if (buf[i] > 0)
            size++;
        }
        
        this.freq = size > 0 ?  height / size : 0; 
      };

      draw();
    }
  },
  computed: {
    synthesizer() {
      return {
        width: `${(this.throttle(this.freq) * 100) / 255}%`
      };
    },
    volumeRaw() {
      return this.throttle(this.freq) / 255;
    },
    scale() {
      let scale = 1;
      if (globalState.viewport.lg) {
        scale = 0.50;
      } else if (globalState.viewport.md) {
        scale = 0.40;
      } else if (globalState.viewport.sm) {
        scale = 0.30;
      } else if (globalState.viewport.xs) {
        scale = 0.20;
      }
      return scale;
    },
    debug() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      return urlParams.has('debug');
    }
  },
  watch: {
    audioUrl(newVal, oldVal) {
      if (!newVal) {
        this.mediaStreamSource?.stop();
      }

      if (!this.threejs.animations.idle.playing && newVal !== oldVal) {
        if (newVal) {
          this.playIdle('reverse');
        } else {
          this.playIdle();
        }
      }
    },
    emotion(newVal, oldVal) {
      if (newVal && newVal!==oldVal) {
        this.playEmotion(newVal);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.synthesizer {
  height: 150px;
  width: 600px;
  max-width: 100%;

  > div {
    height: 30px;
    min-width: 50px;
    transition: width .05s linear;
    box-shadow: 0px 0px 10px $white;
  }
}

.overlay-texture {
  position: absolute;
  background-size: cover;
  background-position: center;
  inset: 0;
}
</style>