<template>
  <form @submit.prevent="paymentIntent" class="payment-form">
    <div class="payment-form-content p-3">
      <div class="h4 text-center">{{ft('ecommerce.amount_recap')}} €{{amount}}</div>
      <div class="form-group">
        <label for="email">{{ft('ecommerce.email')}}</label>
        <input type="email" pattern=".+@.+\..+" class="w-100" placeholder="jenny.rosen@example.com" v-model="billingDetails.email" required>
      </div>
      <div class="form-group">
        <label for="name">{{ft('ecommerce.name')}}</label>
        <input type="text" class="w-100" placeholder="Jenny Rosen" v-model="billingDetails.name" required>
      </div>

      <button class="purchase-btn btn btn-primary mt-3" :disabled="loading">
        <i v-show="loading" class="fa-spin fal fa-circle-notch mr-2"></i>
        <span>{{ft('ecommerce.purchase')}}</span>
      </button>

      <button @click.prevent="$emit('payment-satispay:back')" class="purchase-btn btn-light text-black btn btn-secondary mt-3" :disabled="loading">
        <span>{{ft('ecommerce.button.back')}}</span>
      </button>
    </div>
  </form>
</template>

<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  props: {
    amount: {
      type: [String, Number],
      required: true
    },
    productName: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      currency: null,
      extraBillingDetails: {},
      billingDetails: {
        name: "",
        email: ""
      },
      loading: false,
      errorText: ""
    };
  },
  methods: {
    paymentIntent() {
      this.loading = true;
      Fandom.ajax({
        url: "/api/v5/satispay/create_payment_intent",
        method: "GET",
        data: {
          purchased_content: this.productName,
          amount: this.amount,
          extra_billing_details: this.billingDetails,
        },
        success: (data) => {
          this.loading = false;
          if (data.redirect_url) {
            window.location.href = data.redirect_url;
          }
        },
        error: () => {
          this.loading = false;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-form {
  align-self: center;

  &-content {
    background-color: var(--theme-background);
    color: var(--theme-color);
    border: 2px solid $black;
  }

  label {
    margin-bottom: 0;
    -webkit-font-smoothing: antialiased;
  }
  
  input {
    line-height: 20px;
    padding: 12px
  }

  .amount-input {
    flex: 1;
  }

  .amount-preselection {
    &-container {
      flex-wrap: wrap;
      gap: 10px;

      .btn-amount-preselection {
        flex: 1;
        min-width: 70px;
      }
    }
  }

  .purchase-btn {
    color: #ffffff;
    border-radius: 4px;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;

    &:hover {
      filter: contrast(115%);
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
</style>