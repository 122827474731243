<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { get } from 'lodash';
import postscribe from "postscribe";

export default {
  mixins: [fandomBasicMixin],
  mounted: function() {
    Vue.nextTick(() => {
      this.initFullscreen();

      if (this.content.snippet) {
        postscribe(`#${this.snippetId}`, this.content.snippet);
      }
    });

    Fandom.onEvent("newInteractionsInPage", (data) => {
      this.notice = data;
      this.notice.message = this.ft("globals.versus.notice_new");
    });

    // Fandom.onEvent("newInteractionResultsInPage", (data) => {
    //   this.notice = data;
    //   this.notice.message = this.ft("globals.versus.notice_result");
    // });
  },
  data: function() {
    return {
      embed: {
        fullscreen: false,
        unsupportedFullscreenClass: ""
      },
      notice: null,
      noticeTimeout: null,
      survey: null,
      modal: null,
      modalInfo: null
    }
  },
  watch: {
    notice(newValue, previousValue) {
      if (newValue) {
        clearTimeout(this.noticeTimeout);
        this.noticeTimeout = setTimeout(() => {
          this.notice = null;
        }, 10000);
      } else {
        clearTimeout(this.noticeTimeout);
      }
    }
  },
  methods: {
    jumpToNotice(elId) {
      if (['xs','sm', 'md'].includes(this.currentBreakpoint)) {
        $("html, body").animate({
            scrollTop: $(elId).offset().top - 80
        }, 500);
      } else {
        $(".interactions-area").animate({
            scrollTop: $(".interactions-area").scrollTop() - $(".interactions-area").offset().top + $(elId).offset().top 
        }, 500);
      }
    },
    toggleNotice() {
      if (this.embed.fullscreen) {
        this.toggleFullscreen();
      }

      const elId = `#anchor-${this.notice.containerContentId}-${this.notice.interactionsStatusChangedIds[0]}`;
      this.jumpToNotice(elId);
      
      this.notice = null;
    },
    toggleSurvey() {
      if (this.embed.fullscreen) {
        this.toggleFullscreen();
      }

      this.$refs.survey.toggleSurvey();
    },
    toggleModal() {
      // if (this.embed.fullscreen) {
      //   this.toggleFullscreen();
      // }

      this.$refs.modal.toggleModal();
    },
    isIOsMobile() {
      return /iPad/i.test(navigator.userAgent);
    },
    initFullscreen() {
      const target = $(this.$refs.embed)[0];
      target.addEventListener("fullscreenchange", this.onFullscreenChange);
      target.addEventListener("webkitfullscreenchange", this.onFullscreenChange);
      target.addEventListener("mozfullscreenchange", this.onFullscreenChange);
    },
    onFullscreenChange() {
      this.embed.fullscreen = Fandom.exists(document.fullscreenElement) || 
                              Fandom.exists(document.mozFullScreenElement) || 
                              Fandom.exists(document.webkitFullscreenElement);
    },
    toggleFullscreen() {
      const target = $(this.$refs.embed)[0];
      if (this.embed.fullscreen) {
        if (document.requestFullscreen) {
          document.exitFullscreen();
        } else if (document.mozRequestFullScreen) { /* Firefox */
          document.mozCancelFullScreen();
        } else if (target.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          document.webkitExitFullscreen();
        } else {
          // unsupported
          this.embed.unsupportedFullscreenClass = "";
          this.embed.fullscreen = false;
        }
      } else {
        if (target.requestFullscreen) {
          target.requestFullscreen();
        } else if (target.mozRequestFullScreen) { /* Firefox */
          target.mozRequestFullScreen();
        } else if (target.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          target.webkitRequestFullscreen();
        } else {
          // unsupported
          this.embed.unsupportedFullscreenClass = "unsupported-fullscreen"
          this.embed.fullscreen = true;
        }
      }
    }
  },
  computed: {
    snippetId() {
      return `#embed-advanced-${this.contentId.toString().replace("|", "-")}`;
    },
    currentBreakpoint() {
      for (let breakpoint in globalState.viewport) {
        if (globalState.viewport[breakpoint] == true) {
          return breakpoint;
        }
      }
    } 
  }
};
</script>

<template>
  <div embed-advanced-component>
    <div :id="snippetId" v-if="content.snippet"></div>
    <course-survey-carousel v-if="content.survey" :content="content.survey" :opt-container-content="containerContent" @notice="(data) => survey = data" ref="survey"></course-survey-carousel>
    <div ref="embed" class="d-flex align-items-center bg-black" :class="[embed.unsupportedFullscreenClass, {'position-absolute-center': embed.fullscreen}]" >
      <live-modal v-if="content.modal" :content="content.modal" :opt-container-content="containerContent" ref="modal" @notice="(data) => modal = data"></live-modal>
      <live-modal v-if="content.modal_info" :content="content.modal_info" :opt-container-content="containerContent" ref="modal-info" @notice="(data) => modalInfo = data"></live-modal>
      <div v-if="content.embed_media" v-html="content.embed_media" class="w-100" :class="{'embed_content': embed.fullscreen}"></div>
      <div v-else-if="exists(content.play)" class="w-100" :class="{'embed_content': embed.fullscreen}">
        <component 
          class="embed-responsive embed-responsive-16by9" 
          :is="`embed-${content.play.media_type}`"  
          :content="content" 
          :opt-container-content="containerContent"
          @video-player-handler="$emit('video-player-handler', arguments[0])"
          @overvideo-interaction-handler="$emit('overvideo-interaction-handler', arguments[0])">
        </component>  
      </div>
      <div v-else-if="content.image" class="bg-cover-center ar-16by9 w-100" :style="{'background-image': `url(${content.image.url})`}">
      </div>
      <a v-if="embed.fullscreen && !isIOsMobile()" href="javascript: void(0)" @click="toggleFullscreen()" class="embed-fullscreen__close-icon p-3 text-white">
        <i class="fal fa-times"></i>
      </a>
      <div class="embed_notification d-flex-inline flex-column" :class="{'embed_notification--ipad': embed.fullscreen && isIOsMobile()}">
        <transition :enter-active-class="['xs','sm', 'md'].includes(currentBreakpoint) ? 'animated fadeInDown' : 'animated fadeInUp'" :leave-active-class="['xs','sm', 'md'].includes(currentBreakpoint) ? 'animated fadeOutUp' : 'animated fadeOutDown'">
          <button v-if="survey && survey.active" type="button" class="btn btn-light font-weight-normal d-flex align-items-center mb-2 w-100 justify-content-center" @click="toggleSurvey()">
            {{ft("globals.survey.notice")}}<i class="ml-2 fal fa-arrow-right"></i>
          </button>
        </transition>
        <transition :enter-active-class="['xs','sm', 'md'].includes(currentBreakpoint) ? 'animated fadeInDown' : 'animated fadeInUp'" :leave-active-class="['xs','sm', 'md'].includes(currentBreakpoint) ? 'animated fadeOutUp' : 'animated fadeOutDown'">
          <button v-if="modal && modal.active" type="button" class="btn btn-light font-weight-normal d-flex align-items-center mb-2 w-100 justify-content-center" @click="toggleModal()">
            {{content.modal.button_notice_text || ft("globals.modal.notice")}}<i class="ml-2 fal fa-arrow-right"></i>
          </button>
        </transition>
        <transition :enter-active-class="['xs','sm', 'md'].includes(currentBreakpoint) ? 'animated fadeInDown' : 'animated fadeInUp'" :leave-active-class="['xs','sm', 'md'].includes(currentBreakpoint) ? 'animated fadeOutUp' : 'animated fadeOutDown'">
          <button v-if="notice" type="button" class="btn btn-light font-weight-normal d-flex align-items-center mb-2 w-100 justify-content-center" @click="toggleNotice()">
            {{notice.message}}<i class="ml-2 fal fa-arrow-right"></i>
          </button>
        </transition>
      </div>
    </div>
    <div class="w-100 embed-fullscreen__controls py-2 px-3 d-block-ie-flex align-items-center" :class="containerContent.theme == 'dark' ? 'bg-gray-1000' : 'bg-black'">
      <a v-if="content.embed_media || content.play" href="javascript: void(0)" @click="toggleFullscreen()" class="text-white font-weight-bold d-flex align-items-center justify-content-end"><i class="mr-3 fal fa-expand-wide"></i>Fullscreen</a>
    </div>
  </div>
</template>


<style lang="scss">
[embed-advanced-component] {
  .embed-responsive {
    height: 100%;
  }

  .embed_notification {
    opacity: 0.7;
    position: absolute;
    right: 2rem;
    bottom: 3.25rem;
    z-index: 1050;
    
    .btn { 

      i {
        font-size: 1rem;
        margin-top: 1px;
      }
    }
  } 

  .embed-fullscreen__close-icon {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 2rem;
  }

  .embed-fullscreen__controls {

    i {
      font-size: 1.5rem;
    }

    &.bg-gray-1000 {
      background-color: $gray-1000;
    }
  }
  
  .unsupported-fullscreen {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 1090;
    background-color: $black;
  }

  .embed_content {
    width: 100vw;
  }

  @include media-breakpoint-down(md) {

    .embed_notification {
      left: 1rem;
      top: 1rem;
      bottom: auto;
      right: auto;

      &.embed_notification--ipad {
        top: 5rem !important;
      }
    } 
  }

  @media (min-aspect-ratio: 16/9) {
    .embed_content {
      height: 100vh;
      width: calc((16 / 9) * 100vh) !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
}
</style>
