import { render, staticRenderFns } from "./ai-face-recognition.vue?vue&type=template&id=d4af3396&scoped=true"
import script from "./ai-face-recognition.vue?vue&type=script&lang=js"
export * from "./ai-face-recognition.vue?vue&type=script&lang=js"
import style0 from "./ai-face-recognition.vue?vue&type=style&index=0&id=d4af3396&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4af3396",
  null
  
)

export default component.exports