<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  methods: {
    hasBio(item) {
      return get(item, ["bio"], false);
    }
  },
  computed: {
    isMobile() {
      return globalState.viewport.sm || globalState.viewport.xs;
    },
    areArrowsVisible() {
      return !this.isMobile;
    },
    paddingY() {
      const paddingY = get(this, ["content", "padding_y"], 4);
      return [
        `py-${paddingY}`
      ];
    },
    containerClasses() {
      return this.propagateBackground ? "themed-item" : "";
    },
    containerStyles() {
      return {
        "background-image": this.propagateBackground ? this.backgroundImage : ""
      };
    },
    contentClasses() {
      return this.propagateBackground ? "" : "themed-item";
    },
    contentStyles() {
      return {
        "min-height": this.content.height,
        "background-image": this.propagateBackground ? "" : this.backgroundImage
      };
    },
    backgroundImage() {
      return this.getContentResponsiveImageURL("background_image");
    },
    propagateBackground() {
      return get(this, ["content", "propagate_background"], false);
    },
    columnClasses() {
      const columnWidth = get(this, ["content", "width"], 8);
      return `col-lg-${columnWidth}`;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="contentType" :style="theme">
    <div class="w-100 bg-cover-center themed-item" :class="[containerClasses, paddingY]" :style="containerStyles">
      <div class="container" :class="paddingY">
        <div class="row justify-content-center">
          <div class="col-12" :class="columnClasses">
            <div class="row mx-0 align-items-center" :class="content.theme">
              <div class="px-0 col-12" :class="{'mt-3': index > 0}" v-for="(person,index) in content.items" :key="`${index}`">
                <people-modal :content="person" :optContainerContent="containerContent">
                  <div class="p-3 person d-flex justify-content-begin align-items-center" :class="{'has-bio': hasBio(person)}">
                    <div class="photo">
                      <div class="ar-1by1 bg-cover-center rounded-circle" :title="person.name" :style="{'background-image': `url('${person.photo.url}')`}"></div>
                    </div>
                    <div class="d-flex flex-column justify-content-center mx-3 description">
                      <h3 class="text-uppercase name mb-2 h4">{{person.name}}</h3>
                      <p class="mb-0" v-html="person.role" v-if="person.role"></p>
                    </div>
                    <div v-if="areArrowsVisible && hasBio(person)" class="text-right flex-grow-1 arrow">
                      <i class="fal fa-long-arrow-right"></i>
                    </div>
                  </div>
                </people-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.person {
  color: $dark;
  background-color: #f9f9f9;

  .dark & {
    color: $light;
    background-color: #1a1a1a;
  }
  
  &.has-bio {
    @include hover() {
      .arrow {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

.arrow {
  font-size: 1.5rem;
  transform: translate3d(-1.5rem, 0, 0);
  transition: transform .5s;
  width: 3rem;
}

.role {
  font-size: 1rem;
}

.description {
  min-width: 50%;
}

.photo {
  width: 3.5rem;
  min-width: 3.5rem;

  @include media-breakpoint-up(md) {
    width: 5rem;
    min-width: 5rem;
  }
}
</style>
