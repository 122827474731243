import { fandomTranslate } from "src/modules/common_module";
import { componentsMixin } from "src/modules/inline_editing_module.js";
import Intersect from "vue-intersect";
import { get } from "lodash";
import { USER_TYPE_RULES } from './utils/constants';

const fandomBasicMixin = {
  props: {
    content: Object,
    optContainerContent: Object
  },
  mixins: [componentsMixin],
  data() {
    return {
      pageModel: globalState.pageModel,
      globalState: globalState
    };
  },
  computed: {
    anchorId() {
      if (this.content.stripe_id) {
        return this.content.stripe_id;
      } else {
        return this.isContent ? this.containerContent.name : `${this.containerContent.name}_${this.content.$id}`;
      }
    },
    containerContent() {
      return this.optContainerContent ? this.optContainerContent : this.content;
    },
    easyadminId() {
      return this.contentName;
    },
    contentId() {
      return this.isContent ? this.containerContent.id : `${this.containerContent.id}|${this.content.$id}`;
    },
    contentName() {
      return this.isContent ? this.containerContent.name : `${this.containerContent.name}|${this.content.$id}`;
    },
    contentType() {
      return this.isContent ? "_content" : "_content _content-record";
    },
    decorator() {
      return this.getDecorator(this.content);
    },
    isContent() {
      return !Fandom.exists(this.content.$id);
    },
    mainContent() {
      const mainContentName = this.pageModel.main_content_name;
      return get(this.pageModel, ["name_to_content", mainContentName], {});
    },
    isEmbedded() {
      return globalState.isEmbedded;
    }
  },
  methods: {
    fandomTranslate: fandomTranslate,
    ft: fandomTranslate,
    isContentVisibleToUser: Fandom.isContentVisibleToUser,
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
    getContentResponsiveImage: Fandom.getContentResponsiveImage,
    getTileThumbnailUrl: Fandom.getTileThumbnailUrl,
    exists: Fandom.exists,
    getTarget: Fandom.getTarget,
    getRelNoFollow: Fandom.getRelNoFollow,
    getContent: Fandom.getContent,
    getDecorator: Fandom.getDecorator,
    applyContextToUrl: Fandom.applyContextToUrl,
    expandUrl: Fandom.expandUrl,
    getPrimaryButtonClass: Fandom.getPrimaryButtonClass,
    getSecondaryButtonClass: Fandom.getSecondaryButtonClass,
    registerAnimation: Fandom.registerAnimation
  }
};

const fandomRootMixin = {
  data() {
    return { 
      globalState: globalState,
      fandomTranslate: fandomTranslate,
      ft: fandomTranslate
    };
  },
  components: { Intersect },
  mounted() {
    Vue.nextTick(() => {
      this.offset = $("#main-navbar").outerHeight();
      $("body").css({ '--main-navbar-height': `${this.offset}px` });
    });
  },
  computed: {
    header() {
      return get(globalState, ["pageModel", "layout", "header"]) || {}; 
    },
    footer() {
      return get(globalState, ["pageModel", "layout", "footer"]) || {}; 
    },
  },
};

const fandomExtendedMixin = {
  props: {
    inheritThemeType: String
  },
  components: { Intersect },
  data() {
    return {
      inview: false,
      paddingYDefault: 0
    };
  },
  methods: {
    setInview() {
      this.inview = true;
    },
    toggleInview() {
      this.inview = !this.inview;
    },
    isContentVisibleToUser: Fandom.isContentVisibleToUser
  },
  computed: {
    themeType() {
      const decoratorThemeType = get(this, ["decorator", "theme"], "light"); 
      return this.content.theme || this.inheritThemeType || decoratorThemeType;
    },
    theme() {
      const isLight = this.themeType == "light";
      const defaultBackgroundColor = isLight ? "var(--white)" : "var(--dark)";
      const defaultTextColor = isLight ? "var(--dark)" : "var(--light)";
      const textColor = get(this, ["content", "text_color"], defaultTextColor);
      return {
        "--theme-background": get(this, ["content", "background_color"], defaultBackgroundColor),
        "--theme-color": textColor,
        "--theme-border-color": get(this, ["content", "border_color"], textColor)
      };
    },
    themeButtonClass() {
      return (this.content.button_class) ? this.content.button_class : Fandom.getPrimaryButtonClass(null, this.themeType);
    },
    extendedTheme() {
      let extendedTheme;
      if (this.themeType == "light") {
        extendedTheme = {
          "--theme-color-darker": "var(--gray-light)",
          "--theme-color-lighter": "var(--white)",
          "--theme-background-darker": "var(--gray-light)",
          "--theme-background-lighter": "var(--white)"
        };
      } else {
        extendedTheme = {
          "--theme-color-darker": "var(--black)",
          "--theme-color-lighter": "var(--gray-dark)",
          "--theme-background-darker": "var(--black)",
          "--theme-background-lighter": "var(--gray-dark)"
        };
      }
      return {...this.theme, ...extendedTheme};
    },
    paddingY() {
      const paddingY = get(this.content, ["padding_y"], this.paddingYDefault);
      const paddingTop = get(this.content, ["padding_top"], paddingY);
      const paddingBottom = get(this.content, ["padding_bottom"], paddingY);
      return `pt-${paddingTop} pb-${paddingBottom}`;
    }
  }
};

const fandomTileMixin = {
  computed: {
    miniformat() {
      const decorator = Fandom.getDecorator(this.content);
      if (!!decorator) {
        return decorator;
      } else {
        const categoryContent = Fandom.getContent(this.content.category || "");
        if (!!categoryContent) {
          return categoryContent;
        }
        return !!this.content.category ? { title: this.content.category } : false;
      }
    }
  }
}

const fandomAuthorizationMixin = {
  methods: {
    lockedContentClickHandler(event) {
      if (!this.isAccessible) {
        switch (this.lockingRuleType) {
          case "registration":
            Fandom.emit('interaction-unauthorized', {type: "registration"});
            break;
          case "user_group":
            Fandom.emit('show-user-group-required-modal', this.protectedContent);
            break;
          case "user_contents":
            Fandom.emit('show-user-contents-required-modal', {});
            break;
          default:
            break;
        }
      }
    },
    canComment(userId) {
      const userType = this.userType(userId);
      return get(userType, USER_TYPE_RULES.CAN_COMMENT, false);
    },
    userType(userId) {
      const user = Fandom.getUserById(userId);
      return user.type;
    },
    loggedUserBannedByCc(ccName) {
      return this.pageModel.user.banned?.includes(ccName) ?? false;
    }
  },
  computed: {
    protectedContent() {
      return (this.content.url && this.content.url.content ? Fandom.getContent(this.content.url.content) : this.content) || {};
    },
    isAccessible() {
      return get(this.pageModel.name_to_content_authorization, [this.protectedContent.name, "authorized"], true);
    },
    lockingRuleType() {
      return get(this.pageModel.name_to_content_authorization, [this.protectedContent.name, "type"], 'none');
    },
    loggedUserType() {
      return this.pageModel.user.type;
    },
    loggedUserCanComment() {
      return get(this.loggedUserType, USER_TYPE_RULES.CAN_COMMENT, false);
    },
    loggedUserBanned() {
      return this.pageModel.user.banned?.includes((this.content || this.optContainerContent).name) ?? false;
    }
  }
}

export {
  fandomBasicMixin,
  fandomRootMixin,
  fandomTileMixin,
  fandomExtendedMixin,
  fandomAuthorizationMixin
};
