const getAuthorizationHeaders = () => {
  if (globalState.isEmbedded && sessionStorage.getItem("session-jwt")) {
    return { "Authorization": `Bearer ${sessionStorage.getItem("session-jwt")}` };
  } else {
    return {};
  }
};

export {
  getAuthorizationHeaders
}
