<script>
import { fandomTranslate } from "src/modules/common_module";
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { audiEventsBasicMixin } from '../../modules/audi_events_mixins_module.js';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, audiEventsBasicMixin],
  props: {
    playButton: Boolean,
    buttonIds: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    buttonsRequired() {
      return (this.button || this.content.buttons || this.playButton || this.content.unregistered_buttons) && !this.signInRequired;
    },
    button() {
      if (Fandom.isUserRegistered()) {
        return this.content.registered_button ?? this.content.button;
      } else {
        return this.content.anonymous_button ?? this.content.button;
      }
    },
    buttonId() {
      if (this.buttonIds) {
        if (Fandom.isUserRegistered()) {
          return this.content.registered_button ? "registered_button_heading" : null;
        } else {
          return this.content.anonymous_button ? "anonymous_button_heading" : null;
        }
      }
    }
  },
  methods: {
    isUserRegistered: Fandom.isUserRegistered,
  }
};
</script>

<template>
  <div class="align-btn-group" v-if="buttonsRequired" buttons-component>
    <a 
      v-if="button" 
      :id="buttonId"
      class="btn my-2" 
      :class="getPrimaryButtonClass(content.button_class, content.theme)" 
      :target="getTarget(button)"
      @click.prevent="trackingButtonUrl($event)"
      :href="button.url"
    >
      {{button.title}}
    </a>
    <button
      v-if="playButton" 
      class="btn my-2" 
      :class="[getPrimaryButtonClass('btn-black'), content.button ? 'ml-2' : '', content.buttons ? 'mr-2' : '']" 
      @click="$emit('openvideo')"
    >
      {{ft("globals.watch_video")}}
    </button>
    <template v-if="content.buttons">
      <a 
        class="btn my-2" 
        v-for="(buttonRecord, index) in content.buttons" 
        :key="index" 
        :class="[getPrimaryButtonClass(buttonRecord.button_class, content.theme), index > 0 ? 'ml-2' : '']" 
        :target="getTarget(buttonRecord.button)"
        @click.prevent="trackingButtonUrl($event, buttonRecord.button_tracking, buttonRecord.button_event_cookie)"
        :href="buttonRecord.button.url"
      >
        {{buttonRecord.button.title}}
      </a>
    </template>
    <template v-if="content.unregistered_buttons && !isUserRegistered()">
      <a 
        class="btn my-2 ml-2" 
        v-for="(buttonRecord, index) in content.unregistered_buttons" 
        :key="index" 
        :class="getPrimaryButtonClass(buttonRecord.button_class, content.theme)" 
        :target="getTarget(buttonRecord.button)"
        @click.prevent="trackingButtonUrl($event, buttonRecord.button_tracking, buttonRecord.button_event_cookie)"
        :href="buttonRecord.button.url"
      >
        {{buttonRecord.button.title}}
      </a>
    </template>
  </div>
</template>

<style lang="scss">
[buttons-component] {
  .align-btn-group {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}
</style>
