import { render, staticRenderFns } from "./page-live-event-alert.vue?vue&type=template&id=2e41c6da&scoped=true"
import script from "./page-live-event-alert.vue?vue&type=script&lang=js"
export * from "./page-live-event-alert.vue?vue&type=script&lang=js"
import style0 from "./page-live-event-alert.vue?vue&type=style&index=0&id=2e41c6da&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e41c6da",
  null
  
)

export default component.exports