<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module';
import { mqLess } from 'src/modules/viewport_module';
import { TEXT_TO_SPEECH_SERVICES } from 'src/modules/utils/constants';

export default {
  mixins: [fandomBasicMixin],
  props: {
    parent: Object
  },
  data: function () {
    return {
      list: [],
      activeChat: null,
      editAction: false,
      settingsSection: false,
      expand: !mqLess("sm"),
      speechServices: TEXT_TO_SPEECH_SERVICES
    };
  },
  mounted() {
    this.fetchLists();
  },
  watch: {
    // "activeChat.settings.speech_service"(newVal, previousVal) {
    //   console.log("here");
    // },
    editAction(newVal, previousVal) {
      if (previousVal && !newVal) {
        this.editChat();
      }
    }
  },
  methods: {
    update(data) {
      this.$emit("update", data);
    },
    removeList(name) {
      if (confirm("La conversazione verrà eliminata definitivamente. Sei sicuro di voler procedere?")) {
        Fandom.ajax({
          method: "POST",
          url: Fandom.applyContextToUrl("/api/v5/ai/chat/delete/list"),
          data: {name: name},
          success: () => {
            this.list = this.list.filter((item) => item.name != name);
          }
        })
      }
    },
    editChat() {
      fetch(Fandom.applyContextToUrl("/api/v5/ai/chat/save"), {
        method: 'POST',
        body: JSON.stringify({
          content_name: this.content.name,
          name: this.activeChat.name,
          title: this.activeChat.title
        }),
        headers: {'Content-Type': 'application/json', 'X-CSRF-Token': $("meta[name=\"csrf-token\"]").attr("content")}
      }).then(response => response.json())
        .then(response => {
          // nothing to do
        })
        .catch(error => console.log(error))
    },
    createChat() {
      fetch(Fandom.applyContextToUrl(`/api/v5/ai/chat/create`), {
        method: 'POST',
        body: JSON.stringify({
          content_name: this.content.name
        }),
        headers: {'Content-Type': 'application/json', 'X-CSRF-Token': $("meta[name=\"csrf-token\"]").attr("content")}
      }).then(response => response.json())
        .then(response => {
          this.activeChat = response.chat;
          this.list.unshift(this.activeChat);
          this.update({chat: this.activeChat});
        })
        .catch(error => console.log(error))
    },
    fetchChat(chat) {
      this.activeChat = chat;
      Fandom.ajax({
        method: "GET",
        data: { name: chat.name },
        url: Fandom.applyContextToUrl("/api/v5/ai/chat/fetch"),
        success: (data) => {
          this.activeChat.messages = data.messages;
          this.activeChat.settings = data.settings;
          this.update({chat: this.activeChat});
        }
      });
    },
    fetchLists() {
      Fandom.ajax({
        method: "GET",
        url: Fandom.applyContextToUrl("/api/v5/ai/chat/fetch/list"),
        success: (data) => {
          this.list = data;
          if (this.list.length > 0) {
            this.fetchChat(this.list[0]);
          }
        }
      });
    }
  }
};
</script>

<template>
  <div page-chat-ai-sidebar-component>
    <div class="sidebar my-4" v-if="!settingsSection">
      <div class="heading d-flex justify-content-between align-items-center mb-3">
        <div>
          <h5 class="text-primary mb-0">CHAT</h5>
        </div>
        <div class="d-flex">
          <div v-if="expand" class="edit d-flex align-items-center justify-content-center mr-2" @click.prevent="createChat"><i class="fa-light fa-plus"></i></div>
          <div class="toggle d-flex d-md-none align-items-center justify-content-center mr-2" :class="{'active': expand}" @click="expand = !expand"><i class="fa-light fa-chevron-down"></i></div>
        </div>
      </div>
      <hr>
      <ul class="list-unstyled w-100" v-if="expand">
        <li v-for="(item, index) in list" 
            :key="`ai-list-${index}`" 
            :class="{'active': activeChat.name === item.name}"  
            class="d-flex align-items-center justify-content-between my-1 small"
            @click.prevent="fetchChat(item)">
                
          <input v-model="activeChat.title" v-if="activeChat.name === item.name && editAction" @blur="editAction = false" class="form-control p-2" type="text">
          <span v-else>{{item.title || '...'}}</span>
          <div class="d-flex align-items-center ml-3 actions">
            <i class="fa-light fa-pen-to-square cursor-pointer mr-2" @click="editAction = !editAction" v-if="activeChat.name === item.name && !editAction"></i>
            <i class="fa-light fa-trash-can cursor-pointer text-danger" @click="removeList(item.name)"></i>
          </div>
        </li>
      </ul>
    </div>
    <div class="settings sidebar my-4" v-if="activeChat && settingsSection">
      <div class="heading d-flex justify-content-between align-items-center mb-3">
        <div>
          <h5 class="text-primary mb-0">IMPOSTAZIONI</h5>
          <small>{{activeChat.title || '...'}}</small>
        </div>
        <div class="d-flex">
          <div class="edit d-flex align-items-center justify-content-center" @click.prevent="settingsSection = false"><i class="fa-light fa-close"></i></div>
        </div>
      </div>
      <hr>
      <div class="form-group">
        <label>Servizio per la voce</label>
        <select class="custom-select" v-model="activeChat.settings.speech_service">
          <option :value="null">Non attivo</option>
          <option :value="name" v-for="(name, value) in speechServices">{{value}}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[page-chat-ai-sidebar-component] {
  .sidebar {

    .edit, .toggle {
      width: 2rem;
      height: 2rem;
      border-radius: 0.5rem;
      background-color: $dark;
      color: $light;
      cursor: pointer;

      i { font-size: 1.25rem; }
      &.active { transform: rotate(180deg); }
    }

    li {
      background-color: transparent;
      border: 1px solid $light;
      border-radius: 0.5rem;
      padding: 0.5rem;

      .form-control {
        line-height: normal;
        height: auto;
        border-radius: 0.5rem;
      }

      .actions i { opacity: 0; };

      &.active, &:hover {
        background-color: $light !important; 
        .actions i { opacity: 1; };
      }

      a {
        color: $dark !important;
        text-decoration: none !important;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .sidebar {
      position: sticky;
      top: var(--main-navbar-height);
      width: 26.5rem;
      overflow-y: scroll;
      height: calc(100vh - var(--main-navbar-height));

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
</style>
