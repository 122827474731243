import { createElement, removeAllChild, loadScript } from '../utils';
import Fullscreen from '../Fullscreen';
import { PLAYER_STATUS } from 'src/modules/player/constants';
import defaultConfig from  'src/modules/player/config';

const SVP_EVENTS = {
  INITIALIZED: 'PLAYER.INITIALIZED',
  START: 'PLAYHEAD.START',
  UPDATE: 'PLAYHEAD.UPDATE',
  COMPLETE: 'PLAYHEAD.COMPLETE',
  CONNECTED: 'LIVE.CONNECTED'
}

class Svp {
  /**
   * 
   * @param {HTMLElement} wrapperEl
   * @param {Object} config
   */
  constructor(wrapperEl, config, code) {
    this.videoId = "3kejz7gw6ha8"//code;
    this.config = config;
    this.wrapperEl = wrapperEl;
    removeAllChild(wrapperEl);
    this.prevVolume = null;
    
    const target = typeof config.fullscreenElement === 'object' ? config.fullscreenElement : wrapperEl.parentElement;
    this.fullscreen = new Fullscreen(target);
    
    this.firstPlay = false;

    if (window.SVPDynamicPlayer) {
      this.setup();
    } else {
      // Load the SDK
      loadScript('svp-api', defaultConfig.urls.svp.sdk);
      this.checkSVPDynamicPlayerLoaded();
    }
  }

  /**
   * return the instance id
   */
  get id() {
    return `svp-${this.videoId}`;
  }

  checkSVPDynamicPlayerLoaded() {
    if (!!window.SVPDynamicPlayer) {
      return this.setup();
    } else {
      setTimeout(() => this.checkSVPDynamicPlayerLoaded(), 30);
    }
  }

  setup() {
    this.createSvpElement();
    this.ready();
  }

  createSvpElement() {
    this.videoEl = createElement('div', {id: `svp-${this.videoId}`});
    this.wrapperEl.appendChild(this.videoEl);
  }

  ready() {
    this.setPoster(Fandom.getContentResponsiveImage('thumbnail', this.config));
    const videoConfig = {
      ...defaultConfig.svp,
      //...this.config,
      clip_id: this.videoId,
      auto_play: this.config.autoplay ? "1" : "0",
      pause: this.config.autoplay ? "0" : "1",
      auto_start: "0",
      auto_play_type: this.config.autoplay || this.config.muted ? 'mute' : 'unMute'
    };
  
    this.player = new SVPDynamicPlayer(`svp-${this.videoId}`, "", "100%", "100%", {use_div: `svp-${this.videoId}`, skin:"3"}, videoConfig);
    this.attachListeners();
    this.player.execute();
  
    this.videoEl = document.getElementById(`svp_svp-${this.videoId}`);
    
    if (!this.config.autoplay) {
      this.showPoster();  
    } else {
      /**
       * NB - THIS IS NEEDED FOR MUTE/UNMUTE TO WORK
       */
      this.videoEl.setAttribute('allow', 'autoplay');
      this.videoEl.src = this.videoEl.src;
    }
  }

  attachListeners() {
    for (let state of Object.values(SVP_EVENTS)) {
      this.player.addPlayerEventListener(state, () => {
        const mappedState = this.mapState(state);
        if (mappedState === PLAYER_STATUS.PLAYING) {
          this.firstPlay = true;
          this.hidePoster();
        } else if (mappedState === PLAYER_STATUS.READY) {
          Fandom.emit('player-duration', this.getDuration())
        }

        Fandom.emit(mappedState, {id: this.id});
      });
    }
  }

  /**
   * Map youtube state to Fandom player status
   * @param {SVP_EVENTS} svpState
   * @returns PLAYER_STATUS
   */
  mapState(svpState) {
    let status = '';
    switch (svpState) {
      case SVP_EVENTS.INITIALIZED: 
        status = PLAYER_STATUS.READY
        break;
      case SVP_EVENTS.START:
        status = PLAYER_STATUS.PLAYING
        break;
      case SVP_EVENTS.COMPLETE:
        status = PLAYER_STATUS.ENDED
        break;
      default:
        status = 'unmapped'
        break;
    }

    return status;
  }

  /**
   * Set poster
   * @param {poster} poster 
   */
   setPoster(poster) {
    this.poster = poster;
  }

  /**
   * Show video poster
   */
   showPoster() {
    if (this.poster) {
      this.wrapperEl.style.backgroundImage = `url("${this.poster}")`;
      this.videoEl.classList.add('d-none');
    }
  }

  /**
   * Hide video poster
   */
  hidePoster() {
    this.videoEl.classList.remove('d-none')
  }

  /**
   * Removes the player instance and stops playback.
   * After deletion, the player no longer emits events or responds to API calls.
   */
   destroy() {
    this.player.destroy();
  }

  /**
   * Starts or resumes playback of the stream
   */
  play() {
    this.player.isLive() ? this.player.seekToAndPlay() : this.player.play();
  }

  /**
   * Pauses playback of the current stream
   */
  pause() {
    this.player.isLive() ? this.player.seekToAndPause() : this.player.pause();
  }

  /**
   * Gets the duration of the currently loaded media stream
   */
  getDuration() {
    return this.player.getStreamDuration();
  }

  /**
   * Gets the current position of the player, in seconds
   */
  getPosition() {
    return this.player.getStreamTime();
  }

  /**
   * Seeks to a specified time in the stream
   * @param {Number} time The position to seek to, in seconds
   */
  seekTo(time) {
    this.player.seekToAndPlay(time);
  }

  /**
   * Mutes or unmutes the player
   * @param {Boolean} mute True to mute the player, false to unmute
   * @returns {Number} volume The current volume
   */
  setMuted(mute) {
    if (this.prevVolume === null || mute) {
      this.prevVolume = 0.5;
    }

    const volumeToSet = mute ? 0 : this.prevVolume;
    this.setVolume(volumeToSet);
    this.player[mute ? 'mute' : 'unMute' ]();

    return volumeToSet;
  }

  /**
   * Gets whether the player is muted.
   * @returns boolean - True if the player is muted, false otherwise.
   */
  isMuted() {
    return this.player.isMuted();
  }

  /**
   * Gets the player's volume level.
   * @returns {Number} The volume level of the player.
   */
  getVolume() {
    return this.player.getVolume() / 100;
  }
  
  /**
   * Sets the playback volume
   * @param {Number} volume The volume to be set.
   */
  setVolume(volume) {
    this.player.setVolume(volume*100);
  }

  /**
   * Set of available Quality objects from the loaded source or empty if none are currently available.
   * @returns Quality[]
   */
  getQualities() {
    return []
  }

  /**
   * 
   * @returns Quality
   */
  getQuality() {
    return {};
  }

  /**
   * Sets the quality the player should use for playback.
   * @param {Quality} quality A valid quality entry from Player.getQualities.
   * @param {boolean} adaptive True for an adaptive quality change; that is, to change quality smoothly at the end of the current buffer. False to change quality immediately.
   */
  setQuality(quality, adaptive=false) {}
}

export default Svp;