<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  props: {
    initalCountdown: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      interval: null,
      strokeDashOffset: 352,
      countdown: this.initalCountdown
    }
  },
  mounted() {
    this.initCountdown();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    initCountdown() {
      let percentage = 0;

      let index = 0;
      this.interval = setInterval(() => {
        index++;
        
        percentage = (index * 100) / this.initalCountdown; // 100 : x = initalCountdown : index
        this.strokeDashOffset = 352 - (352 * percentage / 100);

        if (index == this.initalCountdown) {
          clearInterval(this.interval);
          this.goToNextContent();
        }

        this.countdown--;
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.interval);
      this.$emit('countdown-stopped');
    },
    goToNextContent() {
      this.$emit('next-bookmark');
    }
  }
};
</script>

<template>
  <div class="d-flex justify-content-center align-items-center flex-column py-4" course-countdown-component>
    <p class="h3 font-weight-normal mb-0">{{ft('ecommerce.next_lesson_in')}}</p>
    <div class="svg-container position-relative">
      <div class="countdown mx-2 my-3 d-flex flex-column justify-content-center align-items-center">
        <div class="countdown-circle-container d-flex position-absolute-center">        
          <div class="svg-container fill">
            <svg viewBox="0 0 120 120" class="position-absolute-center">
              <circle cx="60" cy="60" r="56" stroke-width="8" fill="none" transform="rotate(90, 60, 60)"/>
            </svg>
          </div>
          <div class="svg-container">
            <svg viewBox="0 0 120 120" class="position-absolute-center">
              <circle cx="60" cy="60" r="56" stroke-width="8" fill="none" transform="rotate(-90, 60, 60)" :style="{'stroke-dashoffset': strokeDashOffset}"/>
            </svg>
          </div>
        </div>
        <div class="countdown-number">{{countdown}}</div>
      </div>
    </div>
    <a href="javascript: void(0)" @click="stopCountdown();" class="course-countdown-undo text-uppercase"><small>{{ft('ecommerce.undo')}}</small></a>
  </div>
</template>

<style lang="scss">

[course-countdown-component] {

  .course-countdown-undo {
    color: $white;
  }

  .svg-container {

    .countdown {
      position: relative;
      height: 70px;
      width: 70px;
      text-align: center;
    }

    .countdown-number {
      color: $white;
      font-size: 1rem;
      font-weight: bold;
      z-index: 1;
    }

    .svg-container svg circle {
      stroke: $white;
      transition: all $animation-time-slow linear;
      stroke-dashoffset: 352; // svg circle circumference
      stroke-dasharray: 352;
    }

    .svg-container.fill svg circle {
      stroke: $black;
      stroke-dashoffset: 705 // svg circle circumference * 2;
    }
  }
}  
</style>