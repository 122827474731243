<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { audiEventsBasicMixin } from '../../modules/audi_events_mixins_module.js';
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin, audiEventsBasicMixin],
  data: function () {
    return {};
  },
  mounted() {
    this.registerAnimation();
  },
  computed: {
    category() {
      if (Fandom.exists(this.content.category)) {
        return this.content.category;
      } else {
        const decorator = Fandom.getDecorator(this.content);
        if (decorator) {
          return decorator.title;
        }
      }
    },
    colClass() {
      return `col-lg-${12/parseInt(get(this.content, 'column_count', 2))}`;
    }
  },
  methods: {
    expandUrl: Fandom.expandUrl,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getTarget: Fandom.getTarget,
    paddingClass(index) {
      const numCol = parseInt(get(this.content, 'column_count', 2));
      const pos = index % numCol;
      return pos === 0 ? 'padding-right' : pos === (numCol - 1) ? 'padding-left' : 'padding-x';
    }
  }
};
</script>

<template>
  <div 
    v-easyadmin="easyadminId" 
    :class="contentType"
    class="px-0 inview_animate"
    v-if="isContentVisibleToUser()" 
    stripe-keypoints-with-boxes
    :id="content.name"
  >
    <div class="container" :id="content.stripe_id">
      <div class="row inset justify-content-center">
        <div class="col-12 col-sm-8 col-lg-12">
          <div class="row">
            <div :class="[colClass, paddingClass(index)]" class="keypoint-col" v-for="(keypoint, index) in content.keypoint">
              <div class="keypoint-box ar-1by1 bg-cover-center" :style="{ 'background-image': getBackgroundUrl(keypoint.background_image.url) }">
                <div class="keypoint-box__info-container d-flex align-items-center">
                  <div class="keypoint-box__info w-100 px-4 d-flex align-items-center flex-column">
                    <p class="keypoint-box__info__category" v-if="keypoint.category">{{keypoint.category}}</p>
                    <h2 class="keypoint-box__info__title h1">{{keypoint.title}}</h2>
                    <p class="keypoint-box__info__subtitle h3" v-html="keypoint.subtitle" v-if="keypoint.subtitle"></p>
                    <p class="keypoint-box__info__description" v-html="keypoint.description" v-if="keypoint.description"></p>
                    <a class="btn btn-white" :href="keypoint.button.url" :target="getTarget(keypoint.button)" v-if="keypoint.button" @click.prevent="trackingButtonUrl($event, keypoint.button_tracking, keypoint.button_event_cookie)">{{keypoint.button.title}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-keypoints-with-boxes] {

  .keypoint-box {
    position: relative;
  }

  .keypoint-box__info-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .keypoint-box__info__category {
    color: white;
    text-align: center;
    font-size: 15px;
  }

  .keypoint-box__info__title {
    color: white;
    text-align: center;
  }

  .keypoint-box__info__description, .keypoint-box__info__subtitle {
    color: white;
    text-align: center;
    font-weight: normal;
    font-size: 1.5rem;
  }

  .keypoint-col.padding-right {
    padding-right: 1px;
    padding-left: 0;
  }

  .keypoint-col.padding-left {
    padding-left: 1px;
    padding-right: 0;
  }

  .keypoint-col.padding-x {
    padding-left: 1px;
    padding-right: 1px;
  }

  @include media-breakpoint-down(xs) {

    .keypoint-col:nth-child(odd) {
      padding-right: 0;
    }

    .keypoint-col:nth-child(even) {
      padding-left: 0;
    }
  }

}

</style>