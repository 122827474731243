<script>
import { get, debounce } from "lodash";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      sidebar: false
    };
  },
  methods: {
    toggleSidebar() {
      if (this.sidebar) {
        clearAllBodyScrollLocks();
        this.sidebar = false;
      } else {
        disableBodyScroll(this.$refs["sidebar"]);
        this.sidebar = true;
      }
    },
    itemId(item) {
      return `main-navbar-item-${item.$id}`;
    },
    isUrlCollapse(item) {
      return item.url.url == '$collapse';
    }
  },
  computed: {
    mainContentDecorator() {
      return Fandom.getDecorator(this.mainContent, "default");
    },
    theme() {
      return this.mainContent.theme || "light";
    },
    brandLogoUrl() {
      const defaultLink = get(this.content, ["brand_logo_url", "url"], "/");
      const contentLink = get(this.mainContent, ["brand_logo_url", "url"], defaultLink);
      return contentLink ? contentLink : decoratorLink;
    },
    isUserRegistered() {
      return Fandom.isUserRegistered();
    },
    navbarItems() {
      return get(globalState, ["pageModel", "layout", "header", "navbar_items"], []); 
    },
    logoAlt() {
      return get(this.pageModel, ['seo', 'title'], '');
    },
    logoUrl() {
      return get(this.content, [`${this.theme}_logo`, "url"], "");
    },
    whiteLogoUrl() {
      return get(this.content, ["dark_logo", "url"], "");
    },
    signInUrl() {
      const eventMktCode = this.mainContent.event_mkt_code;
      let result = this.applyContextToUrl('/auth/open_id_connect_audi_events'); 
      if (eventMktCode) {
        result += `?eventMktCode=${eventMktCode}`;
      }
      return result;
    },
    sidebarItems() {
      return get(globalState, ["pageModel", "layout", "header", "sidebar_items"], []); 
    },
    sidebarBottomLinks() {
      return get(globalState, ["pageModel", "layout", "header", "sidebar_bottom_links"], []); 
    }
  },
};
</script>

<template>
  <div class="sticky-top">
    <nav class="navbar navbar-expand-lg justify-content-between px-2" :class="theme" navbar-component>
      <div class="d-flex ml-3">
        <div v-if="sidebarItems.length > 0" class="d-flex justify-content-center align-items-center sidebar-toggler cursor-pointer" @click="toggleSidebar">
          <i class="far fa-bars fa-lg"></i>
        </div>
        <a :class="{'ml-3': sidebarItems.length > 0}" class="navbar-brand" :href="brandLogoUrl">
          <h1 class="mb-0">
            <img :src="logoUrl" class="navbar-logo mt-n4" :alt="logoAlt"/>
            <div class="logo-text">
              my<span class="font-weight-bold">Audi</span> Experience
            </div>
          </h1>
        </a>
      </div>
      <div class="px-4 d-none d-md-flex justify-content-center align-items-center">
        <div v-for="(item, index) in navbarItems" :key="`navbar-item-${index}`" class="nav-item px-4">
          <a :href="item.url.url" :target="getTarget(item.url)" v-if="item.url" class="nav-item-url px-2">{{item.url.title}}</a>
        </div>
      </div>
      <div class="pr-3">
        <div class="nav-item mb-1 d-flex justify-content-end">
          <a v-if="!isUserRegistered" class="nav-item-url d-flex" :href="signInUrl">
            {{ft('globals.navbar.sign_in')}}
          </a>
          <a v-else class="nav-item-url" :href="globalState.pageModel.user.end_session_redirect">
            {{ft('globals.navbar.sign_out')}}
          </a>
        </div>
      </div>
    </nav>
    <transition enter-active-class="animated faster fadeIn" leave-active-class="animated faster fadeOut">
      <div class="position-fixed" @click="toggleSidebar" v-if="sidebar" sidebar-layer></div>
    </transition>
    <transition enter-active-class="animated faster slideInLeft" leave-active-class="animated faster slideOutLeft">
      <div class="position-fixed bg-dark text-white" ref="sidebar" v-if="sidebar" sidebar>
        <div class="close-icon position-absolute p-4 cursor-pointer">
          <i class="fal fa-times fa-lg" @click="toggleSidebar"></i>
        </div>
        <div class="d-flex flex-column h-100 p-4">
          <div>
            <a :href="brandLogoUrl">
              <img :src="whiteLogoUrl" class="sidebar-logo pb-md-3 pb-2" :alt="logoAlt"/>
              <div class="logo-text text-white">
                my<span class="font-weight-bold">Audi</span> Experience
              </div>
            </a>
            <div class="py-4 mt-2 mt-md-4">
              <div class="text-muted pb-2 small">myAudi Streaming</div>
              <div class="py-2 border-bottom" v-for="(item, index) in sidebarItems" :key="`sidebar-item-${index}`">
                <a
                  class="nav-item-url text-white d-flex align-items-center"
                  :href="isUrlCollapse(item) ? `#${itemId(item)}` : item.url.url"
                  :target="isUrlCollapse(item) ? '_self' : getTarget(item.url)"
                  :aria-controls="isUrlCollapse(item) ? itemId(item) : null"
                  :data-toggle="isUrlCollapse(item) ? 'collapse' : null"
                  :role="isUrlCollapse(item) ? 'button' : null"
                  :aria-expanded="isUrlCollapse(item) ? 'false' : null"
                >
                  <svg v-if="item.icon" class="user-icon mr-2">
                    <use :xlink:href="`#${item.icon}-icon`"></use>
                  </svg>
                  <span class="flex-grow-1">{{item.url.title}}</span>
                  <a
                    v-if="item.items"
                    :href="`#${itemId(item)}`"
                    class="nav-item-url text-white collapsed collapse-toggle"
                    data-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    :aria-controls="itemId(item)"
                  >
                    <i class="fal fa-chevron-up"></i>
                  </a>
                </a>
                <div class="collapse multi-collapse" :id="itemId(item)" v-if="item.items">
                  <ul class="list-unstyled mb-0 mt-2">
                    <li v-for="(subitem, subindex) in item.items" :key="'index-' + index + '-subindex-' + subindex">
                      <a class="nav-item-url text-white my-2 text-normal" :href="subitem.url.url" :target="getTarget(subitem.url)" >
                        {{subitem.url.title}}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2 mt-md-4">
            <div class="text-muted pb-2 small">Utente</div>
            <div class="bg-black p-4 mx-n4">
              <a v-if="!isUserRegistered" class="nav-item-url d-flex align-items-center text-white" :href="signInUrl">
                <svg class="user-icon mr-4">
                  <use xlink:href="#user-icon"></use>
                </svg>
                {{ft('globals.navbar.sign_in')}}
                <i class="fal fa-chevron-right ml-3"></i>
              </a>
              <a v-else class="nav-item-url text-white" :href="globalState.pageModel.user.end_session_redirect">
                {{ft('globals.navbar.sign_out')}}
              </a>
            </div>
          </div>
          <div class="py-4 mt-2 mt-md-4">
            <div class="text-muted pb-2 small" v-if="sidebarBottomLinks.length > 0">Links</div>
            <div class="py-2 border-bottom" v-for="(item, index) in sidebarBottomLinks" :key="`sidebar-bottom-link-item-${index}`">
              <a class="nav-item-url text-white" :href="item.url.url" :target="getTarget(item.url)" v-if="item.url">
                <svg class="user-icon mr-2" v-if="item.icon">
                  <use :xlink:href="`#${item.icon}-icon`"></use>
                </svg>
                {{item.url.title}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
[navbar-component] {
  min-height: $header-height-sm;
  padding-left: 0;
  padding-right: 0;
  transition: all .5s;

  & > div:first-child,
  & > div:last-child {
    width: 10rem;
  }

  .navbar-brand {
    position: relative;
  }

  .navbar-logo {
    width: 3rem !important;
    height: 20px;
  }

  @include media-breakpoint-up(md) {
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: transparent;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

[sidebar-layer] {
  z-index: $zindex-sticky + 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($dark, .5);
}

[sidebar] {
  z-index: $zindex-sticky + 1;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 25rem;
  //padding-top: 5rem;
  overflow-y: auto;

  .user-icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: #fff;
    stroke: $white;
  }

  // .sidebar-content,
  // .sidebar-footer {
  //   padding-left: 2rem;
  //   padding-right: 2rem;

  // }

  .logo-text {
    font-size: 1.5rem;
    position: static !important;
  }

  .close-icon {
    top: 0;
    right: 0;
  }

  .sidebar-logo {
    width: 6rem !important;
    height: auto;
  }

  .border-bottom {
    border-color: rgba($very-light-pink, 0.15) !important;
  }

  @include media-breakpoint-down(sm) {
    max-width: 17.5rem;
    //padding-top: 1.5rem;

    .sidebar-logo {
      width: 4.375rem !important;
    }

    .nav-item-url,
    .logo-text {
      font-size: .875rem;
    }

    // .sidebar-content,
    // .sidebar-footer {
    //   padding-left: 1.25rem;
    //   padding-right: 1.25rem;
    // }
  }
}

.sidebar-toggler, .close-icon {
  transition: color .25s;
  
  &:hover {
    color: $gray-600;
  }
}

.nav-item-url {
  font-weight: normal;
  text-decoration: none;
  transition: color .25s;

  &.collapsed .fal {
    transform: rotate(180deg);
  }
}

.nav-item {
  // text-transform: uppercase;
  font-size: .875rem;
}

.logo-text {
  font-family: $font-family-headings;
  font-size: $font-size-xs;
  position: absolute;
  font-weight: 100;
  bottom: 0;
  left: 0;
}

.light { 
  color: $black;
  background-color: $white;
  //box-shadow: 0 2px 10px 0 #cccccc;
  border-bottom: 1px solid #cccccc;

  .logo-text,
  .nav-item-url {
    color: $black;
  }
}

.dark {
  color: $white;
  background-color: $gray-1000;
  //box-shadow: 0 2px 10px 0px $black;
  border-bottom: 1px solid $black;
  
  .logo-text,
  .nav-item-url {
    color: $white;
  }
}
</style>
