<script>
import postscribe from "postscribe";

export default {
  props: {
    script: String,
    id: String,
    options: {
      type: Object,
      default: () => { return {} }
    }
  },
  mounted() {
    postscribe(`#${this.id}`, this.script, this.options);
  }
}
</script>

<template>
  <div :id="id" vue-script-component></div>
</template>
