<template>
  <video 
    fandom-video-component
    ref="fandomVideo"
    :src="src"
    @play="(e) => $emit('play', e)"
    @canplay="(e) => $emit('canplay', e)"
    @canplaythrough="(e) => $emit('canplaythrough', e)"
    @playing="(e) => $emit('playing', e)"
    @pause="(e) => $emit('pause', e)"
    @durationchange="(e) => $emit('durationchange', e)"
    @volumechange="(e) => $emit('volumechange', e)"
    @ended="(e) => $emit('ended', e)"
  >
    <source :src="src" :type="type">
  </video>
</template>

<script>
import Hls from "hls.js/dist/hls.min";

export default {
  props: {
    type: {
      type: String,
      default: "video/mp4"
    },
    src: {
      type: String
    },
    refPrefix: {
      type: String,
      default: ""
    }
  },
  mounted() {
    this.initVideo();
  },
  methods: {
    initVideo() {
      const extension = this.src.split('.').pop();
      if (extension === 'm3u8' && Hls.isSupported()) {
        Vue.nextTick(()=>{
          const hls = new Hls({enableWorker: false});
          hls.loadSource(this.src);
          hls.attachMedia(this.videoEl);
        });
      } else {
        this.videoEl?.pause();
        Vue.nextTick(()=>this.videoEl?.load());
      }

      if (this.videoEl?.hasAttribute('muted')) {
        this.videoEl.muted = true;
        this.videoEl.hasAttribute('autoplay') && this.videoEl?.play();
      }

      this.$parent.$refs[`${this.refPrefix}fVideo`] = this.videoEl;
    }
  },
  computed: {
    videoEl() {
      return this.$refs.fandomVideo;
    }
  },
  watch: {
    src(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.initVideo();
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>