<script>
import { fandomTranslate } from "src/modules/common_module";

export default {
  props: {
    done: {
      type: Boolean,
      default: false
    },
    counter: {
      type: Number,
      default: 0
    }
  },
  methods: {
    ft: fandomTranslate
  },
  computed: {
    visible() {
      return this.done || this.counter > 0;
    }
  }
};
</script>

<template>
  <div class="p-2 d-inline-flex align-items-center bg-white text-black border border-gold" v-if="visible" points-marker-component>
    <div class="mx-3">
      <h6 v-if="done">{{ft("globals.done")}}</h6>
      <h6 v-else>{{ft("globals.earnable_points")}}</h6>
    </div>
    <div class="d-inline-block internal-marker" :class="{'done': done}">
      <span v-if="done"><i class="fas fa-check"></i></span>
      <span v-else>{{counter}}<i class="ml-1 fas fa-star"></i></span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[points-marker-component] {
  border-radius: 2rem;
  
  h6 { 
    font-size: 1.25rem;
    margin-bottom: 0;
  }

  .internal-marker {
    background-color: $yellow;
    font-size: 0.85rem;
    padding: 0.25rem 1rem;
    border-radius: 2rem;
  }

  .done {
    background-color: $success;
    color: $white;
  }
}
</style>
