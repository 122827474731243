<template>
  <div console class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12 p-0 d-flex justify-content-center">
        <div class="main-content-container position-relative h-100vh">
          <div v-if="activeStep===STEPS.USER_ASK" class="hint-label">
            <p class="hint-label__text text-white text-center text-uppercase">{{ hintLabel }}</p>
          </div>
          <div v-if="activeStep===STEPS.IDLE && !isUserPresent" class="idle-label">
            <p class="idle-label__text text-white text-center text-uppercase" v-html="ft('ai.mh.idle_label')"></p>
          </div>
          <div class="disclaimer-label" v-if="content.disclaimer">
            <p class="disclaimer-label__text text-white text-center" v-html="content.disclaimer"></p>
          </div>
          <div v-if="showMHTranscription" class="mh-ask-label">
            <div ref="transcriptionElMh" class="transcription position-relative">
              <p v-if="transcriptionMh.length>0" class="mb-2 transcription__text text-left text-white w-100" v-html="transcriptionMh"></p>
            </div>
          </div>
          <div v-if="activeStep===STEPS.USER_ASK" class="user-speech">
            <ai-speech 
              :audio="content.recording_audio"
              :enable-voice-recognition="content.enable_voice_recognition"
              :label="ft('ai.mh.listening')"
              :min-decibel="content.audio_min_decibel"
              :line-width="1"
              line-color="white"
              :show-microphone="transcription.length===0"
              :immediate="true"
              @stop-recording="sendQuestion"
            ></ai-speech>
            <div v-if="!isUnrealMH" ref="transcriptionEl" class="transcription position-relative">
              <p v-if="transcription.length>0" class="mb-0 transcription__text text-center text-white w-100">{{transcription}}</p>
            </div>
          </div>
          <template v-if="isUnrealMH">
            <transition enter-active-class="animated fadeIn animate__slow" leave-active-class="animated fadeOut animate__fast">
              <div v-if="activeScenario===SCENARIO.CHAT && showWaitingVideo" class="waiting-video h-100vh w-100">
                <div class="bubble-loading-container">
                  <p class="bubble-loading_text text-uppercase text-center text-white">{{ ft("ai.mh.loading_label") }}</p>
                  <div class="bubble-loading_content d-flex align-items-center justify-content-center">
                    <i class="fa-solid fa-circle fa-fade" style="--fa-animation-delay: 0s"></i>
                    <i class="fa-solid fa-circle fa-fade mx-2 mx-lg-4" style="--fa-animation-delay: 0.25s"></i>
                    <i class="fa-solid fa-circle fa-fade" style="--fa-animation-delay: 0.50s"></i>
                  </div>
                </div>
                <div class="embed-responsive embed-responsive-9by16 h-100vh">
                  <f-video :src="waitingVideo" type="video/mp4" autoplay loop></f-video>
                </div>
                <div class="user-speech">
                  <transition enter-active-class="animated fadeIn animate__fast" leave-active-class="animated fadeOut animate__fast">
                    <div v-if="transcription.length>0" class="overlay"></div>
                  </transition>
                  <div ref="transcriptionEl" class="transcription position-relative">
                    <p v-if="transcription.length>0" class="mb-2 transcription__text text-center text-white w-100">{{transcription}}</p>
                  </div>
                </div>
              </div>
            </transition>
          </template>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module';
import { ActionCableComponentsMixin } from 'src/modules/websocket_module.js';
import { STEPS, SCENARIO, CONVERSATION_TYPE, ACTIONS } from 'src/modules/utils/constants';
import { createChat, fetchQuestion, transcribeAudio, startInterview } from 'src/modules/services/ai-service';
import AiSpeech from 'ai/ai-speech';

export default {
  mixins: [fandomBasicMixin, ActionCableComponentsMixin],
  components: { AiSpeech },
  props: {
    value: {
      type: String
    },
    activeScenario: {
      type: String
    },
    transcriptionData: {
      type: Object
    },
    isUserPresent: {
      type: Boolean
    },
    metahuman: {
      type: String
    }
  },
  data: function() {
    return {
      SCENARIO,
      STEPS,
      activeStep: this.value,
      currentAction: null,
      transcription: '',
      showWaitingVideo: false,
      waitingVideo: '',
      transcriptionMh: '',
      mhResponse: '',
      chatInteractions: 0,
      chat: null,
      conversationStatus: ''
    }
  },
  mounted() {
    this.handleScenario();
  },
  methods: {
    updateChatSubscription() {
      this.deleteSubscriptions();
      this.subscribe(
        { channel: "ListenerChannel", room: this.chat },
        { received: (data) => {this.conversationStatus = data.status} }
      );
    },
    handleScenario() {
      this.reset();
      if (this.activeScenario===SCENARIO.CHAT) {
        this.newChat();
      }
    },
    reset() {
      this.showWaitingVideo=false;
      this.transcription = '';
      this.transcriptionMh = '';
      this.mhResponse = '';
      this.chatInteractions = 0;
    },
    async newChat() {
      let response = await createChat(this.content.id, this.content.name);
      let data = await response.json();
      this.chat = data.chat.name;
      this.updateChatSubscription();

      if (this.isInterview) {
        try {
          const body = {chat: this.chat, content_cache_id: this.content.id};
          response = await Fandom.retry(()=>startInterview(body), 3, 500);
          data = await response.json();
          this.mhResponse = data.text.content;
          this.$emit('change-status', { action: ACTIONS.SITE.TALK, text: this.mhResponse, step: STEPS.MH_ANSWER });
        } catch (error) {
          console.log(`%cNew chat error ${error}`, "background-color: red;");
          this.$emit('change-status', { action: ACTIONS.SITE.STOP, step: STEPS.IDLE, hasError: true });
        }
      } else {
        this.transcriptionMh = '';
        this.mhResponse = '';
        this.$emit('change-status', { action: ACTIONS.SITE.VIDEO, step: STEPS.INTRO_VIDEO });
      }
    },
    async sendQuestion(blob) {
      this.showWaitingVideo = true;
      Vue.nextTick(()=>this.waitingVideo = this.content.waiting_video.url);
      this.transcription = '';
      this.mhResponse = '';
      let body = new FormData();
      const fileObject = new File([blob], "audio.mp4", {type: 'audio/mp4'});
      body.append("audio", fileObject);
      body.append("service", this.transcriptionService);
      body.append("keywords", this.transcriptionKeywords);
      
      try {
        let response = await Fandom.retry(()=>transcribeAudio(body), 3, 500);
        let data = await response.json();
        this.transcription = '';
        if (!data.transcription) {
          this.mhResponse = this.ft("ai.mh.message_transcription.no_question_answer");
          this.$emit('change-status', { action: ACTIONS.SITE.TALK, text: this.mhResponse, step: STEPS.MH_ANSWER });
        } else {
          Vue.nextTick(()=>this.writeTranscription(`“${data.transcription}”`));
          body = {
            chat: this.chat,
            content_cache_id: this.content.id,
            user_message: data.transcription
          };

          response = await Fandom.retry(()=>fetchQuestion(body), 3, 500);
          data = await response.json();
          if (data.hasAnswer) {
            this.chatInteractions++;
            this.transcription = '';
            this.transcriptionMh = '';
            if (this.activeScenario === SCENARIO.CHAT) {
              this.mhResponse = data.text.content;
              this.$emit('change-status', { action: ACTIONS.SITE.TALK, text: this.mhResponse, step: STEPS.MH_ANSWER });
            }
          } else {
            this.$emit('change-status', { action: ACTIONS.SITE.TALK, text: data.text.content, step: STEPS.MH_ASK });
          }
        }
      } catch (error) {
        console.log(`%c Question error ${error}`, "background-color: red;");
        this.$emit('change-status', { action: ACTIONS.SITE.STOP, step: STEPS.IDLE, hasError: true });
      }
    },
    writeTranscription(text, isMh=false, duration=0) {
      let words = [];
      let timeoutS = 0.1;
      if (isMh && duration>0) {
        words = text.match(/[^ ]+ ?| /g);
        timeoutS = duration/words.length;
      } else {
        let i = 0;
        while (i < text.length) {
          let charsToPrint = Math.floor(Math.random() * 6);
          let to = i+charsToPrint < text.length ? i+charsToPrint : text.length
          words.push(text.substring(i, to));
          i += charsToPrint;
        }
      }
      this.animateTranscription(words, isMh, timeoutS);
    },
    animateTranscription(words, isMh=false, timeoutS=0.1, index=0) {
      const suffix = isMh ? 'Mh' : '';
      if (index < words.length) {
        this.$refs[`transcriptionEl${suffix}`]?.classList.add("blink-animation");
        const currentWord = words[index];
        this[`transcription${suffix}`] += currentWord;
        index++;
        setTimeout(() => this.animateTranscription(words, isMh, timeoutS , index), timeoutS*1000);
      } else {
        this.$refs[`transcriptionEl${suffix}`]?.classList.remove("blink-animation");
        setTimeout(()=>{
          this.transcription = '';
          this.transcriptionMh = '';
        }, 1000);
      }
    }
  },
  computed: {
    hintLabel() {
      return this.activeScenario === SCENARIO.CHAT ? this.ft("ai.mh.ask_now") : "";
    },
    isUnrealMH() {
      return true;

      // TODO: check if it's necessary
      // const url = new URL(this.content.metahuman);
      // return url.host !== window.location.host;
    },
    showMHTranscription() {
      return [STEPS.MH_ASK, STEPS.MH_ANSWER, STEPS.MH_GREETINGS].includes(this.activeStep);
    },
    transcriptionService() {
      return this.content.transcription?.service ?? 'open_ai';
    },
    transcriptionKeywords() {
      return this.content.transcription?.keywords ?? '';
    },
    isInterview() {
      return this.content.conversation_type === CONVERSATION_TYPE.INTERVIEW;
    },
    isEnded() {
      return this.conversationStatus=="ended" || this.chatInteractions>=this.content.chat_mode_interactions;
    }
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.isInterview && this.isEnded) {
          this.$emit('change-status', { action: ACTIONS.SITE.STOP });
          this.reset();
        } else if (newVal === STEPS.MH_ASK && this.isEnded) {
          this.activeStep = STEPS.MH_GREETINGS;
          this.mhResponse = this.content.messages?.end_message || this.ft("ai.mh.message_transcription.end_message");
          this.$emit('change-status', { action: ACTIONS.SITE.END_MESSAGE, step: STEPS.MH_GREETINGS, delay: 1500 });
        } else if (newVal === STEPS.MH_ASK) {
          this.activeStep = newVal;
          this.mhResponse = this.ft("ai.mh.message_transcription.question_message");
          this.$emit('change-status', { action: ACTIONS.SITE.ASK_MESSAGE, step: STEPS.MH_ASK, delay: 1500 });
        } else {
          this.activeStep = newVal;
          this.transcription = '';
        }
      }
    },
    activeScenario() {
      this.handleScenario();
    },
    transcriptionData: {
      handler(newVal, oldVal) {
        if (newVal && oldVal && newVal.timestamp > oldVal.timestamp) {
          this.showWaitingVideo = false;
          this.waitingVideo = '';
          this.transcriptionMh = '';
          if (this.mhResponse) {
            Vue.nextTick(() => this.writeTranscription(this.mhResponse, newVal.isMh, newVal.duration));
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
[console] {
  .main-content-container {
    aspect-ratio: 9 / 16;
    width: 100%;
  }

  .hint-label, .idle-label {
    top: 50px;
    position: absolute; 
    display: flex; 
    justify-content: center; 
    width: 100%;

    &__text {
      font-size: 2vh;
      font-weight: 600;
      width: 60vw;
    }
  }

  .disclaimer-label {
    bottom: 0;
    position: absolute; 
    display: flex; 
    justify-content: center; 
    width: 100%;
    
    &__text {
      font-size: 1vh;
      font-weight: 300;
      opacity: .75;
    }
  }

  .mh-ask-label {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .mh-ask-label, .user-speech, .waiting-video {
    .transcription {
      width: 80%;
      max-height: 14vh;
      overflow: hidden;
      display: flex;
      flex-direction: column-reverse;

      &::before {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        content: "";
      }

      p {
        font-size: 3.5vh;
        line-height: 1.3;
        font-weight: 600;
        width: 80%;
        text-shadow: 4px 2px 5px #444444;
        margin-bottom: 0;
      }
    }
  }

  .waiting-video {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #000000;

    .embed-responsive {
      position: absolute;
      top: 0;
    }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .bubble-loading-container {
      position: absolute;
      display: flex;
      align-items: center;
      flex-direction: column;
      top: 10vh;
      z-index: 9;
      width: 100%;

      p {
        font-size: 2vh;
        font-weight: 600;
        width: 60vw;
        margin-bottom: 2.5vh;
      }

      .bubble-loading_content {
        width: 100%;
        border-radius: 20px;
        display: inline-block;
        position: relative;
        color: $white;
        font-size: 1.5vh;

        .fa-circle {
          --fa-fade-opacity: 0;
        }
      }
    }
  }

  .user-speech {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

    ::v-deep [ai-speech] {
      canvas {
        width: 70vw !important;
        height: 14vh;
        max-width: 100%;
      }

      .icon-container {
        width: 6vh;
        height: 6vh;
        position: absolute;
        border: 1px solid $primary;
        margin-left: 0 !important;
      }

      .label {
        position: absolute;
        font-size: 2vh;
        bottom: 0;
      }

      .fa-microphone {
        font-size: 3vh;
        line-height: .04167em;
        vertical-align: -0.125em;
      }
    }
  }

  .transcription {
    &.blink-animation {
      .transcription__text::after {
        animation: blink 1s steps(5,start) infinite;
        content: "_";
        margin-left: 0.25rem;
        vertical-align: baseline;
      }
    }
  }
}
</style>