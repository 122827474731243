<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { liveEventsMixinV2 } from 'src/modules/live_events_v2_module.js';
import { get, cloneDeep, isEqual } from 'lodash';

export default {
  mixins: [fandomBasicMixin, liveEventsMixinV2, fandomExtendedMixin],
  data() {
    return {
      // notices are buttons clickable on the video content
      // they are objects made this way: {id, message, callback}
      notices: [],
      footerNotice: null,
      footerNoticeActivation: false,
      activeInteractionIndex: -1,
      flashInteraction: null,
      flashInteractionTimer: 0,
      videoPlayer: {},
      isEmbedAdvancedFullscreen: false
    }
  },
  mounted() {
    // footerNoticeActivation inizialization
    const footerNotice = this.footerNoticeHandler;
    if (footerNotice) {
      this.footerNoticeActivation = true;
      this.footerNotice = footerNotice; 
    }
  },
  watch: {
    footerNoticeHandler(newVal, oldVal) {
      if (newVal && !isEqual(newVal, this.footerNotice)) {
        this.footerNoticeActivation = false;
        setTimeout(() => {
          this.footerNoticeActivation = true;
          this.footerNotice = newVal;
        }, 1000);
      } else {
        this.footerNotice = newVal;
      }
      
    },
    "videoPlayer.firstPlay"(newVal, oldVal) {
      if (newVal) {
        const userTrackingContent = get(this.content, ['play', "user_tracking_content"], false);
        if (userTrackingContent) {
          Fandom.ajax({
            type: "POST",
            url: Fandom.applyContextToUrl("/api/v1/event_mkt_tracking"),
            data: {
              content_cache_id: this.content.id,
              interaction_id: this.content.play.interaction.id
            },
            success: (data) => {
              console.log("%cfandom tracking\n\tfandom_action: " + (data.fandom_action || 'none') + "\n\tmyaudi_action: " + (data.myaudi_action || 'none'), "color: #aaaaaa");
            }
          });
        }
      }
    },
    liveInteractions(newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        const record = newVal[newVal.length - 1];
        this.newInteractionHandler({
          elId: `anchor-${this.containerContent.id}-${record.interaction.id}`,
          interactionIndex: newVal.length - 1
        });
      }
    },
    flashInteractions(newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        const record = newVal[newVal.length - 1];
        this.flashInteractionHandler(record);
      }
    }
  },
  methods: {
    removeNotice(noticeId) {
      this.notices = this.notices.filter(notice => notice.id != noticeId);
    },
    mayAddTempNotice(noticeId, data, callback) {
      if (!this.notices.map(notice => notice.id).includes(noticeId)) {
        this.notices.push({
          description: data.description,
          message: data.message,
          callback: callback,
          icon: data.icon,
          id: noticeId
        });
        setTimeout(() => {
          this.removeNotice(noticeId);
        }, 15000);
      }
    },
    mayAddPermaNotice(noticeId, data, callback) {
      if (!this.notices.map(notice => notice.id).includes(noticeId)) {
        this.notices.push({
          description: data.description,
          message: data.message,
          callback: callback,
          icon: data.icon,
          id: noticeId
        });
      }
    },
    getRecordOrContent(extraField) {
      if (typeof extraField == 'string') {
        return get(this.pageModel, ['name_to_content', extraField], false);
      } else {
        return extraField;
      }
    },
    newInteractionHandler(data) {
      const interactionRecord = this.liveInteractions[data.interactionIndex];
      const message = interactionRecord.interaction.type == "Vote" ? 
                      this.ft('page_live_event.interactions.vote_notice') :
                      this.ft('page_live_event.interactions.notice')
      this.mayAddTempNotice(
        `interactions-modal-${interactionRecord.id || interactionRecord.$id}`,
        {
          message: message,
          description: this.ft('page_live_event.interactions.notice_description'),
          icon: 'far fa-poll'
        },
        () => {
          this.activeInteractionIndex = data.interactionIndex;
        }
      )
    },
    flashInteractionHandler(record) {
      this.flashInteraction = null;
      this.flashInteractionTimer = 3;
      let interval = setInterval(() => {
        const tmp = this.flashInteractionTimer;
        this.flashInteractionTimer = null;
        Vue.nextTick(() => {
          if (tmp == 1) {
            this.flashInteraction = record;
            this.flashInteractionTimer = null;
            clearInterval(interval);
          } else {
            this.flashInteractionTimer = tmp - 1;
          }
        });
      }, 1000);
    },
    closeModalHandler(target, record, icon) {
      this.mayAddPermaNotice(
        `${target}-modal-${record.id || record.$id}`,
        {
          message: this.ft(`page_live_event.${target}.notice`),
          description: this.ft(`page_live_event.${target}.notice_description`),
          icon: icon
        },
        () => { this.$refs[target].openModal() }
      )
    },
    handleVideoPlayer(player) {
      this.videoPlayer = player;
    },
    handleAdvancedPlayerFullscreen(data) {
      console.log(data);
    }
  },
  computed: {
    currentBreakpoint() {
      for (let breakpoint in globalState.viewport) {
        if (globalState.viewport[breakpoint] == true) {
          return breakpoint;
        }
      }
    },
    survey() {
      return this.getRecordOrContent(get(this.interactiveContent, ["survey"], false)) || this.getRecordOrContent(get(this.content, ["survey"], false));
    },
    waitingGame() {
      return this.getRecordOrContent(get(this.interactiveContent, ["waiting_game"], false)) || this.getRecordOrContent(get(this.content, ["waiting_game"], false));
    },
    footerNoticeHandler() {
      return this.getRecordOrContent(get(this.interactiveContent, ["footer_notice"], false)) || this.getRecordOrContent(get(this.content, ["footer_notice"], false));
    },
    alert() {
      return this.getRecordOrContent(get(this.interactiveContent, ["alert"], false)) || this.getRecordOrContent(get(this.content, ["alert"], false));
    },
    liveInteractions() {
      return get(this.interactiveContent, ["live_interactions"], []);
    },
    interactiveContent() {
      return this.getRecordOrContent(this.content.interactive_content);
    },
    interactiveContentContainer() {
      return typeof this.content.interactive_content == 'string' ? this.interactiveContent : this.containerContent;
    },
    flashInteractions() {
      return get(this.interactiveContent, ["flash_interactions"], []);
    },
    showSidebar() {
      return get(this.interactiveContent, ["live_interactions"], false) || get(this.interactiveContent, ["comments"], false);
    },
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :style="extendedTheme" v-if="isContentVisibleToUser()" :class="contentType" page-live-event-component>
    <div :class="showSidebar ? 'container-fluid' : 'container'">
      <div class="row justify-content-center">
        <div class="col-12" :class="showSidebar ? 'col-md' : 'col-md-10'">
          <div class="row">
            <div class="col-12 px-0 bg-light">
              <embed-advanced-new :class="{'container-lg px-0': !interactiveContent}" :content="content" :opt-container-content="containerContent" :notices="notices" :timer="flashInteractionTimer" @fullscreen-handler="isEmbedAdvancedFullscreen = arguments[0]" @video-player-handler="handleVideoPlayer">
                <page-live-event-trivia-game-modal
                  ref="waiting_game"
                  v-if="waitingGame"
                  :content="waitingGame"
                  :opt-container-content="containerContent"
                  @close="closeModalHandler('waiting_game', waitingGame, 'far fa-trophy-alt')"
                  @clearnotice="removeNotice($event)"
                ></page-live-event-trivia-game-modal>
                <page-live-event-survey
                  ref="survey"
                  v-if="survey"
                  :content="survey"
                  :opt-container-content="containerContent"
                  @close="closeModalHandler('survey', survey, 'far fa-poll')"
                  @clearnotice="removeNotice(`survey-modal-${survey.id || survey.$id}`)"
                ></page-live-event-survey>
                <page-live-event-alert
                  ref="alert"
                  v-if="alert"
                  :content="alert"
                  :opt-container-content="containerContent"
                  @close="closeModalHandler('alert', alert, 'far fa-info-circle')"
                  @clearnotice="removeNotice(`alert-modal-${alert.id || alert.$id}`)"
                ></page-live-event-alert>
                <page-live-event-interaction-modal
                  ref="active-interaction"
                  v-if="activeInteractionIndex > -1"
                  :content="liveInteractions[activeInteractionIndex]"
                  :opt-container-content="interactiveContentContainer"
                  @close="(contentId) => {
                    removeNotice(`interactions-modal-${contentId}`);
                    activeInteractionIndex = -1;
                  }"
                ></page-live-event-interaction-modal>
                <transition enter-active-class="animated fadeInUp" leave-active-class="animated fadeOutDown">
                  <interaction-trivia-flash
                    v-if="flashInteraction"
                    :timer="interactiveContent.timer || 10"
                    :content="flashInteraction"
                    @close-flash="flashInteraction = null"
                    :opt-container-content="containerContent"
                  ></interaction-trivia-flash>
                </transition>
                <transition enter-active-class="animated fadeInUp" leave-active-class="animated fadeOutDown">
                  <page-live-event-notice
                    v-if="footerNotice && footerNoticeActivation && (isEmbedAdvancedFullscreen || (!isEmbedAdvancedFullscreen && ['lg','xl'].includes(currentBreakpoint)))"
                    :content="footerNotice"
                    :opt-container-content="containerContent"
                    @clearnotice="footerNoticeActivation = false"
                  ></page-live-event-notice>
                </transition>
              </embed-advanced-new>
              <page-live-event-notice
                  v-if="footerNotice && footerNoticeActivation && (!isEmbedAdvancedFullscreen && ['xs','sm','md'].includes(currentBreakpoint))"
                  :content="footerNotice"
                  style="position: relative !important; bottom: 0 !important;"
                  class="bg-black"
                  :opt-container-content="containerContent"
                  @clearnotice="footerNoticeActivation = false"
                ></page-live-event-notice>
            </div>
            <div v-if="['xs','sm','md'].includes(currentBreakpoint) && showSidebar" class="col-12 d-block d-lg-none px-0">
              <page-live-event-interactive-sidebar
                :inherit-theme-type="content.theme"
                :content="interactiveContent"
                :opt-container-content="interactiveContentContainer"
              ></page-live-event-interactive-sidebar>
            </div>
            <div class="col-12 px-0">
              <component :is="stripe.layout" :content="stripe" v-for="(stripe) in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
            </div>
          </div>
        </div>
        <div v-if="['lg','xl'].includes(currentBreakpoint) && showSidebar" class="col-lg-auto d-none d-lg-block px-4 live-event-interactive-sidebar">
          <page-live-event-interactive-sidebar
            :inherit-theme-type="content.theme"
            :content="interactiveContent"
            :opt-container-content="interactiveContentContainer"
          ></page-live-event-interactive-sidebar>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
[page-live-event-component] {

  color: var(--theme-color);
  background-color: var(--theme-background);

  .live-event-interactive-sidebar {
    width: 26.5rem;
  }

  // styles to apply to all modals in this place
  // TODO: better put it somewhere else?
  [modal] {
    .live-event-modal__background {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      pointer-events: none;
      z-index: $zindex-modal-backdrop;
      background-color: var(--theme-color);
      opacity: .75;
    }

    .live-event-modal {
      top: 0;
      left: 0;
      position: fixed;
      z-index: $zindex-modal;
    }
  }
  
  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: .75;}
  }

  @keyframes fadeOut {
    0% {opacity: .75;}
    100% {opacity: 0;}
  }
}
</style>
