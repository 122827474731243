<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { interactionMixin } from 'src/modules/interactions_module.js';
import { commentsMixin } from 'src/modules/comments_module.js';


export default {
  mixins: [fandomBasicMixin, interactionMixin, commentsMixin],
  props: {},
  data: function() {
    return {
    };
  },
  computed: {
    isUserRegistered() {
      return Fandom.isUserRegistered();
    },
  }
};

</script>
<template>
  <div v-easyadmin="easyadminId" :class="contentType" class="pb-4" faq>
    <div class="container-fluid py-3 border-bottom-gray-500">
      <div id="comment-input" class="row justify-content-center">
        <div class="col-12 px-0">
          <h4 class="h2 pt-3 font-weight-normal">{{ft("globals.q_and_a.title")}}</h4>
          <p class="font-14">
            {{ft("globals.q_and_a.description_1")}} {{ft("globals.q_and_a.description_2")}}
          </p>
        </div>
        <div class="col-12 px-0 text-right pb-4">
          <textarea class="w-100 p-2 mb-2 rounded-0 font-14" :class="`textarea-${containerContent.theme}`" :placeholder="ft('globals.q_and_a.text_placeholder')" v-model="text"/>
          <button class="btn py-2 px-5 font-weight-normal" :class="containerContent.theme == 'dark' ? 'btn-white' : 'btn-dark'" :disabled="commentIsLoading " @click="tryToAddComment(null)">
            {{ft('globals.q_and_a.send_btn')}}
          </button>
        </div>  
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[faq] {

  .border-bottom-gray-500 {
    border-bottom: solid 1px $gray-500;
  }

  .font-14 {
    font-size: 14px;
  }

  .indent {
    width: 0.25rem;
    position: relative;
    height: 100%;

    &:before {
      content: "";
      width: 1px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: $gray-600;
    }
  }

  .bg-gray-100 {
    background-color: $gray-100;
  }

  textarea {
    border: solid 1px $gray-250;
    background-color: $white;
    min-height: 4rem;
    resize: none;

    &.textarea-dark {
      border-color: $gray-100;
      background-color: $black;
      color: $white;
    }
  }
}

</style>
