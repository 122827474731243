<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { isInteractionDone } from "src/modules/interactions_module.js";
import Flickity from "flickity";

export default {
  mixins: [fandomBasicMixin],
  data: function () {
    return {
      carousel: null,
      carouselIndex: 0
    };
  },
  mounted() {
    Vue.nextTick(() => {
      this.carousel = new Flickity(this.$refs["survey-carousel"], {
        pageDots: false,
        prevNextButtons: false,
        draggable: false,
        initialIndex: this.surveyInitialIndex,
        accessibility: false
      });

      this.carouselIndex = this.surveyInitialIndex;
      this.carousel.on("change", (index) => {
        this.carouselIndex = Math.min(index, this.content.survey.length - 1);
      });
    });
  },
  computed: {
    surveyInitialIndex() {
      let index = 0;
      this.content.survey.forEach((surveyStep) => {
        if (isInteractionDone(this.containerContent, surveyStep.interaction)) {
          index++;
        }
      });
      return index;
    },
    surveyDone() {
      let done = true;
      this.content.survey.forEach((surveyStep) => {
        done = done && isInteractionDone(this.containerContent, surveyStep.interaction);
      });
      return done;
    },
    surveyPercentage() {
      if (this.surveyDone) {
        return 100;
      } else {
        return this.carouselIndex * 100 / this.content.survey.length;
      }
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" class="container" course-survey-carousel-inline-component>
    <div class="row">
      <div class="col-12">
        <div class="w-100 py-5" ref="survey-carousel">
          <div v-for="(surveyContent, index) in content.survey" class="survey-item d-flex justify-content-center w-100">
            <div class="col-12 col-md-auto px-4">
              <course-survey-carousel-item :content="surveyContent" :opt-container-content="containerContent" @submission-success="carousel.next()"></course-survey-carousel-item>  
            </div>
          </div>
          <!-- last slide -->
          <div class="w-100 h-100 d-flex justify-content-center align-items-center flex-column py-5 text-center survey-item__end">
            <div class="px-5">
              <div class="my-4" v-html="ft('globals.survey.success')"></div>
            </div>
          </div>
        </div>
        <div class="p-3 text-center">
          <div class="progress survey__progress mx-auto my-2">
            <div class="progress-bar" role="progressbar" :style="{'width': `${surveyPercentage}%`}"></div>
          </div>
          <p class="mb-0"><small><b>{{Math.floor(surveyPercentage)}}%</b> completato</small></p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[course-survey-carousel-inline-component] {

  .survey__progress {
    height: 10px;
  } 

  [course-survey-carousel-item-component], .survey__progress {
    width: 600px;
    max-width: 100%;
  }
}
</style>