<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { interactionMixin } from 'src/modules/interactions_module.js';
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  mounted() {
    if (this.content.thankyou_modal) {
      Fandom.onEvent("comment-added", (data) => {
        const contentCacheId = this.containerContent.id;
        const interactionId = this.content.interaction.id;
        if (data.content_cache_id == contentCacheId && data.interaction_id == interactionId) {
          this.$refs['qa-thankyou-modal'].openModal();
        }
      });
    }
  },
  computed: {
    description() {
      return this.content.description || this.ft("page_live_event.qa.description");
    }
  }
};

</script>
<template>
  <div id="comment-input" class="row justify-content-center" v-easyadmin="easyadminId" :class="contentType" page-live-event-qa-component>
    <div class="col-12 py-4">
      <p v-html="description"></p>
      <comments :content="content" :opt-container-content="containerContent"></comments>
    </div>
    <page-live-event-alert
      ref="qa-thankyou-modal"
      v-if="content.thankyou_modal"
      :content="content.thankyou_modal"
      :opt-container-content="containerContent"
    ></page-live-event-alert>
  </div>
</template>

<style lang="scss" scoped>
.question {
  border: solid 1px $gray-200;
  min-height: 4rem;
  resize: none;
}
</style>
