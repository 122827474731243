<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { liveEventsMixin } from 'src/modules/live_events_module.js';
import { addPolling, removePolling } from 'src/modules/polling_module.js';
import { isInteractionDone } from "src/modules/interactions_module.js";

export default {
  mixins: [fandomBasicMixin, liveEventsMixin],
  data: function () {
    return {
      showModal: false,
      noticeEmitted: false,
    };
  },
  mounted() {
    if(this.content.active == true){
     this.emitNotice();
    }
  },
  watch: {
    showModal(newValue, previousValue) {
      if (newValue) {
        disableBodyScroll();
      } else {
        clearAllBodyScrollLocks();
      }
    },
    'content.active'(newValue) {
      if(newValue == true) {
        this.emitNotice();
      } else {
        this.showModal = false;
        this.$emit("notice", { active: false });
        this.noticeEmitted = false;
      }
    }
  },
  computed: { },
  methods: {  
    emitNotice() {
      if(!this.noticeEmitted) {
        this.showModal = true;
        this.$emit("notice", { active: true });
        this.noticeEmitted = true;
      }
    },
    toggleModal() {
      this.showModal = !this.showModal;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" live-modal-component>
    <transition 
      enter-active-class="animated fadeIn faster" 
      leave-active-class="animated fadeOut faster" 
    >
      <div class="modal__background" v-if="showModal"></div>
    </transition>
    <transition 
      enter-active-class="animated slideInDown faster" 
      leave-active-class="animated slideOutUp faster" 
    >
      <div class="live-modal d-flex justify-content-center align-items-center" v-if="showModal">
        <div class="modal__content position-relative py-5">
          <div class="h-100 d-flex flex-column justify-content-between align-items-center">
            <div class="modal__header w-100 text-dark d-flex justify-content-end position-absolute">
              <a href="javascript: void(0)" @click="toggleModal()" class="p-4"><i class="fal fa-times"></i></a>
            </div>
            <div class="w-75 h-100 text-center">
              <div class="d-flex justify-content-center"><h2 class="mb-0 p-3 h1">{{content.title}}</h2></div>
              <div class="font-weight-normal" v-html="content.description" v-if="content.description"></div>
              <div class="w-100 d-flex justify-content-center align-items-center py-4" v-if="content.button">
                <a class="btn" :class="content.button_class || 'btn-black'" :href="content.button.url" :target="getTarget(content.button)">{{content.button.title}}</a>
              </div>
            </div>
            <div class="footer"></div>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<style lang="scss">
[live-modal-component] {

  .modal__background {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    background-color: var(--theme-color);
    opacity: .75;
    z-index: 1090;
  }

  .live-modal {
    z-index: 1090; 

    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    .modal__content {
      overflow-y: scroll;
      width: 65%;
      max-height: calc(100% - 3rem);
      background-color: var(--theme-background);
      color: var(--theme-color);

      @include media-breakpoint-down(sm) {
        width: calc(100% - 3rem);
      }
    }
  }

  .modal__header {
    top: 0;
  }

  .modal__header a {
    color: var(--theme-color);
    font-size: 2rem;
    line-height: 0;

    & > i {
      line-height: 1.3rem;
    }
  }

  // for backdrop, because otherwise it will do something weird
  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: .75;}
  }

  @keyframes fadeOut {
    0% {opacity: .75;}
    100% {opacity: 0;}
  }
}
</style>