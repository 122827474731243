<template>
  <div class="h-100" chapters-list>
    <div class="container border-bottom py-3 py-md-5 px-medium px-md-double w-100">
      <div class="row">
        <div class="col-12">
          <p class="small mb-2">{{ title }}<span class="pl-3 text-muted text-capitalize">{{ startDate }}</span></p>
          <h4 class="mb-0">{{ subtitle }}</h4>
        </div>
      </div>
    </div>
    <div class="list-container hide-scrollbar">
      <div class="container px-medium px-md-double">
        <div class="row">
          <div class="col-12">
            <div  v-for="(chapter, index) in chapters" :key="`chapter-${index}`" class="card border-bottom" @click="goToChapter(chapter)">
              <div class="card-body px-0">
                <div class="card-body__image">
                  <img class="img-fluid" :src="chapter.icon.url" :alt="chapter.icon.alt">
                </div>
                <div class="card-body__content pl-3 position-relative">
                  <span v-if="activeChapter && chapter.$id === activeChapter.$id" class="playing-badge text-uppercase">{{ ft('globals.live_shopping.playing_now') }}</span>
                  <p class="card-title h5 mb-0">{{chapter.title}}</p>
                  <small class="text-muted">{{ chapter.seconds | toTime }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { sortedIndexBy } from 'lodash';

import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { toTime } from 'src/modules/utils/time';

export default {
  mixins: [fandomBasicMixin],
  props: {
    chapters: {
      type: Array,
      required: true
    },
    title: {type: String},
    subtitle: {type: String},
    description: {type: String},
    currentTime: {
      type: Number,
    }
  },
  methods: {
    goToChapter(chapter) {
      this.$emit('go-to-chapter', chapter);
    }
  },
  computed: {
    startDate() {
      moment.locale(this.globalState.lang ?? 'en');
      return this.content.start_time ? moment(this.content.start_time).format("D MMMM - HH:mm") : '';
    },
    activeChapter() {
      const index = sortedIndexBy(this.chapters, { 'seconds': this.currentTime }, chapter => { return chapter.seconds; });
      const chapter = this.chapters[index];
      if (chapter?.seconds === this.currentTime) {
        return chapter;
      } else {
        return this.chapters[index-1];
      }
    }
  },
  filters: {
    toTime
  }
};
</script>

<style lang="scss" scoped>
[chapters-list] {
  display: flex;
  flex-direction: column;

  .list-container {
    overflow-y: scroll;
    flex: 1;

    @include media-breakpoint-down(md) {
      overflow-y: visible;
    }
  }
}
.card {
  border: 0;
  cursor: pointer;

  &-body {
    display: flex;

    &__image {
      width: 7rem;
      min-width: 7rem;
      height: auto;
      overflow: hidden;

      @include media-breakpoint-down(sm) {
        width: 4rem;
        min-width: 4rem;
      }

      img {
        border-radius: .875rem;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      flex: 1;

      .card-title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media-breakpoint-down(sm) {
          font-size: .875rem !important;
          max-width: 75%;
        }
      }

      .playing-badge {
        width: fit-content;
        font-size: .5rem;
        font-weight: 600;
        padding: .1rem .25rem;
        color: $white;
        border-radius: .875rem;
        background-color: $player-red-color;
      }
    }
  }
}
</style>