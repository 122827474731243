<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { liveEventsMixin } from 'src/modules/live_events_module.js';
import { addPolling, removePolling } from 'src/modules/polling_module.js';
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin, liveEventsMixin, fandomExtendedMixin],
  mounted: function() {
  },
  data: function() {
    return {
    }
  },
  methods: {
  },
  computed: {
    backgroundColor() {
      return get(this, ['content','background_color'], null) || get(this, ['decorator','color'], null);
    },
    currentBreakpoint() {
      for(let breakpoint in globalState.viewport){
        if(globalState.viewport[breakpoint] == true){
          return breakpoint;
        }
      }
    } 
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :style="theme" v-if="isContentVisibleToUser()" :class="contentType" course-product-streaming-component>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md p-4">
          <div class="row justify-content-center mx-n4 mx-lg-n3">
            <div class="col-12 mt-n4 mt-lg-2 px-0 px-lg-3">
              <embed-advanced :content="content" :opt-container-content="containerContent"></embed-advanced>
            </div>
            <div class="col-12 d-block d-lg-none pb-5 px-3">
              <live-streaming-sidebar v-if="['xs','sm','md'].includes(currentBreakpoint)" :content="content" :opt-container-content="containerContent"></live-streaming-sidebar>
            </div>
            <div class="col-12 col-lg-8 py-4 px-3">
              <h2 class="h1 py-3" v-if="content.title">{{content.title}}</h2>
              <div v-html="content.description" v-if="content.description"></div> 
            </div>
            <div class="col-12 stripes__container px-0">
              <component class="container-fluid" :is="stripe.layout" :content="stripe" v-for="(stripe, index) in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
            </div>
          </div>
        </div>
        <div class="col-lg-auto d-none d-lg-block live-steaming-sidebar px-lg-0">
          <live-streaming-sidebar v-if="['lg','xl'].includes(currentBreakpoint)" :content="content" :opt-container-content="containerContent"></live-streaming-sidebar>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
[course-product-streaming-component] {

  background-color: var(--theme-background);
  color: var(--theme-color);

  .live-steaming-sidebar {
    width: 26.5rem;
  }

  .stripes__container .container {
    width: 100% !important;
    max-width: none;
  }
}
</style>
