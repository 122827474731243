import Fullscreen from '../Fullscreen';
import { removeAllChild } from '../utils';
import Player from '@vimeo/player';
import { PLAYER_STATUS, HTML5_STATUS, AUTO_QUALITY } from 'src/modules/player/constants';
import defaultConfig from  'src/modules/player/config';
import { get } from "lodash-es";

class Vimeo {
  /**
   * 
   * @param {HTMLElement} wrapperEl
   * @param {Object} config
   */
  constructor(wrapperEl, config, code) {
    this.videoId = code;
    this.config = config;
    this.wrapperEl = wrapperEl;
    removeAllChild(wrapperEl);
    this.prevVolume = null;

    const target = typeof config.fullscreenElement === 'object' ? config.fullscreenElement : wrapperEl.parentElement;
    this.fullscreen = new Fullscreen(target);

    this.player = this.setup();
    this.player
      .ready()
      .then(() => this.ready());
  }

  /**
   * return the instance id
   */
  get id() {
    return `vimeo-${this.videoId}`;
  }

  setup() {
    const videoKey = this.videoId.indexOf('/') != -1 ? 'url' : 'id';
    const videoConfig = {
      ...defaultConfig,
      controls: false,
      autoplay: get(this.config, "autoplay", false),
      mute: get(this.config, "autoplay", false) ? true : get(this.config, "muted", false),
      [videoKey]: this.videoId
    }

    this.setPoster(Fandom.getContentResponsiveImage('thumbnail', this.config));
    
    return new Player(this.wrapperEl, videoConfig);
  }

  ready(event) {
    if (!this.config.autoplay) {
      this.showPoster();
      this.iframeOnLoad();
    } else {
      this.player.element.onload = () => {
        this.player.ready()
          .then(()=>{this.player.setMuted(true)})
          .then(() => {this.iframeOnLoad()})
      }

      this.player.element.src = `${this.player.element.src}?controls=0`;
    }
  }

  async iframeOnLoad() {
    this.attachListeners();
    const duration = await this.player.getDuration();
    Fandom.emit(PLAYER_STATUS.READY, {id: this.id});
    Fandom.emit(PLAYER_STATUS.DURATION_CHANGED, {id: this.id});
  }

  /**
   * Attach player event listeners
   */
  attachListeners() {
    for (let state of Object.values(HTML5_STATUS)) {
      this.player.on(state, () => {
        const mappedState = this.mapState(state);
        if (mappedState === PLAYER_STATUS.PLAYING) {
          this.hidePoster();
        } else if (mappedState === PLAYER_STATUS.LIVESTREAMOFFLINE) {
          this.showPoster();
        }
        Fandom.emit(mappedState, {id: this.id});
      });
    }
  }

  /**
   * Map vimeo state to Fandom player status
   * @param {HTML5_STATUS} state
   * @returns PLAYER_STATUS
   */
  mapState(state) {
    let status = '';
    switch (state) {
      case HTML5_STATUS.PLAYING:
        status = PLAYER_STATUS.PLAYING;
        break;
      case HTML5_STATUS.DURATIONCHANGE:
        status = PLAYER_STATUS.DURATION_CHANGED;
        break;
      case HTML5_STATUS.ENDED:
        status = PLAYER_STATUS.ENDED;
        break;
      case HTML5_STATUS.PAUSE:
        status = PLAYER_STATUS.PAUSED;
        break;
      case HTML5_STATUS.LIVESTREAMOFFLINE:
        status = PLAYER_STATUS.LIVESTREAMOFFLINE;
        break;
    }

    return status;
  }

  /**
   * Set poster
   * @param {poster} poster 
   */
   setPoster(poster) {
    this.poster = poster;
  }

  /**
   * Show video poster
   */
   showPoster() {
    if (this.poster) {
      this.wrapperEl.style.backgroundImage = `url("${this.poster}")`;
      this.player.element.classList.add('d-none');
    }
  }

  /**
   * Hide video poster
   */
   hidePoster() {
    this.player.element.classList.remove('d-none')
  }

  /**
   * Removes the player instance and stops playback.
   * After deletion, the player no longer emits events or responds to API calls.
   */
  destroy() {
    this.player.destroy();
  }

  /**
   * Starts or resumes playback of the stream
   */
  async play() {
    await this.player.play();
  }

  /**
   * Pauses playback of the current stream
   */
  async pause() {
    await this.player.pause();
  }

  /**
   * Gets the duration of the currently loaded media stream
   */
   async getDuration() {
    return await this.player.getDuration();
  }

  /**
   * Gets the current position of the player, in seconds
   */
  async getPosition() {
    return await this.player.getCurrentTime();
  }

  /**
   * Seeks to a specified time in the stream
   * @param {Number} time The position to seek to, in seconds
   */
  async seekTo(time) {
    await this.player.setCurrentTime(time);
  }

  /**
   * Mutes or unmutes the player
   * @param {Boolean} mute True to mute the player, false to unmute
   * @returns {Number} volume The current volume
   */
  setMuted(mute) {
    if (this.prevVolume === null || mute) {
      this.prevVolume = 0.5;
    }

    const volumeToSet = mute ? 0 : this.prevVolume;
    this.player.setMuted(mute);

    return volumeToSet;
  }

  /**
   * Gets whether the player is muted.
   * @returns boolean - True if the player is muted, false otherwise.
   */
  async isMuted() {
    return await this.player.getVolume() === 0;
  }

  /**
   * Gets the player's volume level.
   * @returns {Number} The volume level of the player, between 0.0f and 1.0f.
   */
  async getVolume() {
    return await this.player.getVolume();
  }
  
  /**
   * Sets the playback volume
   * @param {Number} volume The volume to be set. Valid values: in the range 0.0f to 1.0f.
   */
  async setVolume(volume) {
    await this.player.setVolume(volume);
  }

  /**
   * Set of available Quality objects from the loaded source or empty if none are currently available.
   * @returns Quality[]
   */
  async getQualities() {
    const qualities = await this.player.getQualities();
    return qualities
      .filter(q => q.id !== AUTO_QUALITY.id)
      .map(q => {
        return {...q, name: q.label}
      })
  }

  /**
   * 
   * @returns Quality
   */
  async getQuality() {
    const quality = await this.player.getQuality();
    return { name: quality };
  }

  /**
   * Sets the quality the player should use for playback.
   * @param {Quality} quality A valid quality entry from Player.getQualities.
   * @param {boolean} adaptive True for an adaptive quality change; that is, to change quality smoothly at the end of the current buffer. False to change quality immediately.
   */
  setQuality(quality, adaptive=false) {
    this.player.setQuality(quality.id);
  }
}

export default Vimeo;