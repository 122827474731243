<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin],
  methods: {
    getTarget: Fandom.getTarget
  },
  computed: {
    mainContent() {
      const mainContentName = globalState.pageModel.main_content_name;
      return globalState.pageModel.name_to_content[mainContentName];
    },
    theme() {
      return this.mainContent.theme || "light";
    },
    navbarSections() {
      if (get(this, ["content","navbar"], []).length >= 3)
        return get(this, ["content","navbar"], [])
      else {
        const nav = [...get(this, ["content","navbar"], [])];
        while (nav.length < 3)
          nav.push({});
        return nav;
      }
    }
  }
};
</script>

<template>
  <div footer-component :class="theme">
    <component v-if="content.stripes" :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="content"></component>
    <div class="container d-flex flex-column">
      <div class="pt-5 d-flex flex-wrap" v-if="content.navbar || content.right_links">
        <div class="footer-section" v-for="(col, colIndex) in navbarSections" :key="`col-${colIndex}`">
          <div class="d-flex justify-content-between align-items-center">
            <a v-if="col.url" class="d-block section-title pb-4 pt-3 pt-md-0" :href="col.url.url" :target="getTarget(col.url)">{{col.url.title}}</a>
            <div v-else-if="col.title" class="section-title pb-4 pt-3 pt-md-0">{{col.title}}</div>
          </div>
          <div class="pb-3 pb-md-0">
            <div class="section-item mb-3" v-for="(item, itemIndex) in col.items" :key="`item-${colIndex}-${itemIndex}`">
              <a :href="item.url.url" :target="getTarget(item.url)">{{item.url.title}}</a>
            </div>
          </div>
        </div>
        <div class="footer-section right-links d-flex justify-content-md-end" v-if="content.right_links" key="right_links">
          <div class="pb-3 pb-md-0">
            <div class="section-item mb-3" v-for="(item, itemIndex) in content.right_links" :key="`right-link-${itemIndex}`">
              <a :href="item.url.url" class="d-flex align-items-center" :target="getTarget(item.url)" v-if="item.url">
                <svg class="user-icon mr-2" v-if="item.icon">
                  <use :xlink:href="`#${item.icon}-icon`"></use>
                </svg>
                {{item.url.title}}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row order-2 pt-2 pt-md-0" v-if="content.social_icons">
        <div class="col d-flex justify-content-center justify-content-md-end py-4">
          <a :href="icon.url.url" :target="getTarget(icon.url)" v-for="(icon, index) in content.social_icons" :key="`icon-${index}`" class="mx-3">
            <i class="fab" :class="icon.icon"></i>
          </a>
        </div>
        <div class="col-12"><div class="border-bottom w-100"></div></div>
      </div>
      <div class="row pt-3 pb-4 order-3">
        <div class="col-12 col-md my-2 text-center text-md-left">
          <a v-for="(item, index) in content.navbar_bottom" :href="item.url.url" :target="getTarget(item.url)" class="mr-3 section-item" :key="index">
            {{item.url.title}}
          </a>
        </div>
        <div class="col-12 col-md my-2 d-flex justify-content-center justify-content-sm-end section-item align-items-center">
          {{content.disclaimer}}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.fab {
  font-size: 2rem;
}

.border-bottom {
  border-color: #cccccc !important;
}

.footer-section {
  width: calc(100% / 4);
  &.right-links > div {
    width: 12rem;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.section-title {
  font-family: "Audi";
  font-size: 1.25rem;
  font-weight: 400;
}

.section-item {
  font-size: $font-size-xs;
  font-weight: 200;
  font-family: Audi;
  transition: color .5s ease-in-out;

  @include media-breakpoint-up(md) {
    border-bottom: 0 !important;
  }
}

.collapse-toggle {
  transition: all .25s;

  &[aria-expanded=true] {
    transform: rotate(180deg);
  }
}

.user-icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: $black;
}

.light {
  color: $black;
  background-color: $very-light-pink;

  a {
    color: $black;
  }

  .section-item {
    color: $black;

    @include hover {
      color: $black;
    }
  }
}

.dark {
  color: $white;
  background-color: $gray-1000;

  a {
    color: $white;
  }

  .section-item {
    color: $white;

    @include hover {
      color: $very-light-pink;
    }
  }

  .user-icon {
    //stroke: #fff;
    fill: #fff;
  }
}
</style>
