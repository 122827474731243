<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { liveEventsMixin } from 'src/modules/live_events_module.js';
import { addPolling, removePolling } from 'src/modules/polling_module.js';
import { get } from 'lodash';


export default {
  mixins: [fandomBasicMixin, liveEventsMixin, fandomExtendedMixin],
  mounted: function() {
    addPolling("liveInteraction", () => {
      this.getLiveInteraction.call(this);
    });
  },
  data: function() {
    return {
    }
  },
  methods: {
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :style="theme" v-if="isContentVisibleToUser()" :class="contentType" course-product-streaming-component>
    <div class="bg-black">
      <div class="container">
        <div class="row">
          <div class="col-12 pt-4">
            <embed-advanced :content="content" :opt-container-content="containerContent"></embed-advanced>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="content.title || content.description">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8 py-4">
          <h2 class="h1 py-3" v-if="content.title">{{content.title}}</h2>
          <div v-html="content.description" v-if="content.description"></div> 
        </div>
      </div>
    </div>
    <component :is="stripe.layout" :content="stripe" v-for="(stripe, index) in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
  </div>
</template>


<style lang="scss">
[course-product-streaming-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);
}
</style>
