<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  methods: {
    isNumeric(value) {
      return /^-?\d+$/.test(value);
    }
  },
  computed: {
    spacerClass() {
      if (this.isNumeric(this.content.space)) {
        const breakpoint = this.content.breakpoint ? `${this.content.breakpoint}-` : "";
        return this.content.space ? `pt-${breakpoint}${this.content.space}` : `pt-${breakpoint}4`;
      } else {
        return this.content.space;
      }
      
    }
  }
};
</script>

<template>
  <div class="themed-item" v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="[contentType, spacerClass]" :style="theme" stripe-spacer-component>
  </div>
</template>

<style lang="scss">
[stripe-spacer-component] {
  @include media-breakpoint-up(sm) {
    &.mb-n6 {
      margin-bottom: -5rem;
    }
  }
}
</style>
