<template>
  <div class="container-fluid">
    <div class="row">
      <div class="d-flex col-12 h-100vh justify-content-center align-items-center flex-column flex-md-row">
        <template v-if="isStarted">
          <button @click="startSpeech" type="button" class="btn btn-lg btn-primary mb-3 mb-md-0">Talk</button>
          <button @click="back" type="button" class="btn btn-lg btn-primary">Back</button>
          <button @click="reset" type="button" class="btn btn-lg btn-primary">Reset</button>
        </template>
        <button v-else @click="start" type="button" class="btn btn-lg btn-primary">Start</button>
      </div>
    </div>
  </div>
</template>

<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { broadcasting } from 'src/modules/services/ai-service';

export default {
  mixins: [ fandomBasicMixin ],
  data: function() {
    return {
      isStarted: false
    }
  },
  methods: {
    start() {
      broadcasting({ room: "interview_console", type: "start" });
      this.isStarted = true;
    },
    startSpeech() {
      broadcasting({ room: "interview_console", type: "talk" });
    },
    back() {
      broadcasting({ room: "interview_console", type: "back" });
    },
    async reset() {
      await broadcasting({ room: "interview_console", type: "reset" });
      this.isStarted = false;
    }
  }
}
</script>

<style lang="scss" scoped>
video {
  object-fit: cover;
}

.btn.btn-lg {
  margin: 0 1rem;
  font-size: 3rem;
  width: 200px;
  height: 200px;
  text-transform: uppercase;
  border-radius: 50%;
}
</style>