import { PRESETS_TYPE } from './constants';

const defaults = {
  general: {
    autoplay: false,
    playsinline: true,
    muted: false,
    hide_controls: true,
  },

  // Quality default
  quality: {
    default: 576,
    options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240],
  },
  // Speed default and options to display
  speed: {
    selected: 1,
    // The options to display in the UI, if available for the source media (e.g. Vimeo and YouTube only support 0.5x-4x)
    options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 4],
  },
  // Fullscreen settings
  fullscreen: {
    enabled: false, // Allow fullscreen?
    fallback: false, // Use fallback using full viewport/window
    forcecss: false // Use the css fullscreen only
  },

  controlBarTimeout: 1000,
  controlBarTimeoutMobile: 3000,
  prefetchPolling: 5000,

  // Default controls
  controls: [
    'play',
    'current-time',
    'mute',
    'volume',
    'settings',
    'pip',
    'airplay',
    'fullscreen',
    'wishlist',
    'products',
    'chapters',
    'comments',
    'like'
  ],
  presets: {
    [PRESETS_TYPE.DEFAULT]: ['play','mute','volume','fullscreen','pip','settings','like'],
    [PRESETS_TYPE.INTERACTION]: ['play','mute','volume','settings','like'],
    [PRESETS_TYPE.LIVESHOPPING]: ['play','mute','volume','pip','settings','info', 'comments','wishlist','products','quiz','like'],
    [PRESETS_TYPE.ONDEMAND_SHOPPING]: ['play','mute','volume','pip','settings','info', 'chapters','comments','wishlist','products','like'],
    [PRESETS_TYPE.LIVEEVENT]: ['play','mute','volume','chapters','fullscreen','pip','settings','like']
  },
  settings: ['captions', 'quality', 'speed'],

  // URLs
  urls: {
    vimeo: {
      sdk: 'https://player.vimeo.com/api/player.js',
      iframe: 'https://player.vimeo.com/video/{0}?{1}',
      api: 'https://vimeo.com/api/oembed.json?url={0}',
    },
    youtube: {
      sdk: 'https://www.youtube.com/iframe_api',
      api: 'https://noembed.com/embed?url=https://www.youtube.com/watch?v={0}',
    },
    svp: {
      sdk: 'https://play.streamingvideoprovider.com/js/dplayer.js',
    }
  },

  // Vimeo plugin
  vimeo: {
    byline: false,
    portrait: false,
    title: false,
    speed: true,
    transparent: false,
    customControls: true,
  },

  // YouTube plugin
  youtube: {
    rel: 0, // No related vids
    showinfo: 0, // Hide info
    iv_load_policy: 3, // Hide annotations
    modestbranding: 1, // Hide logos as much as possible (they still show one in the corner when paused)
  },

  svp: {transparent:"true",pause:"0",repeat:"",bg_color:"#ffffff",fs_mode:"2",no_controls:"1",start_img:"0",start_volume:"0",close_button:"",brand_new_window:"1",auto_hide:"1",stretch_video:"",player_align:"NONE",offset_x:"0",offset_y:"0",player_color_ratio:0.6,skinAlpha:"50",colorBase:"#250864",colorIcon:"#ffffff",colorHighlight:"#7f54f8",direct:"false",is_responsive:"true",viewers_limit:0,cc_position:"bottom",cc_positionOffset:70,cc_multiplier:0.03,cc_textColor:"#ffffff",cc_textOutlineColor:"#ffffff",cc_bkgColor:"#000000",cc_bkgAlpha:0.1,aspect_ratio:"adaptive",play_button:"0",play_button_style:"static",sleek_player:"1",auto_play:"0",auto_play_type:"mute",floating_player:"none"}
};

export default defaults;