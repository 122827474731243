<script>
import { get } from "lodash";
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl
  },
  computed: {
    paddingY() {
      const paddingY = get(this, ["content", "padding_y"], 4);
      return [
        `py-${paddingY}`
      ];
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :style="theme" :class="[contentType, paddingY]" class="container-fluid themed-item quote">
      <div class="row mx-0 py-4 justify-content-center">
        <div class="col-12 col-md-8 d-flex flex-column justify-content-center align-items-center py-2" :class="content.theme">
          <div 
            v-if="content.avatar" 
            class="avatar rounded-circle mb-3 bg-cover-center"
            :style="{'background-image': getBackgroundUrl(content.avatar.url)}"
          ></div>
          <span class="text-center" v-if="content.role">{{content.role}}</span>
          <span class="name h2 pb-4" v-if="content.name">{{content.name}}</span>
          <div class="text-center font-weight-normal" v-if="content.description" v-html="content.description"></div>
        </div>
      </div>    
    </div>
</template>

<style lang="scss" scoped>
.avatar {
  width: 6rem;
  height: 6rem;
}

.name {
  font-family: $font-family-headings;
}

.quote {
  font-size: 1.125rem;
}
</style>
