<template>
  <div v-easyadmin="easyadminId" class="save-the-date bg-cover-center" :class="contentType" :style="coverStyle" page-live-event-countdown-component>
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-6 text-center">
          <img v-if="content.icon" class="icon my-4" :alt="content.icon.alt" :src="content.icon.url" :style="iconWidth">
          <h1 :class="content.titleClass" class="mb-0 pb-5">{{ content.title }}</h1>
          <template v-if="!countdownEnded">
            <p class="mb-0 small text-uppercase">{{ft("globals.live_shopping.countdown.message")}}</p>
            <vue-countdown @end="handleCountdownEnd" tag="div" :time="time" :transform="transform">
              <template #default="{days, hours, minutes, seconds}">
                <div class="countdown">
                  <div class="countdown__number">
                    <span class="h1 font-weight-normal">{{days}}</span>
                    <span class="small text-uppercase">{{ft("globals.live_shopping.countdown.days")}}</span>
                  </div>
                  <span class="countdown__separator h1 font-weight-normal">:</span>
                  <div class="countdown__number">
                    <span class="h1 font-weight-normal">{{hours}}</span>
                    <span class="small text-uppercase">{{ft("globals.live_shopping.countdown.hours")}}</span>
                  </div>
                  <span class="countdown__separator h1 font-weight-normal">:</span>
                  <div class="countdown__number">
                    <span class="h1 font-weight-normal">{{minutes}}</span>
                    <span class="small text-uppercase">{{ft("globals.live_shopping.countdown.minutes")}}</span>
                  </div>
                  <span class="countdown__separator h1 font-weight-normal">:</span>
                  <div class="countdown__number">
                    <span class="h1 font-weight-normal">{{seconds}}</span>
                    <span class="small text-uppercase">{{ft("globals.live_shopping.countdown.seconds")}}</span>
                  </div>
                </div>
              </template>
            </vue-countdown>
          </template>
          <p v-else class="h3">{{ft("globals.live_shopping.countdown.ended_message")}}</p>
          <p class="h4 mb-0 pt-3 pb-4 text-capitalize">{{ formattedDate }} Uhr</p>
          <a 
            v-if="content.ical && !countdownEnded"
            target="_black"
            download
            :href="content.ical.url"
            class="btn btn-save font-weight-bold"
          >
            {{ft("globals.live_shopping.countdown.button_text")}}
          </a>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/it';

import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  components: {
    VueCountdown
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      countdownEnded: false,
      startTime: this.value
    }
  },
  mounted() {
    this.countdownEnded = this.time === 0;
  },
  methods: {
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        props[key] = value < 10 ? `0${value}` : value;
      });

      return props;
    },
    handleCountdownEnd() {
      this.countdownEnded = true;
    }
  },
  computed: {
    time() {
      const diff = moment(this.startTime).diff(moment());
      return Math.max(diff, 0);
    },
    formattedDate() {
      moment.locale(this.globalState.lang ?? 'en');
      return moment(this.startTime).format('dddd, DD. MMMM - HH:mm');
    },
    coverStyle() {
      return {backgroundImage: this.getContentResponsiveImageURL('backgroundImage')}
    },
    iconWidth() {
      return {
        width: this.content.icon_width ?? "6rem"
      }
    },
  },
  watch: {
    time(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.countdownEnded = newVal === 0;
      }
    },
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.startTime = newVal;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
[page-live-event-countdown-component] {
  &.save-the-date {
    background-color: $black;
    position: fixed;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1090;

    .container-fluid {
      background: linear-gradient(1deg, #10111D 1.74%, rgba(16, 17, 29, 0.0001) 100%);
    }

    .fs-big {
      font-size: 3.125rem;
    }

    .small {
      color: $player-grey;
    }

    .row {
      height: 100vh;
    }

    .icon {
      max-width: 100%;
    }

    .countdown {
      display: flex;
      justify-content: center;
      align-items: center;

      &__number {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .small {
          font-size: .5rem;
        }
      }

      &__separator {
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 1.5rem;
      }
    }

    .btn-save {
      background: $white;
      border: 1.5px solid $white;
      border-radius: 14px;
      color: $black;
    }
  }
}
</style>