<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  props: {
    width: {
      type: Number,
      default: 8
    }
  },
  mounted() {
    Vue.nextTick(() => {
      // Animation management.
      this.registerAnimation();
    });
  },
  methods: {
    expandUrl: Fandom.expandUrl,
    exists: Fandom.exists
  },
  computed: {
    columnClasses() {
      const columnWidth = Fandom.exists(this.content.width) ? this.content.width : this.width;
      return [
        `col-lg-${columnWidth}`,
        `text-${this.content.text_align}`
      ];
    },
    componentClasses() {
      const classes = [
        this.contentType, 
        this.content.theme ? `${this.content.theme}-theme` : "",
        (this.content.padding_y || this.content.padding_top || this.content.padding_bottom) ? this.paddingY : "inset"
      ]
      if (!!this.content.background_class && !this.content.background_color) {
        classes.push(this.content.background_class.indexOf("bg-") >= 0 ? this.content.bakcground_class : `bg-${this.content.background_class}`);
      }
      if (!!this.content.section_title) {
        classes.push('pb-0');
      }
      return classes;
    },
    containerClasses() {
      return [
        Fandom.exists(this.content.background_fixed) && this.content.background_fixed && !this.isViewportMobile ? "bg-fixed" : "bg-cover-center",
        this.content.theme ? `${this.content.theme}-theme` : ""
      ];
    },
    containerStyles() {
      return {
        "min-height": this.content.height,
        "color": this.content.text_color
      };
    },
    isViewportMobile() {
      return globalState.viewport.sm || globalState.viewport.xs || globalState.viewport.md;
    }
  },
};

</script>

<template>
  <div :id="content.stripe_id" v-easyadmin="easyadminId" :class="componentClasses" v-if="isContentVisibleToUser()" class="inview_animate" :style="{'background-color': content.background_color }" one-col-component>
    <div class="container google-seo-height d-flex flex-column justify-content-center position-relative overflow-hidden" :style="containerStyles">
      <div 
        v-if="content.background_image"
        class="position-absolute-center bg-cover-center w-100 h-100"
        :class="{ 'inview_animate_zoom': !exists(content.image_zoom) || content.image_zoom == true }" 
        :style="{'background-image': getContentResponsiveImageURL('background_image')}"
      ></div>
      <div class="row text-align-center justify-content-center">
        <div :class="columnClasses">
          <text-section :content="content" :opt-container-content="containerContent"></text-section>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/*TODO: to remove after fixed in variables file*/
$secondary-nav-height: 48px;

.anchor{
  display: block;
  height: calc(#{$header-height-sm} + #{$secondary-nav-height});
  margin-top:  calc(-#{$header-height-sm} - #{$secondary-nav-height});
  visibility: hidden;
}

table {
  font-size: 1rem;
  font-weight: 600;
}
</style>
