<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { audiEventsBasicMixin } from '../../modules/audi_events_mixins_module.js';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, audiEventsBasicMixin],
  created() {
    const theme = this.content.theme || "light";
    if (theme == "light") {
      this.content.background_color = this.content.background_color || "var(--gray-200)";
      this.content.text_color = this.content.text_color || "var(--black)";
      this.content.button_class = this.content.button_class || "btn-black";
      this.content.border_color = "var(--gray-300)";
    } else {
      this.content.background_color = this.content.background_color || "var(--gray-1000)";
      this.content.button_class = this.content.button_class || "btn-white";
      this.content.border_color = "var(--black)";
    }
  },
  computed: {
    button() {
      if (Fandom.isUserRegistered()) {
        return this.content.registered_button ?? this.content.button;
      } else {
        return this.content.anonymous_button ?? this.content.button;
      }
    },
    buttonId() {
      if (Fandom.isUserRegistered()) {
        return this.content.registered_button ? "registered_button_banner" : null;
      } else {
        return this.content.anonymous_button ? "anonymous_button_banner" : null;
      }
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :style="theme" v-if="isContentVisibleToUser()" class="border-top" :class="contentType" stripe-button-component>
    <div class="container d-flex flex-column flex-md-row align-items-center justify-content-md-between py-4">
      <component
        :class="content.banner_title_class || 'banner-title'"
        :is="content.banner_title_tag || 'div'"
        v-html="content.banner_title"
        v-if="content.banner_title"
        class="mb-3 mb-md-0"
      />
      <a
        class="btn"
        :id="buttonId"
        v-if="button"
        :href="button.url"
        :target="getTarget(button)"
        :class="content.button_class"
        @click.prevent="trackingButtonUrl($event)"
      >
        {{button.title}}
      </a>
    </div>
  </div>
</template>


<style lang="scss">
[stripe-button-component] {
  bottom: 0;
  width: 100%;
  position: fixed;
  z-index: $zindex-fixed;

  color: var(--theme-color);
  background-color: var(--theme-background);
  border-color: var(--theme-border-color) !important;

  .banner-title {
    font-size: 1.375rem;
    font-family: 'Audi Extended';
  }

  @include media-breakpoint-down(sm) {
    a.btn {
      width: 100%;
    }
  }
}
</style>