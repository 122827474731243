import { debounce } from "lodash";

function checkQueries(viewport) {
  let match = false;
  for (let breakpoint in globalState.viewport.breakpoints) {
    const breakpointMatch = match ? false : viewport.innerWidth < globalState.viewport.breakpoints[breakpoint];
    Vue.set(globalState.viewport, breakpoint, breakpointMatch);
    if (match) {
      // nothing to do
    } else {
      match = breakpointMatch;
    }
  }
}

const breakpoints = {
  "xs": 576,
  "sm": 768,
  "md": 992,
  "lg": 1200,
  "xl": 9999
};

function mqLess(mq) {
  let match = false;

  ["xs", "sm", "md", "lg", "xl"].some((el) => {
    match = match || globalState.viewport[el];
    return el == mq;
  });

  return match;
}

const viewportMixin = {
  methods: {
    windowResizing(event) {
      const target = event.currentTarget ? event.currentTarget : event.srcElement;
      checkQueries(target);
    },
    mediaStateChanged(name, matches) {
      Vue.set(this.globalState.screen, name, matches);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResizing);
  },
  created() {
    if (!globalState.viewport) {
      Vue.set(globalState, "viewport", { breakpoints });
    }

    if (!globalState.screen) {
      Vue.set(globalState, "screen", {});
      const query = window.matchMedia('(orientation: portrait)');
      this.mediaStateChanged('portrait', query.matches);
      this.mediaStateChanged('landscape', !query.matches);
    }

    checkQueries(window);
  },
  mounted() {
    const query = window.matchMedia('(orientation: portrait)');
    if ('addEventListener' in query) {
      query.addEventListener('change', (e) => {
        this.mediaStateChanged('portrait', e.matches);
        this.mediaStateChanged('landscape', !e.matches);
      });
    }

    checkQueries(window);
    window.addEventListener("resize", debounce(this.windowResizing, 200));
  }
};

export {
  viewportMixin,
  mqLess
};
