import anime from 'animejs/lib/anime.es.js';

const ANIMATION_SIDE = {
  LEFT: 'left',
  RIGHT: 'right'
}

const ANIMATION_STATUS = {
  NOT_STARTED: 'not_started',
  STARTED: 'started',
  FINISHED: 'finished'
}

/**
 * 
 * @param {String, Element, String[], Element[]} tagTarget Tag element containing the label
 * @param {String, Element, String[], Element[]} iconTarget Live/Replay icon
 */
const animateLiveHeader = (tagTarget, iconTarget) => {
  return anime.timeline({autoplay: false})
    .add({
      targets: tagTarget,
      opacity: [0, 1],
      easing: 'easeInOutCubic',
      duration: 600,
      begin: ()=> {
        anime({
          targets: iconTarget,
          color: ['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 1)'],
          easing: 'easeOutCubic',
          duration: 600,
          loop: true,
          direction: 'alternate'
        });
      }
    })
}

/**
 * 
 * @param {String, Element, String[], Element[]} target element to animate
 * @param {ANIMATION_SIDE} side 
 */
const showSidebar = (target, side=null) => {
  const config = {
    targets: target,
    opacity: [0, 1],
    easing: 'easeInOutCubic',
    duration: 450,
  };

  if (side !== null) {
    config.translateX = {
      value: side === ANIMATION_SIDE.RIGHT ? ['-50%', 0] : ['50%', 0],
      easing: 'easeOutQuint'
    }
  }

  return anime(config);
}

/**
 * 
 * @param {String, Element, String[], Element[]} target element to animate
 * @param {ANIMATION_SIDE} side 
 */
 const hideSidebar = (target, side=null) => {
  const config = {
    targets: target,
    autoplay: false,
    opacity: [1, 0],
    easing: 'easeInOutCubic',
    duration: 450
  };

  if (side !== null) {
    config.translateX = {
      value: side === ANIMATION_SIDE.RIGHT ? [0, '-50%'] : [0, '50%'],
    }
    config.complete = () => {
      $(target).css('transform', 'translateX(0%)');
    }
  }

  return anime(config);
}

/**
 * 
 * @param {String, Element, String[], Element[]} targetToHide 
 * @param {String} targetToShow
 * @returns 
 */
const showSidebarDetail = (targetToHide, targetToShow) => {
  return anime
    .timeline({autoplay: false})
    .add({
      targets: targetToHide,
      opacity: [1, 0],
      translateX: [0, '-50%'],
      easing: 'easeInOutCubic',
      duration: 400,
      complete: () => {
        // TODO - change with class (in order to add CSS prefix)
        $(targetToShow).css("opacity", 1);
        $(targetToShow).css('transform', 'translateX(0%)');
      }
    })
    .add({
      targets: `${targetToShow} .row`,
      opacity: [0, 1],
      translateX: {
        value: ['50%', 0],
        easing: 'easeOutQuint'
      },
      easing: 'easeInOutCubic',
      duration: 450,
      delay: anime.stagger(80)
    }, '-=200');
}

/**
 * 
 * @param {String, String[]} targetToShow
 * @returns 
 */
const showQuiz = (targetToShow) => {
  return anime
    .timeline({autoplay: true})
    .add({
      targets: targetToShow,
      opacity: [0, 1],
      translateX: {
        value: ['50%', 0],
        easing: 'easeOutQuint'
      },
      easing: 'easeInOutCubic',
      duration: 450,
      delay: anime.stagger(80)
    }, '+=450');
}

/**
 * 
 * @param {String, String[]} targetToHide
 * @returns 
 */
const hideQuiz = (targetToHide) => {
  return anime
    .timeline({autoplay: false})
    .add({
      targets: targetToHide,
      opacity: [1, 0],
      translateX: {
        value: [0, '-50%'],
        easing: 'easeOutQuint'
      },
      easing: 'easeInOutCubic',
      duration: 450,
      delay: anime.stagger(80)
    });
}

/**
 * 
 * @param {String, Element, String[], Element[]} targetToHide 
 * @param {String} targetToShow
 * @returns 
 */
 const back = (targetToHide, targetToShow) => {
  return anime
    .timeline({autoplay: false})
    .add({
      targets: `${targetToHide} .row`,
      opacity: [1, 0],
      translateX: [0, '50%'],
      easing: 'easeInOutCubic',
      duration: 400,
      delay: anime.stagger(80),
      complete: () => {
        // TODO - change with class (in order to add CSS prefix)
        $(targetToShow).css("opacity", 0);
        $(targetToShow).css('transform', 'translateX(-100%)');
      }
    }, '-=200')
    .add({
      targets: targetToShow,
      opacity: [0, 1],
      translateX: {
        value: ['-50%', 0],
        easing: 'easeOutQuint'
      },
      easing: 'easeInOutCubic',
      duration: 450
    }, '-=200');
}

const expandBanner = () => {
  const animation = anime.timeline({autoplay: false})
    .add({
      targets: '.banner',
      width: {
        value: ['250px', '72px'],
        easing: 'easeOutQuint'
      },
      duration: 450
    })
    .add({
      targets: '.banner .banner__content',
      opacity: [1, 0],
      easing: 'easeInOutSine',
      duration: 150,
      complete: () => {
        const banner = document.querySelector('.banner');
        if (banner) {
          banner.classList.remove('small');
        }
      }
    }, 0);

  animation.reverse();
  animation.play();
}

const addBanner = () => {
  const t1 = anime.timeline()
    .add({
      targets: '.banner:first-child',
      backgroundColor: ['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 1)'],
      translateX: {
        value: ['-50%', 0],
        easing: 'easeOutQuint'
      },
      width: {
        value: ['0px', '250px'],
        easing: 'easeOutQuint'
      },
      easing: 'easeInOutSine',
      duration: 450
    })
    .add({
      targets: '.banner:first-child .banner__content',
      opacity: [0, 1],
      translateX: {
        value: ['-50%', 0],
        easing: 'easeOutQuint'
      },
      easing: 'easeInOutSine',
      duration: 450
    }, '150')

  const t2 = anime.timeline()
    .add({
      targets: '.banner:nth-child(2)',
      width: {
        value: ['250px', '72px'],
        easing: 'easeOutQuint'
      },
      duration: 450
    })
    .add({
      targets: '.banner:nth-child(2) .banner__content',
      opacity: [1, 0],
      easing: 'easeInOutSine',
      duration: 150,
      complete: () => {
        const banner = document.querySelector('.banner:nth-child(2)');
        if (banner) {
          banner.classList.add('small');
        }
      }
    }, 0)
    .add({
      targets: '.banner:nth-child(2) .banner__content',
      opacity: [0, 1],
      translateX: {
        value: ['-50%', 0],
        easing: 'easeOutQuint'
      },
      easing: 'easeInOutSine',
      duration: 450
    }, 150);
  
  const t3 = anime.timeline()
    .add({
      targets: '.banner:nth-child(3) .banner__content',
      translateX: [0, '-0.2rem', 0],
      easing: 'easeOutSine',
      duration: 450,
      begin: () => {
        const banner = document.querySelector('.banner:nth-child(3)');
        if (banner) {
          banner.classList.add('small');
        }
      }
    });

  return [t1, t2, t3];
}

const showBanners = () => {
  const tl = anime.timeline()
    .add({
      targets: '.banner',
      backgroundColor: ['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 1)'],
      translateX: {
        value: ['-100%', 0],
        easing: 'easeOutQuint'
      },
      easing: 'easeInOutSine',
      duration: 450    
    })
    .add({
      targets: ['.banner .banner__content', '.banner .banner__countdown'],
      opacity: [0, 1],
      translateX: {
        value: ['-4rem', 0],
        easing: 'easeOutQuint'
      },
      easing: 'easeInOutSine',
      duration: 450
    }, '-=300');

  return tl;
}

const hideBanner = (targets) => {
  return anime({
    autoplay: false,
    targets,
    opacity: [1, 0],
    easing: 'easeOutSine',
    duration: 450,
  })
}

export {
  animateLiveHeader,
  showSidebar,
  hideSidebar,
  showSidebarDetail,
  showQuiz,
  hideQuiz,
  back,
  addBanner,
  expandBanner,
  hideBanner,
  showBanners,
  ANIMATION_SIDE,
  ANIMATION_STATUS
}