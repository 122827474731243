// module for inline editing
function markerTemplate(binding) {
  const href = binding.value.startsWith("http") ? binding.value : Fandom.applyContextToUrl(getHrefFromContent(binding.value));
  return `
    <a href="${href}" target="_blank" class="easyadmin-marker align-items-center justify-content-center">
      <i class="fal fa-pen-alt"></i>
    </a>
  `;
}

function getHrefFromContent(value) {
  const [content, record] = value.split("|");
  return (typeof record !== "undefined") ? `/easyadmin/content/edit/${content}?record_id=${record}` : `/easyadmin/content/edit/${content}`;
}

const componentsMixin = {
  directives: {
    easyadmin: {
      bind(el, binding) {
        if (globalState.allowedToSeeEasyadminShortcuts) {
          el.insertAdjacentHTML("beforeend", markerTemplate(binding));
        }
      }
    }
  }
};

const rootMixin = {
  methods: {
    easyadminToggleInlineEditingButtons() {
      globalState.easyadminMode = !globalState.easyadminMode;
    }
  }
};

export {
  componentsMixin,
  rootMixin
};
