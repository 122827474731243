<script>
export default {
  props: {
    miniformat: Object
  }
};
</script>

<template>
  <div class="px-2 py-1 d-inline-flex align-items-center" miniformat-badge-component :style="{'background-color': `${miniformat.background_color} !important`}">
    <div v-if="miniformat.icon" :class="miniformat.icon" class="mr-1"></div>{{miniformat.title}}
  </div>
</template>

<style lang="scss">
[miniformat-badge-component] {
  background-color: $primary;
  color: $white;
  font-size: 0.75rem;
}
</style>
