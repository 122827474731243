import Vue from 'vue';
import { get } from 'lodash-es';

export default Vue.extend({
  methods: {
    getBusinessNamePublicKey(name) {
      const publicKeys = get(this.globalState.pageModel, 'stripeConfig.publicKeys', []);
      const businessName = name || get(this.globalState.pageModel, 'stripeConfig.defaultBusinessName', "");
      const publicKey = publicKeys.filter( item => {
        return typeof item[businessName] !== 'undefined'
      });

      if (publicKey.length === 0) {
        return null;
      }

      return publicKey[0][businessName];
    }
  }
})