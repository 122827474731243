<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { liveEventsModalMixin } from "src/modules/live_events_v2_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, liveEventsModalMixin],
  mounted() {
    if(this.content.active == true){
     this.openModal();
    }
  },
  computed: {
    buttonUrl() {
      const url = get(this.content, ["button","url"], false);
      return url == "$close" ? "javascript:void(0)" : url;
    }
  },
  methods: {
    mayCloseModal(url) {
      if (url === "$close") {
        this.closeModal();
      }
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" modal page-live-event-alert-component>
    <transition 
      enter-active-class="animated fadeIn faster" 
      leave-active-class="animated fadeOut faster" 
    >
      <div class="live-event-modal__background" v-if="showModal"></div>
    </transition>
    <transition 
      enter-active-class="animated slideInDown faster" 
      leave-active-class="animated slideOutUp faster" 
    >
      <div class="live-event-modal d-flex justify-content-center align-items-center p-4 h-100vh w-100vw" v-if="showModal">
        <div class="alert-modal__content position-relative">
          <div class="h-100 py-5 px-4 d-flex flex-column justify-content-between align-items-center">
            <div class="w-100 text-center py-4">
              <h2 class="mb-0 px-4 h2">{{content.title}}</h2>
              <i @click="closeModal()" class="fal fa-lg fa-times p-4 cursor-pointer position-absolute"></i>
            </div>
            <div class="w-100 h-100 text-center pb-4">
              <div class="font-weight-normal" v-html="content.description" v-if="content.description"></div>
              <div class="w-100 d-flex justify-content-center align-items-center pt-2" v-if="content.button">
                <a
                  class="btn"
                  :class="content.button_class || (containerContent.theme == 'dark' ? `btn-outline-light` : 'btn-outline-dark')"
                  :href="buttonUrl"
                  :target="getTarget(content.button)"
                  @click="mayCloseModal(content.button.url)"
                >
                  {{content.button.title}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<style lang="scss" scoped>
.live-event-modal {
  .fa-times {
    top: 0;
    right: 0;
  }
}

.alert-modal__content {
  width: 100%;
  max-width: 30rem;
  overflow-y: scroll;
  border-radius: 0;
  background-color: var(--theme-background);
  color: var(--theme-color);
  max-height: calc(100% - 3rem);
}
</style>
