<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { ActionCableComponentsMixin } from 'src/modules/websocket_module.js';
import { interactionMixin } from 'src/modules/interactions_module.js';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, interactionMixin, ActionCableComponentsMixin],
  props: {
    waitingTitle: {
      type: String,
      default: ""
    },
    waitingDescription: {
      type: String,
      default: ""
    },
  },
  data() {
    return {}
  },
  methods: {
    initSubscriptions() {
      this.subscribe(
        { channel: "PublicChannel", room: `${this.containerContent.name}|${this.content.interaction.id}`, lang: globalState.lang },
        {
          connected: () => {
            this.$emit("websocket-connected", this.content);
          },
          received: (receivedData) => {
            this.updateIdToAnswer(this.containerContent, this.content.interaction, receivedData);
          }
        }
      );
    }
  },
  computed: {
    computedWaitingTitle() {
      return this.waitingTitle || this.ft("globals.interaction.feedback.title");
    },
    computedWaitingDescription() {
      return this.waitingDescription || this.ft("globals.interaction.feedback.description");
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="bg-cover-center" :class="contentType" v-if="isContentVisibleToUser()" :style="theme" interaction-widget-wrapper>
    <div class="container-fluid">
      <div class="row align-items-center justify-content-center position-relative">
        <div class="col-12 py-3 question-card" :style="{'background-color': `var(--gray-${content.theme || 'light'})`}">
          <slot name="waiting">
            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
              <div v-if="showWaiting" class="wait-message d-flex flex-column align-items-center p-4 justify-content-center position-absolute-center">
                <div class="w-100 h3 mb-2 text-center">{{ computedWaitingTitle }}</div>
                <div class="w-100 text-center">{{ computedWaitingDescription }}</div>
              </div>
            </transition>
          </slot>
          <slot name="closed">
            <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
              <div v-if="votingClosed && !showWaiting && !showResults" class="wait-message d-flex flex-column align-items-center p-4 justify-content-center position-absolute-center">
                <div class="w-100 h3 mb-2 text-center">{{ft("globals.interaction.voting_closed.title")}}</div>
                <div class="w-100 text-center">{{ ft("globals.interaction.voting_closed.description") }}</div>
              </div>
            </transition>
          </slot>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[interaction-widget-wrapper] {
  .question-card {
    overflow: hidden;
    border-radius: 12px;
    color: var(--theme-color);

    .wait-message {
      background-color: var(--white);
      color: var(--theme-color);
      z-index: 10;
    }
  }

  @include ie {
    min-width: 100%;
  }

}
</style>