<template>
  <span 
    mark-interaction-component 
    class="d-flex align-items-center justify-content-center rounded-circle mark" 
    :class="[status, value.type]"
    @click="handleClick"
  >
    <i class="far" :class="iconClass"></i>
  </span>
</template>

<script>
const ANSWER_STATUS = {
  CORRECT: 'correct',
  WRONG: 'wrong',
  NOT_ANSWERED: 'not-answered'
}

export default {
  props: {
    value: Object
  },
  methods: {
    handleClick(e) {
      e.stopPropagation();
      this.$emit('go-to-mark', this.value)
    }
  },
  computed: {
    status() {
      const userInteraction = this.value.userInteraction;
      if (typeof userInteraction !== "undefined") { // this means interaction has been done
        const status = this.value.interaction.type == "TestStep" ? true : (userInteraction.data?.answer_status ?? true);
        return status ? ANSWER_STATUS.CORRECT : ANSWER_STATUS.WRONG;
      }

      return ANSWER_STATUS.NOT_ANSWERED;
    },
    iconClass() {
      if (this.value.icon) {
        return this.value.icon;
      } else if (this.status === ANSWER_STATUS.CORRECT) {
        return "fa-thumbs-up";
      } else if (this.status === ANSWER_STATUS.WRONG) {
        return "fa-thumbs-down";
      } else {
        return "fa-question";
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  [mark-interaction-component] {
    $mark-size: 1.5rem;

    &.mark {
      width: $mark-size !important;
      height: $mark-size !important;
      background-color: $primary;
      border: 1px solid $primary;
      color: $white;
      z-index: 5;

      &.correct {
        background-color: $success;
        border-color: $success;
      }

      &.wrong {
        background-color: $danger;
        border-color: $danger;
      }

      .far {
        font-size: $mark-size * .5;
      }

      @include media-breakpoint-down(sm) {
        width: $mark-size * .75 !important;
        height: $mark-size * .75 !important;

        .far {
          font-size: $mark-size * .3;
        }
      }
    }
  }
</style>