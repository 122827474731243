<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js'
import { interactionMixin } from 'src/modules/interactions_module.js'
import { ActionCableComponentsMixin } from 'src/modules/websocket_module.js'
import { get, has } from "lodash"

export default {
  mixins: [fandomBasicMixin, interactionMixin, ActionCableComponentsMixin],
  props: {
    answerColumns: {
      type: Number,
      default: 12
    },
    answerPaddingX: {
      type: Number,
      default: 0,
    },
    questionAlignment: {
      type: String,
      default: "left"
    }
  },
  mounted() {
    this.initSubscriptions();
  },
  methods: {
    initSubscriptions() {
      this.subscribe(
        { channel: "PublicChannel", room: `${this.containerContent.name}|${this.content.interaction.id}`, tenant: this.tenant, lang: globalState.lang },
        {
          connected: () => {
            this.$emit("websocket-connected", this.content);
          },
          received: (receivedData) => {
            this.updateIdToAnswer(this.containerContent, this.content.interaction, receivedData);
          }
        }
      );
    },
    getAnswerClasses(answer) {
      const classes = [];
      if (this.showResults) {
        classes.push('not-answerable');
      }
      if (this.isInteractionDone(this.containerContent, this.content.interaction)) {
        const userInteraction = this.getUserInteraction(this.containerContent, this.content.interaction);
        classes.push(userInteraction.data.answer_id == answer.$id && !this.displayWaitMessage ? "choose" : "not-choose");
      }
      return classes;
    },
    mayUpdateVersusInteraction(containerContent, content, interaction, answerId){
      const contentUserInfo = this.getContentUserInfo(this.containerContent);
      if(!get(contentUserInfo, ["id_to_interaction", this.content.interaction.id, "updated_at"], null)){
        this.updateVersusInteraction(containerContent, content, interaction, answerId, () => {}, () => {this.$emit('interaction-updated')});
      }
    },
    getAnswerPercentage(answer) {
      const contentUserInfo = this.getContentUserInfo(this.containerContent);
      if((this.isInteractionDone(this.containerContent, this.content.interaction) && !this.displayWaitMessage) || get(contentUserInfo, ["id_to_interaction", this.content.interaction.id, "updated_at"], null)) {
        let percentage = get(this.getContentUserInfo(this.containerContent).id_to_interaction[this.content.interaction.id],
        ["id_to_answer", answer.$id, "percentage"], 0);
        return percentage.toFixed(0);
      }
    },
    progressWidthStyle(percentage){
      if (this.optContainerContent && this.optContainerContent.reverse_progress_bars){
        return {['width']: `${parseInt(100 - percentage)}%`};
      } else {
        return {['width']: `${parseInt(percentage)}%`};
      }
    }
  },
  computed: {
    showResults() {
      const contentUserInfo = this.getContentUserInfo(this.containerContent);
      return ((this.isInteractionDone(this.containerContent, this.content.interaction) && !this.displayWaitMessage) || get(contentUserInfo, ["id_to_interaction", this.content.interaction.id, "updated_at"], null)) ;
    },
    displayWaitMessage(){
      if(this.isInteractionDone(this.containerContent, this.content.interaction)) {
        const contentUserInfo = this.getContentUserInfo(this.containerContent);
        if(!get(contentUserInfo, ["id_to_interaction", this.content.interaction.id, "updated_at"], null) && get(contentUserInfo, ["id_to_interaction", this.content.interaction.id, "deferred"], false)) {
          return true;
        }
      }
      return false;
    },
    getUserAvatar() {
      return get(this.pageModel, ["user", "avatar", "url"], false);
    },
    getUserBadgeStyle() {
      let styles = {};
      if (this.getUserAvatar) {
        styles['background-image'] = `url(${this.getUserAvatar})`;
        styles['background-color'] = "transparent";
      }
      return styles;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" versus-component >
    <div class="container-fluid pt-3">
      <div class="row align-items-center justify-content-center mb-4 position-relative">
        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
          <div v-if="displayWaitMessage" class="wait-results d-flex flex-column align-items-center p-4 justify-content-center">
            <div class="w-100 h3 mb-2 text-center">{{ft("globals.versus.feedback_title")}}</div>
            <div class="w-100 text-center">{{ft("globals.versus.feedback_description")}}</div>
          </div>
        </transition>
        <div class="col-12 px-0 text-center question-tile">
          <div class="h3 question-title" :class="`text-${questionAlignment}`" :style="{'color': content.text_color}">{{content.question}}</div>
          <div class="row mx-0 justify-content-center">
            <div
              class="postion-relative d-flex justify-content-center align-items-center my-1"
              :class="[getAnswerClasses(answer), `col-12 col-md-${answerColumns}`, `px-${answerPaddingX}`]"
              v-for="answer in content.answers"
              @click="mayUpdateVersusInteraction(containerContent, content, content.interaction, answer.$id)"
              :key="`answer-${content.interaction}-${answer.$id}`"
            > 
              <div class="answer py-3 w-100 d-flex h-100">
                <div v-if="showResults" class="progress-bar-box progress w-100 position-absolute">
                  <div class="progress-bar" :style="progressWidthStyle(getAnswerPercentage(answer))" role="progressbar" aria-valuenow="22" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="px-3 answer-text flex-grow-1 d-flex align-items-center" :class="[`text-${showResults ? 'left' : 'center'}`, `justify-content-${showResults ? 'start' : 'center'}`]">{{answer.text}}</div>
                <div v-if="showResults" class="percentage-box d-flex h-100 px-3">
                  <div class="percentage-content d-flex align-items-center w-50 h-100">
                    {{getAnswerPercentage(answer)}}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[versus-component] {

  .question-title {
    font-weight: 700;
    font-size: $font-size-base;
    font-family: $font-family-headings;
  }

  .percentage-box {
    .percentage-content {
      font-weight: 500;
    }
  }

  .progress-bar-box {
    height: 4px;
    background-color: transparent;
    right: 0;
    bottom: 0;
    z-index: 2;
    border-radius: 0;

    .progress-bar {
      background: var(--theme-color);
      transition: width .5s;
    }
  }

  .answer-text {
    z-index: 1;
  }

  .not-choose {
    opacity: 0.65;
  }

  .choose {
    font-weight: 700;
  }

  .wait-results {
    position: absolute;
    top: -0.5rem;
    bottom: -0.5rem;
    left: 0;
    right: 0;
    background: var(--theme-color);
    z-index: 10;
    color: var(--theme-background);
    i {
      font-size: 4rem;
      z-index: 20;
    }
  }

  .answer {
    font-size: 0.875rem;
    line-height: 1.25;
    cursor: pointer;
    position: relative;
    border: solid 1px #808080;
    overflow: hidden;
    transition: all $animation-time-fast linear;
  }

  .answer:not(.not-answerable):hover {
    background-color: var(--theme-color);
    color: var(--theme-background);
    font-weight: 700;
  }


  @include media-breakpoint-down(xs) {
    .answer {
      font-size: 0.9rem;
    }
  }
}
</style>