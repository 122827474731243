<template>
  <div v-if="Object.keys(notification).length>0" class="notification-header d-flex justify-content-center w-100">
    <div class="tag small font-weight-bold rounded d-flex justify-content-between" @click="clearNotification">
      <i :class="notification.icon" class="mr-2"></i>
      <span class="title">{{ notification.title }}</span>
    </div>
  </div>
</template>

<script>
import { isEqual } from 'lodash-es';

const NOTIFICATION_TIMEOUT = 3000;

export default {
  props: {
    value: {
      type: Object
    }
  },
  data: function() {
    return {
      notification: {}
    }
  },
  mounted() {
    this.initNotification();
  },
  methods: {
    initNotification() {
      if (Object.keys(this.value).length>0) {
        this.notification = {...this.value};
        setTimeout(this.clearNotification, NOTIFICATION_TIMEOUT);
      }
    },
    clearNotification() {
      this.notification = {};
      this.$emit('input', this.notification);
    }
  },
  watch: {
    value(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.initNotification();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.notification-header {
  position: absolute;
  top: 0;

  .tag {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.7);
    color: #10111D;
    max-height: 24px;
    padding: 6px 8px;

    .title {
      font-size: .75rem;
      line-height: 13px;
    }
  }
}
</style>