<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import EmbedAdvanced from './embed-advanced';

export default {
  mixins: [fandomBasicMixin],
  components: {EmbedAdvanced},
  props: {
    notices: {
      type: Array,
      default: () => []
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    unsupportedFullscreenClass: {
      type: String,
      default: ""
    }
  },
  methods: {
    activate(stream) {
      const oldActiveStream = this.activeStream;
      Vue.set(stream, 'active', true);
      Vue.set(oldActiveStream, 'active', false);
      if (!this.mainStream) {
        this.$refs[`active-stream-${oldActiveStream.$id}`][0].videoPlayer.mute();
        this.$refs[`active-stream-${stream.$id}`][0].videoPlayer.unMute();
      }
    },
    isActive(stream) {
      return stream.$id === this.activeStream.$id;
    },
    hasControlBar(stream) {
      return this.mainStream ? (this.mainStream.$id === stream.$id && this.isActive(stream)) : this.isActive(stream);
    }
  },
  computed: {
    activeStream() {
      return this.streams.find(stream => stream.active) || this.mainStream || this.streams[0];
    },
    passiveStreams() {
      return this.streams.filter(stream => stream.$id !== this.activeStream.$id);
    },
    mainStream() {
      return this.streams.find(stream => stream.main);
    },
    streams() {
      return this.content.play_streams || [];
    },
    style() {
      return {
        '--secondary-stream-width': `${100 / this.passiveStreams.length}%`,
      }
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :style="style" v-if="isContentVisibleToUser()" :class="contentType" embed-multistream-component>
    <div class="d-flex flex-wrap bg-black px-2">
      <div
        :class="isActive(stream) ? 'w-100 flex-grow-1 mx-n2' : 'secondary-video-stream cursor-pointer'"
        v-for="stream in [activeStream, ...passiveStreams]"
        :key="`key-${stream.$id}`"
        @click="!isActive(stream) ? activate(stream) : null"
      >
        <embed-advanced
          :content="stream"
          :control-bar="hasControlBar(stream)"
          :opt-container-content="containerContent"
          :ref="`active-stream-${stream.$id}`"
          :notices="isActive(stream) ? notices : []"
          :unmute-button="hasControlBar(stream)"
          :prevent-pip="!isActive(stream)"
          :fullscreen="embed.fullscreen"
          :unsupported-fullscreen-class="embed.unsupportedFullscreenClass"
          @embed-advanced:toggleFullScreen="$emit('embed-advanced:toggleFullScreen')"
          @click-notice="(noticeId)=>$emit('click-notice', noticeId)"
        >
          <slot v-if="isActive(stream)"></slot>
        </embed-advanced>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[embed-multistream-component] {
  .secondary-video-stream {
    width: var(--secondary-stream-width);
    max-width: 33%;
    padding: 16px 8px;
    transition: transform .25s ease-in-out;

    &:hover {
      transform: scale(0.97);
    }

    video {
      border-radius: 4px;
    }
  }
}
</style>