import { createElement, removeAllChild, loadScript } from '../utils';
import Fullscreen from '../Fullscreen';
import { PLAYER_STATUS, AUTO_QUALITY } from 'src/modules/player/constants';
import defaultConfig from  'src/modules/player/config';
import { get } from 'lodash-es';

const allInstances = [];

class Youtube {
  /**
   * 
   * @param {HTMLElement} wrapperEl
   * @param {Object} config
   */
  constructor(wrapperEl, config, code) {
    this.videoId = code;
    this.config = config;
    this.wrapperEl = wrapperEl;
    removeAllChild(wrapperEl);
    this.prevVolume = null;
    
    const target = typeof config.fullscreenElement === 'object' ? config.fullscreenElement : wrapperEl.parentElement;
    this.fullscreen = new Fullscreen(target);

    allInstances.push(this);

    if (typeof window.YT === 'object' && typeof window.YT.Player === 'function') {
      this.setup();
    } else {
      // Set callback to process queue
      window.onYouTubeIframeAPIReady = () => {
        allInstances.forEach(yt => {
          if (typeof yt.videoEl === "undefined") {
            yt.setup();
          }
        })
      };

      // Load the SDK
      loadScript('youtube-api', defaultConfig.urls.youtube.sdk);
    }
  }

  /**
   * return the instance id
   */
  get id() {
    return `yt-${this.videoId}`;
  }

  setup() {
    this.createYTElement();
    this.ready();
  }

  createYTElement() {
    this.videoEl = createElement('div', {id: this.id});
    this.wrapperEl.appendChild(this.videoEl);
  }

  ready() {
    this.setPoster(Fandom.getContentResponsiveImage('thumbnail', this.config));
    this.player = new YT.Player(this.videoEl, {
      playerVars: {
        html5: 1,
        fs: 0,
        controls: 0,
        disablekb: 0,
        rel: 0,
        wmode: "transparent",
        showinfo: 0,
        playsinline: 1,
        modestbranding: 1,
        cc_load_policy: this.config.subtitle ? 1 : 0,
        autoplay: this.config.autoplay ? 1 : 0,
        mute: this.config.autoplay ? 1 : (this.config.muted ? 1 : 0),
      },
      videoId: this.videoId,
      events: {
        onReady: (event) => {
          this.onReady(event);
        },
        onStateChange: (event) => {
          this.onStateChange(event);
        },
        onError: (event) => {
          console.error('Error',event);
        }
      },
    });
    this.videoEl = this.player.getIframe();
    if (!this.config.autoplay) {
      this.showPoster();  
    }
  }

  onReady(event) {
    Fandom.emit(PLAYER_STATUS.READY, {id: this.id});
    if (this.config.autoplay) {
      this.player.playVideo();
    }
  }

  onStateChange(event) {
    const state = this.mapState(event.data);
    if (state === PLAYER_STATUS.PLAYING) {
      this.hidePoster();
      Fandom.emit(PLAYER_STATUS.DURATION_CHANGED, {id: this.id});
    } else if (state === PLAYER_STATUS.ENDED) {
      this.showPoster();
    }
    Fandom.emit(state, {id: this.id});
  }

  /**
   * Map youtube state to Fandom player status
   * @param {PlayerState} ytState
   * @returns PLAYER_STATUS
   */
  mapState(ytState) {
    let status = '';
    switch (ytState) {
      case -1: 
        status = PLAYER_STATUS.UNSTARTED;
        break;
      case YT.PlayerState.BUFFERING:
        status = PLAYER_STATUS.BUFFERING
        break;
      case YT.PlayerState.PLAYING:
        status = PLAYER_STATUS.PLAYING
        break;
      case YT.PlayerState.ENDED:
        status = PLAYER_STATUS.ENDED
        break;
      case YT.PlayerState.PAUSED:
        status = PLAYER_STATUS.PAUSED;
        break;
    }

    return status;
  }

  /**
   * Set poster
   * @param {poster} poster 
   */
   setPoster(poster) {
    this.poster = poster;
  }

  /**
   * Show video poster
   */
   showPoster() {
    if (this.poster) {
      this.wrapperEl.style.backgroundImage = `url("${this.poster}")`;
      this.videoEl.classList.add('d-none');
    }
  }

  /**
   * Hide video poster
   */
  hidePoster() {
    this.videoEl.classList.remove('d-none')
  }

  /**
   * Removes the player instance and stops playback.
   * After deletion, the player no longer emits events or responds to API calls.
   */
   destroy() {
    this.player.destroy();
  }

  /**
   * Starts or resumes playback of the stream
   */
  play() {
    this.player.playVideo();
  }

  /**
   * Pauses playback of the current stream
   */
  pause() {
    this.player.pauseVideo();
  }

  /**
   * Gets the duration of the currently loaded media stream
   */
  getDuration() {
    const isLive = get(this.player.playerInfo.videoData, 'isLive', false);
    return isLive ? Infinity : this.player.getDuration();
  }

  /**
   * Gets the current position of the player, in seconds
   */
  getPosition() {
    return this.player.getCurrentTime();
  }

  /**
   * Seeks to a specified time in the stream
   * @param {Number} time The position to seek to, in seconds
   */
  seekTo(time) {
    this.player.seekTo(time);
  }

  /**
   * Mutes or unmutes the player
   * @param {Boolean} mute True to mute the player, false to unmute
   * @returns {Number} volume The current volume
   */
  setMuted(mute) {
    if (this.prevVolume === null || mute) {
      this.prevVolume = this.getVolume();
    }

    const volumeToSet = mute ? 0 : this.prevVolume;
    this.player.setVolume(volumeToSet);
    this.player[mute ? 'mute' : 'unMute' ]();

    return volumeToSet;
  }

  /**
   * Gets whether the player is muted.
   * @returns boolean - True if the player is muted, false otherwise.
   */
  isMuted() {
    return this.player.isMuted();
  }

  /**
   * Gets the player's volume level.
   * @returns {Number} The volume level of the player, between 0.0f and 1.0f.
   */
  getVolume() {
    return this.player.getVolume() / 100;
  }
  
  /**
   * Sets the playback volume
   * @param {Number} volume The volume to be set. Valid values: in the range 0.0f to 1.0f.
   */
  setVolume(volume) {
    this.player.setVolume(volume*100);
  }

  /**
   * Set of available Quality objects from the loaded source or empty if none are currently available.
   * @returns Quality[]
   */
  getQualities() {
    return this.player
      .getAvailableQualityLevels()
      .filter(quality => quality !== AUTO_QUALITY.name)
      .map(quality => {
        return {name: quality}
      });
  }

  /**
   * 
   * @returns Quality
   */
  getQuality() {
    return {name: this.player.getPlaybackQuality()};
  }

  /**
   * Sets the quality the player should use for playback.
   * @param {Quality} quality A valid quality entry from Player.getQualities.
   * @param {boolean} adaptive True for an adaptive quality change; that is, to change quality smoothly at the end of the current buffer. False to change quality immediately.
   */
  setQuality(quality, adaptive=false) {
    this.player.setPlaybackQuality(quality.name);
  }
}

export default Youtube;