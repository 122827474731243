import { interactionMixin } from "src/modules/interactions_module.js";
import Player from '@vimeo/player';
import { merge, get } from 'lodash';
import { Fandom } from "./fandom_module";

const videoContentIdToVimeoPlayer = {};
const contentIdToInteractionFeedbackTimeout = {};
const contentIdToInteractionReviewTimeout = {};

const defaultVideoConfig = {
  autoplay: false, // Warning: to allow mobile devices to go into autoplay set the video as mute.
  muted: false
};

function initVideoPlayer(component, videoContent, callback) {
  const videoConfig = merge(defaultVideoConfig, component.videoConfig);
  if (videoConfig.autoplay) {
    videoConfig.muted = true;
  }

  if (videoContent.media_code.indexOf('/') != -1) {
    videoConfig.url = videoContent.media_code;
  } else {
    videoConfig.id = videoContent.media_code;
  }

  const vimeoPlayer = new Player(component.videoContentId, videoConfig);
  videoContentIdToVimeoPlayer[component.videoContentId] = vimeoPlayer;

  vimeoPlayer.ready().then(() => {
    Vue.set(component.videoPlayer, "ready", true);

    setupVideoPlayerPlayback(component);
    
    // setting chaptering and cuepoints
    vimeoPlayer.getDuration().then(duration => {
      Vue.set(component.videoPlayer, 'duration', duration);
      const overvideoInteractions = get(component, ['content', 'play', 'overvideo_interactions'], []);
      if (overvideoInteractions.length > 0) {
        Vue.set(component.content, "chaptering", []);
        overvideoInteractions.forEach((cuePointInfo, index) => {
          const seconds = (cuePointInfo.seconds === "end") ? parseInt(duration) : parseInt(cuePointInfo.seconds);
          const position = `${((parseInt(seconds) * 100) / duration).toFixed(2)}%`;
          vimeoPlayer.addCuePoint(seconds, cuePointInfo).then((cuepointId) => {
            Vue.set(component.content.chaptering, index, {
              id: cuePointInfo.interaction.id,
              interaction: cuePointInfo,
              position,
              active: false,
              seconds: seconds - 1
            });
          }).catch((error) => {
            console.error(error);
          });
        });
      }
    })

    registerVideoPlayerEvents(component, component.videoContent);
  });
}

function registerVideoPlayerEvents(component, videoContent) {
  const vimeoPlayer = videoContentIdToVimeoPlayer[component.videoContentId];

  // register on play event
  vimeoPlayer.on('play', () => {
    // Warkaround to force the value 0 in the getVolume method when the video started muted.
    if (videoContent.autoplay && !component.videoPlayer.firstPlay) {
      component.videoPlayer.changeVolume(0);
    }

    Vue.set(component.videoPlayer, "playing", true);
    Vue.set(component.videoPlayer, "firstPlay", true);
    Vue.set(component.videoPlayer, "end", false);

    if (typeof component.updatePlayInteraction == "function") { 
      component.updatePlayInteraction(component.containerContent, videoContent, videoContent.interaction);
    }
  });

  let thirdQuartileInteractionDone = false;
  vimeoPlayer.on('timeupdate', (data) => {
    // Muted management
    vimeoPlayer.getVolume().then((volume) => {
      Vue.set(component.videoPlayer, "muted", !volume);
    });

    // Progress management
    Vue.set(component.videoPlayer, "progress", (data.percent * 100).toFixed(3));

    // register play third quartile event if videoContent has this kind of interaction
    if (data.percent >= 0.75 && !!videoContent.play_third_quartile && !thirdQuartileInteractionDone && typeof component.updatePlayThirdQuartileInteraction == "function") {
      component.updatePlayThirdQuartileInteraction(component.containerContent, videoContent.play_third_quartile, videoContent.play_third_quartile.interaction);
      thirdQuartileInteractionDone = true;
    }
  });

  // register ended event
  vimeoPlayer.on('ended', () => {
    const overvideoInteractions = get(component, ['content', 'play', 'overvideo_interactions'], []);
    let overvideoInteraction = null;
    overvideoInteractions.forEach((oi, index) => {
      if (oi.seconds == "end") {
        console.log(oi)
        overvideoInteraction = oi;
      }
    });

    if (overvideoInteraction) {
      Vue.set(component, "overvideoInteraction", overvideoInteraction);
    } else {
      Vue.set(component.videoPlayer, "end", true);
      Vue.set(component.videoPlayer, "playing", false);
    }
  });

  // register cuepoints events
  vimeoPlayer.on('cuepoint', (cuepoint) => {
    Vue.set(component, "overvideoInteraction", cuepoint.data);
  });
}

function unregisterVideoPlayerEvents(component) {
  const vimeoPlayer = videoContentIdToVimeoPlayer[component.videoContentId];

  vimeoPlayer.off('play');
  vimeoPlayer.off('timeupdate');
  vimeoPlayer.off('ended');
}

function setupVideoPlayerPlayback(component) {
  const vimeoPlayer = videoContentIdToVimeoPlayer[component.videoContentId];

  component.videoPlayer.play = () => {
    return vimeoPlayer.play();
  };

  component.videoPlayer.pause = () => {
    Vue.set(component.videoPlayer, "playing", false);
    return vimeoPlayer.pause();
  };

  component.videoPlayer.getDuration = () => {
    return component.videoPlayer.duration;
  };

  component.videoPlayer.skipToSecond = (second) => {
    return vimeoPlayer.setCurrentTime(second);
  };

  component.videoPlayer.changeVolume = (volume) => {
    return vimeoPlayer.setVolume(volume);
  };

  component.videoPlayer.unMute = () => {
    return component.videoPlayer.changeVolume(1);
  };

  // TODO - delete unMute after compatibility check
  component.videoPlayer._unMute = () => {
    return component.videoPlayer.changeVolume(1);
  };
}

const videoInteractiveMixin = {
	mixins: [interactionMixin],
	data() {
    return {
      overvideoInteraction: null
    };
  },
  watch: {
  	overvideoInteraction(newValue, previousValue) {
      if (!this.videoConfig.preventOvervideoInteractionPause) {

        if (Fandom.exists(newValue) && newValue.prevent_pause) {
          var timeout = parseFloat(this.overvideoInteraction.max_seconds_to_interact || 3) * 1000;
          contentIdToInteractionFeedbackTimeout[this.contentId] = setTimeout(() => {
            this.overvideoInteraction = null;
          }, timeout);
        } else {
          clearTimeout(contentIdToInteractionFeedbackTimeout[this.contentId]);
          clearTimeout(contentIdToInteractionReviewTimeout[this.contentId]);

          if (Fandom.exists(newValue)) {
            this.videoPlayer.pause();
            if (this.isInteractionDone(this.containerContent, this.overvideoInteraction.interaction) && !this.isEndInteraction) {
              contentIdToInteractionReviewTimeout[this.contentId] = setTimeout(() => {
                this.overvideoInteraction = null;
              }, 3000);
            }
          } else if (Fandom.exists(previousValue) && !Fandom.exists(newValue) && get(previousValue, "interaction.type", "") !== 'Test') {
            this.videoPlayer.play();
          }
        }
      }
    },
  },
	computed: {
    isEndInteraction() {
      return get(this.overvideoInteraction, "seconds", "") === "end";
    }
	}
}

const videoBasicMixin = {
  mixins: [interactionMixin],
  data() {
    return {
      // videoContent required
      videoPlayer: {},
    };
  },
  mounted() {
    Vue.nextTick(() => {
      initVideoPlayer(this, this.videoContent);
    });
  },
  beforeDestroy() {
    unregisterVideoPlayerEvents(this);
  },
  computed: {
    videoConfig() {
      return this.videoContent.videoConfig || {};
    },
    videoContentId() {
      let result = `videoplayer__id_${this.containerContent.id}`;
      if (this.videoContent.$id) {
        result += `_${this.videoContent.$id}`;
      }
      return result;
    }
  },
  methods: {
  }
};

export {
  videoBasicMixin,
  videoInteractiveMixin
}