<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  mounted() {
    Vue.nextTick(() => {
      Fandom.onEvent('show-user-contents-required-modal', () => {
        $("#modal-user-contents-required").modal("show");
      });
    });
  }
}
</script>

<template>
  <div id="modal-user-contents-required" class="modal fade" tabindex="-1" role="dialog" moda-user-contents-required-component>
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body py-0 px-1">
          <button class="close modal-close-icon" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="d-flex flex-column justify-content-center align-items-center text-center py-5 px-3">
            <h3>{{content.title || ft("globals.user_contents_modal.title")}}</h3>
            <p>{{content.description || ft("globals.user_contents_modal.description")}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>