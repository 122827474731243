<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { interactionMixin } from "src/modules/interactions_module.js";
import { get } from "lodash";
import Flickity from "flickity";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  data: function () {
    return {
    };
  },
  mounted() {
  },
  computed: {
  	isCompleted() {
  		return this.getContentUserInfo(this.containerContent).status == "completed";
  	},
    interactionResultMap() {
    	const result = { total_count: 0, correct_count: 0 }; 
    	const contentUserInfo = this.getContentUserInfo(this.containerContent);

      Object.keys(contentUserInfo.id_to_interaction).forEach((key, index) => {
			  const interaction = contentUserInfo.id_to_interaction[key];
			  if (interaction.interaction_type == "Trivia" && interaction.required_to_complete) {
			  	const correct = interaction.user_interaction.data.answer_status;
			  	result.total_count++;
			  	if (correct) {
			  		result.correct_count ++;
			  	}
			  }
			});

			return result;
    }
  },
  methods: {   
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" course-product-trivia-result-component>
    <div class="row mx-0 justify-content-center">
      <div class="col-12 col-lg-8 py-5 d-flex align-items-center justify-content-center mh-100vh-header">
      	<div v-if="isCompleted" class="text-center">
      		<h3 class="h2">{{ft("course_product_trivia_result.title")}}</h3>
      		<p>{{ft("course_product_trivia_result.description")}}</p>
      		<div class="d-flex align-items-center w-100 my-4 justify-content-center">
      			<div class="counter-box text-center font-weight-bold">{{interactionResultMap.correct_count}}</div>
      			<div class="px-3">{{ft("course_product_trivia_result.correct_on_total")}}</div>
      			<div class="counter-box text-center font-weight-bold">{{interactionResultMap.total_count}}</div>
      		</div>
      	</div>
      	<div v-else>
      		<h3>{{ft("course_product_trivia_result.uncomplete")}}</h3>
      	</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[course-product-trivia-result-component] {

	.counter-box {
		width: 4rem;
    height: 4rem;
    border: 1px solid $gray-800;
    line-height: 4rem;
    font-size: 2.5rem;
	}
}
</style>