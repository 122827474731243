import { createElement } from './utils';
import { isEmpty } from 'lodash-es'

class Fullscreen {
  constructor(target, config={}) {
    this.target = target;
      
    // Get prefix
    this.prefix = Fullscreen.prefix;
    this.property = Fullscreen.property;

    // Scroll position
    this.scrollPosition = { x: 0, y: 0 };

    this.fallback = false;
    this.active = false;
    this.overlay = null;
    this.forcecss = config.forcecss;
  }

  // Toggle state
  async toggle() {
    if (!this.active) {
      await this.enter();
    } else {
      this.exit();
    }

    this.active = !this.active;
  };

  // Enter to fullscreen
  async enter() {
    if (Fullscreen.native && !this.forcecss) {
      try {
        if (!this.prefix) {
          await this.target.requestFullscreen({ navigationUI: 'hide' });
        } else if (!isEmpty(this.prefix)) {
          await this.target[`${this.prefix}Request${this.property}`]();
        }
      } catch (error) {
        this.toggleFallback(true);
      }
    } else {
      this.toggleFallback(true);
    }
  };

  // Exit from fullscreen
  exit() {
    if (!Fullscreen.native || this.forcecss) {
      this.toggleFallback(false);
    } else if (document.fullscreenElement === this.target) {
      if (!this.prefix) {
        (document.cancelFullScreen || document.exitFullscreen).call(document);
      } else if (!isEmpty(this.prefix)) {
        const action = this.prefix === 'moz' ? 'Cancel' : 'Exit';
        document[`${this.prefix}${action}${this.property}`]();
      }
    }
  };

  toggleFallback(toggle = false) {
    // Store or restore scroll position
    if (toggle) {
      this.scrollPosition = {
        x: window.scrollX || 0,
        y: window.scrollY || 0,
      };
    } else {
      window.scrollTo(this.scrollPosition.x, this.scrollPosition.y);
    }

    this.fallback = toggle;

    const body = document.getElementsByTagName('body')[0];
    if (toggle) {
      body.classList.add('bg-black');
      this.createOverlay();
    } else {
      body.classList.remove('bg-black');
      this.overlay && this.removeOverlay();
    }
  };

  createOverlay() {
    this.overlay = createElement('div', {
      class: 'fplayer-overlay position-absolute-center bg-black h-100',
      style: 'z-index: 1090'
    });
    const vueRootEl = document.getElementById('vue-root');
    if (vueRootEl) {
      vueRootEl.classList.add('position-relative');
      vueRootEl.appendChild(this.overlay);
    }
  }

  removeOverlay() {
    this.overlay.remove();
    this.overlay = null;
    const vueRootEl = document.getElementById('vue-root');
    if (vueRootEl) {
      vueRootEl.classList.remove('position-relative');
    }
  }

  // STATIC FUNCTION
  // Determine if native supported
  static get native() {
    return !!(
      document.fullscreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnabled
    );
  }

  // Get the prefix for handlers
  static get prefix() {
    // No prefix
    if (typeof document.exitFullscreen === 'function') {
      return '';
    }

    // Check for fullscreen support by vendor prefix
    let value = '';
    const prefixes = ['webkit', 'moz', 'ms'];

    prefixes.some((pre) => {
      if (typeof document[`${pre}ExitFullscreen`] === 'function' || typeof document[`${pre}CancelFullScreen`] === 'function') {
        value = pre;
        return true;
      }

      return false;
    });

    return value;
  }

  static get property() {
    return this.prefix === 'moz' ? 'FullScreen' : 'Fullscreen';
  }
}

export default Fullscreen;