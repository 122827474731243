// import wasmBinaryPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.wasm';
// import wasmWorkerPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.js';
// import * as IVSPlayer from 'amazon-ivs-player';
import { interactionMixin } from "src/modules/interactions_module.js";
import { merge, get } from 'lodash';
const PLAYER_SRC = "https://player.live-video.net/1.3.1/amazon-ivs-player.min.js";

/**
 * Loads ivs api from amazon cdn, in order to bypass the aws problem of npm version.
 * TODO: to be removed as soon as possible
 */
function loadIvsApi() {
  Fandom.executeOnce("ivs-api", () => {
    const tag = document.createElement("script");
    tag.src = PLAYER_SRC;
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  });
  return new Promise((resolve, _) => {
    (function waitForIvs() {
      if (!!window.IVSPlayer)
        return resolve();
      setTimeout(waitForIvs, 30);
    })();
  });
}

/**
 * Initialize a ivs player, given a vue component, a Fandom video content and an optional callback function
 * 
 * @param { VueInstance } component the 'embed-ivs' vue instance in which the player should be initialized
 * @param { Function } callback a function that should be fired when the player is ready
 */
function initVideoPlayer(component, createParams, callback = () => {}) {
  // If a browser doesn't support web assembly, it cannot use ivs player
  if (!IVSPlayer.isPlayerSupported) {
    throw new Error('IVS Player is not supported in this browser');
  }

  const ivsPlayer = IVSPlayer.create(createParams);
  Vue.set(component, 'videoPlayer', ivsPlayer);

  const htmlVideoElement = document.getElementById(component.videoContentId);
  ivsPlayer.attachHTMLVideoElement(htmlVideoElement);
  setPlayerInterface(component);
  setOptions(ivsPlayer, component.videoConfig);
  registerVideoPlayerEvents(component);
  ivsPlayer.addEventListener(IVSPlayer.PlayerState.READY, () => {
    callback();
    Vue.set(component.videoPlayer, 'ready', true);
    Vue.set(component.videoPlayer, "availableQualities", component.videoPlayer._getQualities());
    component.videoPlayer._getQualities()
  });
  Fandom.ajax({
    url: `/api/v5/live_events/amazon_ivs_token?content_cache_id=${component.containerContent.id}&interaction_id=${(component.videoContent.interaction || {}).id}`,
    method: "GET",
    success: (data) => {
      ivsPlayer.load(`${component.videoConfig.media_code}?token=${data.token}`);
    },
    error: (error) => {
      ivsPlayer.load(`${component.videoConfig.media_code}`);
    }
  });
}

/**
 * Sets developer-defined options to the ivs player passed as a parameter.
 * Allowed options:
 * - autoplay: Boolean, sets autoplay
 * - muted: Boolean, sets muted
 * - auto_quality_mode: Boolean, true to set an automatic quality for the video, false otherwise
 * 
 * @param { Object } player the player to be configured 
 * @param { Object } param1 the configurations that should be applied to the player
 */
function setOptions(player, { autoplay, muted, auto_quality_mode }) {
  player.previousVolume = 1;
  if (autoplay) {
    player.setAutoplay(true);
  }
  if (muted) {
    player._mute()
  } else {
    player._unMute();
  }
  if (auto_quality_mode) {
    player.setAutoQualityMode(true);
  }
}

/**
 * Registers the most common video player events
 * 
 * @param { VueInstance } component the vue instances containing the video player
 */
function registerVideoPlayerEvents(component) {
  component.videoPlayer.addEventListener(IVSPlayer.PlayerState.PLAYING, videoPlayerEvents.PLAYING(component));
  component.videoPlayer.addEventListener(IVSPlayer.PlayerState.ENDED, videoPlayerEvents.ENDED(component));
}

/**
 * Map of functions binded to specific ivs player events.
 * Need to be defined in a map because they cannot be unregistered otherwise (they would be not referentiable).
 */
const videoPlayerEvents ={
  'PLAYING': (component) => {
    return () => {
      Vue.set(component.videoPlayer, "firstPlay", true);
      Vue.set(component.videoPlayer, "end", false);
      if (typeof component.updatePlayInteraction == "function") { 
        component.updatePlayInteraction(component.containerContent, component.videoContent, component.videoContent.interaction);
      }
    }
  },
  'ENDED': (component) => {
    return () => { Vue.set(component.videoPlayer, "end", true); }
  }
}

/**
 * Adds some common methods to the ivs player object,
 * in order to be used in components including different players.
 * 
 * @param { VueInstance } component the vue instance containing the video player
 */
function setPlayerInterface(component) {
  const player = component.videoPlayer;

  player._changeVolume = (event) => {
    const volumeValue = parseFloat(event.target.value)/100;
    if (volumeValue === 0) {
      player._mute();
    } else {
      player._unMute();
      player.setVolume(volumeValue);
    }
  },
  player._mute = () => {
    Vue.set(player, 'muted', true);
    player.previousVolume = player.getVolume();
    player.setMuted(true);
    player.setVolume(0);
  },
  player._unMute = () => {
    Vue.set(player, 'muted', false);
    player.setMuted(false);
    player.setVolume(player.previousVolume);
  }
  player._toggleMute = () => {
    if (player.isMuted())
      player._unMute();
    else
      player._mute(); 
  }
  player._isReady = () => {
    return player.ready;
  }
  player._play = function() {
    return player.play();
  };
  player._pause = function() {
    return player.pause();
  };
  player._isPaused = function() {
    return player.isPaused();
  };
  player._getVolume = function() {
    return player.getVolume()*100;
  };
  player._getQualities = () => {
    return player.getQualities();
  }
  player._getQuality = () => {
    return player.getQuality().name;
  }
  player._setQuality = (quality='auto') => {
    quality === 'auto' ? player.setAutoQualityMode(true) : player.setQuality(quality);
  }
}

/**
 * Unregister the previously defined video player events
 * 
 * @param { VueInstance } component the vue instances containing the video player
 */
function unregisterVideoPlayerEvents(component) {
  component.videoPlayer.removeEventListener(videoPlayerEvents.PLAYING(component));
  component.videoPlayer.removeEventListener(videoPlayerEvents.ENDED(component));
}

const videoBasicMixin = {
  mixins: [interactionMixin],
  data() {
    return {
      // videoContent required
      videoPlayer: {},
      unsupportedBrowser: false
    };
  },
  mounted() {
    Vue.nextTick(() => {
      // !!! ENTRYPOINT LOADING FROM NODE MODULES
      // const createAbsolutePath = (assetPath) => new URL(assetPath, document.URL).toString();
      // const createParams = {
      //   wasmWorker: createAbsolutePath(wasmWorkerPath),
      //   wasmBinary: createAbsolutePath(wasmBinaryPath)
      // }
      // initVideoPlayer(this, createParams);

      // !!! ENTRYPOINT LOADING FROM CDN
      loadIvsApi().then(_ => {
        try {
          initVideoPlayer(this);
        } catch (e) {
          console.error(e);
          Vue.set(this, "unsupportedBrowser", true);
        }
      });
    });
  },
  beforeDestroy() {
    unregisterVideoPlayerEvents(this);
  },
  computed: {
    videoConfig() {
      return merge((get(this, ['videoContent', 'videoConfig'], {})), this.parseConfigs(this.videoContent || {}));
    },
    videoContentId() {
      let result = `videoplayer__id_${this.containerContent.id}`;
      if (this.videoContent.$id) {
        result += `_${this.videoContent.$id}`;
      }
      return result;
    }
  },
  methods: {
    parseConfigs(data) {
      // extract just the keys I want from videoContent
      return (({autoplay, media_code, muted, auto_quality_mode}) => ({autoplay, media_code, muted, auto_quality_mode}))(data);
    }
  }
};

export {
  videoBasicMixin
}
