<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { audiEventsBasicMixin } from '../../modules/audi_events_mixins_module.js';
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, audiEventsBasicMixin],
  props: {
    customShareSlug: {
      type: String,
      default: ""
    },
    playButton: Boolean
  },
  methods: {
    isUserRegistered: Fandom.isUserRegistered,
    facebookUrl: Fandom.facebookUrl,
    getButtonClass: Fandom.getButtonClass,
    getPrimaryButtonClass: Fandom.getPrimaryButtonClass,
    getSecondaryButtonClass: Fandom.getSecondaryButtonClass
  },
  computed: {
    textAlign() {
      // Allowed values: left, right and center.
      return (this.content.text_align) ? `text-${this.content.text_align}` : null;
    },
    hasShareInteraction() {
      return get(this, "content.share.interaction.type", false) === "Share";
    },
    category() {
      return Fandom.exists(this.content.category) ? this.content.category : this.content.miniformat_title;
    },
    signInRequired() {
      return !Fandom.isUserRegistered() && this.content.enable_sign_in;
    },
    buttonsRequired() {
      return (this.button || this.content.buttons || this.playButton || this.content.unregistered_buttons || this.content.registered_buttons) && !this.signInRequired;
    },
    mainClasses() {
      return [
        this.textAlign
      ]
    },
    button() {
      if (Fandom.isUserRegistered()) {
        return this.content.registered_button ?? this.content.button;
      } else {
        return this.content.anonymous_button ?? this.content.button;
      }
    }
  }
};
</script>

<template>
  <div :class="mainClasses" class="my-4" text-section-component>
    <img v-if="content.icon" class="icon my-4" :alt="content.icon.alt" :src="content.icon.url">
    <p v-if="category" class="category" :style="{'color': content.color}">{{category}}</p>
    <component v-if="content.title" :class="content.title_class || 'h1'" :is="content.title_tag || 'h2'" :style="{'color': content.color}" title>
      {{content.title}}
    </component>
    <component v-if="content.subtitle" :is="content.subtitle_tag || 'h3'" :class="content.subtitle_class || 'h2'" :style="{'color': `${content.color}`}" subtitle>{{content.subtitle}}</component>
    <component :is="content.description_tag || 'div'" :class="content.description_class" v-if="content.description" :style="{'color': `${content.color}`}" v-html="content.description" description></component>
    <div v-if="buttonsRequired" class="align-btn-group my-4">
      <a 
        v-if="button" 
        class="btn m-2 my-2" 
        :class="getPrimaryButtonClass(content.button_class, content.theme)" 
        :target="getTarget(button)"
        @click.prevent="trackingButtonUrl($event)"
        :href="button.url"
      >
        {{button.title}}
      </a>
      <button
        v-if="playButton" 
        class="btn m-2 my-2" 
        :class="getPrimaryButtonClass('btn-black')" 
        @click="$emit('openvideo')"
      >
        {{ft("globals.watch_video")}}
      </button>
      <template v-if="content.buttons">
        <a 
          class="btn m-2" 
          v-for="(buttonRecord, index) in content.buttons" 
          :key="index" 
          :class="getPrimaryButtonClass(buttonRecord.button_class, content.theme)" 
          :target="getTarget(buttonRecord.button)"
          @click.prevent="trackingButtonUrl($event, buttonRecord.button_tracking, buttonRecord.button_event_cookie)"
          :href="buttonRecord.button.url"
        >
          {{buttonRecord.button.title}}
        </a>
      </template>
      <template v-if="content.unregistered_buttons && !isUserRegistered()">
        <a 
          class="btn m-2" 
          v-for="(buttonRecord, index) in content.unregistered_buttons" 
          :key="index" 
          :class="getPrimaryButtonClass(buttonRecord.button_class, content.theme)" 
          :target="getTarget(buttonRecord.button)"
          @click.prevent="trackingButtonUrl($event, buttonRecord.button_tracking, buttonRecord.button_event_cookie)"
          :href="buttonRecord.button.url"
        >
          {{buttonRecord.button.title}}
        </a>
      </template>
      <template v-if="content.registered_buttons && isUserRegistered()">
        <a 
          class="btn m-2" 
          v-for="(buttonRecord, index) in content.registered_buttons" 
          :key="index" 
          :class="getPrimaryButtonClass(buttonRecord.button_class, content.theme)" 
          :target="getTarget(buttonRecord.button)"
          @click.prevent="trackingButtonUrl($event, buttonRecord.button_tracking, buttonRecord.button_event_cookie)"
          :href="buttonRecord.button.url"
        >
          {{buttonRecord.button.title}}
        </a>
      </template>
    </div>
    <div v-if="signInRequired" class="sign-in-section d-flex flex-wrap justify-content-center flex-column">
      <div class="sign-in-button-group-section">
        <a class="btn btn-dark mr-md-2 my-2 col-8 col-md-auto" :href="applyContextToUrl('/users/sign_up')">
          {{ft("globals.login.sign_up")}}
        </a>
        <a class="btn btn-facebook ml-md-2 my-2 col-8 col-md-auto" :href="facebookUrl()">
          {{ft("globals.login.facebook")}}
        </a>
      </div>
      <p v-html="ft('globals.login.sign_in')"></p>
    </div>
    <share-icons 
      class="align-btn-group my-4 m-0" 
      v-if="hasShareInteraction" 
      :content="content" 
      :container-content="containerContent" 
      :custom-share-slug="customShareSlug"
    ></share-icons>
  </div>
</template>

<style lang="scss" scoped>
[text-section-component] {

  .align-btn-group {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .icon {
    max-width: 100%;
    width: 6rem;
  }

  .category {
    font-size: 1.125rem;
    font-family: $font-family-base;
    text-transform: none;
    letter-spacing: normal;
    font-weight: 400;
  }

  [subtitle] {
    font-family: $font-family-base;
    font-size: 1.5rem;
    font-weight: normal;
  }
}
</style>
