<script>
  import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js'
  import { interactionMixin } from 'src/modules/interactions_module.js'
  import { get } from "lodash";
  
  export default {
    mixins: [fandomBasicMixin, fandomExtendedMixin, interactionMixin],
    props: {
      alignment: String
    },
    data() {
      return { }
    },
    computed: {
      alignmentClass() {
        return `justify-content-${this.alignment}`;
      },
      range() {
        const size = (this.content.max_vote || 5) - (this.content.max_vote || 1) + 1;
        return [...Array(size).keys()].map(i => i + 1);
      },
      userInteractionVote() {
        return get(this.getContentUserInfo(this.containerContent), ["id_to_interaction", this.content.interaction.id, "user_interaction", "data", "vote"]);
      }
    }
  };
  </script>
  
  <template>
    <interaction-wrapper :content="content" :opt-container-content="containerContent" :style="theme" :alignment="alignment" interaction-vote-component>  
      <template #default="{expired, done}">
      <p class="h4 mb-3">{{content.question}}</p>
      <div class="d-flex items-container mx-n2" :class="[{'done': done, 'expired': expired && !done}, alignmentClass]">
        <div v-for="(vote, index) in range" 
             :key="`item-${index}`" 
             class="item px-2" 
             :class="{'active': vote == userInteractionVote}"
             @click="updateVoteInteraction(containerContent, content, content.interaction, vote)">
          <i class="fal fa-star"></i>
        </div>
      </div>
      <div v-if="expired || done" class="mt-2 footer">
        <small v-if="expired">Votazione chiusa!</small>
        <small v-else-if="done">Grazie per aver votato!</small>
      </div>
    </template>   
    </interaction-wrapper>
  </template>
  
  <style lang="scss">
  [interaction-vote-component] {
  
    p {
      font-weight: 700;
      font-family: $font-family-headings;
      color: var(--theme-color);
    }

    .footer {
      color: var(--theme-color);
    }

    .item i {
      font-size: 1.25rem;
    }

    .expired { 
      pointer-events: none;
    }

    .items-container {
      
      &.done > .item > i {
        font-weight: 900;
      }

      .item.active ~ .item > i {
        color: var(--theme-color);
        font-weight: 300;
      }
    }

    .items-container:not(.done):not(.expired) {
  
      .item {
        color: var(--theme-color);
        font-weight: 300;
      }
  
      &:hover > .item > i {
        font-weight: 900;
      }
  
      .item:hover > i {
        font-weight: 900;
      }
  
      .item:hover ~ .item > i {
        color: var(--theme-color);
        font-weight: 300;
      }
    }
  
  }
  </style>
  