<script>

export default {
  props: {
    // Required
    tileId: {
      type: String,
      required: true
    },
    aspectRatio: {
      type: Number,
      required: true
    },
    showPin: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    // Not required
    activeSpeaker: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    },
    volume: {
      type: Number,
      default: 0
    },
    muted: {
      type: Boolean,
      default: true
    },
    admin: {
      type: Boolean,
      default: false
    },
    pinned: {
      type: Boolean,
      default: false
    },
    attendeeInfo: {
      type: Object,
      default: () => null
    }
  }
}
</script>

<template>
  <div class="bg-black tile" :class="{'highlighted': activeSpeaker}" :style="{ 'padding-bottom': aspectRatio * 100 + '%' }" page-live-event-meeting-tile>
    <div class="position-absolute-center d-flex justify-content-center align-items-center text-white" v-if="!visible">
      <div v-if="attendeeInfo.avatar" class="avatar" :style="{ 'background-image': 'url(' + attendeeInfo.avatar + ')' }"></div>
      <div v-else class="avatar default"></div>
    </div>
    <video v-show="visible" class="embed-video" :id="`tile-video-${tileId}`"/>
    <div
      v-if="(activeSpeaker || muted) && type == 'user'"
      class="microphone-icon small d-flex justify-content-center align-items-center position-absolute text-white"
      :style="{
        'width': `${30 + volume*6}px`,
        'height': `${30 + volume*6}px`,
        'top': `${8 - volume*3}px`,
        'right': `${8 - volume*3}px`
      }">
      <i v-if="muted" class="fas fa-microphone-slash"></i>
      <i v-else class="fas fa-microphone"></i>
    </div>
    <template v-if="attendeeInfo">
      <div v-if="type == 'user'" class="user-label py-2 px-3">{{attendeeInfo.first_name}} {{attendeeInfo.last_name[0]}}.</div>
      <div v-else class="user-label py-2 px-3">{{attendeeInfo.first_name}} {{attendeeInfo.last_name[0]}}. Screen</div>

      <!-- v-if="showPin || admin" -->
      <div v-if="showPin" class="position-absolute-center d-flex justify-content-center align-items-center text-white admin-controls-container">
        <div class="admin-controls d-flex justify-content-center align-items-center p-3">
          <template v-if="showPin">
            <i v-if="pinned" class="fas fa-lg fa-thumbtack cursor-pointer mx-2" @click="$emit('toggle-pin', {value: false})"></i>
            <i v-else class="far fa-lg fa-thumbtack cursor-pointer mx-2" @click="$emit('toggle-pin', {value: true})"></i>
          </template>
          
          <!-- To debug and fix
          <template v-if="admin">
            <i v-if="muted" class="fas fa-lg fa-microphone-slash cursor-pointer mx-2" @click="$emit('toggle-prop', {action: 'muted', value: false})"></i>
            <i v-else class="fas fa-lg fa-microphone cursor-pointer mx-2" @click="$emit('toggle-prop', {action: 'muted', value: true})"></i>
            <i class="fas fa-lg fa-ban cursor-pointer mx-2" @click="$emit('toggle-prop', {action: 'banned', value: true})"></i>
          </template> -->
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>

.tile {
  &::after {
    z-index: 1;
    content: "";
    max-height: 25%;
    height: 3rem;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(180deg, transparent, rgba(0,0,0,0.4));
  }
}

.embed-video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.highlighted {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.user-label {
  color: white;
  font-weight: 600;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
}

.microphone-icon {
  border-radius: 50%;
  background-color: rgba(0,0,0,.5);
  transition: all 0.05s ease-in-out;
}

.admin-controls-container {
  .admin-controls {
    opacity: 0;
    border-radius: 1rem;
    transition: opacity 0.25s ease-in-out;
    background-color: rgba(0,0,0,.5);
    
  }
  &:hover .admin-controls {
    opacity: 1;
  }
}

.small{
  font-size: 0.875rem !important;
}

</style>