<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { liveEventsModalMixin } from 'src/modules/live_events_v2_module.js';
import { isInteractionDone } from 'src/modules/interactions_module.js';
import { disableBodyScroll } from 'body-scroll-lock';
import Flickity from 'flickity';

export default {
  mixins: [fandomBasicMixin, liveEventsModalMixin],
  data: function () {
    return {
      carousel: null,
      carouselIndex: 0,
    };
  },
  mounted() {
    if (this.content.active & !this.surveyDone) {
      this.openModal();
    }
  },
  watch: {
    surveyDone(newVal) {
      if (newVal) {
        Vue.set(this, 'preventModalOpen', true);
        this.deactivateModal();
      } else {
        Vue.set(this, 'preventModalOpen', false);
      }
    },
  },
  computed: {
    surveyDone() {
      return this.content.survey.reduce((result, surveyStep) => {
        return result && isInteractionDone(this.containerContent, surveyStep.interaction)
      }, true);
    },
    surveyInitialIndex() {
      if (this.surveyDone) {
        return 0;
      } else {
        return this.content.survey.findIndex(surveyStep => !isInteractionDone(this.containerContent, surveyStep.interaction));
      }
    },
    surveyPercentage() {
      if (this.surveyDone) {
        return 100;
      } else {
        return this.carouselIndex * 100 / this.content.survey.length;
      }
    },
  },
  methods: {
    resizeCarousel() {
      Vue.nextTick(() => {
        this.carousel.resize();
      })
    },
    openModal() {
      this.showModal = true;
      Vue.nextTick(() => {
        disableBodyScroll(this.$el);
        this.carousel = new Flickity(this.$refs["survey-carousel"], {
          pageDots: false,
          prevNextButtons: false,
          draggable: false,
          initialIndex: this.surveyInitialIndex,
          accessibility: false
        });

        this.carouselIndex = this.surveyInitialIndex;
        this.carousel.on("change", (index) => {
          this.carouselIndex = Math.min(index, this.content.survey.length - 1);
        });

      });
    },
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" modal page-live-event-survey-component>
    <transition 
      enter-active-class="animated fadeIn faster" 
      leave-active-class="animated fadeOut faster" 
    >
      <div class="live-event-modal__background" v-if="showModal"></div>
    </transition>
    <transition
      enter-active-class="animated slideInDown faster" 
      leave-active-class="animated slideOutUp faster" 
    >
      <div class="live-event-modal p-4 h-100vh w-100vw" v-if="showModal">
        <div class="survey-modal__content h-100 w-100 position-relative">
          <i @click="closeModal()" class="fal fa-lg fa-times pr-4 cursor-pointer position-absolute"></i>
          <div class="d-flex flex-column justify-content-between h-100">
            <div class="col-auto p-4 flex-shrink-1">
              <h2 class="title mx-auto pr-3">{{ft("page_live_event.survey.title")}}</h2>
            </div>
            <div class="w-100 survey-carousel py-1 py-md-5 flex-grow-1" ref="survey-carousel">
              <div v-for="(surveyContent, index) in content.survey" :key="`survey-content-${index}`" class="survey-item d-flex justify-content-center w-100">
                <div class="col-12 col-md-auto px-4">
                  <page-live-event-survey-item
                    :content="surveyContent"
                    :opt-container-content="containerContent"
                    @submission-success="carousel.next()"
                    @submission-errors="resizeCarousel()"
                  ></page-live-event-survey-item>  
                </div>
              </div>
              <!-- last slide -->
              <!-- <div class="w-100 h-100 d-flex justify-content-center align-items-center flex-column py-0 py-md-5 text-center survey-item__end">
                <div class="px-5">
                  <div class="my-4" v-html="ft('page_live_event.survey.success')"></div>
                  <a href="javascript: void(0)" class="btn btn-outline-dark my-4" @click="toggleSurvey()">{{ft("page_live_event.survey.close")}}</a>
                </div>
              </div> -->
            </div>
            <div class="p-3 text-center">
              <div class="progress survey__progress mx-auto my-2 rounded-0">
                <div class="progress-bar" role="progressbar" :style="{'width': `${surveyPercentage}%`}"></div>
              </div>
              <p class="mb-0"><small><b>{{Math.floor(surveyPercentage)}}%</b> {{ft('page_live_event.survey.complete_percentage')}}</small></p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
[page-live-event-survey-component] {
  .live-event-modal {
    .survey-modal__content {
      overflow-y: scroll;
      border-radius: 0;
      background-color: var(--theme-background);

      .title {
        width: 600px;
        max-width: 100%;
      }

      .fa-times {
        right: 0;
        top: 1.5rem;
        z-index: 1;
      }

      .flickity-slider {
        display: flex;
        align-items: center;
      }
    }
  }

  .survey__progress {
    height: 10px;
  } 

  [page-live-event-survey-item-component], .survey__progress {
    width: 600px;
    max-width: 100%;
  }
}
</style>