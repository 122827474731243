import { sendFandomAnalyticsEvent } from "src/modules/fandom_analytics_module.js";
import { merge, get, set, throttle } from "lodash";
import { v4 as uuidv4 } from 'uuid';

const aux = {
  contentIdToInteractionFeedbackTimeout: {}
}

function updateInstantWinInteraction(containerContent, interactionRecord, interaction, cb) {
  const updateInteractionSuccessCallback = (data) => {
    if (!!cb && typeof cb == "function") cb(data);
    console.log(data);
  }
  updateInteraction.call(this, containerContent, interactionRecord, interaction, {}, () => {}, updateInteractionSuccessCallback);
}

function updateWildcardInteraction(containerContent, interactionRecord, interaction, params, cb) {
  const updateInteractionSuccessCallback = (data) => {
    if (!!cb && typeof cb == "function") cb(data);
    console.log(data);
  }
  updateInteraction.call(this, containerContent, interactionRecord, interaction, params, () => {}, updateInteractionSuccessCallback);
}

function updateLikeInteraction(containerContent, interactionRecord, interaction, params=null) {
  // If like is repeatable always send like set to true and don't update number_of_likes
  // THIS BEHAVIOR IS NEEDED FOR VIDEO LIKE (E.G. INSTAGRAM)
  if (params && params.repeatable) {
    updateInteraction.call(this, containerContent, interactionRecord, interaction, { ...params, like: true});
  } else {
    const params = { like: !isLikeDone(containerContent, interaction) }
    const updateInteractionSuccessCallback = (data) => {
      let likeCounter = getLikeCounter(containerContent, interaction);
      likeCounter = (params.like) ? (likeCounter + 1) : (likeCounter - 1)

      const contentUserInfo = getContentUserInfo(containerContent);
      set(contentUserInfo, `id_to_interaction[${interaction.id}].likes_info.number_of_likes`, likeCounter);
    };
    updateInteraction.call(this, containerContent, interactionRecord, interaction, params, () => {}, updateInteractionSuccessCallback);
  }
}

function updateFavouriteInteraction(containerContent, interactionRecord, interaction, favouriteListId) {
  const params = { favourite_list_id: favouriteListId };
  const updateInteractionSuccessCallback = (data) => {
    if (this.hasOwnProperty('selectedFavouriteLists')){
      if(this.selectedFavouriteLists[favouriteListId]) {
        Vue.delete(this.selectedFavouriteLists, favouriteListId);
      } else {
        Vue.set(this.selectedFavouriteLists, favouriteListId, true);
      }
    }

    let favouriteInteraction = getUserInteraction(containerContent, interaction);
    Vue.set(favouriteInteraction, "is_favourite", data["is_favourite"]);
    Vue.set(favouriteInteraction, "favourite_list_ids", data["favourite_list_ids"]);
  }
  updateInteraction.call(this, containerContent, interactionRecord, interaction, params, () => {}, updateInteractionSuccessCallback);
}

function openFavouriteModal(containerContent, interactionRecord, interaction) {
  Vue.set(globalState.requiredModals, "favourite", {
    containerContent: containerContent,
    interactionRecord: interactionRecord,
    interaction: interaction
  });
  Vue.nextTick(() => {
    $('#favourite-interaction-modal').modal('show');
  });
}

function isFavourite(containerContent, interaction) {
  const favouriteInteraction = getUserInteraction(containerContent, interaction);
  if (favouriteInteraction) {
    if (favouriteInteraction.hasOwnProperty('is_favourite')) {
      return favouriteInteraction["is_favourite"];
    }
  }
  return false;
}

function isFavouriteInListId(containerContent, interaction, listId) {
  const favouriteInteraction = this.getUserInteraction(containerContent, interaction);
  return !!favouriteInteraction && favouriteInteraction.favourite_list_ids ? favouriteInteraction.favourite_list_ids.includes(parseInt(listId)) : false;
}

function updateCheckInteraction(containerContent, content, interaction, successCallback, unauthorizedCallback) {
  updateInteraction.call(this, containerContent, content, interaction, {}, null, successCallback, unauthorizedCallback)
}

function updateDownloadInteraction(containerContent, content, interaction, successPartialCallback, attachmentUrl, newTab, unauthorizedCallback) {
  let tab;
  const beforeBlock = function() {
    // to prevent popup block the new window or tab must be opened befor ajax call
    if (newTab) {
      tab = window.open();
    }
  };
  const successCallback = function(data) {
    if (newTab) {
      tab.location = attachmentUrl;
    } else {
      window.location = attachmentUrl;
    }
    if (successPartialCallback) {
      successPartialCallback(data);
    }
  };
  updateInteraction.call(this, containerContent, content, interaction, {}, beforeBlock, successCallback, unauthorizedCallback);
}

function updateRandomInteraction(containerContent, interaction, excluded_content_name) {
  // layoutExtraFieldName and layoutExtraFieldNameMissing used by next content converted.
  //function updateInteraction(containerContent, content, interaction, params, updateInteractionBefore, updateInteractionSuccess)
  var content = this.content;
  var component = this;
  var params = {
    random_content_tag_name: content.random_content,
    excluded_content_name: excluded_content_name
  };
  var successBlock = function(data) {
    component.randomContent =  data.next_random_content;
  };
  updateInteraction.call(this, containerContent, content, interaction, params, null, successBlock);
}

function updatePinInteraction(containerContent, content, interaction, beforeCallback, successCallback, unauthorizedCallback) {
  updateInteraction.call(this, containerContent, content, interaction, {}, beforeCallback, successCallback, unauthorizedCallback);
}

function updateVersusInteraction(containerContent, content, interaction, answerId, beforeCallback, successCallback) {
  const sc = (data) => {
    updateIdToAnswer(containerContent, interaction, data);

    if (typeof successCallback == "function") {
      successCallback(containerContent, interaction, data);
    }
  };

  updateInteraction.call(this, containerContent, content, interaction, { answer_id: answerId }, beforeCallback, sc);
}

function updateTriviaInteraction(containerContent, content, interaction, answerId, beforeCallback, successCallback) {
  const sc = (data) => {
    updateIdToAnswer(containerContent, interaction, data);

    // feedback audio management
    const userInteractionAnswerId = getUserInteractionAnswerId(containerContent, interaction);
    const feedbackAudioType = (isAnswerCorrect(containerContent, interaction, userInteractionAnswerId)) ? "interaction_success_audio" : "interaction_wrong_audio";
    const feedbackAudio = Fandom.mayGetAudioAsset(feedbackAudioType, content, containerContent);
    if (feedbackAudio) {
      new Audio(feedbackAudio.url).play();
    }

    if (typeof successCallback == "function") {
      successCallback(containerContent, interaction, data);
    }
  };
  updateInteraction.call(this, containerContent, content, interaction, { answer_id: answerId }, beforeCallback, sc);
}

function updateVoteInteraction(containerContent, interactionRecord, interaction, vote, success) {
  const contentUserInfo = getContentUserInfo(containerContent);

  let numberOfVotes = get(contentUserInfo, ["id_to_interaction", interaction.id, "votes_info", "number_of_votes"], 0);
  let votesSum = get(contentUserInfo, ["id_to_interaction", interaction.id, "votes_info", "votes_sum"], 0);

  const bc = () => {
    if (Fandom.exists(success)) {
      success();
    }
    if (isInteractionDone(containerContent, interaction)) {
      // Restore votes_info without previous user vote
      votesSum -= getUserInteraction(containerContent, interaction).data.vote;
      numberOfVotes -= 1;
    }
  };
  const sc = (data) => {
    set(contentUserInfo, `id_to_interaction[${interaction.id}].votes_info.number_of_votes`, (numberOfVotes + 1));
    set(contentUserInfo, `id_to_interaction[${interaction.id}].votes_info.votes_sum`, (votesSum + vote));
  };
  updateInteraction.call(this, containerContent, interactionRecord, interaction, { vote: vote }, bc, sc);
}

function updateVoteListInteraction(containerContent, interactionRecord, interaction, data) {
  const successCallback = (data) => {
    updateIdToAnswer(containerContent, interaction, data);
  };
  updateInteraction.call(this, containerContent, interactionRecord, interaction, data, () => {}, successCallback);
}

function updateTestInteraction(containerContent, interactionRecord, interaction, beforeCallback, successCallback, errorCallback) {
  const bc = () => {
    if (typeof beforeCallback == "function") {
      beforeCallback();
    }
  };
  const sc = (data) => {
    if (typeof successCallback == "function") {
      successCallback(data);
    }
  };

  const ec = (data) => {
    if (typeof errorCallback === "function") {
      errorCallback(data);
    }
  };


  updateInteraction.call(this, containerContent, interactionRecord, interaction, {}, bc, sc, null, true, ec);
}

function resetTestInteraction(containerContent, interactionRecord, interaction, beforeCallback, successCallback) {
  const sc = (data) => {
    data.user_interactions_removed.forEach(interactionId => {
      const contentUserInfo = getContentUserInfo(containerContent);
      Vue.set(contentUserInfo.id_to_interaction[interactionId], 'user_interaction', null);
    })

    if (typeof successCallback == "function") {
      successCallback(data, true);
    }
  };
  updateInteraction.call(this, containerContent, interactionRecord, interaction, { reset: true }, beforeCallback, sc);
}

function updateEvaluateInteraction(containerContent, content, interaction, itemIdToUserAnswerId, successCallback) {
  const sc = (data) => {
    if (typeof successCallback == "function") {
      successCallback(containerContent, interaction, data);
    }
  };

  updateInteraction.call(this, containerContent, content, interaction, { item_id_to_user_answer_id: itemIdToUserAnswerId }, () => {}, sc);
}

function updateIdToAnswer(containerContent, interaction, data) {
  const contentUserInfo = getContentUserInfo(containerContent);

  if (!contentUserInfo.id_to_interaction[interaction.id].id_to_answer &&
      (Fandom.exists(data.answer_id_to_status) || Fandom.exists(data.answer_id_to_percentage))) {
    Vue.set(contentUserInfo.id_to_interaction[interaction.id], "id_to_answer", {});
  }

  if (Fandom.exists(data.answer_id_to_status)) {
    for (const answerId in data.answer_id_to_status) {
      if (!contentUserInfo.id_to_interaction[interaction.id].id_to_answer[answerId]) {
        Vue.set(contentUserInfo.id_to_interaction[interaction.id].id_to_answer, answerId, {});
      }

      Vue.set(contentUserInfo.id_to_interaction[interaction.id].id_to_answer[answerId], "correct", data.answer_id_to_status[answerId])
    }
  }

  if (Fandom.exists(data.answer_id_to_percentage)) {
    for (const answerId in data.answer_id_to_percentage) {
      if (!contentUserInfo.id_to_interaction[interaction.id].id_to_answer[answerId]) {
        Vue.set(contentUserInfo.id_to_interaction[interaction.id].id_to_answer, answerId, {});
      }

      Vue.set(contentUserInfo.id_to_interaction[interaction.id].id_to_answer[answerId], "percentage", data.answer_id_to_percentage[answerId])
    }
  }

  if (Fandom.exists(data.votes_info)) {
    Vue.set(contentUserInfo.id_to_interaction[interaction.id], "votes_info", data.votes_info);
  }

}

function updateSubmitInteraction(containerContent, interaction, data) {
  const contentUserInfo = getContentUserInfo(containerContent);
  if (Fandom.exists(data.user_interaction)) {
    Vue.set(contentUserInfo.id_to_interaction[interaction.id], "user_interaction", data.user_interaction);
  }
}

function mayUserInteractionPlayAudio(containerContent, content) {
  const interactionSelectedAudio = Fandom.mayGetAudioAsset('interaction_selected_audio', content, containerContent);
  if (interactionSelectedAudio) {
    (new Audio(interactionSelectedAudio.url)).play();
  }
}

function initContentUserInfo(containerContent) {
  Vue.set(globalState.pageModel.name_to_content_user_info, containerContent.name, { id_to_interaction: {} });
  return getContentUserInfo(containerContent);
}

function getContentUserInfo(containerContent) {
  return globalState.pageModel.name_to_content_user_info[containerContent.name];
}

function getUserInteraction(containerContent, interaction) {
  if (isInteractionDone(containerContent, interaction)) {
    return getContentUserInfo(containerContent).id_to_interaction[interaction.id].user_interaction;
  }
}

function getLikeCounter(containerContent, interaction, noLikesDefault = 0) {
  const contentUserInfo = getContentUserInfo(containerContent);
  const numberOfLikes = get(contentUserInfo, ["id_to_interaction", interaction.id, "likes_info", "number_of_likes"], 0);
  return numberOfLikes != 0 ? numberOfLikes : noLikesDefault
}

function isLikeDone(containerContent, interaction) {
  const contentUserInfo = getContentUserInfo(containerContent);
  return get(contentUserInfo, ["id_to_interaction", interaction.id, "user_interaction", "data", ["like"]], 0);
}

function isAnswerCorrect(containerContent, interaction, answerId) {
  if (isInteractionDone(containerContent, interaction)) {
    const contentUserInfo = getContentUserInfo(containerContent);
    return get(contentUserInfo.id_to_interaction[interaction.id], ['id_to_answer', answerId, 'correct'], false);
  }
}

function isTestAnswer(containerContent, interaction, answerId) {
  if (isInteractionDone(containerContent, interaction)) {
    return !Fandom.exists(getContentUserInfo(containerContent).id_to_interaction[interaction.id].id_to_answer[answerId].correct);
  }
}

function getUserInteractionAnswerId(containerContent, interaction) {
  if (isInteractionDone(containerContent, interaction)) {
    return getUserInteraction(containerContent, interaction).data.answer_id;
  }
}

function isInteractionDone(containerContent, interaction) {
  const contentUserInfo = getContentUserInfo(containerContent);
  let ui = get(contentUserInfo, ["id_to_interaction", interaction.id, "user_interaction"], null);
  return Fandom.exists(ui) && !isUserInteractionToRedo(ui);
}

function isUserInteractionToRedo(ui) {
  const toRedo = get(ui, ["aux", "to_redo"], null);
  return Fandom.exists(toRedo) && toRedo;
}

function toggleInteractionFeedback(containerContent, interactionRecord, interaction, value) {
  const contentUserInfo = getContentUserInfo(containerContent);
  if (!contentUserInfo.id_to_interaction) {
    contentUserInfo.id_to_interaction = {};
  }

  if (!contentUserInfo.id_to_interaction[interaction.id]) {
    contentUserInfo.id_to_interaction[interaction.id] = {};
  }

  Vue.set(contentUserInfo.id_to_interaction[interaction.id], "feedback", value);
  Fandom.emit("interaction-feedback-phase", { containerContent: containerContent, content: interactionRecord, value: value });
}

function isInteractionFeedbackPhase(containerContent, interaction) {
  return isInteractionDone(containerContent, interaction) &&
         getContentUserInfo(containerContent).id_to_interaction[interaction.id].feedback;
}

function updatePlayInteraction(containerContent, content, interaction) {
  if (!!interaction)
    updateInteraction.call(this, containerContent, content, interaction, {});
  else
    console.warn("No interaction found: updatePlayInteraction aborted.");
};

function updatePlayThirdQuartileInteraction(containerContent, content, interaction) {
  if (!!interaction)
    updateInteraction.call(this, containerContent, content, interaction, {});
  else
    console.warn("No interaction found: updatePlayThirdQuartileInteraction aborted.");
};

const formatForShare = {
  facebook(url) {
    const appId = get(globalState.pageModel, ["aux", "authentications", "facebook", "app_id"], false);
    return {
      shareUrlParams: (appId) ? `display=popup&link=${url}&app_id=${appId}` : `m2w&s=100&p[url]=${url}`,
      shareUrlPrefix: (appId) ? "https://www.facebook.com/dialog/feed" : "https://www.facebook.com/sharer/sharer.php"
    };
  },
  twitter(url, message) {
    return {
      shareUrlParams: `url=${url}&text=${message}`,
      shareUrlPrefix: "https://twitter.com/intent/tweet"
    };
  },
  whatsapp(url, message) {
    return {
      shareUrlParams: `text=${message} ${url}`,
      shareUrlPrefix: "whatsapp://send"
    };
  },
  linkedin(url, message) {
    return {
      shareUrlParams: `mini=true&url=${url}&title=${message}&summary=${message}`,
      shareUrlPrefix: "https://www.linkedin.com/shareArticle"
    }
  },
  link() {
    return {
      shareUrlParams: false,
      shareUrlPrefix: false
    };
  },
  webShare() {
    return {
      shareUrlParams: false,
      shareUrlPrefix: false
    };
  }
};

function getUrlFromPath(path, iframe, contentName) {
  if (iframe) {
    return `${window.location.protocol}//${window.location.host}/share/content/${contentName}`;
  } else if (path) { // Custom template path.
    return `${window.location.origin}/${path}`.replace(/(https?:\/\/)|(\/)+/g, "$1$2");
  } else {
    return window.location.href.split("?")[0];
  }
}

function updateShareInteraction(containerContent, content, interaction, provider, path, iframe, useWebShare) {
  const message = encodeURIComponent(get(globalState, ["pageModel", "seo", "title"], ""));
  const url = getUrlFromPath(path, iframe, this.content.name);
  const { shareUrlPrefix, shareUrlParams } = formatForShare[provider](url, message, this.containerContent.id);

  // Allows the usage of web share api
  if (useWebShare && "share" in navigator) {
    navigator.share({
      title: message,
      text: "",
      url: url
    })
    .then(() => console.log("Share was successful."))
    .catch((error) => console.log("Sharing failed", error));
  } else if (shareUrlPrefix && shareUrlParams) {
    window.open(`${shareUrlPrefix}?${shareUrlParams}`);
  }
  updateInteraction.call(this, containerContent, content, interaction, { provider: provider });
}

function interactionAjaxData(containerContent, interaction, params) {
  return merge(params, {
    content_cache_id: containerContent.id,
    interaction_id: interaction.id,
  });
}

function updateInteraction(containerContent ,interactionRecord, interaction, params, updateInteractionBefore, updateInteractionSuccess, unauthorizedCallback, executeEffects = true, errorCallback) {
  if (checkRepeatable(containerContent, interactionRecord, interaction) && checkRegistration(interactionRecord)) {
    if(executeEffects) {
      mayUserInteractionPlayAudio(containerContent, interactionRecord);
    }

    if (Fandom.exists(updateInteractionBefore)) {
      updateInteractionBefore();
    }

    this.ajaxLoading = true;
    Fandom.ajax({
      method: "POST",
      data: interactionAjaxData(containerContent, interaction, {...params, cid: this.cid}),
      url: "/api/v5/update_user_interaction",
      success: (data) => {
        if (data.error && Fandom.exists(errorCallback)) {
          return errorCallback(data);
        }

        updateUserRewardInfo(data);
        updateContentUserInfo(containerContent, interaction, data);

        // interaction success function
        if (Fandom.exists(updateInteractionSuccess)) {
          updateInteractionSuccess(data);
        }

        this.$emit('user-interaction-updated', {
          content: containerContent, // Add info about content
          contentUserInfo: getContentUserInfo(containerContent),
          interactionId: interaction.id, // legacy
          interaction: interaction
        });

        this.ajaxLoading = false;

        // toggle feedback on
        toggleInteractionFeedback(containerContent, interactionRecord, interaction, true);
        // toggle feedback off
        aux.contentIdToInteractionFeedbackTimeout[interactionRecord.id] = setTimeout(() => {
          this.$emit("interaction-done", { interaction: interaction }); // The end of the interaction is notified. It is used by the videos to restart
          toggleInteractionFeedback(containerContent, interactionRecord, interaction, false);
        }, 3000);
        let label = containerContent.name + "__interaction__" + interaction.id;

        sendFandomAnalyticsEvent("Interaction", interaction.type, label);
        sendCustomGAEvent(interactionRecord);
      }
    });
  } else {
    const unauthorizedType = !checkRegistration(interactionRecord) ? "registration" : "repeatable"
    Fandom.emit("interaction-unauthorized", { type: unauthorizedType });

    if (Fandom.exists(unauthorizedCallback)) {
      unauthorizedCallback();
    }

  }
}

function updateUserRewardInfo(data) {
  if (data.user_reward_name_to_counter) {
    Vue.set(globalState.pageModel.user, "reward_name_to_counter", data.user_reward_name_to_counter);
  }
}

function updateContentUserInfo(containerContent, interaction, data) {
  let contentUserInfo = getContentUserInfo(containerContent) || initContentUserInfo(containerContent);

  if (!contentUserInfo.id_to_interaction[interaction.id]) {
    Vue.set(contentUserInfo.id_to_interaction, interaction.id, {});
  }
  Vue.set(contentUserInfo.id_to_interaction[interaction.id], "user_interaction", data.user_interaction);
}

function checkRepeatable(containerContent, content, interaction) {
  const repeatable = get(content, ["repeatable"], false);
  const interactionDone = isInteractionDone(containerContent, interaction);
  return (interactionDone && repeatable) || !interactionDone;
}

function checkRegistration(interactionRecord) {
  return (Fandom.isUserRegistered() && interactionRecord.registration_needed) || !interactionRecord.registration_needed;
}

function sendCustomGAEvent(interactionRecord) {
  if (Fandom.exists(interactionRecord.tracking)) {
    const category = get(interactionRecord, ["tracking", "category"], "not defined");
    const action = get(interactionRecord, ["tracking", "action"], "not defined");
    const label = get(interactionRecord, ["tracking", "label"], "not defined");
    sendFandomAnalyticsEvent(category, action, label);
  }
}

function contentUserInfoStatus(containerContent) {
  const contentUserInfo = getContentUserInfo(containerContent);
  const reward_name_to_counter = {};

  let complete = true;
  Object.keys(contentUserInfo.id_to_interaction).forEach(interactionId => {
    const interaction = contentUserInfo.id_to_interaction[interactionId];
    if (interaction.reward_name_to_counter) {
      if (isInteractionDone(containerContent, {...interaction, ...{ id: interactionId }})) {
        // nothing to do
      } else {
        complete = false;
      } 
      Object.keys(interaction.reward_name_to_counter).forEach(name => {
        if (!reward_name_to_counter[name]) {
          reward_name_to_counter[name] = 0;
        }
        reward_name_to_counter[name] += interaction.reward_name_to_counter[name];
      });
    }
  });

  return {
    complete: complete,
    reward_name_to_counter: reward_name_to_counter
  }
}

function initValidTo() {
  if (!!this.content.valid_to) {
    const validTo = new Date(this.content.valid_to);
    const now = new Date();
    if (now > validTo) {
      return this.votingClosed = true;
    } else {
      this.votingTimeoutID = setTimeout(() => {
        this.votingClosed = true;
      }, validTo - Date.now());
    }
    
    this.votingClosed = false;
  }
}

const interactionMixin = {
  data() {
    return {
      // ajax flag: false if there is no pending ajax call, true otherwise
      ajaxLoading: false,
      votingClosed: false,
      // likes related data
      cid: uuidv4(),
      counterLike: 0,
      totalLikeCount: 0
    };
  },
  mounted() {
    this.initValidTo();
  },
  computed: {
    showResults() {
      const contentUserInfo = this.getContentUserInfo(this.containerContent);
      const deferrer = get(contentUserInfo, ["id_to_interaction", this.content.interaction.id, "deferred"], false);
      const done = this.isInteractionDone(this.containerContent, this.content.interaction);

      if (deferrer) {
        const computed = !!get(contentUserInfo, ["id_to_interaction", this.content.interaction.id, "updated_at"], null);
        return computed;
      } else {
        return done || this.votingClosed;
      }
    },
    showWaiting() {
      const contentUserInfo = this.getContentUserInfo(this.containerContent);
      const deferrer = get(contentUserInfo, ["id_to_interaction", this.content.interaction.id, "deferred"], false);
      const computed = !!get(contentUserInfo, ["id_to_interaction", this.content.interaction.id, "updated_at"], null);
      const done = this.isInteractionDone(this.containerContent, this.content.interaction);

      return done && deferrer && !computed;
    },
    questionTitle() {
      return this.showResults ? get(this.content, "result_title", this.content.question) : this.content.question;
    },
    likeInteraction() {
      return get(this.content.play, ["like"], null);
    },
  },
  watch: {
    "content.valid_to"() {
      if (this.votingTimeoutID) {
        clearTimeout(this.votingTimeoutID);
      }
      this.initValidTo();
    }
  },
  methods: {
    sendLike() {
      this.counterLike++;
      this.totalLikeCount++;
      this.updateLike();
    },
    updateLike: throttle(
      function() {
        const params = {
          repeatable: this.likeInteraction.repeatable || false,
          counter: this.counterLike
        }
        this.updateLikeInteraction(this.containerContent, this.likeInteraction, this.likeInteraction.interaction, params);
        this.counterLike = 0;
      }, 1000, {trailing: true}
    ),
    contentUserInfoStatus,
    updateShareInteraction,
    updateWildcardInteraction,
    updateTriviaInteraction,
    updateTestInteraction,
    resetTestInteraction,
    updatePlayInteraction,
    updateRandomInteraction,
    updatePinInteraction,
    updateVersusInteraction,
    updateLikeInteraction,
    updateFavouriteInteraction,
    updateVoteInteraction,
    updateVoteListInteraction,
    updateEvaluateInteraction,
    updateCheckInteraction,
    updateDownloadInteraction,
    updatePlayThirdQuartileInteraction,
    updateSubmitInteraction,
    // utilities methods
    isFavourite,
    isFavouriteInListId,
    openFavouriteModal,
    checkRegistration,
    checkRepeatable,
    getUserInteractionAnswerId,
    getUserInteraction,
    isAnswerCorrect,
    isTestAnswer,
    isInteractionDone,
    isInteractionFeedbackPhase,
    getContentUserInfo,
    getLikeCounter,
    isLikeDone,
    interactionAjaxData,
    updateUserRewardInfo,
    updateContentUserInfo,
    updateInstantWinInteraction,
    updateIdToAnswer,
    initValidTo
  }
};

export {
  interactionMixin,
  isInteractionDone,
  isAnswerCorrect,
  checkRegistration,
  isTestAnswer,
  getUserInteraction,
  getUserInteractionAnswerId,
  getContentUserInfo,
  getLikeCounter,
  interactionAjaxData,
  updateUserRewardInfo,
  updateContentUserInfo,
  getUrlFromPath,
  sendCustomGAEvent
};
