<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import Intersect from 'vue-intersect';
import postscribe from "postscribe";
import { get, isEqual } from 'lodash';
import PageLiveEventNotice from 'page-live-event-notice';
import { Player } from 'player';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  components: {
    Intersect,
    PageLiveEventNotice,
    Player
  },
  props: {
    notices: {
      type: Array,
      default: () => []
    },
    controlBar: {
      type: Boolean,
      default: true
    },
    unmuteButton: {
      type: Boolean,
      default: true
    },
    ratioClass: {
      type: String,
      default: "embed-responsive embed-responsive-16by9"
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    unsupportedFullscreenClass: {
      type: String,
      default: ""
    },
    fullscreenTargetClass: {
      type: String,
      required: false
    },
    heightAdaptive: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      embed: {
        fullscreen: this.fullscreen,
        unsupportedFullscreenClass: this.unsupportedFullscreenClass
      },
      videoPlayer: {},
      overvideoInteraction: null,
      reloadEmbed: false,
      minifiedVideo: false,
      showControls: false,
      footerNotice: null,
      footerNoticeActivation: false
    }
  },
  mounted() {
    Vue.nextTick(() => {
      if (this.content.snippet) {
        postscribe(`#${this.snippetId}`, this.content.snippet);
      }
    });

    this.initFooterNotice();
  },
  watch: {
    'content.play.media_code'(_) {
      this.reload();
    },
    'content.snippet'(_) {
      this.reload();
    },
    'content.embed_media'(_) {
      this.reload();
    },
    fullscreen(newVal) {
      this.embed.fullscreen = newVal;
    },
    unsupportedFullscreenClass(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.embed.unsupportedFullscreenClass = newVal;
      }
    },
    footerNoticeHandler(newVal) {
      if (newVal && !isEqual(newVal, this.footerNotice)) {
        this.footerNoticeActivation = false;
        setTimeout(() => {
          this.footerNoticeActivation = true;
          Vue.set(this, 'footerNotice', newVal);
        }, 1000);
      } else {
        Vue.set(this, 'footerNotice', newVal);
      }
    }
  },
  methods: {
    reload() {
      this.reloadEmbed = true;
      Vue.nextTick(() => {
        this.reloadEmbed = false;
        if (this.content.snippet) {
          Vue.nextTick(() => {
            postscribe(`#${this.snippetId}`, this.content.snippet);  
          })
        }
      })
    },
    handleToggleFullscreen(data) {
      this.$emit('fplayer:toggleFullscreen', data);
    },
    initFooterNotice() {
      const footerNotice = this.footerNoticeHandler;
      if (footerNotice) {
        this.footerNoticeActivation = true;
        Vue.set(this, 'footerNotice', footerNotice); 
      }
    }
  },
  computed: {
    snippetId() {
      return `#embed-advanced-${this.contentId.toString().replace("|", "-")}`;
    },
    playerStringified() {
      const {core, ...player} = this.videoPlayer;
      return JSON.stringify(player);
    },
    currentBreakpoint() {
      for (let breakpoint in globalState.viewport) {
        if (globalState.viewport[breakpoint] == true) {
          return breakpoint;
        }
      }
    },
    interactiveContent() {
      return Fandom.getRecordOrContent(this.content.interactive_content);
    },
    footerNoticeHandler() {
      return Fandom.getRecordOrContent(get(this.interactiveContent, ["footer_notice"], false)) || Fandom.getRecordOrContent(get(this.content, ["footer_notice"], false));
    }
  }
}; 
</script>

<template>
  <div :style="theme" embed-advanced-component>
    <div :id="snippetId" v-if="content.snippet && !reloadEmbed"></div>
    <div ref="embed" class="d-flex align-items-center position-relative embed-advanced" :class="{'embed-advanced--fullscreen': embed.fullscreen}">
      <slot></slot>
      <div v-if="content.embed_media && !reloadEmbed" v-html="content.embed_media" class="w-100" :class="{'embed_content': embed.fullscreen}"></div>
      <div v-else-if="exists(content.play)" class="w-100 video-container" :class="{'embed_content': embed.fullscreen}">
        <transition :enter-active-class="'animated fadeInUp'" :leave-active-class="'animated fadeOutDown'">
          <page-live-event-notice 
            :opt-container-content="containerContent"
            :content="footerNotice"
            :key="footerNotice.$id"
            v-if="footerNotice && footerNoticeActivation"
            @clearnotice="footerNoticeActivation = false"
          ></page-live-event-notice>
        </transition>
        <div :class="ratioClass" v-if="minifiedVideo"></div>
        <player 
          ref="player"
          class="fandom-player"
          :height-adaptive="heightAdaptive"
          :content="content.play"
          :fullscreen-target-class="fullscreenTargetClass"
          :disable-left-sidebar="true"
          :opt-container-content="containerContent"
          @player:toggleFullscreen="handleToggleFullscreen"
        >
          <template #sub-header>
            <div class="embed_notification d-flex-inline flex-column">
              <transition-group enter-active-class="animated fadeInDown" leave-active-class="animated fadeOutUp">
                <button
                  v-for="notice in notices"
                  type="button"
                  class="notification d-flex mb-2 w-100"
                  @click="$emit('click-notice', notice.id)"
                  :key="notice.id"
                >
                  <i :class="notice.icon" class="mr-2"></i>
                  <div class="text-left">
                    <div class="font-weight-bold">{{notice.message}}</div>
                  </div>
                </button>
              </transition-group>
            </div>
          </template>
        </player>
        <slot name="actions" :showControls="showControls"></slot> 
      </div>
      <div
        v-else-if="content.image"
        class="bg-cover-center ar-16by9 w-100"
        :style="{'background-image': `url(${content.image.url})`}"
      ></div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
[embed-advanced-component] {
  .embed-advanced {
    overflow: hidden;
    background-color: var(--theme-background);

    &--fullscreen {
      background-color: transparent;
      height: 100% !important;

      .video-container {
        position: relative;
      }
    }

    ::v-deep [page-live-event-notice-component] {
      position: absolute;
      bottom: calc(#{$player-control-bar-height} + 1.5rem);
      left: 0;
      right: 0;
      z-index: 1030;
    }

    @include media-breakpoint-down(md) {
      &:not(.embed-advanced--fullscreen) {
        .video-container {
          display: flex;
          flex-direction: column;
          justify-content: center;

          ::v-deep [page-live-event-notice-component] {
            position: relative;
            bottom: auto;
            order: 1;
            width: 100%;
          }
        }
      }

      &.embed-advanced--fullscreen {
        ::v-deep [page-live-event-notice-component] {
          position: fixed;
        }
      }
    }
  }

  .embed-fullscreen__close-icon {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 2rem;
    z-index: 1;
  }

  ::v-deep .embed-fullscreen__controls {
    width: 100%;
    background-color: $dark;

    i {
      font-size: 1.5rem;
    }

    span {
      top: 2px;
      position: relative;
    }
  }

  .embed_notification {
    position: absolute;
    z-index: 1090;
    
    .notification { 
      padding: 0.75rem 1rem;
      background-color: var(--theme-background);
      color: var(--theme-color);
      border-radius: 6px;
      border-width: 0;
      
      &:focus {
        outline:0;
      }

      i {
        font-size: 1.25rem;
        margin-top: 1px;
      }
    }
  } 

  @include media-breakpoint-down(md) {

    .embed_notification {
      &.embed_notification--ipad {
        top: 5rem !important;
      }
    }

    .embed-responsive.small {
      inset: auto 16px 16px auto;
    }
  }

  @media (min-aspect-ratio: 16/9) {
    .embed_content {
      height: var(--inner-height);
      width: Min(calc((var(--inner-height)) * 16 / 9), 100%) !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  ::v-deep .fandom-player {
    &.player-container {
      .ratio-9by16 {
        .player-overvideo-items {
          right: auto;
          left: auto;
        }
      }

      .ratio-16by9 {
        .embed-responsive {
          position: relative;
          height: 100%;
        }

        .player-overvideo-items {
          .main-content {
            min-height: auto;
          }
        }
      }

      .close-icon { display: none; }

      &.fullscreen {
        .ratio-16by9 {
          @include media-breakpoint-down(xs) {
            transition: height .4s ease-in-out;
          }
        }

        &:not(.fullscreen--fallback) {
          .ratio-16by9 {
            @include media-breakpoint-down(xs) {
              height: auto !important;
            }
          }
        }
      }
    }
  }

  &.active-side-nav {
    ::v-deep .fandom-player {
      &.player-container {
        &.fullscreen--fallback {
          .ratio-16by9 {
            @include media-breakpoint-down(xs) {
              height: calc((100vw + 0.5rem) * 9 / 16);
            }
          }

          .player-overvideo-items, .embed-responsive {
            width: calc(100% - var(--sidebar-width));
            transition: all .4s ease-in-out;
            left: 0 !important;
            transform: translateX(0);

            @include media-breakpoint-down(xs) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
