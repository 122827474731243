<template>
  <div
    ref="playerFullscreen"
    class="player-container overflow-hidden bg-cover-center"
    :style="containerStyle"
    :class="containerClasses">
    <div 
      class="position-relative p-lg-2 p-0 w-limiter"
      :class="[`ratio-${ratio}`, {'active-right-content':rawShowRightContent, 'active-left-content':rawShowLeftContent, 'filter-blur': content.background_image}]"
    >
      <div class="position-relative embed-responsive-container" :class="{'w-100': !heightAdaptive}">
        <div v-if="isIvs && isBuffering" class="buffer-loader d-flex align-items-center justify-content-center position-absolute-center">
          <i class="fa-light fa-spinner-third fa-spin fa-4x"></i>
        </div>
        <div 
          ref="playerWrapper"
          class="embed-responsive bg-cover-center rounded"
          :class="[content.class, `embed-responsive-${ratio}`]"
        ></div>
      </div>
      <div ref="playerOvervideo" class="player-overvideo-items m-0" :class="{'active-right-content': rawShowRightContent, 'w-100': !heightAdaptive}">
        <player-overvideo-interaction 
          v-if="overvideoInteractions.length>0"
          :interactions="overvideoInteractions"
          :opt-container-content="containerContent"
          :interaction-suspense-audio="content.interaction_suspense_audio"
          :status="status"
          :time="time"
          @toggle-play="handleTogglePlay"
          @interaction-done="handleInteractionDone"
        ></player-overvideo-interaction>
        <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
          <component
            v-if="showEndVideoOverlay"
            :is="content.end_video.layout"
            class="w-100 player-end-content themed-item bg-cover-center d-flex align-items-center justify-content-center position-absolute-center"
            :content="content.end_video"
            :opt-container-content="containerContent"
            inherit-theme-type="dark"
          ></component>
        </transition>
        <div v-if="hasLeftContent" class="left-content" :class="{'active': rawShowLeftContent, 'has-banners': hasActiveBanners}">
          <div class="content-container left h-100 p-0 p-lg-2">
            <slot name="left-content" :ratio="ratio"></slot>
          </div>
        </div>
        <div v-easyadmin="easyadminId" :class="contentType" class="main-content rounded overflow-hidden">
          <div
            class="overlay h-100 w-100 position-absolute" 
            @click="toggleRightContent(rawActiveRightContent)"
            v-if="rawShowRightContent && (globalState.viewport.xs || globalState.viewport.sm)"
          ></div>
          <div class="gradient-bottom"></div>
          <div class="main-content-container h-100 p-medium p-lg-double">
            <div class="h-100 position-relative">
              <slot name="player-header">
                <player-header 
                  v-if="isEventShopping || !isYouTube || isVideoPopup"
                  :duration="duration"
                  :is-live="isLiveEvent"
                  :show-unmute="muted && showUnmuteBanner"
                  @toggle-mute="handleToggleMute"
                  @close="handleClose"
                >
                  <template #notification>
                    <slot name="notifications"></slot>
                  </template>
                </player-header>
              </slot>
              <div class="sub-header" :class="{'d-none d-md-block': rawShowRightContent}">
                <slot name="sub-header"></slot>
              </div>
              <div 
                @click="handleTogglePlay"
                v-if="overvideoInteractions.length == 0 && status == PLAYER_STATUS.PAUSED" 
                :class="`ar-${ratio}`"
                class="play-container text-light text-center cursor-pointer d-flex align-items-center justify-content-center pb-0"
              >
                <i class="fa-thin fa-play fa-6x"></i>
              </div>
              <div class="handle-toggle-play" @click="handleTogglePlay"></div>
              <player-settings
                class="player-settings rounded"
                :qualities="qualities"
                v-model="quality"
                @input="handleQualityChange"
                v-if="qualities.length>0"
                v-show="showSettings"
              ></player-settings>
              <player-controls-interactive 
                v-show="status" 
                class="player-side"
                :content="content"
                :quiz="quiz"
                :opt-container-content="containerContent"
                :preset="content.preset"
                :active-controls="activeControls"
                :group-icons="isMobileLandscape && is16by9"
                @toggle-comments="handleToggleComments"
                @toggle-right-content="toggleRightContent"
              ></player-controls-interactive>
              <div v-show="status" class="player-bottom" ref="playerControlBarWrapper">
                <progress-bar
                  v-if="showProgressBar"
                  :value="time"
                  @input="handleChangeTime"
                  :duration="duration"
                  :status="status"
                  :marks="marks"
                  @dragStart="isDragging=true"
                  @seekTo="handleSeekTo"
                ></progress-bar>
                <player-controls
                  v-if="status"
                  :content="content"
                  :opt-container-content="containerContent"
                  :status="status"
                  :muted="muted"
                  :volume="volume"
                  :preset="content.preset"
                  :active-controls="activeControls"
                  :fullscreen="fullscreen.active"
                  @toggle-play="handleTogglePlay"
                  @toggle-mute="handleToggleMute"
                  @toggle-fullscreen="handleToggleFullscreen"
                  @toggle-pip="handleTogglePip"
                  @toggle-settings="handleToggleSettings"
                  @change-volume="handleChangeVolume"
                  @toggle-right-content="toggleRightContent"
                ></player-controls>
              </div>
            </div>
          </div>
        </div>
        <div class="right-content" v-if="isEventShopping" :class="{'active': rawShowRightContent}">
          <div class="content-container h-100 right overflow-auto p-0 p-lg-2">
            <slot name="right-content" :ratio="ratio" :isMobileLandscape="isMobileLandscape" :toggleRightContent="toggleRightContent"></slot>
          </div>
        </div>
      </div>
    </div>

    <div class="rotate-container align-items-center justify-content-center flex-column" v-if="!is16by9">
      <div class="phone"></div>
      <div class="message">{{ ft('globals.live_shopping.rotate_message') }}</div>
    </div>
  </div>
</template>

<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module';
import { interactionMixin } from 'src/modules/interactions_module';
import defaultConfig from 'src/modules/player/config';
import { animateLiveHeader, showSidebar, hideSidebar, ANIMATION_SIDE } from  'src/modules/player/animations';
import { PROVIDERS, PLAYER_STATUS, PIP_STATE, RATIO, PLAYER_CONTENT, PRESETS_TYPE, ACTIONS, INTERACTION_TYPES } from 'src/modules/player/constants';
import { Ivs, Youtube, Vimeo, Svp } from 'src/modules/player/providers';
import { browser } from 'src/modules/player/utils';
import { isEqual, debounce, camelCase, isNil } from 'lodash';
import { PlayerOvervideoInteraction, PlayerHeader, PlayerSettings, PlayerControlsInteractive, PlayerControls } from 'player';
import ProgressBar from './progress-bar';

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  components: { PlayerOvervideoInteraction, PlayerHeader, PlayerSettings, PlayerControlsInteractive, ProgressBar, PlayerControls },
  props: {
    currentChapter: {
      type: Object,
      required: false
    },
    quiz: {
      type: Array,
      required: false
    },
    disableLeftSidebar: {
      type: Boolean,
      default: false
    },
    hasActiveBanners: {
      type: Boolean,
      default: false
    },
    heightAdaptive: {
      type: Boolean,
      default: true
    },
    activeRightContent: {
      type: String,
      default: PLAYER_CONTENT.EMPTY,
      validator: (value) => {
        return Object.values(PLAYER_CONTENT).includes(value)
      }
    },
    fullscreenTargetClass: {
      type: String,
      required: false
    },
    closeBehaviour: {
      type: String,
      default: "fullscreen",
      validator: (value) => {
        return ["fullscreen", "back"].includes(value)
      }
    }
  },
  data: function() {
    return {
      PLAYER_STATUS,
      PLAYER_CONTENT,
      provider: null,
      mediaInstance: null,
      status: PLAYER_STATUS.PAUSED,
      config: {},
      time: 0,
      duration: null,
      muted: false,
      showUnmuteBanner: true,
      volume: 0,
      fullscreen: {
        active: false,
        native: true,
        fallback: false
      },
      isPipActive: false,
      showControlBar: true,
      showControlBarTimeout: null,
      showSettings: false,
      qualities: [],
      quality: {},
      likes: 0,
      rawShowRightContent: false,
      rawShowLeftContent: false,
      rawActiveRightContent: this.activeRightContent,
      activeControls: [],
      liveHeaderAnimation: null,
      isDragging: false,
      isBuffering: false,
      currentOvervideoInteraction: null,
      overvideoInteractions: []
    }
  },
  mounted() {
    // init player
    this.initPlayer(); 

    // add event listeners
    for (let status of Object.values(PLAYER_STATUS)) {
      Fandom.onEvent(status, (payload) => {
        if (payload.id === this.mediaInstance.id) {
          this.updateStatus(status);
          this[camelCase(`handle_${status}`)]?.(payload?.data);
        }
      });
    }

    // show sidebars
    if (this.rawActiveRightContent !== PLAYER_CONTENT.EMPTY) {
      this.showRightContent(this.rawActiveRightContent, true);
    }

    if (!this.is16by9 || (this.is16by9 && this.isMobilePortrait)) {
      this.handleToggleLeftContent();
    }

    this.updateWindowHeight();
    window.addEventListener('resize', debounce(() => this.updatePlayerLayout(), 250));

    this.overvideoInteractions = (this.content?.overvideo_interactions ?? [])
      .sort((a,b) => a.seconds-b.seconds)
      .map(oi => {
          const result = {
            ...oi, 
            type: INTERACTION_TYPES.INTERACTION,
            seconds: oi.seconds === "end" ? oi.seconds : parseFloat(oi.seconds)
          }
          if (oi.interaction) {
            result.userInteraction = this.getUserInteraction(this.containerContent, oi.interaction);
          } else if (oi.items) {
            result.type = INTERACTION_TYPES.LIST_INTERACTIONS
          }
          return result;
        })
  },
  beforeDestroy() {
    this.resetPlayer();
    for (let status of Object.values(PLAYER_STATUS)) {
      Fandom.offEvent(status);
    }
    document.getElementById('show-sidebar-action')?.removeEventListener('mouseover', this.clearControlsTimeout);
    document.getElementById('show-sidebar-action')?.removeEventListener('mouseleave', this.resetControlsTimeout);
    this.toggleListeners("removeEventListener");
    clearTimeout(this.showControlBarTimeout);
  },
  methods: {
    async initPlayer() {
      this.config = {
        ...defaultConfig.general,
        // BACKWARDS COMPATIBILITY
        autoplay: this.content.autoplay,
        muted: this.content.muted,
        // END
        ...this.content.config || {},
        fullscreenElement: this.fullscreenTargetClass
          ? document.getElementsByClassName(this.fullscreenTargetClass)[0]
          : this.$refs.playerFullscreen,
        fullscreen: defaultConfig.fullscreen
      };
      this.config.muted = (this.config?.autoplay ?? false) ? !this.isVideoPopup : (this.config?.muted ?? false);
      this.muted = this.config.muted;
      this.config.fullscreen.forcecss = this.content.preset == PRESETS_TYPE.INTERACTION;
      if (this.content.config?.fullscreen) {
        this.config.fullscreen.enabled = true;
      }

      this.provider = this.mediaType;
      if (!Object.values(PROVIDERS).includes(this.provider)) {
        throw new Error('Setup failed: Invalid provider');
      }

      if (this.mediaInstance) {
        this.resetPlayer();
      }

      if (this.isYouTube) {
        this.mediaInstance = new Youtube(this.$refs.playerWrapper, this.config, this.mediaCode);
      } else if (this.isSVP) {
        this.mediaInstance = new Svp(this.$refs.playerWrapper, this.config, this.mediaCode);
      } else if (this.isVimeo) {
        this.mediaInstance = new Vimeo(this.$refs.playerWrapper, this.config, this.mediaCode);
      } else if (this.isIvs) {
        this.mediaInstance = new Ivs(this.$refs.playerWrapper, this.config);
        this.mediaInstance.prefetchVideo(this.mediaCode, this.containerContent.id, this.playInteraction.id);
      }

      if (this.isEventShopping) {
        await this.mediaInstance.fullscreen.toggleFallback(true);
        this.fullscreen.active = this.mediaInstance.fullscreen.active;
        this.fullscreen.fallback = this.mediaInstance.fullscreen.fallback;
      } else if (this.config.fullscreen.enabled) {
        this.handleToggleFullscreen();
      }

      this.mediaInstance.fullscreen.target.addEventListener("fullscreenchange", this.onFullscreenChange);
      this.mediaInstance.fullscreen.target.addEventListener("webkitfullscreenchange", this.onFullscreenChange);
      this.mediaInstance.fullscreen.target.addEventListener("mozfullscreenchange", this.onFullscreenChange);

      this.mediaInstance.videoEl?.addEventListener('leavepictureinpicture', () => {this.isPipActive = false});

      if (this.config.hide_controls) {        
        // NB: If player used in page-live-event component
        const sidebarEl = document.getElementById('show-sidebar-action');
        if (sidebarEl) {
          document.getElementById('show-sidebar-action').addEventListener('mouseover', this.clearControlsTimeout);
          document.getElementById('show-sidebar-action').addEventListener('mouseleave', this.resetControlsTimeout);
        }
      }
    },
    onFullscreenChange() {
      if (!document.fullscreenElement) {
        if (this.isEventShopping && this.closeBehaviour==="back") {
          this.handleClose();
        } else {
          this.mediaInstance.fullscreen.exit();
          this.mediaInstance.fullscreen.active = false;
          this.fullscreen.active = false;
          this.fullscreen.fallback = false;

          this.$emit('player:toggleFullscreen', this.fullscreen);
        }
      }
    },
    resetPlayer() {
      this.mediaInstance.destroy();
      this.mediaInstance = null;
      this.time = 0;
    },
    async initQualitySettings() {
      this.quality = await this.mediaInstance.getQuality();
      this.qualities = await this.mediaInstance.getQualities();
    },
    async handleReady() {
      this.muted = await this.mediaInstance.isMuted();
      this.volume = this.muted ? 0 : await this.mediaInstance.getVolume();
      this.isFullscreen = this.mediaInstance.fullscreen.active;
      
      await this.initQualitySettings();
      if (this.config.start_at) {
        await this.handleSeekTo(this.config.start_at);
      }
    },
    handleBuffering() {
      this.isBuffering = true;
    },
    async handlePlaying() {
      this.isBuffering = false;
      this.updatePlayInteraction?.(this.containerContent, this.content, this.content.interaction);
      // Align play time
      this.time = await this.mediaInstance.getPosition();
      await this.initQualitySettings();
    },
    handlePaused() {
      this.mediaInstance.pause();
    },
    async handleEnded() {
      const contentToShow = this.content.highlight_on_end;
      if (contentToShow && Object.values(PLAYER_CONTENT).includes(contentToShow)) {
        this.toggleRightContent(this.content.highlight_on_end, true);
      }

      if (this.isLiveEvent) {
        this.handleLivestreamoffline();

        if (this.isIvs) {
          this.initPlayer();
        }
      }
    },
    handleTogglePlay() {
      if (this.status === PLAYER_STATUS.PLAYING) {
        this.mediaInstance.pause();
        //this.status = PLAYER_STATUS.PAUSED;
      } else {
        if (this.status === PLAYER_STATUS.ENDED && this.time >= this.duration) {
          this.time = 0
        }
        this.mediaInstance.play();
        //this.status = PLAYER_STATUS.PLAYING;
      }
    },
    async handleToggleMute() {
      this.muted = !this.muted;
      this.showUnmuteBanner = false;
      this.volume = await this.mediaInstance.setMuted(this.muted);
    },
    async handleSeekTo(time) {
      this.isDragging = false;
      await this.mediaInstance.seekTo(time);
      await this.handleChangeTime(time);
    },
    async handleDurationChanged() {
      this.duration = await this.mediaInstance.getDuration();
      Vue.nextTick(() => {
        if (!this.liveHeaderAnimation) {
          this.liveHeaderAnimation = animateLiveHeader('.tag-live', ['.tag-live i.fa-video', '.tag-live i.fa-play']);
          this.liveHeaderAnimation.play();
        }
      })
    },
    handleLivestreamoffline() {
      // this will trigger hide of live tag
      this.duration = null;
      this.liveHeaderAnimation?.pause();
      this.liveHeaderAnimation = null;
    },
    async handleChangeTime(time) {
      this.time = await this.mediaInstance.getPosition();
      this.$emit('player:time', time);
    },
    async handleToggleFullscreen() {
      await this.mediaInstance.fullscreen.toggle();
      this.fullscreen.active = this.mediaInstance.fullscreen.active;
      this.fullscreen.fallback = this.mediaInstance.fullscreen.fallback;

      this.$emit('player:toggleFullscreen', this.fullscreen);
    },
    handleTogglePip() {
      // Safari
      if (typeof this.mediaInstance.videoEl.webkitSetPresentationMode === 'function') {
        this.mediaInstance.videoEl.webkitSetPresentationMode(!this.isPipActive ? PIP_STATE.ACTIVE : PIP_STATE.INACTIVE);
        this.isPipActive = !this.isPipActive;
      } else if (typeof this.mediaInstance.videoEl.requestPictureInPicture === 'function') {
        // Chrome
        this.isPipActive ? document.exitPictureInPicture() : this.mediaInstance.videoEl.requestPictureInPicture();
        this.isPipActive = !this.isPipActive;
      }
    },
    handleChangeVolume(volume) {
      this.mediaInstance.setVolume(volume);
      this.volume = volume;

      if (this.muted) {
        this.handleToggleMute();
      }
    },
    handleToggleSettings() {
      this.showSettings = !this.showSettings;
    },
    handleQualityChange() {
      this.mediaInstance.setQuality(this.quality);
      this.showSettings = !this.showSettings;
    },
    handleToggleComments() {
      !this.is16by9 || (this.is16by9 && this.isMobilePortrait) ? this.handleToggleLeftContent() : this.toggleRightContent(PLAYER_CONTENT.COMMENTS);
    },
    handleToggleLeftContent() {
      const side = this.is16by9 || this.isMdAndMinor ? null : ANIMATION_SIDE.LEFT;
      this.rawShowLeftContent ? this.hideLeftContent(side) : this.showLeftContent(side);
    },
    showLeftContent(side=null) {
      this.activeControls.push(PLAYER_CONTENT.COMMENTS);
      showSidebar('.content-container.left', side);
      this.rawShowLeftContent = !this.rawShowLeftContent;
      this.$emit('player:show-left-content', {content: PLAYER_CONTENT.COMMENTS});
    },
    hideLeftContent(side=null) {
      this.activeControls.splice(this.activeControls.indexOf(PLAYER_CONTENT.COMMENTS), 1);
      const animation = hideSidebar('.content-container.left', side);
      this.rawShowLeftContent = !this.rawShowLeftContent;
      animation.play();
    },
    handleToggleChapters() {
      this.toggleRightContent(PLAYER_CONTENT.CHAPTERS);
    },
    toggleRightContent(content, stayOpen=false) {
      const side = (globalState.viewport.xs || globalState.viewport.sm || globalState.viewport.md) ? null : (this.is16by9 ? ANIMATION_SIDE.LEFT : ANIMATION_SIDE.RIGHT);
      if (this.isMobileLandscape && this.is16by9) { // grouped icons
        if (this.rawShowRightContent) {
          this.hideRightContent(side);
        } else {
          this.rawShowRightContent = !this.rawShowRightContent;
          showSidebar('.content-container.right', side);
        }
        return;
      }

      if (this.rawActiveRightContent === content && stayOpen) {
        // do nothing
        return;
      } else if (this.rawActiveRightContent === content || content === PLAYER_CONTENT.EMPTY) {
        // sidebar already opened with content
        this.hideRightContent(side);
      } else if (this.rawShowRightContent) {
        // sidebar already opened with other content
        this.activeControls.splice(this.activeControls.indexOf(this.rawActiveRightContent), 1);
        this.rawActiveRightContent = content;
        this.$emit('player:change-right-content', content);
        this.activeControls.push(content);
      } else {
        // sidebar closed, open it
        this.showRightContent(content, side)    
      }
    },
    showRightContent(content, side) {
      if (this.isMobilePortrait && this.is16by9 && content===PLAYER_CONTENT.COMMENTS) {
        // comments on mobile should not be on the right sidebar
        return;
      }
      
      this.rawShowRightContent = true;
      this.rawActiveRightContent = content;
      this.$emit('player:show-right-content', content);
      this.activeControls.push(content);

      showSidebar('.content-container.right', side);
    },
    hideRightContent(side) {
      this.rawShowRightContent = false;
      const animation = hideSidebar('.content-container.right', side)
      animation.finished.then(()=>{
        const index = this.activeControls.indexOf(this.rawActiveRightContent);
        if (index > -1) {
          this.activeControls.splice(index, 1);
        }
        if (!(this.isMobileLandscape && this.is16by9)) {
          this.rawActiveRightContent = PLAYER_CONTENT.EMPTY;
          this.$emit('player:hide-right-content');
        }
      })
      animation.play();
    },
    resetControlsTimeout() {
      this.clearControlsTimeout();
      this.setControlTimeout();  
    },
    async clearControlsTimeout(event=null) {
      if (event && !this.isDragging) {
        event.stopPropagation();
      }
      if (this.showControlBarTimeout) {
        clearTimeout(this.showControlBarTimeout);
      }

      // Align play time
      if (this.mediaInstance) {
        this.time = await this.mediaInstance.getPosition();
      }
      this.showControlBar = true;
    },
    setControlTimeout() {
      if (this.config.hide_controls && !this.showSettings) {
        const timeout = this.isMdAndMinor && browser.isTouch ? defaultConfig.controlBarTimeoutMobile : defaultConfig.controlBarTimeout
        this.showControlBarTimeout = window.setTimeout(()=>{
          this.showControlBar = false;
        }, timeout);
      }
    },
    updateStatus(status) {
      const toMatch = Object.values(PLAYER_STATUS).filter(item => ![PLAYER_STATUS.DURATION_CHANGED ,PLAYER_STATUS.READY].includes(item));
      if (toMatch.includes(status)) {
        this.status = status;
      } else if (status === PLAYER_STATUS.READY && this.config?.autoplay) {
        this.status = PLAYER_STATUS.PLAYING;
      } else if (status === PLAYER_STATUS.ENDED) {
        this.status = PLAYER_STATUS.ENDED;
      }

      this.config.hide_controls && this.updateControlBarListeners(status);
    },
    updateControlBarListeners(status) {
      let fn = 'addEventListener';
      if (status === PLAYER_STATUS.PLAYING) {
        this.setControlTimeout();
      } else if (status === PLAYER_STATUS.PAUSED && this.isYouTube) {
        this.clearControlsTimeout();
        fn = 'removeEventListener';
      } else {
        return;
      }
      
      this.toggleListeners(fn);
    },
    toggleListeners(fnName) {
      const playerControlBarWrapperEl = this.$refs.playerControlBarWrapper;
      this.$refs.playerOvervideo[fnName]('mousemove', this.resetControlsTimeout);
      playerControlBarWrapperEl[fnName]('mouseover', this.clearControlsTimeout);
      playerControlBarWrapperEl[fnName]('mousemove', this.clearControlsTimeout);
      playerControlBarWrapperEl[fnName]('mouseleave', this.resetControlsTimeout);
    },
    handleClose() {
      if (this.isEmbedded) {
        Fandom.emit('embed.post-message', {action: ACTIONS.PLAYER.CLOSE});
      } else if(this.isVideoPopup) {
        this.handleToggleFullscreen();
        this.$emit("close");
      } else if (document.referrer && document.referrer !== window.location.href) {
        window.location.href = document.referrer;
      } else {
        history.length > 1 ? history.back() : window.location.href = '/'
      }
    },
    async handleInteractionDone(data) {
      if (data.seconds) {
        await this.handleSeekTo(data.seconds);
      }
      data.togglePlay && this.handleTogglePlay();
    },
    updatePlayerLayout() {
      this.updateWindowHeight();

      Vue.nextTick(() => {
        if (this.is16by9) {
          if (this.isMobileLandscape && this.rawShowLeftContent) {
            this.hideLeftContent();
          } else if (this.isMobilePortrait && !this.rawShowLeftContent) {
            this.showLeftContent();
          }
        }
      })
    },
    updateWindowHeight() {
      // SAFARI WORKAROUND TO TRIGGER VIEWPORT RESIZE
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      
      if (this.is16by9 && this.isMobileLandscape) {
        document.body.style.setProperty("height", `calc(${window.outerHeight}px + 1px)`);
      } else {
        document.body.style.removeProperty("height");
      }
    }
  },
  computed: {
    isEventShopping() {
      return [PRESETS_TYPE.LIVESHOPPING, PRESETS_TYPE.ONDEMAND_SHOPPING].includes(this.content.preset);
    },
    isVideoPopup() {
      return !!this.globalState.videoPopupContent;
    },
    containerStyle() {
      return { 
        "background-image": this.getContentResponsiveImageURL("background_image") 
      };
    },
    containerClasses() {
      return [
        {
          'fullscreen--fallback': this.fullscreen.fallback,
          'show-controls': this.showControlBar,
          'show-settings': this.showSettings,
          'active-right-content': this.rawShowRightContent,
          'active-left-content': this.rawShowLeftContent,
          'live-event': this.duration && this.isLiveEvent,
          'ondemand-event': this.duration && !this.isLiveEvent,
          'landscape': globalState.screen.landscape,
          'is-mobile': this.isMdAndMinor,
          'ios': browser.isIos,
          'is-touch': browser.isTouch,
        }, 
        this.provider, this.status, `preset-${this.content.preset}`,
        this.isEventShopping ? 'event-shopping' : 'simple-event',
        this.fullscreen.active ? 'fullscreen' : 'no-fullscreen',
      ]
    },
    hasLeftContent() {
      return (!this.is16by9 || (this.is16by9 && this.isMobilePortrait)) && !this.disableLeftSidebar && this.content.preset !== PRESETS_TYPE.INTERACTION;
    },
    playInteraction() {
      return this.content.interaction;
    },
    marks() {
      return (this.content.chapters ?? [])
        .map(c => { return {type: "chapter", ...c} })
        .concat(this.overvideoInteractions);
    },
    ratio() {
      return this.isMobile && this.content?.ratio_mobile ? this.content.ratio_mobile : (this.content?.ratio ?? RATIO.HORIZONTAL);
    },
    is16by9() {
      return this.ratio == RATIO.HORIZONTAL;
    },
    isHTML5() {
      return this.provider === PROVIDERS.HTML5;
    },
    isYouTube() {
      return this.provider === PROVIDERS.YOUTUBE;
    },
    isSVP() {
      return this.provider === PROVIDERS.SVP;
    },
    isVimeo() {
      return this.provider === PROVIDERS.VIMEO;
    },
    isIvs() {
      return this.provider === PROVIDERS.IVS;
    },
    isLiveEvent() {
      if ("is_live" in this.content) {
        return this.content.is_live;
      }

      return this.duration === Infinity || this.duration === 0;
    },
    isMdAndMinor() {
      return globalState.viewport.xs || globalState.viewport.sm || globalState.viewport.md;
    },
    isMobileLandscape() {
      return globalState.screen.landscape && browser.isTouch && this.isMdAndMinor;
    },
    isMobilePortrait() {
      return globalState.screen.portrait && browser.isTouch && this.isMdAndMinor;
    },
    isMobile() {
      return this.isMobileLandscape || this.isMobilePortrait;
    },
    mediaCode() {
      return this.isMobile && this.content.media_code_mobile ? this.content.media_code_mobile : this.content.media_code;
    },
    mediaType() {
      return this.isMobile && this.content.media_type_mobile ? this.content.media_type_mobile : this.content.media_type;
    },
    showProgressBar() {
      return !this.isLiveEvent && this.duration>0 && this.duration != Infinity;
    },
    showEndVideoOverlay() {
      return this.content.end_video && this.showProgressBar && this.duration-this.time<=0.9;
    }
  },
  watch: {
    currentChapter(newVal) {
      if (newVal?.seconds) {
        this.handleSeekTo(newVal.seconds);
      }
    },
    activeRightContent(newVal, oldVal) {
      if (newVal !== oldVal && newVal !== this.rawActiveRightContent) {
        this.toggleRightContent(newVal);
      }
    },
    quiz(newVal) {
      if (this.rawActiveRightContent === PLAYER_CONTENT.QUIZ && isNil(newVal)) {
        this.toggleRightContent(PLAYER_CONTENT.EMPTY);
      }
    },
    "content.media_code"(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initPlayer();
      }
    },
    "content.media_type"(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initPlayer();
      }
    },
    "content.config.thumbnail": {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal) && this.mediaInstance && this.isIvs) {
          this.mediaInstance.setPoster?.(newVal.url);
          this.mediaInstance.showPoster?.();
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
$video-z-index: 9;
$left-content-height: 40vh;

@media (min-width: 2500px) {
	.w-limiter {
		//max-width: 70%;
		margin-left: auto;
		margin-right: auto;

    @media (min-height: 2500px) {
      --inner-height: 60vh;
    }
	}
}

.rounded {
  border-radius: $player-border-radius !important;
}

.player-container {
  --inner-height: calc(var(--vh, 1vh) * 100);
  --header-height: #{$header-height};
  --video-margin: .5rem;
  --player-control-bar-height: #{$player-control-bar-height};
  --player-progress-bar-height: 0px;
  --sidebar-controls-width: 40px;
  --handle-toggle-play-height: 70px;

  &.ondemand-event {
    --player-progress-bar-height: 2rem;
  }

  &.simple-event {
    .ratio-16by9, .ratio-9by16 {
      display: flex;
    }

    .unmute-header {
      position: absolute;
      top: -.5rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 12;

      .tag-unmute {
        cursor: pointer;
        background: $white;
        color: #10111D;
        border-radius: $player-border-radius-sm;
        display: flex;
        font-weight: 700;
        max-height: 24px;
        width: max-content;
        max-width: 180px;
        padding: 6px 8px;

        span {
          font-size: 12px;
          line-height: 13px;
        }
      }
    }

    .play-container {
      height: 100%;
    }
  }

  &.youtube {
    &.paused, &.ended {
      .handle-toggle-play {
        display: block !important;
      }
    }
  }

  .play-container {
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    width: 100%;

    &.ar-9by16 {
      aspect-ratio: 9 / 16;
    }

    &.ar-16by9 {
      aspect-ratio: 16 / 9;
    }

    @include media-breakpoint-down(sm) {
      i { font-size: 4rem; }
    }
  }

  @include media-breakpoint-down(md) {
    --header-height: #{$header-height-sm};

    &:not(.active-right-content) {
      .ratio-9by16 {
        --video-margin: 0px;
      }

      .embed-responsive-9-16, video, iframe {
        border-radius: 0px !important;
      }
    }
  }

  .filter-blur {
    backdrop-filter: blur(5px);
  }

  .gradient {
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    height: 15%;
    background-image: linear-gradient(180deg, rgba(16, 17, 29, 0.5) 1%, rgba(16, 17, 29, 0.0001) 100%);
    position: absolute;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .gradient-bottom {
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: $player-border-radius;
    border-bottom-right-radius: $player-border-radius;
    height: calc(var(--player-control-bar-height) + 35px);
    width: 100%;
    background: linear-gradient(0deg, rgba(16, 17, 29, 0.7) 1.2%, rgba(16, 17, 29, 0.0001) 100%);
    transition: all .4s ease-in-out, transform .4s ease-in-out;
  }

  &.fullscreen--fallback {
    position: fixed !important;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1090;
    background-color: $black;
    height: var(--inner-height);
    min-height: var(--inner-height);
    max-height: var(--inner-height);
    flex: 1 1 var(--inner-height);
  }

  &.no-fullscreen {
    .embed-responsive-9by16 {
      max-height: 100%;
      height: calc(100% - var(--header-height));
    }

    .player-overvideo-items {
      padding: var(--video-margin);
    }
  }

  &.fullscreen, &.fullscreen--fallback {
    --header-height: 0px !important;

    .ratio-16by9 {
      height: calc(var(--inner-height) - var(--header-height));
    }

    .player-overvideo-items {
      padding: 0;
    }
  }

  .buffer-loader {
    z-index: calc(#{$video-z-index} + 1);
    width: 100%;
    transition: all .4s ease-in-out, transform .4s ease-in-out;

    i {
      --fa-animation-duration: 1.4s;
    }
  }

  .embed-responsive {
    z-index: $video-z-index;
    transition: all .4s ease-in-out, transform .4s ease-in-out;
  }

  .overlay {
    z-index: 1;
  }

  .ratio-9by16 {
    display: flex;
    justify-content: center;
    height: calc(var(--inner-height) - var(--header-height));

    .embed-responsive-9by16 {
      // NB this suppose the video with full height
      width: calc((var(--inner-height) - var(--video-margin)*2 - var(--header-height)) * 9 / 16);

      ::v-deep {
        iframe, video {
          object-fit: cover;
        }
      }

      @include media-breakpoint-down(sm) {
        width: 100vw;
      }
    }

    .player-overvideo-items {
      display: flex;
      justify-content: center;

      .main-content {
        position: relative;
        width: calc((var(--inner-height) - var(--video-margin)*2 - var(--header-height)) * 9 / 16);
        height: calc(100% - var(--video-margin)*2 - var(--header-height));
        margin-top: var(--video-margin);
        z-index: calc(#{$video-z-index} + 1);
        transition: all .4s ease-in-out, transform .4s ease-in-out;
      }

      .left-content, .right-content {
        flex: 1;
        position: relative;
        max-width: calc((100vh - var(--header-height)) * 9 / 16);

        @include media-breakpoint-up(lg) {
          .content-container {
            position: absolute;
            width: 100%;
            top: 0;
            bottom: 0;

            &.left, &.right {
              opacity: 0;
            }
          }
        }
      }

      @include media-breakpoint-down(sm) {
        width: 100vw;

        .main-content {
          width: 100%;
        }

        .left-content, .right-content {
          max-width: 100%;
        }
      }
    }
  }

  .ratio-16by9.active-right-content {
    .embed-responsive-container {
      width: 100% !important;
    }

    .embed-responsive, .main-content, .buffer-loader {
      width: calc(100% / 3 * 2) !important;
      transform: translateX(0px);

      @media (orientation: landscape) {
        @include media-breakpoint-down(lg) {
          width: 60% !important;
        }
      }

      @media (orientation: portrait) {
        @include media-breakpoint-down(lg) {
          width: 100% !important;
        }
      }
    }
  }

  .ratio-9by16.active-right-content {
    .embed-responsive {
      display: flex;
      justify-content: center;

      ::v-deep video, iframe {
        position: relative;
        width: auto;
        border-radius: 10px;
        object-fit: contain;
      }
    }

    @include media-breakpoint-down(md) {
      height: calc(var(--inner-height) - var(--header-height));

      .embed-responsive, .main-content {
        margin-top: var(--video-margin);
        height: calc(100vw * 9 / 16) !important; // to full view 16:9
        width: 100vw;
        transition: all .4s ease-in-out, transform .4s ease-in-out;
      }

      .main-content {
        .gradient-bottom, .player-side {
          display: none;
        }
      }
    }
  }

  .ratio-9by16.active-left-content {
    .gradient-bottom {
      @include media-breakpoint-down(sm) {
        height: calc(var(--player-control-bar-height) + 40vh);
      }
    }
  }

  .ratio-16by9 {
    height: auto;
    width: auto !important;
    margin: auto;
    max-width: 100%;
    padding: .5rem !important;

    .embed-responsive-container {
      width: Min(calc((var(--inner-height) - var(--header-height) - var(--video-margin)*2) * 16 / 9), 100%);
      transition: width .4s ease-in-out, transform .4s ease-in-out, left 0s ease-in-out;
      display: flex;
      top: 0;
      right: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .embed-responsive {
      @media (orientation: portrait) {
        width: 100%;
        left: 0% !important;
        transform: translateX(0%);
      }

      ::v-deep {
        iframe, video {
          border-radius: $player-border-radius;
        }
      }
    }

    @include media-breakpoint-down(md) {
      .embed-responsive video {
        height: auto !important;
      }
    }

    .player-overvideo-items {
      left: 0;
      right: 0;
      display: flex;
      z-index: calc(#{$video-z-index} + 2);

      .main-content {
        position: relative;
        width: 100%;
        height: 100%;
        transition: all .4s ease-in-out, transform .4s ease-in-out;
      }

      .left-content, .right-content {
        width: calc(100% / 3);

        @include media-breakpoint-between(md, lg) {
          width: 40%;
        }
      }
    }

    @media (orientation: landscape) {
      --video-margin: .5rem;

      .player-overvideo-items {
        transition: width .4s ease-in-out, transform .4s ease-in-out;
        padding: var(--video-margin);
        width: Min(calc((var(--inner-height) - var(--header-height) + 1px - var(--video-margin)) * 16 / 9), 100%);
        left: 50%;
        transform: translateX(-50%);
        
        .left-content {
          position: absolute;
          bottom: calc(var(--player-control-bar-height) + var(--player-progress-bar-height) + var(--video-margin) + 10px);
          height: 50%;
          
          .content-container {
            display: none;
          }

          &.active {
            z-index: 1;
            .content-container {
              display: block;
            }
          }

          &.active + .main-content {
            background: linear-gradient(270deg, rgba(0, 0, 0, 0.0001) 71.86%, rgba(0, 0, 0, 0.5) 100%);
          }
        }

        .right-content {
          position: absolute;
          top: 0;
          bottom: 0;
          right: -100%;
          transition: right .4s ease-in-out, transform .4s ease-in-out;

          &.active {
            right: 0;
          }

          .content-container {
            opacity: 0;
            padding: .5rem !important;
          }
        }

        @include media-breakpoint-down(md) {
          .left-content, .right-content {
            width: 40%;
          }
        }

        &.active-right-content {
          width: 100%;
        }
      }
    }

    @media (orientation: portrait) {
      &.active-right-content {
        @include media-breakpoint-down(md) {
          .embed-responsive, .main-content, .buffer-loader {
            width: 100% !important;
            height: auto !important;
          }
        }
      }

      .player-overvideo-items {
        .left-content {
          transition: all .4s ease-in-out, transform .4s ease-in-out;
          position: absolute;
          width: calc(100% - var(--sidebar-controls-width) - 1rem);
          bottom: calc(var(--player-control-bar-height) + var(--player-progress-bar-height));
          top: calc(100vw * 9 / 16);

          &.has-banners {
            top: calc((100vw * 9 / 16) + 1rem + 48px);
          }

          .content-container {
            display: none;
          }

          &.active {
            z-index: 11;
            .content-container {
              display: block;
            }
          }
        }

        .right-content {
          position: absolute;
          bottom: -100vh;
          top: 100%;
          left: 0;
          width: 100vw;
          height: auto;
          transition: all .4s ease-in-out, transform .4s ease-in-out;
          z-index: 12;

          &.active {
            bottom: 0;
            top: calc((100vw * 9 / 16) + var(--video-margin)*2);
            height: auto;
          }
        }
      }
    }
  }
  
  .ratio-9by16{
    .player-overvideo-items {
      @include media-breakpoint-down(md) {
        .left-content {
          position: absolute;
          width: calc(100% - var(--sidebar-controls-width) - 1rem);
          left: 0;
          bottom: calc(var(--player-control-bar-height) + var(--player-progress-bar-height));
          height: $left-content-height;

          .content-container {
            display: none;
          }

          &.active {
            z-index: 11;
            .content-container {
              display: block;
            }
          }
        }

        .right-content {
          position: absolute;
          bottom: -100vh;
          top: 100%;
          width: 100vw;
          height: auto;
          transition: all .4s ease-in-out, transform .4s ease-in-out;
          z-index: 12;

          &.active {
            bottom: 0;
            top: calc((100vw * 9 / 16) + var(--video-margin)*2);
            height: auto;
          }
        }
      }
    }
  }

  .player-overvideo-items {
    position: absolute;
    top: 0;
    bottom: 0;

    @include media-breakpoint-up(lg) {
      right: 0;
      left: 0;
    }

    .player-settings {
      position: absolute;
      bottom: var(--player-control-bar-height);
      right: 0;
      z-index: 16;
    }

    .sub-header {
      display: block;
      position: absolute;
      top: 42px;
      width: 100%;
      z-index: 14;
    }

    .handle-toggle-play {
      height: var(--handle-toggle-play-height); 
      width: 100%;
      position: absolute;
      top: calc(50% - var(--handle-toggle-play-height) / 2);
      display: none;
    }

    .overvideo-interaction, .player-end-content {
      z-index: 11;
      overflow-y: scroll; 

      ::v-deep .themed-item {
        background-color: transparent !important;
      }
    }
  }

  &.event-shopping {
    .ratio-16by9 {
      @media (orientation: portrait) {
        @include media-breakpoint-down(md) {
          .right-content.active {
            top: calc((100vw * 9 / 16) + var(--video-margin)) !important;
          }

          .sub-header {
            top: calc((100vw * 9 / 16) + .5rem);
          }
        }
      }
    }
  }

  .player {
    &-bottom {
      position: absolute;
      bottom: 0;
      height: var(--player-control-bar-height);
      width: 100%;
      transition: opacity .4s ease-in-out, transform .4s ease-in-out;
    }

    &-side {
      position: absolute;
      //bottom: var(--player-control-bar-height);
      bottom: calc(var(--player-control-bar-height) + var(--player-progress-bar-height) + var(--video-margin));
      right: 0;
      font-size: 1.3rem;
      display: flex;
      flex-direction: column;
      padding-left: 14px;
      z-index: 15;
    }
  }

  &.show-controls {
    .player-bottom {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &:not(.show-controls) {
    .gradient-bottom {
      height: 0;
    }

    .player-bottom {
      opacity: 0;
      transform: translateY(100%);
    }
  }

  @include media-breakpoint-down(sm) {
    &.active-right-content {
      .player-bottom, .left-content {
        opacity: 0;
        transform: translateY(100%); 
      }
    }
  }
}

.rotate-container {
  background: $black;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: none;

  @include media-breakpoint-down(md) {
    @media (orientation: landscape) and (max-height: 414px) {
      display: flex;
    }
  }

  .phone {
    height: 50px;
    width: 100px;
    border: 3px solid $white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
  }

  .message {
    color: $white;
    font-size: 1em;
    margin-top: 40px;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg)
    }
    50% {
      transform: rotate(-90deg)
    }
    100% {
      transform: rotate(-90deg)
    }
  }
}
</style>