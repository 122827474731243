<script>
import { get } from "lodash";

export default {
  props: {
    ready: {
      type: Boolean,
      default: false,
    },
    meetingStatus: {
      type: String,
      required: true
    },
    previewingVideo: {
      type: Boolean,
      default: false,
    },
    isAudioOutputSelectionAvailable: {
      type: Boolean,
      default: false,
    },
    audioInputDevices: {
      type: Array,
      default: () => [],
    },
    audioOutputDevices: {
      type: Array,
      default: () => [],
    },
    videoInputDevices: {
      type: Array,
      default: () => [],
    },
    defaultAudioInputDeviceId: {
      type: String,
      default: null,
    },
    defaultAudioOutputDeviceId: {
      type: String,
      default: null,
    },
    defaultVideoInputDeviceId: {
      type: String,
      default: null,
    }
  },
  mounted() {
    this.$emit('select-device', this.audioInputDevices.find(d => d.deviceId === this.defaultAudioInputDeviceId), 'AudioInput');
    this.$emit('select-device', this.audioOutputDevices.find(d => d.deviceId === this.defaultAudioOutputDeviceId), 'AudioOutput');
    this.$emit('select-device', this.videoInputDevices.find(d => d.deviceId === this.defaultVideoInputDeviceId), 'VideoInput');
  },
  methods: {
    selectDevice(event, deviceType) {
      const device = get(this, [`${deviceType}Devices`,event.target.selectedIndex], null);
      const type = deviceType[0].toUpperCase() + deviceType.slice(1);
      this.$emit('select-device', device, type);
    },
    startPreview() {
      this.$emit('start-preview', {videoElement: this.$refs['localVideo'], audioElement: this.$refs['oscilloscope']});
    },
  }
}
</script>

<template>
  <div class="modal fade" tabindex="-1" role="dialog" page-live-event-meeting-settings-modal-component>
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Call Settings</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="mb-0">Set your preferences to join the meeting.</p>
          <small>Remember to allow your browser to access microphone and camera functionalities.</small>
          <div class="row pt-4 justify-content-center">
            <div class="col-12">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Choose audio input device</label>
                <select class="form-control" @change="selectDevice($event, 'audioInput')" id="audio-input-device">
                  <option
                    :selected="inputDevice.deviceId === defaultAudioInputDeviceId"
                    v-for="(inputDevice, index) in audioInputDevices"
                    :key="`audioinput-${index}`"
                    :value="inputDevice"
                  >{{inputDevice.label}}</option>
                </select>
              </div>
              <div class="form-group" v-if="isAudioOutputSelectionAvailable">
                <label for="exampleFormControlSelect1">Choose audio output device</label>
                <select class="form-control" @change="selectDevice($event, 'audioOutput')" id="audio-output-device">
                  <option
                    :selected="outputDevice.deviceId === defaultAudioOutputDeviceId"
                    v-for="(outputDevice, index) in audioOutputDevices"
                    :key="`audiooutput-${index}`"
                    :value="outputDevice"
                  >{{outputDevice.label}}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="exampleFormControlSelect1">Choose video input device</label>
                <select class="form-control" @change="selectDevice($event, 'videoInput')" id="video-input-device">
                  <option
                    :selected="inputDevice.deviceId === defaultVideoInputDeviceId"
                    v-for="(inputDevice, index) in videoInputDevices"
                    :key="`videoinput-${index}`"
                    :value="inputDevice"
                  >{{inputDevice.label}}</option>
                </select>
              </div>
              <div class="w-100 pt-4" :class="{'d-none': !previewingVideo}">
                <video id="localVideo" ref="localVideo"/>
                <canvas id="oscilloscope" ref="oscilloscope"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div v-if="meetingStatus != 'joined'" class="modal-footer">
          <button class="btn btn-outline-primary rounded" v-if="ready && !previewingVideo" @click="startPreview">Start preview</button>
          <button class="btn btn-primary rounded ml-auto" @click="$emit('join-user')">Join call</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[page-live-event-meeting-settings-modal-component] {
  #localVideo {
    max-width: 100%;
    height: auto;
  }

  #oscilloscope {
    width: 100%;
    height: 3rem;
  }
}
</style>