<script>
import { interactionMixin, getUrlFromPath } from "src/modules/interactions_module.js";
import { fandomTranslate } from "src/modules/common_module";
import { get } from "lodash";

const textToFlex = {
  right: "end",
  left: "start",
  center: "center"
};

export default {
  mixins: [interactionMixin],
  props: {
    content: Object,
    containerContent: Object,
    // Placed temporary as a props, maybe it is better to place it in the globalState
    isIframe: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      shareUrl: ""
    };
  },
  methods: {
    providerIcon(provider) {
      const providerClass = Fandom.getProviderIcon(provider);
      const providerWeight = provider === "link" ? "far" : "fab";
      return [providerClass, providerWeight];
    },
    ft: fandomTranslate,
    startShare(provider) {
      // We must copy the value to the clipboard here because if we go too deep in the call stack
      //   we lose the origin by a click event and so we can't copy anything to the clipboard
      if (["link"].includes(provider) && !this.canUseWebShare) {
        this.copyToClipboard();
      }
      this.updateShareInteraction(this.containerContent, this.content, this.shareInteraction, provider, false, this.isIframe, this.canUseWebShare);
    },
    isOnlyMobile(provider) {
      return ["whatsapp"].includes(provider);
    },
    copyToClipboard() {
      const textarea = this.$refs["textarea"];
      const selection = document.getSelection();
      const range = document.createRange();

      this.shareUrl = getUrlFromPath(false, this.isIframe, this.content.name);
      range.selectNode(textarea);
      selection.removeAllRanges();
      selection.addRange(range);

      const copyStatus = document.execCommand("copy");
      if (copyStatus) {
        $(this.$refs["modal"]).modal("show");
      }

      selection.removeAllRanges();
    }
  },
  computed: {
    canUseWebShare() {
      return "share" in navigator;
    },
    shareRecord() {
      const record = get(this, "content.interactions.share", {});
      return get(this, "content.share", record);
    },
    shareInteraction() {
      return Fandom.exists(this.shareRecord.interaction) ? this.shareRecord.interaction : {};
    },
    shareProviders() {
      return Fandom.exists(this.shareRecord.providers) ? this.shareRecord.providers.split(",") : [];
    },
    iconsAlignment() {
      const horizontalAlign = textToFlex[get(this, "content.text_align", "left")];
      return `align-items-center justify-content-${horizontalAlign}`;
    },
  }
};
</script>

<template>
  <div class="d-flex" :class="iconsAlignment">
    <template v-if="!canUseWebShare">
      <span
        class="cursor-pointer share-icon d-inline-flex align-items-center justify-content-center"
        v-for="(provider, index) in shareProviders"
        :key="index"
        :class="{'d-inline-block d-lg-none': isOnlyMobile(provider), 'mr-2 mr-lg-3': index + 1 < shareProviders.length}"
        @click="startShare(provider)"
        >
        <i class="fa-lg" :class="providerIcon(provider)"></i>
      </span>
      <div class="modal fade" ref="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header position-relative pb-0 border-0">
              <h5 class="h2 w-100 d-inline-block text-center">{{ft("globals.share_with_friends")}}</h5>
              <button type="button" class="close position-absolute" data-dismiss="modal" aria-label="Close">
                <i class="far fa-times-circle"></i>
              </button>
            </div>
            <div class="modal-body">
              <div class="input-group">
                <textarea class="w-100 form-control" ref="textarea" v-model="shareUrl"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <span class="cursor-pointer share-icon d-inline-flex align-items-center justify-content-center" @click="startShare('webShare')" v-else>
      <i class="fa-2x far fa-share-alt"></i>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.close {
  top: .5rem;
  right: .5rem;

  > i {
    font-size: 1.25rem;
  }
}

.share-icon {
  border-radius: 100%;
  width: 2rem;
  height: 2rem;

  .fa-lg {
    font-size: 1rem;
  }

  @include media-breakpoint-up(lg) {
    width: 3rem;
    height: 3rem;

    .fa-lg {
      font-size: 1.5rem;
    }
  }
}
</style>
