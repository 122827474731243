<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js'
import { interactionMixin } from 'src/modules/interactions_module.js'
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  data() {
    return {
      activated: false,
      timeout: null
    }
  },
  props: {
    timer: {
      default: 10,
      type: [String, Number]
    }
  },
  mounted() {
    Vue.nextTick(() => {
      setTimeout(() => {
        this.activated = true;
        this.timeout = setTimeout(() => {
          this.$emit('close-flash');
        }, parseInt(this.timer)*1000);
      }, 100);
    });
  },
  beforeDestroy() {
    if (!!this.timeout) {
      clearInterval(this.timeout);
    }
  },
  methods: {
    isContentVisibleToUser: Fandom.isContentVisibleToUser,
    getAnswerClasses(answer) {
      const defaultButtonClass = 'btn-light';
      if (this.isInteractionDone(this.containerContent, this.content.interaction)) {
        if (this.isAnswerCorrect(this.containerContent, this.content.interaction, answer.$id)) {
          return "btn-success";
        } else {
          const userInteraction = this.getUserInteraction(this.containerContent, this.content.interaction);
          const userInteractionAnswerId = Fandom.exists(userInteraction.answer_id) ? userInteraction.answer_id : parseInt(userInteraction.data.answer_id);
          return userInteractionAnswerId == answer.$id ? "btn-danger" : defaultButtonClass;
        }
      }
      return defaultButtonClass;
    },
    interact(answer) {
      let cmp = this;
      this.updateTriviaInteraction(
        this.containerContent,
        this.content,
        this.content.interaction,
        answer.$id,
        () => {},
        () => {
          if (!!this.timeout) {
            clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(() => {
            cmp.$emit('close-flash');
          }, 3500);
        }
      );
    }
  },
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" trivia-component>
    <div class="container position-relative interaction-container">
      <div class="row align-items-center justify-content-center pt-5 pb-4 py-md-4">
        <div class="col-lg-8 text-center">
          <h3 class="h4 mb-2">{{content.question}}</h3>
          <div class="row">
            <div class="col-12 col-md-6 px-4 py-2 py-md-3" v-for="answer in content.answers" :key="`answer-${answer.$id}`">
              <button 
                @click="interact(answer)"
                class="btn w-100 position-relative"
                :class="getAnswerClasses(answer)"
              >
                {{answer.text}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 px-0">
          <div class="round-time-bar" :style="{'--duration': `${timer}s`}">
            <div :class="{'activated': activated}"></div>
          </div>
        </div>
      </div>
      <i @click="$emit('close-flash')" class="fal fa-lg fa-times p-3 p-md-4 cursor-pointer"></i>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[trivia-component] {
  z-index: $zindex-modal;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  left: 1.5rem;

  .interaction-container {
    border-radius: 15px;
    background-color: #333333;
    color: $white;
    overflow: hidden;
    width: 1100px;
    max-width: 100%;
  }

  .fa-times {
    position: absolute;
    top: 0;
    right: 0;
  }

  .round-time-bar {
    background: var(--theme-color);

    div {
      height: 6px;
      width: 100%;
      transition: width var(--duration) linear;
      background: $primary;

      &.activated {
        width: 0;
      }
    }
  }
}
</style>