import {
  create,
  ErrorType,
  LogLevel,
  isPlayerSupported,
  PlayerEventType,
  PlayerState,
} from 'amazon-ivs-player';
import { createElement, removeAllChild } from '../utils';
import { get } from 'lodash-es';
import Fullscreen from '../Fullscreen';
import { PLAYER_STATUS, AUTO_QUALITY } from 'src/modules/player/constants';
import defaultConfig from  'src/modules/player/config';

// These imports are loaded via the file-loader, and return the path to the asset.
import wasmBinaryPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.wasm';
import wasmWorkerPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.js';

class Ivs {
  /**
   * 
   * @param {HTMLElement} wrapperEl
   * @param {Object} config
   */
  constructor(wrapperEl, config) {
    if (!isPlayerSupported) {
      throw new Error('IVS Player is not supported in this browser');
    }

    this.config = config;
    this.videoEl = createElement('video', {playsinline: '', class: 'bg-black'});
    this.wrapperEl = wrapperEl;
    removeAllChild(wrapperEl);
    this.wrapperEl.appendChild(this.videoEl);

    const target = typeof config.fullscreenElement === 'object' ? config.fullscreenElement : wrapperEl.parentElement;
    this.fullscreen = new Fullscreen(target, this.config.fullscreen);

    this.prevVolume = null;
    this.createPlayer();
  }

  /**
   * return the instance id
   */
  get id() {
    return `ivs-${this.videoId}`;
  }

  /**
   * Create the player
   * Web Workers and WASM Workers need to be created via URL. Webpack has created the relative URL for us via file-loader,
   * now we just have to create the absolute (fully qualified) URL.
   */
  createPlayer() {
    const createAbsolutePath = (assetPath) => new URL(assetPath, document.URL).toString();
    this.player = create({
      wasmWorker: createAbsolutePath(wasmWorkerPath),
      wasmBinary: createAbsolutePath(wasmBinaryPath),
    });

    this.player.attachHTMLVideoElement(this.videoEl);
    this.attachListeners();
    this.configPlayer();
  }

  /**
   * Check if stream available
   * @param {string} stream 
   * @param {int} ccId content cache id 
   * @param {*} playId play interaction id
   */
  prefetchVideo(stream, ccId, playId) {
    fetch(stream)
      .then(response => {
        if (response.status == 200) {
          this.destroy();
          this.createPlayer(this.config);
          this.loadStream(stream, ccId, playId);
        } else {
          this.handlePrefetchError(response, stream, ccId, playId)
        }
      })
      .catch(error => this.handlePrefetchError(error, stream, ccId, playId))
  }

  handlePrefetchError(error, stream, ccId, playId) {
    Fandom.emit(PLAYER_STATUS.NOT_AVAILABLE, {id: this.id});
    this.showPoster();
    // start polling
    this.timeoutId = window.setTimeout(() => this.prefetchVideo(stream, ccId, playId), defaultConfig.prefetchPolling);
  }

  /**
   * Get amazon iws token and load stream
   * @param {string} stream 
   * @param {int} ccId content cache id 
   * @param {*} playId play interaction id
   */
  loadStream(stream, ccId, playId) {
    Fandom.ajax({
      url: `/api/v5/live_events/amazon_ivs_token?content_cache_id=${ccId}&interaction_id=${playId}`,
      method: "GET",
      success: (data) => {
        const appendUrl = data.token ? `?token=${data.token}` : '';
        this.player.load(`${stream}${appendUrl}`);
      },
      error: () => {
        this.player.load(stream);
      }
    });
  }

  /**
   * Config the player
   */
  configPlayer() {
    this.setPoster(Fandom.getContentResponsiveImage('thumbnail', this.config));

    if (this.config.autoplay) {
      this.player.setAutoplay(true);
      this.player.setMuted(this.config.muted);
    } else {
      this.showPoster(true);
    }
    
    
    if (this.config.muted) {
      this.player.setMuted(true);
    }

    if (this.config.auto_quality_mode) {
      this.player.setAutoQualityMode(true);
    }

    if (new URLSearchParams(window.location.search).has('debug')) {
      this.player.setLogLevel(LogLevel.DEBUG);
    }
  }

  /**
   * Set poster
   * @param {poster} poster 
   */
  setPoster(poster) {
    this.poster = poster;
  }

  /**
   * Show video poster
   * @param {Boolean} force Force set of video poster
   */
  showPoster(force=false) {
    const poster = this.videoEl.getAttribute("poster");
    if (force || (!poster && this.poster) || (this.poster !== poster)) {
      this.videoEl.setAttribute("poster", this.poster);
    }
  }

  /**
   * Attach player event listeners
   */
  attachListeners() {
    for (let state of [...Object.values(PlayerState), PlayerEventType.DURATION_CHANGED]) {
      this.player.addEventListener(state, (data) => {
        Fandom.emit(this.mapState(state), {id: this.id, data});
      });
    }

    this.player.addEventListener(PlayerEventType.ERROR, (error) => {
      const statusTooManyRequests = 429;
      if (error.type === ErrorType.NOT_AVAILABLE && error.code === statusTooManyRequests) {
        console.error('Concurrent-viewer limit reached', error);
      } else {
        console.error('ERROR', error);
      }

      this.showPoster(true);
      Fandom.emit(PLAYER_STATUS.NOT_AVAILABLE, {id: this.id});
    });
  }

  /**
   * Map IVS state to Fandom player status
   * @param {PlayerState} ivsState
   * @returns PLAYER_STATUS
   */
  mapState(ivsState) {
    let status = '';
    switch (ivsState) {
      case PlayerState.READY:
      case PlayerState.BUFFERING:
      case PlayerState.PLAYING:
      case PlayerState.ENDED:
        status = ivsState.toLowerCase();
        break;
      case PlayerEventType.DURATION_CHANGED:
        status = PLAYER_STATUS.DURATION_CHANGED;
        break;
      case PlayerState.PAUSED:
      case PlayerState.IDLE:
        status = PLAYER_STATUS.PAUSED.toLowerCase();
        break;
    }

    return status;
  }

  /**
   * Removes the player instance and stops playback.
   * After deletion, the player no longer emits events or responds to API calls.
   */
  destroy() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
    this.player.delete();
  }

  /**
   * Starts or resumes playback of the stream
   */
  play() {
    this.player.play();
  }

  /**
   * Pauses playback of the current stream
   */
  pause() {
    this.player.pause();
  }

  /**
   * Gets the duration of the currently loaded media stream
   */
  getDuration() {
    return this.player.getDuration();
  }

  /**
   * Gets the current position of the player, in seconds
   */
  getPosition() {
    return this.player.getPosition();
  }

  /**
   * Seeks to a specified time in the stream
   * @param {Number} time The position to seek to, in seconds
   */
  seekTo(time) {
    this.player.seekTo(time);
  }

  /**
   * Mutes or unmutes the player
   * @param {Boolean} mute True to mute the player, false to unmute
   * @returns {Number} volume The current volume
   */
  setMuted(mute) {
    if (this.prevVolume === null || mute) {
      this.prevVolume = this.getVolume();
    }

    if (mute) {
      this.player.setVolume(0);
    } else {
      this.setVolume(this.prevVolume);
    }
    this.player.setMuted(mute);

    return this.getVolume();
  }

  /**
   * Gets whether the player is muted.
   * @returns boolean - True if the player is muted, false otherwise.
   */
  isMuted() {
    return this.player.isMuted();
  }

  /**
   * Gets the player's volume level.
   * @returns {Number} The volume level of the player, between 0.0f and 1.0f.
   */
  getVolume() {
    return this.player.getVolume();
  }
  
  /**
   * Sets the playback volume
   * @param {Number} volume The volume to be set. Valid values: in the range 0.0f to 1.0f.
   */
  setVolume(volume) {
    this.player.setVolume(volume);
  }

  /**
   * Set of available Quality objects from the loaded source or empty if none are currently available.
   * @returns Quality[]
   */
  getQualities() {
    return this.player.getQualities();
  }

  /**
   * 
   * @returns Quality
   */
  getQuality() {
    return this.player.isAutoQualityMode() ? AUTO_QUALITY : this.player.getQuality();
  }

  /**
   * Sets the quality the player should use for playback.
   * @param {Quality} quality A valid quality entry from Player.getQualities.
   * @param {boolean} adaptive True for an adaptive quality change; that is, to change quality smoothly at the end of the current buffer. False to change quality immediately.
   */
  setQuality(quality, adaptive=false) {
    quality.name === 'auto' ?
      this.player.setAutoQualityMode(true) :
      this.player.setQuality(quality, adaptive);
  }

  setMaxBitrate(mbps) {
    const bitrate = mbps * 1000000;
    const quality = {
      name: "custom",
      bitrate: bitrate,
      height: 0,
      width: 0,
      framerate: 0,
      codecs: '',
      group: '',
      isDefault: true
    }
    this.player.setAutoMaxQuality(quality, true);
  }
}

export default Ivs;