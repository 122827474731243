<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { liveEventsMixin } from 'src/modules/live_events_module.js';
import { addPolling, removePolling } from 'src/modules/polling_module.js';
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin, liveEventsMixin, fandomExtendedMixin],
  mounted: function() {
    addPolling("liveInteraction", () => {
      this.getLiveInteraction.call(this);
    });
  },
};
</script>

<template>
  <div :style="theme" class="w-100 d-flex sidebar-content sticky-top flex-column  pr-lg-4">
    <div class="w-100 faq-area">
      <faq class="position-relative" v-if="content.comments" :content="content.comments" :opt-container-content="containerContent"></faq>
    </div>
    <div v-if="liveInteractions && liveInteractions.length > 0" class="w-100 interactions-area">
      <h4 class="h2 mb-0 font-weight-normal">{{ft("globals.versus.poll")}}</h4>
      <transition-group enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" tag="div">
        <div v-for="liveInteraction in liveInteractions" :key="`live-interaction-${liveInteraction['$id']}`">
          <component
            :is="liveInteraction.layout"
            :content="liveInteraction"
            :id="`anchor-${containerContent.id}-${liveInteraction.interaction.id}`"
            :opt-container-content="containerContent"
            :inherit-theme-type="'dark'"
          ></component>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<style lang="scss" scoped>

  .sidebar-content {
    z-index: 1019;
    height: calc(100vh - #{$header-height});
    top: $header-height;
    background-color: var(--theme-background);
    color: var(--theme-color);
  }

  .interactions-area {
    flex: 1;
    overflow-y: scroll;

    @include ie {
      height: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    .interactions-area {
      overflow: visible;
    }

    .sidebar-content {
      background: none;
      position: relative;
      height: auto;
      top: 0;
    }
  }

</style>
