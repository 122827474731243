<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  props: {
    entry: {
      type: Object
    }
  },
  methods: {
    getUsernameByUser: Fandom.getUsernameByUser,
    getUserAvatarByUser: Fandom.getUserAvatarByUser,
    getCurrentUserId() {
      if (Fandom.isUserRegistered()) {
        return globalState.pageModel.user.id;
      }
    }
  }
};
</script>

<template>
  <div class="row align-items-center flex-nowrap py-2 ranking-entry" :class="{'active': getCurrentUserId() == entry.user.id}" ranking-entry-component>
    <div class="col-2 col-sm-1">
      <div class="ranking-entry-position">{{entry.position}}</div>
    </div>
    <div class="col-auto d-none d-sm-block">
      <div class="ranking-entry-avatar bg-cover-center d-flex align-items-center justify-content-center" :style="{'background-image': `url(${getUserAvatarByUser(entry.user)})`}">
        <i v-if="!getUserAvatarByUser(entry.user)" class="fal fa-user"></i>
      </div>
    </div>
    <div class="col">
      <p class="ranking-entry-username mb-0" v-if="entry.user.public_id">
        <a :href="applyContextToUrl('/profilo-pubblico/livelli?public_id=' + entry.user.public_id)">{{getUsernameByUser(entry.user)}}</a>
      </p>
      <p class="ranking-entry-username mb-0" v-else>
        {{getUsernameByUser(entry.user)}}
      </p>
    </div>
    <div class="col-auto">
      <div class="px-3 py-1 d-inline-block ranking-entry-reward-marker">{{entry.counter}} <i class="fal fa-star"></i></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[ranking-entry-component] {
  &.active {
    background-color: $gray-200;
  }

  .ranking-entry-reward-marker {
    border-radius: 2rem;
    min-width: 3rem;

    background-color: $yellow;
    color: $white;
  }

  .ranking-entry-avatar {
    position: relative;
    width: 3rem; 
    height: 3rem; 
    border-radius: 100%;
    background-color: $primary;
    font-size: 1.5rem;
    color: $white;
  }
}
</style>
