<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js'

export default {
  mixins: [fandomBasicMixin],
  computed: {
    contentClasses() {
      const classes = [this.contentType];
      if (this.content.content && this.getContent(this.content.content) && this.getContent(this.content.content).sticky) {
        classes.push("sticky-top");
      }
      return classes;
    }
  }
}
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentClasses" v-if="isContentVisibleToUser()" shared-content-component>
    <component v-if="content.content" :is="(getContent(content.content) || {}).layout" :content="getContent(content.content)" :key="(getContent(content.content) || {}).name"></component>
  </div>
</template>

<style lang="scss" scoped>
  .sticky-top {
    top: $header-height;

    @include media-breakpoint-down(sm) {
      top: $header-height-sm;
    }
  }
</style>
