<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  props: {
    alignmentClass: {
      type: String,
      default: "justify-content-end"
    },
    spacing: {
      type: Boolean,
      default: false
    }
  },
  mixins: [fandomBasicMixin],
  computed: {
    isMobile() {
      return globalState.viewport.xs || globalState.viewport.sm;
    },
    isWide() {
      return Fandom.exists(this.content.isotope_size) && this.content.isotope_size === "widecontent";
    },
    smallTextRequired() {
      // return this.isWide || this.isMobile;
      return this.isMobile;
    },
    tileUrl() {
      return Fandom.exists(this.content.link) ? this.content.link.url : this.content.slug;
    },
    tileTarget() {
      return Fandom.exists(this.content.link) ? this.getTarget(this.content.link) : "_self";
    },
    buttonTitle() {
      return Fandom.exists(this.content.link) ? this.content.link.title : this.ft("globals.find_more");
    },
    hasUrl() {
      return Fandom.exists(this.tileUrl) && this.tileUrl !== "";
    },
    showButton() {
      return Fandom.exists(this.content.disable_button) ? !this.content.disable_button : true;
    },
    tileStyle() {
      const style = {};
      if (this.content.thumbnail) {
        style["background-image"] = Fandom.getBackgroundUrl(this.content.thumbnail.url);
      }
      return style;
    }
  }
};
</script>

<template>
  <component
    :is="hasUrl ? 'a' : 'div'"
    class="position-absolute-center overflow-hidden d-block"
    v-easyadmin="easyadminId"
    :class="[contentType, spacing ? 'my-2' : 'my-md-2']"
    :href="tileUrl"
    :target="tileTarget"
    tile-component
  >
    <div class="p-4 bg-cover-center text-white h-100 d-flex flex-column" :style="tileStyle" :class="alignmentClass">
      <div class="tile-text" :class="{'small-text': smallTextRequired}">
        <div class="small" :class="spacing ? 'mb-3' : 'mb-1'" v-if="content.category">{{content.category}}</div>
        <h2 v-if="content.title" class="tile-title" :class="spacing ? 'mb-3' : 'mb-1'">{{content.title}}</h2>
        <p v-if="content.description" v-html="content.description" class="description" :class="spacing ? 'mb-3' : 'mb-1'"></p>
        <div class="small event-date-text" v-if="content.event_date_text" v-html="content.event_date_text"></div>
      </div>
    </div>
  </component>
</template>

<style lang="scss" scoped>
[tile-component] {
  color: $dark !important;
  
  .event-date-text {
    ::v-deep p {
      margin-bottom: 0;
    }
  }

  .small {
    font-weight: 200;
  }

  .tile-title {
    .small-text & {
      font-size: 1.125rem;
    }
  }

  .description {
    font-size: 1.15rem;
    font-weight: 400;

    .small-text & {
      font-size: .8125rem;
    }

    ::v-deep p {
      margin-bottom: 0;
    }
  }
}
</style>
