<template>
  <div player-header class="header">
    <div class="header__wrapper position-relative">
      <div class="live-header">
        <div class="d-flex justify-content-between">
          <div>
            <div v-if="isLive" class="tag tag-live small overflow-hidden"><i class="fas fa-video"></i></div>
          </div>
          <i class="close-icon text-white cursor-pointer fal fa-times font-weight-normal mb-0" @click="$emit('close')"></i>
        </div>
      </div>
      <div v-if="showUnmute" class="unmute-header d-flex justify-content-center">
        <div class="tag tag-unmute small d-flex justify-content-between" @click="$emit('toggle-mute')">
          <i class="fal mr-2 fa-volume-slash"></i>
          <span>{{ ft('globals.live_shopping.unmute') }}</span>
        </div>
      </div>
      <slot name="notifications"></slot>
    </div>
  </div>  
</template>

<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module';

export default {
  mixins: [fandomBasicMixin],
  props: {
    duration: {type: Number},
    isLive: {type: Boolean},
    showUnmute: {type: Boolean}
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 11;

  .tag {
    border-radius: $player-border-radius-sm;
    display: flex;
    font-weight: 700;
    max-height: 24px;
    padding: 6px 8px;

    span {
      font-size: 12px;
      line-height: 13px;
    }
  }

  .live-header {
    min-height: 24px;

    .tag-live {
      background: $player-red-color;
      color: $white;
      text-transform: uppercase;

      i {
        -webkit-text-stroke: 1px $white;
        color: transparent;
      }

      &.replay {
        background: $white;
        color: $player-red-color;

        i {
          -webkit-text-stroke: 1px $player-red-color;
        }
      }
    }

    .close-icon {
      font-weight: 400 !important;
      font-size: 24px;
      opacity: .9;
      line-height: 1;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, .5);
    }
  }

  .unmute-header {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 200px;

    .tag-unmute {
      cursor: pointer;
      background: $white;
      color: #10111D;
    }
  }
}
</style>