<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { interactionMixin } from "src/modules/interactions_module.js";
import Flickity from "flickity";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  props: {
    lastBookmark: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      carousel: null,
      activeContent: null,
      activeContentAlreadyDoneTimeout: null,
      preventTimeout: false
    };
  },
  mounted() {
    Vue.nextTick(() => {
      this.carousel = new Flickity(this.$refs["trivia-carousel"], {
        pageDots: false,
        prevNextButtons: false,
        draggable: false,
        adaptiveHeight: false,
        on: {
          ready: () => {
            this.activeContent = this.content.trivia_carousel[0];
          },
          change: (index) => {
            this.preventTimeout = false;
            this.activeContent = this.content.trivia_carousel[index];
          }
        }
      });
    })
  },
  computed: {
    showNavigation() {
      return Fandom.exists(this.carousel) && 
             (this.carousel.selectedIndex != this.carousel.cells.length - 1) &&
             (this.carousel.cells.length > 2);
    }
  },
  watch: {
    // The feedback phase is managed by the interaction module
    activeContent(newValue, previousValue) {
      if (this.activeContentAlreadyDoneTimeout) {
        clearTimeout(this.activeContentAlreadyDoneTimeout);
        this.activeContentAlreadyDoneTimeout = null;
      }
      if (Fandom.exists(newValue) && this.isInteractionDone(this.containerContent, newValue.interaction)) {
        this.activeContentAlreadyDoneTimeout = setTimeout(() => {
          this.carousel.next();
        }, (this.preventTimeout ? 0 : 3000));
      }
    },
  },
  methods: {
    updateTriviaInteractionCustomized(answer, triviaContent) {
      this.activeContent = null;
      this.updateTriviaInteraction(this.containerContent, this.content, triviaContent.interaction, answer.$id, () => {}, () => {
        this.preventTimeout = true;
        this.activeContent = triviaContent;
      });
    },
    isUserAnswer(answerId, content) {
      return this.getUserInteractionAnswerId(this.containerContent, content.interaction) == answerId;
    },
    
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="mh-100vh-header" :class="contentType" course-product-trivia-carousel-component>
    <div class="bg-light">
      <div class="container">
  			<div class="row">
  				<div class="col-12 position-relative">
            <div v-if="showNavigation" class="w-100 d-flex justify-content-between my-3 align-items-center position-absolute trivia-carousel__navigation">
              <div class="mx-4">
                <a href="javascript: void(0)" @click="carousel.previous()" :class="{'disabled': carousel.selectedIndex == 1}">
                  <i class="fal fa-arrow-circle-left trivia-carousel__navigation-icon"></i>
                </a>
              </div>
              <transition enter-active-class="animated fast fadeIn" leave-active-class="animated fast fadeOut">
                <h5 class="h5 text-uppercase mb-0">
                  {{ft('ecommerce.question')}} {{carousel.selectedIndex + 1}} {{ft('ecommerce.of')}} {{carousel.cells.length - 1}}
                </h5>
              </transition>
              <div class="mx-4">
                <a href="javascript: void(0)" @click="carousel.next()"><i class="fal fa-arrow-circle-right trivia-carousel__navigation-icon"></i></i></a>
              </div>
            </div>
  					<div class="container-fluid px-0 trivia-carousel" ref="trivia-carousel">
              <div v-for="(triviaContent, index) in content.trivia_carousel" class="w-100 trivia-carousel-item row align-items-center justify-content-center bg-light trivia-question my-5">
                <div class="col-lg-8 text-center">
                  <h3 class="h2 py-4 mb-0">{{triviaContent.question}}</h3>
                  <ul class="list-unstyled">
                    <li v-for="(answer, answer_index) in triviaContent.answers" class="my-2">
                      <button @click="updateTriviaInteractionCustomized(answer, triviaContent)" :class="{'choose': isUserAnswer(answer.$id, triviaContent)}" class="btn btn-block answer">
                        {{answer.text}}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- last slide -->
              <div class="trivia-carousel-item w-100 h-100 row justify-content-center align-items-center bg-light py-5 text-center">
                <div class="col-lg-8" v-html="ft('ecommerce.end_question_list')"></div>
              </div>
            </div>
  				</div>
  			</div>
      </div>
    </div>
    <stripe-one-col-text v-if="content.description" :content="content" :opt-container-content="containerContent" class="py-5"></stripe-one-col-text>
    <component :is="stripe.layout" :content="stripe" v-for="(stripe, index) in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
  </div>
</template>

<style lang="scss" scoped>
[course-product-trivia-carousel-component] {

  .trivia-carousel-item {
    min-height: calc(75vw * 0.5625 - 6rem);

    @include media-breakpoint-down(sm) {
      min-height: calc(100vh - #{$header-height-sm} - 6rem);
    }
  }

  .trivia-carousel__feedback {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    pointer-events: none;
    z-index: 1020;

    > div {
      color: $white;
      padding: 0.5rem 2rem;
      border-radius: 20px;

      &.bg-success {
        color: $dark !important;
      }
    }
  }

  .answer {
    font-size: 0.875rem;
    line-height: 1.25;
    cursor: pointer;
    position: relative;
    border: solid 1px #808080;
    overflow: hidden;
    transition: all $animation-time-fast linear;
    
    &:focus {
      outline: none !important;
      box-shadow: none;
    }
  }

  .answer:not(.not-answerable):hover {
    background-color: $black;
    color: $white;
  }

  .choose {
    box-shadow: inset 0px 0px 0px 2px $black;
  }


  @include media-breakpoint-down(xs) {
    .answer {
      font-size: 0.9rem;
    }
  }

  .trivia-carousel__navigation {
    top: 0;
    left: 0;
    z-index: 1;
    height: 60px;
  }
  
  .trivia-carousel__navigation-icon {
    font-size: 2.5rem;
    color: $dark;
  }

  .trivia-carousel-item ::v-deep [course-countdown-component] {
    .course-countdown-undo {
      color: $black;
    }

    .svg-container {

      .countdown-number {
        color: $black;
      }
    }
  }

}
</style>