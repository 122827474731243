<template>
  <div v-easyadmin="easyadminId" class="h-100" :class="[contentType, {'no-tabs': !showHeader}]" event-interactive-sidebar-component>
    <ul v-if="showHeader" class="nav nav-tabs py-3 justify-content-md-center border-bottom" id="myTab" role="tablist">
      <li class="nav-item px-2 position-relative" :class="{'nav-item--active': activeTab===tab}" role="presentation" v-for="(tab, index) in filteredTabs" :key="`tab-${index}`">
        <a 
          class="text-capitalize nav-link border-0 px-2 px-lg-3 d-flex justify-content-center align-items-center"
          :class="{'active': activeTab===tab}"
          :id="`${tab}-tab`"
          data-toggle="tab"
          :data-target="`#${tab}`"
          role="tab"
          :aria-controls="tab"
          aria-selected="true"
          @click="handleSelectedTab(tab)"
        >
          <i class="position-relative pr-1" :class="getTabIcon(tab)">
            <span v-if="tab===PLAYER_CONTENT.COMMENTS && newCommentsCount>0" class="badge badge-pill">{{newCommentsCount}}</span>
          </i>
          <span class="d-none d-lg-block">{{ tab }}</span>
        </a>
      </li>
    </ul>
    <div class="container">
      <div class="row h-100 justify-content-center">
        <div class="col-12" :class="{'col-md-8 col-lg-6': isStandalone}">
          <div v-show="activeDetailContentName===null" class="right-container__content d-flex flex-column w-100 hide-scrollbar">
            <div class="tab-content" id="myTabContent">
              <div v-if="filteredTabs.includes(PLAYER_CONTENT.COMMENTS) && activeTab === PLAYER_CONTENT.COMMENTS" class="tab-pane fade" :class="[{'show active': activeTab === PLAYER_CONTENT.COMMENTS}]" :id="PLAYER_CONTENT.COMMENTS" role="tabpanel" :aria-labelledby="`${PLAYER_CONTENT.COMMENTS}-tab`">
                <comments 
                  class="masked-element w-100 flex-grow-1 d-flex flex-column-reverse overflow-auto p-medium p-lg-double"
                  :content="content.comments"
                  :opt-container-content="content"
                  :reply="content.comments.users_reply || loggedUserCanComment"
                  :allow-anonymous-comments="!content.comments.registration_needed"
                  :is-banned="loggedUserBanned"
                  :show-more-button="false"
                  :show-date="false"
                  :showFeedback="false"
                  theme="light"
                  layout="live">
                </comments>
              </div>
              <div v-if="filteredTabs.includes(PLAYER_CONTENT.INFO) && activeTab === PLAYER_CONTENT.INFO" class="tab-pane fade tab-content-info" :class="[{'show active': activeTab === PLAYER_CONTENT.INFO}]" :id="PLAYER_CONTENT.INFO" role="tabpanel" :aria-labelledby="`${PLAYER_CONTENT.INFO}-tab`">
                <div class="container border-bottom py-3 py-md-4 px-medium px-md-double w-100">
                  <div class="row">
                    <div class="col-10 col-md-12">
                      <p class="small fs-xs mb-2">{{ content.title }}<span class="pl-3 text-muted text-capitalize">{{ startDate }}</span></p>
                      <h4 v-if="content.info.title" class="mb-0">{{ content.info.title }}</h4>
                    </div>
                  </div>
                </div>
                <component class="info-stripe px-medium px-md-double" :is="stripe.layout" :content="stripe" v-for="stripe in content.info.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
              </div>
              <div v-if="filteredTabs.includes(PLAYER_CONTENT.QUIZ) && content.quiz" class="tab-pane fade" :class="[{'show active': activeTab === PLAYER_CONTENT.QUIZ}]" :id="PLAYER_CONTENT.QUIZ" role="tabpanel" :aria-labelledby="`${PLAYER_CONTENT.QUIZ}-tab`">
                <player-quiz
                  v-if="activeTab === PLAYER_CONTENT.QUIZ"
                  :content="activeQuiz"
                  :opt-container-content="content"
                  :countdown-audio="audios.banner_countdown"
                ></player-quiz>
              </div>
              <div v-if="filteredTabs.includes(PLAYER_CONTENT.PRODUCTS)" class="tab-pane fade" :class="[{'show active': [PLAYER_CONTENT.PRODUCTS, PLAYER_CONTENT.WISHLIST].includes(activeTab)}]" :id="PLAYER_CONTENT.PRODUCTS" role="tabpanel" :aria-labelledby="`${PLAYER_CONTENT.PRODUCTS}-tab`">
                <products-list
                  v-if="[PLAYER_CONTENT.PRODUCTS, PLAYER_CONTENT.WISHLIST].includes(activeTab)"
                  :list="mappedProducts.items"
                  :list-type="activeTab"
                  :title="content.title"
                  :subtitle="activeTab == PLAYER_CONTENT.PRODUCTS ? mappedProducts.title : ft('globals.live_shopping.wishlist_title')"
                  :description="activeTab == PLAYER_CONTENT.PRODUCTS ? mappedProducts.description : ''"
                  :opt-container-content="containerContent"
                  :content="content"
                  @go-to-product="handleGoToDetails"
                  @user-interaction-updated="(data) => $emit('user-interaction-updated', data)"
                ></products-list>
              </div>
              <div v-if="filteredTabs.includes(PLAYER_CONTENT.CHAPTERS)" class="tab-pane fade" :class="[{'show active': activeTab === PLAYER_CONTENT.CHAPTERS}]" :id="PLAYER_CONTENT.CHAPTERS" role="tabpanel" :aria-labelledby="`${PLAYER_CONTENT.CHAPTERS}-tab`">
                <chapters-list
                  v-if="activeTab===PLAYER_CONTENT.CHAPTERS"
                  :content="content"
                  :opt-container-content="containerContent"
                  :chapters="content.play.chapters"
                  :current-time="eventTime"
                  :title="content.title"
                  :subtitle="ft('globals.live_shopping.chapters_title')"
                  @go-to-chapter="(chapter) => $emit('go-to-chapter', chapter)"
                ></chapters-list>
              </div>
              <div
                v-for="extraTab in extraTabs" 
                :key="`extra-tab-${extraTab}`"
                :id="extraTab"
                role="tabpanel"
                :aria-labelledby="`${extraTab}-tab`"
                class="tab-pane fade" 
                :class="[{'show active': activeTab === extraTab}]"
              >
                <slot :name="extraTab"></slot>
              </div>
            </div>
          </div>

          <div :class="{'visible': activeDetailContentName!==null}" class="right-container__detail hide-scrollbar d-flex flex-column w-100">
            <div class="tab-content">
              <product
                v-if="activeDetailContentName!==null"
                :content="rightDetailContent"
                :opt-container-content="containerContent"
                :animation-status="animationStatus"
                @back-to-list="handleBack"
                @buy-product="(data) => $emit('buy-product', data)"
                @user-interaction-updated="(data) => $emit('user-interaction-updated', data)"
              ></product>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { findLast } from 'lodash';

import { fandomBasicMixin, fandomExtendedMixin, fandomAuthorizationMixin } from 'src/modules/fandom_mixins_module.js';
import flashsaleMixin from 'src/modules/flashsale_module.js';
import { ActionCableComponentsMixin } from "src/modules/websocket_module.js";
import navigationMixin from 'src/modules/player/navigation_mixin';
import { PLAYER_CONTENT } from 'src/modules/player/constants';
import { BANNER_FLASHSALE_DEFAULT_DELTA_TIME } from 'src/modules/utils/constants';
import Comments from './comments';
import PlayerQuiz from './player/player-quiz';
import ProductsList from './products-list';
import Product from './product';
import ChaptersList from './chapters-list';


export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, fandomAuthorizationMixin, flashsaleMixin, navigationMixin, ActionCableComponentsMixin],
  components: {
    Comments,
    PlayerQuiz,
    ProductsList,
    Product,
    ChaptersList
  },
  props: {
    tabs: {
      type: Array,
      default: () => {return Object.values(PLAYER_CONTENT)}
    },
    extraTabs: {
      type: Array,
      default: () => {return []}
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showTab: {
      type: String,
      default: PLAYER_CONTENT.EMPTY
    },
    detailContentName: {
      type: String,
      default: null
    },
    eventTime: {
      type: Number
    },
    isStandalone: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      PLAYER_CONTENT,
      activeTab: this.showTab,
      activeDetailContentName: this.detailContentName,
      audios: {},
      products: [],
      newCommentsCount: 0
    };
  },
  mounted() {
    if (this.showTab === PLAYER_CONTENT.EMPTY) {
      this.activeTab = this.highlight === PLAYER_CONTENT.EMPTY ? this.filteredTabs[0] : this.highlight;
    }
    this.initAudios();
    this.initProducts();

    if (this.showHeader && this.filteredTabs.includes(PLAYER_CONTENT.COMMENTS)) {
      const contentName = this.getContentName(this.content.comments, this.content);
      Fandom.onEvent(`${this._uid}`, ({room, status, receivedData}) => {
        if (room !== contentName) {return;}
        if (status === 'received') {
          console.log('received', receivedData);
          if (this.activeTab !== PLAYER_CONTENT.COMMENTS) {
            this.newCommentsCount++;
          }
        }
      });

      this.subscribeWithEvent(this.content.comments, this.content);
    }
  },
  methods: {
    initProducts() {
      if (this.hasProducts) {
        this.products = (this.content?.products?.items ?? [])
          .filter(product => product.valid)
          .map(product => {
            const data = {...product, ...Fandom.getContent(product.content)}
            if (this.hasActiveFlashsale(product)) {
              const startWithDelta = moment(product.flashsale_start).add(product?.flashsale_pre_countdown ?? BANNER_FLASHSALE_DEFAULT_DELTA_TIME, "s");
              const showPreFlashsale = moment().isBetween(product.flashsale_start, startWithDelta);
              if (showPreFlashsale) {
                const duration = Math.ceil(moment.duration(startWithDelta.diff(moment())).asSeconds());
                data.showPreFlashsale = showPreFlashsale;
                setTimeout(()=>{
                  const content = this.products.find(item => item.$id === data.$id);
                  if (content) {
                    content.showPreFlashsale = false;
                  }
                }, duration*1000);
              }
            }

            return data;
          });
      }
    },
    initAudios() {
      if (this.content.banner_countdown_audio?.url) {
        this.createAudio('banner_countdown', this.content.banner_countdown_audio.url);
      }
    },
    createAudio(name, url) {
      this.audios[name] = new Audio(url);
      this.audios[name].volume = 0;
      this.audios[name].currentTime = 0;
      this.audios[name].volume = 1;
    },
    handleSelectedTab(tab) {
      if (tab !== this.activeTab) {
        this.checkNavigation(tab);
      }

      this.activeTab = tab;
      if (this.activeTab === PLAYER_CONTENT.COMMENTS) {
        this.newCommentsCount = 0;
      }
      this.$emit('selected-tab', tab);
    },
    getTabIcon(tab) {
      let iconName = "";
      switch (tab) {
        case PLAYER_CONTENT.QUIZ:
          iconName = "fa-comments-question-check";
          break;
        case PLAYER_CONTENT.COMMENTS:
          iconName = "fa-comment-alt";
          break;
        case PLAYER_CONTENT.WISHLIST:
          iconName = "fa-bookmark";
          break;
        case PLAYER_CONTENT.PRODUCTS:
          iconName = "fa-shopping-bag";
          break;
        case PLAYER_CONTENT.CHAPTERS:
          iconName = "fa-list";
          break;
        case PLAYER_CONTENT.INFO:
          iconName = "fa-circle-info";
          break;
        default:
          iconName = `fa-${tab}`;
      }

      return `${tab === this.activeTab ? "fas" : "fal"} ${iconName}`;
    }
  },
  computed: {
    theme() {
      return this.content.theme ? `${this.content.theme}-theme bg-${this.content.theme} navbar-${this.content.theme}` : "light-theme bg-light navbar-light";
    },
    activeQuiz() {
      return findLast(this.content.quiz ?? [], q => q.valid) ?? {};
    },
    hasProducts() {
      return Object.keys(this.content?.products ?? {}).length>0;
    },
    hasWishlist() {
      return this.hasProducts;
    },
    hasComments() {
      return Object.keys(this.content?.comments ?? {}).length>0;
    },
    hasChapters() {
      return (this.content?.play?.chapters ?? []).length>0;
    },
    hasQuiz() {
      return typeof this.content.quiz !== "undefined";
    },
    hasInfo() {
      return this.content?.info?.valid ?? false;
    },
    mappedProducts() {
      return {...this.content.products, items: this.products};
    },
    rightDetailContent() {
      return this.activeDetailContentName ? this.mappedProducts.items.find(item=>item.name === this.activeDetailContentName) : null;
    },
    highlight() {
      return this.content.highlight ?? PLAYER_CONTENT.EMPTY;
    },
    filteredTabs() {
      return [...this.extraTabs, ...this.tabs].filter(tab => {
        return this[`has${tab.charAt(0).toUpperCase() + tab.slice(1)}`] ?? true;
      })
    },
    startDate() {
      moment.locale(this.globalState.lang ?? 'en');
      return this.content.start_time ? moment(this.content.start_time).format("D MMMM - HH:mm") : '';
    }
  },
  watch: {
    'content.products': {
      handler() {
        this.initProducts();
      },
      deep: true
    },
    showTab(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.checkNavigation(newVal);
        this.activeTab = newVal;
        if (this.activeTab === PLAYER_CONTENT.COMMENTS) {
          this.newCommentsCount = 0;
        }
      }
    },
    detailContentName(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.checkDetailNavigation(newVal);
        this.activeDetailContentName = newVal;
      }
    },
    highlight(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.activeTab = this.content?.highlight ?? (this.isStandalone ? this.filteredTabs[0] : PLAYER_CONTENT.EMPTY);
        this.$emit("active-tab", this.activeTab);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
[event-interactive-sidebar-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);
  height: auto;
  width: 100%;
  
  --nav-height: 68px;

  &.no-tabs {
    --nav-height: 0px;
  }

  .nav {
    flex-wrap: nowrap;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
      
    &::-webkit-scrollbar { display: none; }

    &-item {
      &--active::after {
        content: "";
        height: 4px;
        background: black;
        position: absolute;
        margin-top: calc(1rem - 5px);
        left: 0;
        right: 0;
      }
    }
  }

  .nav-link {
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.5px;
    color: var(--gray);
    background: $white;
    outline: none;
    cursor: pointer;

    &.active {
      color: $black;
      font-weight: 600;
      outline: none;

      i {
        opacity: 0.75;
      }
    }

    .badge {  
      color: $white;
      background: $player-red-color;
      position: absolute;
      left: -9px;
      top: -7px;
      font-weight: 400;
      font-size: .5rem;
      padding: .4em .4em;
      min-width: 14px;
    }
  }

  .tab-content {
    height: 100%;

    .tab-pane {
      height: 100%;
    }

    ::v-deep {
      [comment] {
        .comment {
          &__with-parent {
            &::before, .parent-comment::before {
              background-color: var(--gray) !important;
            }
          }
        }

        .badge-light {
          background-color: var(--gray) !important;
          color: $white !important;
        }
      }

      [comment-add-component] {
        form {
          width: 100% !important;
        }
      }
    }
  }

  > .container {
    height: calc(100% - var(--nav-height));
  }

  .rounded {
    border-radius: $player-border-radius !important;
  }

  .right-container {
    &__content {
      position: absolute;
      overflow-x: hidden;
      z-index: 8;
      height: 100%;
      left: 0;
    }

    &__detail {
      position: absolute;
      overflow-x: hidden;
      overflow-y: scroll;
      height: 100%;
      left: 0;

      &.visible {
        z-index: 9;
        transform: none !important;
      }
    }
  }

  .info-stripe {
    ::v-deep {
      .container {
        padding: 0;
      }

      [class*="col-"] {
        width: 100% !important;
        max-width: 100%;
        flex: 0 0 100%;
      }

      [class*="row-cols-"] {
        &>* {
          width: 100% !important;
          max-width: 100%;
          flex: 0 0 100%;
        }
      }
    }
  }

  ::v-deep {
    [player-quiz-component] {
      [simple-form-component] {
        .container .row {
          padding-top: 0 !important;
        }

        [class*="col-"] {
          width: 100% !important;
          max-width: 100%;
          flex: 0 0 100%;
        }

        [class*="row-cols-"] {
          &>* {
            width: 100% !important;
            max-width: 100%;
            flex: 0 0 100%;
          }
        }
      }
    }
  }
}
</style>

