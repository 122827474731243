<template>
  <div 
    v-easyadmin="easyadminId"
    :class="contentType"
    :style="containerStyle"
    class="container py-3 py-md-4 px-medium px-md-double" 
    product-component
  >
    <div class="row pb-3">
      <div class="col-12">
        <div class="back cursor-pointer" @click="$emit('back-to-list')">
          <i class="fal fa-arrow-left"></i>
          <span class="pl-2">{{ ft(`globals.live_shopping.back_list`) }}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" v-if="!content.showPreFlashsale && !countdownEnded && hasActiveFlashsale(content)">
        <div class="flashsale-text pt-3 pb-2">
          <span class="flashsale-icon rounded-circle">
            <i class="fas fa-bolt text-white"></i>
          </span>
          <span class="text-uppercase px-1">{{ ft(`globals.live_shopping.flash_sale`) }}</span>
          <vue-countdown @end="handleCountdownEnd" :time="flashsaleDuration(content)" :transform="transform">
            <template #default="{minutes, seconds}"><span class="countdown rounded-pill text-white">{{minutes}}:{{seconds}}</span></template>
          </vue-countdown>
        </div>
      </div>
      <div class="col-12">
        <h3 class="mt-3 mb-0">{{ content.title }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="price py-3">
          <span class="price--full" :class="content.discount_price ? 'text-player-grey line-through' : 'font-weight-bold'">{{ content.price }}</span>
          <span :class="{'price--flashsale': !content.showPreFlashsale && !countdownEnded && hasActiveFlashsale(content)}" class="pl-2 font-weight-bold price--discount">{{ content.discount_price }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 pb-5">
        <intersect @leave="showFixedButtons = true" @enter="showFixedButtons = false">
          <div class="buttons-container d-flex">
            <a 
              :href="content.button.url"
              :target="content.button.new_tab ? '_blank' : '_self'"
              @click="buyProduct"
              class="btn btn-dark font-weight-bold mr-2 flex-grow-1"
            >
              <i class="far fa-shopping-cart pr-3"></i>{{ content.button.title }}
            </a>
            <button v-if="content.favourite" @click="toggleWishlist" class="btn btn-outline-dark font-weight-bold">
              <i :class="isProductFavourite ? 'fas' : 'fal'" class="fa-bookmark"></i>
              <span class="pl-3">{{ ft(`globals.live_shopping.${!isProductFavourite ? 'add_to_wishlist' : 'remove_from_wishlist'}`) }}</span>
            </button>
          </div>
        </intersect>
      </div>
    </div>
    <div class="row mx-nmedium mx-md-ndouble pb-5">
      <div class="col-12 px-0">
        <div class="flickity-carousel" ref="flickity-carousel">
          <div
            class="flickity-carousel-item justify-content-center"
            :class="`skin-${content.image_skin}`"
            v-for="(item, index) in content.product_images"
            :key="`carousel-slide-${content.id}-${index}`"
          >
            <img 
              class="img-fluid"
              :src="item.image.url"
              :alt="item.image.alt"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="text-muted" v-html="content.description"></p>
      </div>
    </div>
    <transition enter-active-class="animated slideInUp" leave-active-class="animated slideOutDown">
      <div ref="bottomActions" class="container fixed-bottom d-sm-none" v-show="showFixedButtons && animationStatus===ANIMATION_STATUS.FINISHED">
        <div class="row">
          <div class="col-12 px-0">
            <div class="fixed-buttons-container mx-2 rounded-top bg-white p-3 d-flex justify-content-between align-items-center">
              <a 
                :href="content.button.url"
                :target="content.button.new_tab ? '_blank' : '_self'"
                @click="buyProduct"
                class="mr-2 btn btn-dark font-weight-bold flex-grow-1"
              >
                <i class="far fa-shopping-cart pr-3"></i><span class="f-small">{{ content.button.title }}</span>
              </a>
              <button v-if="content.favourite" @click="toggleWishlist" class="btn btn-outline-dark font-weight-bold">
                <i :class="isProductFavourite ? 'fas' : 'fal'" class="fa-bookmark"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Flickity from "flickity";
import "flickity-imagesloaded";
import Intersect from 'vue-intersect';
import { isEqual, debounce } from 'lodash-es';

import flashsaleMixin from 'src/modules/flashsale_module.js';
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { favouriteInteractionMixin } from 'src/modules/favourite_interaction_module.js';
import { interactionMixin } from "src/modules/interactions_module.js";
import { ANIMATION_STATUS } from  'src/modules/player/animations';

export default {
  mixins: [fandomBasicMixin, flashsaleMixin, favouriteInteractionMixin, interactionMixin],
  components: {
    Intersect
  },
  props: {
    animationStatus: {
      type: String,
      default: ANIMATION_STATUS.NOT_STARTED
    }
  },
  data: function() {
    return {
      slider: null,
      showFixedButtons: false,
      countdownEnded: false,
      ANIMATION_STATUS
    }
  },
  mounted() {
    Vue.nextTick(() => {
      this.initCarousel();
    });

    window.addEventListener('resize', debounce(() => {
      this.slider?.reposition();
      this.slider?.resize();
    }, 500));
  },
  methods: {
    initCarousel() {
      const slideConfig =  {
        draggable: "> 1",
        prevNextButtons: false,
        pageDots: true,
        contain: true,
        cellAlign: "left",
        wrapAround: false,
        groupCells: true,
        adaptiveHeight: false,
        imagesLoaded: true
      }

      this.slider = new Flickity(this.$refs["flickity-carousel"], slideConfig);
      setTimeout(()=>{this.slider.resize()}, 400);
    },
    toggleWishlist() {
      this.updateFavouriteInteraction(this.content, this.content.favourite, this.content.favourite.interaction, this.containerContentFavouriteListId);
    },
    buyProduct() {
      this.$emit("buy-product", this.content);
    },
    handleCountdownEnd() {
      this.countdownEnded = true;
    },
  },
  computed: {
    isProductFavourite() {
      return this.content?.favourite?.interaction ? this.isFavouriteInListId(this.content, this.content.favourite.interaction, this.containerContentFavouriteListId) : false;
    },
    containerStyle() {
      return {
        'padding-bottom': this.showFixedButtons ? `${$(this.$refs.bottomActions).outerHeight()}px !important` : ''
      }
    }
  },
  watch: {
    content(newVal, oldVal) {
      if (newVal && !isEqual(newVal, oldVal)) {
        this.slider.destroy();
        Vue.nextTick(()=>this.initCarousel())
      }
    }
  }
}
</script>

<style lang="scss" scoped>
[product-component] {
  .back {
    font-size: .75rem;
    font-weight: 500;
    float: left;
  }

  .f-small {
    font-size: .9rem;
  }

  .line-through {
    text-decoration: line-through;
  }

  .btn {
    border-radius: $player-border-radius;
  }

  
  @media (max-width: 1650px) {
    .buttons-container {
      flex-direction: column;

      :first-child {
        margin-right: 0 !important;
        margin-bottom: 0.5rem !important;
      }
    }
  }
}

.flickity-carousel-item {
  width: 100%;
  overflow: hidden;
  padding: 2px;

  &.skin-square {
    width: 60%;
    max-width: 250px;

    @include media-breakpoint-down(sm) {
      width: 40%;
    }
  }

  &.skin-vertical {
    width: 60%;
    max-width: 163px;

    @include media-breakpoint-down(sm) {
      width: 40%;
    }
  }

  img {
    border-radius: $player-border-radius;
  }

  > div {
    position: relative;
  }
}

.flashsale {
  &-text {
    font-size: .5rem;
    font-weight: 600;
    color: $player-red-color;

    .countdown {
      padding: 2px .25rem;
      background-color: $player-red-color;
    }
  }

  &-icon {
    width: .875rem;
    height: .875rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $player-red-color;
  }
}

.price {
  &--full {
    font-size: 1rem;

    &.text-muted {
      color: $player-grey !important
    }
  }

  &--discount {
    font-size: 2rem;
  }

  &--flashsale {
    color: $player-red-color;
  }
}

.fixed-bottom {
  .fixed-buttons-container {
    box-shadow: 0px 0px 50px rgba(0, 0, 0, .3);
  }
}

</style>