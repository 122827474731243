<script>
import { get } from "lodash";
import { fandomTranslate } from "src/modules/common_module";

export default {
  data: function () {
    return {
      cookieName: `${globalState.pageModel.tenant.id}_pwa_install`,
      hideBanner: false,
      deferredPrompt: null
    };
  },
  mounted() {
    if (this.isStandAlone) {
      this.hideBanner = true;
      this.setCookie();
    } else {
      Vue.nextTick(() => {
        window.addEventListener('beforeinstallprompt', (event) => {
          event.preventDefault();
          Vue.set(this, 'deferredPrompt', event);
        });
      });
    }
  },
  computed: {
    backgroundColor() {
      return get(globalState, ["pageModel", "layout", "content", "pwa_banner_background_color"], 'var(--primary)');
    },
    bannerText() {
      const field_name = `pwa_banner_${this.agent}`;
      const defaultText = this.ft(`globals.${field_name}`);
      return get(globalState, ["pageModel", "layout", "content", field_name], defaultText);
    },
    showBanner() {
      let cookies = document.cookie.split("; ");
      let response = false;
      cookies.forEach((cookie) => {
        let cookieKey = cookie.split("=")[0];
        if (cookieKey == this.cookieName) {
          response = true;
        }
      });
      return !(response || this.hideBanner) && this.agent != "unknown" && !get(globalState, ["pageModel", "layout", "content", "hide_pwa_banner"], false);
    },
    agent() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /android/i.test(userAgent) ? 'android' : /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream ? 'ios' : 'unknown';
    },
    icon() {
      return get(globalState, ["pageModel", "layout", "content", "seo", "pwa", "icon", "url"], "javascript:void(0)");
    },
    isStandAlone() {
      const isInWebAppiOS = (window.navigator.standalone === true);
      const isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches);
      return isInWebAppiOS || isInWebAppChrome;
    }
  },
  methods: {
    ft: fandomTranslate,
    setCookie() {
      const date = new Date();
      date.setDate(date.getDate() + 7);
      document.cookie = `${this.cookieName}=true; expires=${date.toUTCString()};`;
    },
    setAppInstalled() {
      this.setCookie();
      this.hideBanner = true;
    },
    fireInstallation() {
      if (!!this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice
          .then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt');
            } else {
              console.log('User dismissed the A2HS prompt');
            }
            this.deferredPrompt = null;
            this.setAppInstalled();
          });
      }
    }
  }
};
</script>

<template>
  <div class="w-100 p-2" :style="{'background': backgroundColor}" v-if="showBanner && (!!deferredPrompt || agent != 'android')" pwa-banner-component>
    <div class="d-flex p-1 justify-content-between">
      <img :src="icon" class="icon"/>
      <div class="ml-2 pl-1" v-html="bannerText"></div>
      <div class="d-flex align-items-start justify-content-end">
        <i class="fal fa-times fa-lg cursor-pointer" @click="setAppInstalled()"></i>
      </div>
    </div>
    <div class="d-flex justify-content-end p-1" v-if="agent == 'android'">
      <button @click="fireInstallation" class="btn btn-light py-2">{{ft('globals.install')}}</button>
    </div>
  </div>
</template>

<style lang="scss">
[pwa-banner-component] {
  z-index: calc(#{$zindex-fixed} + 100);
  position: fixed;
  color: white;
  top: 0;

  p { margin: 0; }

  .icon {
    width: 3rem;
    height: 3rem;
    border-radius: 10px;
  }
}
</style>