import { interactionMixin } from "src/modules/interactions_module.js";
import { merge } from "lodash";
import postscribe from "postscribe"

const aux = {
	// videoContentIdToComponent: {}
};

function keys() {
	return globalState.kaltura;
}

function loadVideoApi(component) {
	const jsId = "kaltura-api"
	
	// aux.videoContentIdToComponent[component.videoContentId] = component;
	if (Fandom.executeOnceIds.hasOwnProperty(jsId)) {
		onVideoApiReady(component);
	} else {
		Fandom.executeOnce(jsId, () => {
			$("body").prepend(`<div id=\"${jsId}\"></div>`)

			const partnerId = keys().partnerId;
			const uiConfId = keys().uiConfId;

			const jsSrc = `https://cdnapi.kaltura.com/p/${partnerId}/sp/${partnerId}00/embedIframeJs/uiconf_id/${uiConfId}/partner_id/${partnerId}`;
			postscribe(`#${jsId}`, `<script src="${jsSrc}"></script>`, {
			 		done: () => { onVideoApiReady(component); }
				});
  	});
	}
}

function onVideoApiReady(component) {
	const uiConfId = keys().uiConfId;
	const wid = "_" + keys().partnerId;
	const entryId = component.videoContent.media_code;
	const mediaProxyEntry = {};

	if (component.videoInteractionsCuePoints) { 
		mediaProxyEntry["entryCuePoints"] = component.videoInteractionsCuePoints;
	}

	const flashvars = { // flashvars allows you to set runtime uiVar configuration overrides. 
		'autoPlay': false,
		'EmbedPlayer.WebKitPlaysInline': true,
		'mediaProxy': mediaProxyEntry
	}

	if (component.videoConfig.interactive) {
		// The system controls are hidden in the case of interactive video
		merge(flashvars, {
			'controlBarContainer': {
				'plugin': false
			}
		});
	}

	if (component.videoConfig.autoplay) {
		flashvars["autoPlay"] = component.videoConfig.autoplay;
	}

	merge(flashvars, component.flashvars);

	kWidget.embed({
			'targetId': component.videoContentId,
			'wid': wid,
			'uiconf_id': uiConfId,
			'entry_id': entryId,
			'flashvars': flashvars,
			'params': { // params allows you to set flash embed params such as wmode, allowFullScreen etc
				'wmode': 'transparent' 
			},
			'readyCallback': (playerId) => {
				Vue.set(component.videoPlayer, "ready", true);

				const kdp = document.getElementById(playerId);
				setupVideoPlayerPlayback(component, kdp);
				//const component = aux.videoContentIdToComponent[playerId];				

				kdp.kBind("firstPlay.fandom", () => {
					// Triggered once per content entry when first played. If user initiates a replay this is a new content playback sequence and will triger firstPlay again.
					if (typeof component.updatePlayInteraction == "function") { 
		        component.updatePlayInteraction(component.containerContent, component.videoContent, component.videoContent.interaction);
		      }
		      
		      if (component.videoConfig.interactive) {
						setChaptering(component);
					}
				});

				kdp.kBind("thirdQuartile.fandom", () => {
					// The player reached 75% of the entry playback
					if (typeof component.updatePlayThirdQuartileInteraction == "function" &&
						  component.videoContent.play_third_quartile) { 
						const playThirdQuartileContent = component.videoContent.play_third_quartile;
		        component.updatePlayThirdQuartileInteraction(component.containerContent, playThirdQuartileContent, playThirdQuartileContent.interaction);
		      }
				});

				kdp.kBind("playerPlayEnd.fandom", () => {
					// The played media has reached the end of content playback.
					Vue.set(component.videoPlayer, "end", true);
          Vue.set(component.videoPlayer, "playing", false);
          kdp.sendNotification("closeFullScreen");
				});

				kdp.kBind("playerUpdatePlayhead.fandom", (data, id) => {
					// An update event that notifies about the progress in time when playback is running
					// data = the player's progress time in seconds
					// id = the ID of the player that fired the notification
					if (component.videoConfig.interactive) {
						const progress = parseFloat((100 * data / component.videoPlayer.getDuration()).toFixed(3));
						Vue.set(component.videoPlayer, "progress", progress);
					}
				});

				kdp.kBind("cuePointsReceived.fandom", (cuePointsMap) => {
					console.log("loaded", cuePointsMap)
					// Notification fired when the player has successfully loaded an entry's cue-point configuration
				});

				kdp.kBind("openFullScreen.fandom", () => {
					// Player entered full screen mode
					Vue.set(component.globalState.kaltura, "hasOpenedFullScreen", true);
					$("body").addClass("kaltura-hasOpenedFullScreen");
				});

				kdp.kBind("closeFullScreen.fandom", () => {
					// Player exited full screen mode
					Vue.set(component.globalState.kaltura, "hasOpenedFullScreen", false);
					$("body").removeClass("kaltura-hasOpenedFullScreen");
				});

				kdp.kBind("mute.fandom", () => {
					// Notification fired when the Player is muted
					Vue.set(component.videoPlayer, "mute", true);
				});

				kdp.kBind("unmute.fandom", () => {
					// PNotification fired when the Player is unmuted
					Vue.set(component.videoPlayer, "mute", false);
				});

				kdp.kBind("cuePointReached.fandom", (cuePoint) => {
					// Notification fired when the player reaches a cuePoint
					const interactionId = cuePoint.cuePoint.description.interactionId;
					const interactionRecord = component.interactionIdToRecord[interactionId];

					// workaround to prevent the handler is called each time the user drag the progress bar
					const currentTime = parseInt(kdp.evaluate("{video.player.currentTime}")) * 1000;
					if (currentTime == cuePoint.cuePoint.startTime) {
						component.overvideoInteraction = interactionRecord;
					}
				});

        kdp.kBind("playerPlayed.fandom", () => {
          // Triggered when the player enters a play state. This event be triggered multiple times during a single playback session. For example, playerPlayed will be triggered between ads and when the user plays content after pausing it.
          Vue.set(component.videoPlayer, "playing", true);
        });

        kdp.kBind("playerPaused.fandom", () => {
          //The player is now in pause state
          Vue.set(component.videoPlayer, "playing", false);
        });
			}
		});
}

function setChaptering(component) {
	const overvideoInteractions = component.videoContent.overvideo_interactions;
	if (overvideoInteractions) {
		const chaptering = [];
		
		overvideoInteractions.forEach(interactionRecord => {
			const interactionId = interactionRecord.interaction.id;
			const position = `${((interactionRecord.seconds * 100) / component.videoPlayer.getDuration()).toFixed(2)}%`;
			chaptering.push({
        id: interactionId,
        interaction: interactionRecord,
        position: position,
        active: false,
        seconds: interactionRecord.seconds - 0.5
      });
		});

		Vue.set(component.content, "chaptering", chaptering);
	} else {
		// nothing to do
	}
}

function setupVideoPlayerPlayback(component, kdp) {
	component.videoPlayer.play = () => {
		kdp.sendNotification("doPlay");
	}

	component.videoPlayer.pause = () => {
		kdp.sendNotification("doPause");
	}

	component.videoPlayer.getDuration = () => {
		return kdp.evaluate('{duration}');
	}

	component.videoPlayer.skipToSecond = (second) => {
		if (component.overvideoInteraction) {
			component.overvideoInteraction = null;
		}
		kdp.sendNotification("doSeek", second);
	}

	component.videoPlayer.changeVolume = (volume) => {
		kdp.sendNotification("changeVolume", volume);
	}
}

function initVideoPlayer(component, videoContent) {
  loadVideoApi(component);
};

const videoInteractiveMixin = {
	mixins: [interactionMixin],
	data() {
    return {
    	overvideoInteraction: null
    };
  },
  watch: {
  	overvideoInteraction(newValue, previousValue) {
      if (Fandom.exists(newValue)) {
        this.videoPlayer.pause();
      } else if (Fandom.exists(previousValue) && !Fandom.exists(newValue)) {
        this.videoPlayer.play();
      }
    },
  },
	computed: {
		interactionIdToRecord() {
			const result = {};
			const overvideoInteractions = this.videoContent.overvideo_interactions;
			if (overvideoInteractions) {
				overvideoInteractions.forEach(interactionRecord => {
					const interactionId = interactionRecord.interaction.id;
					result[interactionId] = interactionRecord
				});
			} else {
				// nothing to do
			}
			return result;
		},
		videoInteractionsCuePoints() {
			const cuePoints = []
			const overvideoInteractions = this.videoContent.overvideo_interactions;
			if (overvideoInteractions) {
				overvideoInteractions.forEach(interactionRecord => {
					const interactionId = interactionRecord.interaction.id;
					const description = { interactionId: interactionId };
					const startTime = interactionRecord.seconds * 1000;
					cuePoints.push({
						"cuePointType": "codeCuePoint.Code",
						"startTime": startTime,
						"description": description,
					})
				});
			} else {
				// nothing to do
			}
			return cuePoints;
		}
	}
}

const videoBasicMixin = {
	mixins: [interactionMixin],
  data() {
    return {
    	// videoContent required
    	videoPlayer: {},
    	flashvars: {}
    };
  },
  mounted() {
    Vue.nextTick(() => {
      initVideoPlayer(this, this.videoContent);
    });
  },
  beforeDestroy() {
  	kWidget.destroy(this.videoContentId);
  },
  computed: {
  	videoConfig() {
  		return this.videoContent.videoConfig || {};
  	},
  	videoContentId() {
  		let result = `videoplayer__id_${this.containerContent.id}`;
  		if (this.videoContent.$id) {
  			result += `_${this.videoContent.$id}`;
  		}
  		return result;
  	}
  },
  methods: {
  }
};

export { 
  videoBasicMixin,
  videoInteractiveMixin
};
