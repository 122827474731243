<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  props: {
    inheritTheme: String
  },
  created() {
    if (this.inheritTheme && !this.content.theme) {
      Vue.set(this.content, "theme", this.inheritTheme);
    }
  },
  methods: {
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
    getDateString(date) {
      const options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' };
      return date.toLocaleDateString('it-IT', options);
    }
  },
  computed: {
    href() {
      return this.content.slug ?? this.content.url?.url ?? 'javascript:void(0)';
    },
    /**
     * Computes in which state the tile-related event is.
     * 
     * @returns
     *    - 'live' if the event is ongoing
     *    - 'coming' if the event is to be done
     *    - 'past' if it is a past event
     *    - 'unknown' if no dates are provided
     */
    eventState() {
      if (this.content.valid_from && this.content.valid_to) {
        const from = this.fromDate;
        const to = this.toDate;
        const now = new Date();
        if (now >= from && now <= to)
          return 'live';
        else if (now > to)
          return 'past';
        else
          return 'coming';
      }
      return 'unknown';
    },
    fromDate() {
      return new Date(this.content.valid_from);
    },
    toDate() {
      return new Date(this.content.valid_to);
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="contentType" tile-event-component>
    <a :href="href" :target="getTarget(content.url)">
      <div class="ar-16by9 position-relative bg-transition">
        <div class="position-absolute-center bg bg-cover-center" :style="{'background-image': getContentResponsiveImageURL('thumbnail')}"></div>
        <div class="position-absolute rounded d-flex bg-red text-white text-uppercase align-items-center py-2 px-4 m-4" v-if="eventState == 'live'">
          <svg class="live-icon mr-2">
            <use xlink:href="#live-icon"></use>
          </svg>
          <span class="font-weight-bold">live</span>
        </div>
      </div>
      <div class="p-4 text-section" :style="{'background-color': (!content.theme || content.theme == 'light' ? 'var(--gray-200)' : 'var(--gray-1000)')}">
        <!-- <div v-if="eventState != 'unknown'" class="mb-3" :class="eventState == 'past' ? 'text-gray-600' : 'text-red'">
          <span v-if="eventState == 'past'" class="d-flex align-items-center">
            <svg class="title-icon ondemand mr-2">
              <use xlink:href="#ondemand-icon"></use>
            </svg>
            On Demand
          </span>
          <span class="d-flex align-items-center" v-else>
            <svg class="title-icon calendar mr-2">
              <use xlink:href="#calendar-icon"></use>
            </svg>
            {{ getDateString(fromDate) }}
          </span> 
        </div> -->
        <h4 v-if="content.title">{{content.title}}</h4>
        <div v-if="content.description" v-html="content.description"></div>
      </div>
      <div class="px-4 py-2" v-if="content.category" :style="{'background-color': (!content.theme || content.theme == 'light' ? 'var(--gray-300)' : '#1a1a1a')}">
        <div class="py-1">{{content.category}}</div>
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
[tile-event-component] {
  font-family: $font-family-headings;
  font-size: $h5-font-size;

  .live-icon {
    width: 19px;
    height: 19px;
  }

  .title-icon {
    width: 1.5rem;
    height: 1.5rem;

    &.ondemand {
      stroke: var(--gray-600);
      opacity: .5;
    }
  }

  a {
    color: var(--theme-color) !important;
  }

  .text-section {
    min-height: 13rem;
  }
}
</style>