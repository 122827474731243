// this module is used both in frontend and in easyadmin

// Return the translation by path from globalState in lang.
function fandomTranslate(key, replacements = {}, lang=globalState.lang) {
  if (!lang) {
    return "translation error, missing lang";
  } else {
    return globalState.i18n.t(key, lang, replacements);
  }
}

export { fandomTranslate };
