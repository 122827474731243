<template>
  <div class="player-controls pt-2">
    <div class="left-controls">
      <button v-if="showPlayPause && controls.includes('play')" class="play-button player-button" title="Play" @click="togglePlay">
        <i class="far" :class="iconPlayPause"></i>
      </button>
      <div class="volume-container d-flex align-items-center justify-content-start">
        <button v-if="controls.includes('mute')" class="mute-button player-button" title="Mute" @click="toggleMute">
          <i class="far" :class="iconMuteUnmute"></i>
        </button>
        <vue-slider
          name="volume"
          class="volume-slider py-0"
          :bg-style="sliderStyle"
          :real-time="true"
          process-class="bg-primary"
          :tooltip="false"
          :value="rawVolume"
          @input="changeVolume"
          :min="0"
          :max="1"
          :interval="0.05"
        ></vue-slider>
      </div>
    </div>
    <div class="right-controls">
      <button v-if="controls.includes('chapters') && chapters!==null" class="chapters-button player-button" title="chapters" @click="$emit('toggle-right-content', PLAYER_CONTENT.CHAPTERS)">
        <i class="far fa-list"></i>
      </button>
      <button v-if="enablePipButton && controls.includes('pip')" class="pip-button player-button" title="pip" @click="togglePip">
        <i class="far fa-bring-forward"></i>
      </button>
      <button v-if="controls.includes('fullscreen')" class="fullscreen-button player-button" title="fullscreen" @click="toggleFullscreen">
        <i class="far" :class="fullscreen ? 'fa-compress' : 'fa-expand'"></i>
      </button>
      <button v-if="controls.includes('settings') && !isYouTube" class="settings-button player-button d-none d-md-block" title="settings" @click="toggleSettings">
        <i class="far fa-cog"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { PLAYER_STATUS, PRESETS_TYPE, PROVIDERS, PLAYER_CONTENT } from 'src/modules/player/constants';
import config from 'src/modules/player/config';
import { pip, browser } from 'src/modules/player/utils';
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import vueSlider from 'vue-slider-component';

export default {
  mixins: [fandomBasicMixin],
  components: {
    vueSlider
  },
  props: {
    preset: {
      type: String,
      default: "default",
      validator: (value) => {
        return Object.values(PRESETS_TYPE).includes(value.toLowerCase())
      }
    },
    status: {
      type: String,
      required: true,
      validator: (value) => {
        return Object.values(PLAYER_STATUS).includes(value.toLowerCase())
      }
    },
    muted: {
      type: Boolean,
      required: true
    },
    volume: {
      type: Number,
      required: true
    },
    activeControls: {
      type: Array, // array of PLAYER_CONTENT
      default: () => {return []}
    },
    fullscreen: {
      type: Boolean,
      required: true
    }
  },
  data: function() {
    return {
      rawStatus: this.status,
      PLAYER_CONTENT,
      controls: config.presets[this.preset],
      hideVolumeRange: browser.isIPhone,
      rawVolume: this.volume,
      sliderStyle: {
        "background-color": "rgba(255, 255, 255, 0.2)",
        "border-radius": "2px"
      }
    }
  },
  methods: {
    isPipSupported: pip,
    togglePip() {
      this.$emit("toggle-pip");
    },
    togglePlay() {
      this.$emit("toggle-play");
    },
    toggleMute() {
      this.$emit("toggle-mute");
    },
    toggleFullscreen() {
      this.$emit("toggle-fullscreen");
    },
    toggleSettings() {
      this.$emit("toggle-settings");
    },
    changeVolume(value) {
      // Already muted, we don't need to emit "change-volume" event 
      if (value === 0 && this.muted) {
        return;
      }

      this.rawVolume = value;
      this.$emit("change-volume", value);
    }
  },
  computed: {
    isYouTube() {
      return this.content.media_type === PROVIDERS.YOUTUBE;
    },
    iconPlayPause() {
      return this.rawStatus === PLAYER_STATUS.PLAYING ? 'fa-pause' : 'fa-play';
    },
    iconMuteUnmute() {
      return this.muted ? 'fa-volume-slash' : 'fa-volume';
    },
    chapters() {
      return this.content?.chapters ?? null;
    },
    enablePipButton() {
      return this.isPipSupported() && [PROVIDERS.HTML5, PROVIDERS.IVS].includes(this.content.media_type)
    },
    showPlayPause() {
      return ![PLAYER_STATUS.NOT_AVAILABLE, PLAYER_STATUS.LIVESTREAMOFFLINE].includes(this.rawStatus);
    }
  },
  watch: {
    volume(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.rawVolume = newVal;
      }
    },
    status(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.rawStatus = newVal;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.player-controls {
  display: flex;
  height: 100%;
  font-size: 1.3rem;

  button {
    touch-action: manipulation;
    transition: opacity .1s ease-in-out;
    opacity: 0.9;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);

    &:hover {
      opacity: 1;
    }
  }

  .left-controls {
    display: flex;
    flex: 1;
    overflow: hidden;
    align-items: flex-end;

    button {
      padding: 0 25px;
    }
  }

  .right-controls {
    display: flex;
    align-items: flex-end;

    button {
      width: 40px;
      padding: 0;
      margin: 0 7px;

      &:last-child {
        margin-right: 0;
      }

      i {
        opacity: 0.9;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, .5);
        font-weight: 400 !important;
      }
    }
  }

  .player-button {
    cursor: pointer;
    max-width: 46px;
    padding: 0 0 0 14px;
    border: none;
    background-color: transparent;
    color: $white;

    &:focus {
      outline: none;
    }

    &:first-child {
      padding-left: 0;
    }
  }

  .volume-container {
    flex: 1;

    .volume-slider {
      max-width: 120px !important;
      width: 100% !important;

      @include media-breakpoint-down(sm) {
        display: none !important;
      }

      ::v-deep .vue-slider {
        cursor: pointer;
      }
    }
  }
}
</style>