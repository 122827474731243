import { isInteractionDone } from 'src/modules/interactions_module.js';
import { get } from 'lodash';

const POLLING_TIMING = 60;

const qualityMap = {
  hd1080: '1080p',
  hd720: '720p',
  large: '480p',
  medium: '360p',
  small: '240p',
  tiny: '144p',
}

const aux = { // TODO: move some key into videoPlayer object in component
  youtubeActiveComponent: null,
  youtubeIframeAPIReady: false,
  youtubeVideoInitQueue: [],
  youtubePlayerIdToComponent: {},
  contentIdsYoutubePlayerStarted: [],
  contentIdToYouTubePlayerReady: {},
  contentIdToYoutubeModuleConfig: {},
  youtubePollingInterval: null,
  youtubePollingLocked: [],
  contentIdToInteractionFeedbackTimeout: {},
  contentIdToUnlockYoutubePollingTimeout: {}
};

const youtubeModuleConfig = {
  autoplay: 0, // Warning: to allow mobile devices to go into autoplay set the video as mute.
  mute: 0,
  vcode: null, // Custom vcode.
  controls: 0,
  reviewInteractionFeedbackDuration: 3000, // In 2nd visualization.
  progressBar: true, // Progress bar present. The enabled progress bar requires requires polling.
  preventInteractionTimeout: false,
  interactionFeedbackTime: 3000
};

function getYoutubeModuleConfig(contentId) {
  return aux.contentIdToYoutubeModuleConfig[contentId];
}

function setYoutubeModuleConfig(contentId, config) {
  return aux.contentIdToYoutubeModuleConfig[contentId] = $.extend({}, youtubeModuleConfig, config);
}

function loadYoutubeApi() {
  if (typeof window.YT === "undefined") {
    // Add compatibility with new Youtube provider class
    Fandom.executeOnce("youtube-api", () => {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    });
  } else {
    youtubeReady();
  }
};

function youtubeReady() {
  aux.youtubeIframeAPIReady = true; // Set flag to track youtube api ready.
  $.each(aux.youtubeVideoInitQueue, function(index, component) {
    createYoutubeVideoPlayer(component);
  });
};

window.onYouTubeIframeAPIReady = youtubeReady;

window.onYouTubePlayerReady = function(event) {
  const component = aux.youtubePlayerIdToComponent[event.target.getIframe().id];
  setChaptering(component);

  // Used as reactive
  Vue.set(aux.contentIdToYouTubePlayerReady, component.contentId, true);
  Vue.set(component, "videoPlayerState", -1);
  component.$emit('youtube:ready', component.contentId);
  if (typeof component.videoReadyCallback == "function") { 
    component.videoReadyCallback();
  }
};

function getSeconds(interaction) {
  return Fandom.exists(interaction.seconds) ? parseInt(interaction.seconds) : 0;
}

function orderBySeconds(a, b) {
  return getSeconds(a) - getSeconds(b);
}

function getOvervideoInteractions(content) {
  if(Fandom.exists(content.play.overvideo_interactions) || Fandom.exists(content.overvideo_interactions)) {
    return (content.play.overvideo_interactions || content.overvideo_interactions).sort(orderBySeconds);
  } else {
    return null;
  }
}

function setChaptering(component) {
  const content = component.content;
  const overvideoInteractions = getOvervideoInteractions(content);
  if (overvideoInteractions) { // Content has overvideo interactions.
    Vue.set(content, "chaptering", []);
    overvideoInteractions.forEach(interaction => {
      const seconds = (interaction.seconds === "end") ? parseInt(component.videoPlayer.getDuration()) : parseInt(interaction.seconds);
      const position = `${((parseInt(seconds) * 100) / parseInt(component.videoPlayer.getDuration())).toFixed(2)}%`;

      content.chaptering.push({
        id: interaction.interaction.id,
        interaction: interaction,
        position,
        active: false,
        seconds,
        icon: interaction.icon
      });
    });

    // Legacy
    if (content.end_interaction) {
      content.chaptering.push({
        id: content.end_interaction.interaction.$id,
        interaction: content.end_interaction.interaction,
        position: "100%",
        active: false,
        seconds: (component.videoPlayer.getDuration() - 0.5) // 0.5 offset permits progress bar update
      });
    }
  }
}

function setChapterByInteractionId(component, id) {
  if (Fandom.exists(component.content.chaptering)) {
    component.content.chaptering.forEach(interaction => interaction.active = interaction.id === id);
  }
}

function isPollingRequired(component) {
  // Polling is needed not only for the control of interactions but as a listener of the controls
  return true;
}

function mayStartYoutubeVideoPolling(component) {
  if (isPollingRequired(component)) {
    maySuspendYoutubeVideoPolling(component);
    aux.youtubeActiveComponent = component;
    aux.youtubePollingInterval = setInterval(() => {
      youtubeVideoPolling();
    }, POLLING_TIMING);
  }
}

function maySuspendYoutubeVideoPolling(component) {
  if (isPollingRequired(component)) {
    clearInterval(aux.youtubePollingInterval);
    delete aux.youtubePollingInterval;
    aux.youtubeActiveComponent = null;
  }
};

function youtubeVideoPolling() {
  let component = aux.youtubeActiveComponent;
  updateYoutubeProgressBar(component);
  if (!isYoutubePollingLocked(component)) {
    const currentTime = parseInt(component.videoPlayer.getCurrentTime());
    const duration = parseInt(component.videoPlayer.getDuration());
    let overvideoInteraction = component.overvideoInteractionSecondsToInteraction[currentTime];

    if (overvideoInteraction) {
      aux.youtubePollingLocked.push(component.contentId);
      setOvervideoInteraction(component, overvideoInteraction);
      setChapterByInteractionId(component, overvideoInteraction.$id);
      
      if (!overvideoInteraction.prevent_pause) {
        console.log(component)
        component.videoPlayer.pause();
      }
    }
  }
}

function isYoutubePollingLocked(component) {
  return aux.youtubePollingLocked.indexOf(component.contentId) > -1;
}

function stopYoutubeVideoPlayer(component) {
  prepareVideoToSkip(component, () => {
    component.videoPlayer.stopVideo();
    removeContentIdYoutubePlayerStarted(component.contentId, component);
    maySuspendYoutubeVideoPolling(component);
  });
}

function prepareVideoToSkip(component, skipFun) {
  if (Fandom.exists(component.content.chaptering)) {
    component.content.chaptering.forEach(interaction => interaction.active = false);
  }
  component.overvideoInteraction = null;
  clearInteractionFeedbackTimeout(component);

  if (!isYoutubePollingLocked(component)) {
    aux.youtubePollingLocked.push(component.contentId);
  }

  toggleEndVideo(component, false);
  component.feedback = false;

  skipFun();

  // Timeout used to prevent seek execution time
  unlockYoutubePolling(component, 500);
}

function skipToPreviousInteraction() {
  prepareVideoToSkip(this, () => {
    const youtubePlayerCurrentTime = Math.floor(this.videoPlayer.getCurrentTime());
    let secondToSeek = 0;
    let index = getOvervideoInteractions(this.content).length - 1;
    let previousInteraction = null;
    let overvideoInteraction = null;
    
    do {
      /// overvideoInteractions ordered by second from backoffice
      overvideoInteraction = getOvervideoInteractions(this.content)[index--];
      if (overvideoInteraction && overvideoInteraction.seconds < youtubePlayerCurrentTime) {
        secondToSeek = overvideoInteraction.seconds;
        previousInteraction = overvideoInteraction;
      }
    } while (!(previousInteraction) && overvideoInteraction);

    // Seek and play
    this.videoPlayer.play();
    this.videoPlayer.seek(secondToSeek);
  });
}

function skipToNextInteraction() {
  prepareVideoToSkip(this, () => {
    const youtubePlayerCurrentTime = Math.floor(this.videoPlayer.getCurrentTime());
    let secondToSeek = this.videoPlayer.getDuration() - 0.5; // 0.5 offset permits progress bar update
    let index = 0;
    let nextInteraction = null;
    let overvideoInteraction = null;

    do {
      // overvideoInteractions ordered by second from backoffice
      overvideoInteraction = getOvervideoInteractions(this.content)[index++];
      if (overvideoInteraction && overvideoInteraction.seconds > youtubePlayerCurrentTime) {
        secondToSeek = overvideoInteraction.seconds;
        nextInteraction = overvideoInteraction;
      }
    } while (!(nextInteraction) && overvideoInteraction);

    // Seek and play
    this.videoPlayer.play();
    this.videoPlayer.seek(secondToSeek);
  });
}

function setOvervideoInteraction(component, overvideoInteraction) {
  Vue.set(component, 'overvideoInteraction', overvideoInteraction);
  if (overvideoInteraction.prevent_pause) {
    var timeout = parseFloat(overvideoInteraction.max_seconds_to_interact || 3) * 1000;
    aux.contentIdToInteractionFeedbackTimeout[component.contentId] = setTimeout(function() {
      component.overvideoInteraction = null;
      unlockYoutubePolling(component, 1500);
    }, timeout);
  } else if (isInteractionDone(component.containerContent, overvideoInteraction.interaction) && !component.youtubeConfig.preventInteractionTimeout) {
    aux.contentIdToInteractionFeedbackTimeout[component.contentId] = setTimeout(function() {
      unlockYoutubePolling(component, 1500, component.overvideoInteraction.seconds);
      component.videoPlayer.play();

      setChapterByInteractionId(component, -1);
      component.overvideoInteraction = null;
    }, getYoutubeModuleConfig(component.contentId).reviewInteractionFeedbackDuration);
  }
}

function updateYoutubeProgressBar(component) {
  const content = component.content;
  if (!component.suspendProgressBarUpdating) {
    Vue.set(component.videoPlayer, "muted", component.videoPlayer.isMuted());

    const youtubePlayerProgress = parseFloat((100 * component.videoPlayer.getCurrentTime() / component.videoPlayer.getDuration()).toFixed(3));
    // When the video is not stable yet it may happen that the getCurrentTime value is lower than the previous one.    
    if (content.youtubePlayerProgress) {
      const stabilization = (content.youtubePlayerProgress - 1) < youtubePlayerProgress && youtubePlayerProgress < content.youtubePlayerProgress;
      if (stabilization) {
        const delta = POLLING_TIMING / 100 / component.videoPlayer.getDuration();
        Vue.set(component.videoPlayer, "progress", (parseFloat(content.youtubePlayerProgress) + delta).toFixed(3));
        Vue.set(content, "youtubePlayerProgress", component.videoPlayer.progress); // legacy
        
      } else {
        Vue.set(component.videoPlayer, "progress", youtubePlayerProgress);
        Vue.set(content, "youtubePlayerProgress", component.videoPlayer.progress); // legacy
      }
    } else {
      Vue.set(component.videoPlayer, "progress", youtubePlayerProgress);
      Vue.set(content, "youtubePlayerProgress", component.videoPlayer.progress); // legacy
    }
  }
}

window.onYoutubePlayerStateChange = function(event) {
  var component = aux.youtubePlayerIdToComponent[event.target.getIframe().id];
  var content = component.content;
  var videoPlayerState = component.videoPlayer.getPlayerState();

  Vue.set(component, "videoPlayerState", videoPlayerState);

  switch (videoPlayerState) {
  case -1: // not started
    // Vue.set(component.videoPlayer, "playing", false);
    break;
  case 1: // playing
    Vue.set(component.videoPlayer, "playing", true);
    component.videoEnded = false;
    mayStartYoutubeVideoPolling(component);
    if (!isYoutubePlayerStarted(component.contentId)) {
      Vue.set(component.videoPlayer, "firstPlay", true);
      aux.contentIdsYoutubePlayerStarted.push(component.contentId);
      // check if in component updatePlayInteraction is defined
      if (typeof component.updatePlayInteraction == "function") { 
        component.updatePlayInteraction(component.containerContent, content.play, content.play.interaction);
      }

      Vue.set(component.videoPlayer, "availableQualities", []);
      // NB - setPlaybackQuality function not working 
      // retrive video qualities
      /*
      let qualities = component.videoPlayer.getAvailableQualityLevels();
      qualities = qualities
        .filter(item => item !== 'auto')
        .map(item => {
          return {key: item, name: qualityMap[item]};
        })
      Vue.set(component.videoPlayer, "availableQualities", qualities);
      */
    }
    break;
  case 2: // paused
    maySuspendYoutubeVideoPolling(component);
    Vue.set(component.videoPlayer, "playing", false);
    break;
  case 0: // ended
    Vue.set(component.videoPlayer, "playing", false);
    removeContentIdYoutubePlayerStarted(component);
    maySuspendYoutubeVideoPolling(component);

    const overvideoInteraction = component.content.end_interaction || component.overvideoInteractionSecondsToInteraction["end"];
    if (overvideoInteraction) {
      Vue.set(component, 'overvideoInteraction', overvideoInteraction);
    } else {
      toggleEndVideo(component, true);
    }

    break;
  default:
    // nothing to do
  }
};

function toggleEndVideo(component, activation) {
  component.videoEnded = activation; // legacy
  Vue.set(component.videoPlayer, "end", activation);
}

function initYoutubeVideoPlayer(component, config) {
  const content = component.content;

  loadYoutubeApi();
  setYoutubeModuleConfig(component.contentId, config);

  Vue.set(content, "youtube_id", "yt__video__" + component.contentId);

  Vue.nextTick(function() {
    if (aux.youtubeIframeAPIReady) {
      createYoutubeVideoPlayer(component);
    } else {
      aux.youtubeVideoInitQueue.push(component);
    }

    component.overvideoInteractionSecondsToInteraction = getOvervideoInteractionSecondToInteraction(getOvervideoInteractions(content));
  });
};

function getOvervideoInteractionSecondToInteraction(overvideoInteractions) {
  var overvideoInteractionSecondsToInteraction = {};
  if (overvideoInteractions) {
    overvideoInteractions.forEach(interaction => {
      overvideoInteractionSecondsToInteraction[interaction.seconds] = interaction;
    });
  }
  return overvideoInteractionSecondsToInteraction;
}

function isMobile() {
  return globalState.viewport.xs;
}

function createYoutubeVideoPlayer(component) {
  var content = component.content;

  if (Fandom.exists(getYoutubeModuleConfig(component.contentId).vcode)) {
    var vcode = getYoutubeModuleConfig(component.contentId).vcode;
  } else {
    var vcode = isMobile() && !!content.play.media_code_mobile ? content.play.media_code_mobile : content.play.media_code;
  }

  aux.youtubePlayerIdToComponent[content.youtube_id] = component;
  component.videoPlayer = new YT.Player(content.youtube_id, {
    playerVars: {
      html5: 1,
      fs: 0,
      controls: getYoutubeModuleConfig(component.contentId).controls,
      disablekb: 0,
      cc_load_policy: getYoutubeModuleConfig(component.contentId).cc_load_policy || 0,
      rel: 0,
      wmode: "transparent",
      showinfo: 0,
      playsinline: 1,
      modestbranding: 1,
      autoplay: getYoutubeModuleConfig(component.contentId).autoplay,
      mute: getYoutubeModuleConfig(component.contentId).mute
    },
    height: "100%",
    width: "100%",
    videoId: vcode,
    events: {
      "onReady": onYouTubePlayerReady,
      "onStateChange": onYoutubePlayerStateChange
    },
  });

  const player = component.videoPlayer;

  player._play = function() {
    player.playVideo();
  };
  player.play = player._play; // Alias ​​to ensure backward compatibility

  player._pause = function() {
    player.pauseVideo();
  };
  player.pause = player._pause; // Alias ​​to ensure backward compatibility

  player._seek = function(time, autoplay) {
    // TODO: configure autoplay
    player.seekTo(time, true);
  };
  player.seek = player._seek; // Alias ​​to ensure backward compatibility

  player._skipToSecond = function(second) {
    component.skipToSecond(second);
  };
  player.skipToSecond = player._skipToSecond; // Alias ​​to ensure backward compatibility

  player._toggleEndVideo = function() {
    toggleEndVideo(component, true);
  };

  player._changeVolume = function(event) {
    const volumeValue = parseFloat(event.target.value);
    if (volumeValue === 0) {
      player._mute();
    } else {
      player._unMute();
      player.setVolume(volumeValue);
    }
  };

  player._mute = () => {
    Vue.set(player, 'muted', true);
    player.previousVolume = player.getVolume();
    player._setVolume(0);
    player.mute();
  },
  player._unMute = () => {
    Vue.set(player, 'muted', false);
    player.unMute();
    player._setVolume(player.previousVolume);
  }

  player._toggleMute = function() {
    player.isMuted() ? player._unMute() : player._mute();
  }

  player._isPaused = function() {
    return component.videoPlayerState !== 1;
  };

  player._isReady = () => {
    return player.isYoutubePlayerReady;
  }

  player._getVolume = () => {
    if (typeof component.volume !== 'number') {
      component.volume = player.getVolume();
    }
    return component.volume;
  }

  player._setVolume = (volume) => {
    component.volume = volume;
    player.setVolume(volume);
  }

  player._getQualities = () => {
    return player.availableQualities;
  }

  player._getQuality = () => {
    return player.getPlaybackQuality();
  }

  player._setQuality = (quality='auto') => {
    player.setPlaybackQuality(quality);
  }
};

// Return true if video is started yet.
function isYoutubePlayerStarted(contentId) {
  return aux.contentIdsYoutubePlayerStarted.indexOf(contentId) != -1;
}

function removeContentIdYoutubePlayerStarted(component) {
  var index = aux.contentIdsYoutubePlayerStarted.indexOf(component.contentId);
  if (index > -1) {      
    aux.contentIdsYoutubePlayerStarted.splice(index, 1);
  }
}

// Unlock video polling to detect next overvideo interaction.
// fromTime prevents the same interaction from being shown repeatedly due to buffering.
var unlockYoutubePolling = function(component, timeout, fromTime) {
  const youtubePollingLockedIndex = aux.youtubePollingLocked.indexOf(component.contentId);

  clearTimeout(aux.contentIdToUnlockYoutubePollingTimeout[component.contentId]);

  aux.contentIdToUnlockYoutubePollingTimeout[component.contentId] = setTimeout(() => {
    const youtubePlayerCurrentTime = Math.floor(component.videoPlayer.getCurrentTime());
    // Prevent buffering
    if (!fromTime || (fromTime &&  (youtubePlayerCurrentTime * 1000) > (fromTime * 1000 + 1500))) {
      aux.youtubePollingLocked.splice(youtubePollingLockedIndex, 1);
    } else {
      unlockYoutubePolling(component, 500, fromTime);
    }
  }, timeout);
};

function clearInteractionFeedbackTimeout(component) {
  clearTimeout(aux.contentIdToInteractionFeedbackTimeout[component.contentId]);
  aux.contentIdToInteractionFeedbackTimeout[component.contentId] = null;
}

function toggleYoutubePopup() {
  if (globalState.youtubePopupContent) {
    stopYoutubeVideoPlayer(this);
    Vue.delete(this.contentIdToYouTubePlayerReady, this.contentId);
    Vue.delete(globalState, "youtubePopupContent");
  } else {
    Vue.set(globalState, "youtubePopupContent", { content: this.content, containerContent: this.containerContent });
    Fandom.executeOnce(`youtube-audio-accessories-in-${this.contentId}`, () => {
      if(Fandom.exists(this.videoInteractionAudio)) {
        this.souspanceAudio.play();
        this.souspanceAudio.pause();
      }
    });
  }
}

const youtubeBasicMixin = {
  props: ["vcode"],
  data() {
    return {
      youtubeConfig: {},
      videoPlayer: {},
      videoEnded: false,
      overvideoInteraction: null,
      videoPlayerState: null,
      suspendProgressBarUpdating: false,
      // Track the progress of the video. To be used as v model of an input range.
      youtubePlayerProgress: 0,
      // Make contentIdToYouTubePlayerReady reactive
      contentIdToYouTubePlayerReady: aux.contentIdToYouTubePlayerReady
    };
  },
  mounted: function() {
    if (this.content.play) {

      if (this.progressBarRef) {
        this.progressBarRef.addEventListener("input", this.progressInputHandler);
        this.progressBarRef.addEventListener("change", this.progressChangeHandler);
      }

      if (this.autoplay) {
        this.youtubeConfig.autoplay = this.autoplay;
      }

      if (this.vcode) {
        this.youtubeConfig.vcode = this.vcode;
      } else {
        const youtubeMediaCodeMobile = this.getYoutubeMediaCodeMobile();
        if (youtubeMediaCodeMobile) {
          this.youtubeConfig.vcode = youtubeMediaCodeMobile;
        }
      }

      Vue.nextTick(() => {
        initYoutubeVideoPlayer(this, this.youtubeConfig);
      });

      if (this.content.souspance_audio) {
        this.souspanceAudio = new Audio(this.content.souspance_audio.url);
        this.souspanceAudio.loop = true;
      }
    }
  },
  computed: {
    // Check if the player is paused.
    isYoutubePauseActive() {
      return !this.overvideoInteraction && this.videoPlayerState == 2;
    },
    isYoutubePlayerReady() {
      return this.contentIdToYouTubePlayerReady[this.contentId];
    },
    // The component can contain a progress bar with progress-bar reference.
    progressBarRef() {
      return this.$refs["progress-bar"];
    }
  },
  beforeDestroy() {
    unlockYoutubePolling(this, 0);
    Vue.delete(this.contentIdToYouTubePlayerReady, this.contentId);
  },
  watch: {
    // Update the progress bar
    "content.youtubePlayerProgress"(newValue, previousValue) {
      this.youtubePlayerProgress = newValue;
    },
    isYoutubePlayerReady(newValue, previousValue) {
      // TODO: manage failure
      if (getYoutubeModuleConfig(this.contentId).autoplay && newValue) {
        this.videoPlayer.play();
      }
    }
  },
  methods: {
    toggleYoutubePopup,
    skipToPreviousInteraction,
    skipToNextInteraction,
    unsetOvervideoInteractionByUserInteraction() {
      // TODO: maybe to be removed clearTimeout
      clearInteractionFeedbackTimeout(this);
      const interactionTime = this.overvideoInteraction.seconds;

      if (interactionTime) {
        // TODO: (@ale) generic refactoring
        aux.contentIdToInteractionFeedbackTimeout[this.contentId] = setTimeout(() => {
          setChapterByInteractionId(this, -1);
          this.overvideoInteraction = null;

          unlockYoutubePolling(this, 1500, interactionTime);
          this.videoPlayer.play();
        }, (this.youtubeConfig.interactionFeedbackTime || 3000));
      } else {
        // the interaction is at the end in video. 
      }
    },
    skipToSecond(second) {
      prepareVideoToSkip(this, () => {
        this.videoPlayer.play();
        this.videoPlayer.seek(second);
      });
    },
    // Event corresponding to the input range drag start. Together with progressChangeHandler manages the progress bar update
    progressInputHandler() {
      this.suspendProgressBarUpdating = true; 
    },
    // Event corresponding to the input range drag end
    progressChangeHandler() {
      this.skipToSecond(this.youtubePlayerProgress * this.videoPlayer.getDuration() / 100);
      this.suspendProgressBarUpdating = false;
    },
    toggleYoutubeState() {
      if (this.isYoutubePlayerReady) {
        if (this.videoPlayer.getPlayerState() == 1) { // playing
          if (!this.content.prevent_pause) {
            this.videoPlayer.pause();
          }
        } else {
          this.videoPlayer.play();
        }
      }
    },
    getYoutubeMediaCodeMobile: function() {
      if (globalState.viewport && globalState.viewport.xs && Fandom.exists(this.content.media_code_mobile)) {
        return this.content.media_code_mobile;
      } else {
        return null;
      }
    }
  }
};

// Deprecated
const Youtube = {
  toggleYoutubePopup: toggleYoutubePopup
};

export { 
  Youtube,
  youtubeBasicMixin
};
