<script>
import { filter, get } from "lodash";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import { youtubeBasicMixin } from "src/modules/youtube_module.js";

export default {
  props: {
    autoplay: {
      type: Boolean,
      default: true
    }
  },
  mixins: [fandomBasicMixin, interactionMixin, youtubeBasicMixin],
  data() {
    return {
      overvideoInteraction: null,
    };
  },
  watch: {
    videoEnded: function() {
      if(this.videoEnded && !this.endingLayoutName){
        this.toggleYoutubePopup();
      }
    },
    overvideoInteraction(newValue, previousValue) {
      if (Fandom.exists(this.videoInteractionAudio)) {
        if (Fandom.exists(newValue)) {
          this.videoInteractionAudio.play();
        } else {
          this.videoInteractionAudio.pause();
          this.videoInteractionAudio.currentTime = 0;
        }
      }
    },
  },
  mounted() {
    Fandom.onEvent("youtube-video-restart", this.toggleYoutubeState);
  },
  computed: {
    videoProgress() {
      return `${this.youtubePlayerProgress - 100}%`;
    },
    // retrive end video layout name
    endingLayoutName() { 
      return get(this, ["content", "end_video_layout"], false);
    },
    answers() {
      const chapterTypes = ["Trivia", "TestStep"];
      const contentUserInteractions = this.getContentUserInfo(this.containerContent).id_to_interaction;
      return filter(contentUserInteractions, interaction => chapterTypes.includes(interaction.interaction_type)).map(interaction => {
        return get(interaction, ["user_interaction", "data", "answer_status"], null);
      });
    }
  },
  methods: {
    updateInteraction(contentUserInfo) {
      this.unsetOvervideoInteractionByUserInteraction();
    }
  }
};
</script>

<template>
  <div youtube-popup-component>
    <div v-show="!videoEnded" class="youtube-popup-video-container" :class="{'reverse-ar': getYoutubeMediaCodeMobile(), 'video-16by9': content.mask_16by9}">
      <div :id="content.youtube_id"></div>
      <div class="position-absolute-center youtube-popup-prevent-action"></div>
      <div class="position-absolute-center cursor-pointer" @click="toggleYoutubeState()"></div>
    </div>

    <div
      v-if="isYoutubePauseActive"
      class="bg-black text-white position-absolute-center youtube-popup-pause d-flex align-items-center justify-content-center"
      @click="toggleYoutubeState"
    >
      <i class="fal fa-pause"></i>
    </div>

    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
      <component
        v-if="overvideoInteraction"
        :is="overvideoInteraction.layout"
        class="bg-black text-white position-absolute-center"
        :content="overvideoInteraction"
        :opt-container-content="containerContent"
        @user-interaction-updated="updateInteraction"
      ></component>
    </transition>
    
    <template v-if="!videoEnded">
      <i class="far fa-chevron-left prev-icon cursor-pointer" v-show="overvideoInteraction" @click="skipToPreviousInteraction"></i>
      <i class="far fa-chevron-right next-icon cursor-pointer" v-show="overvideoInteraction" @click="skipToNextInteraction"></i>
      <div class="youtube-progress-bar-container d-flex align-items-center" :class="{'youtube-progress-bar-interaction-inactive': (overvideoInteraction || videoEnded)}">
        <input ref="progress-bar" type="range" step="0.001" min="0" max="100" v-model="youtubePlayerProgress" class="youtube-progress-bar">
        <div class="video-progress-wrapper">
          <div class="video-progress" :style="{'transform': `translate3d(${videoProgress}, 0, 0)`}"></div>
          <div class="video-progress-background"></div>
        </div>
        <span
          class="video-progress-icon position-absolute"
          v-if="content.play.progress_icon"
          :class="{ 'paused': isYoutubePauseActive || overvideoInteraction }"
          :style="{'transform': `translate3d(${videoProgress}, 0, 0)`}"
          >
          <i class="far" :class="content.play.progress_icon"></i>
        </span>
        <div
          class="youtube-progress-bar-chapter d-flex align-items-center justify-content-center"
          v-for="(chapter, index) in content.chaptering"
          :key="`chapter-${index}`"
          :style="{left: chapter.position}"
          :class="{active: chapter.active && answers[index] === null, correct: answers[index], wrong: answers[index] === false}"
          @click="skipToSecond(chapter.seconds)"
          >
          <i
            class="far"
            v-show="!(chapter.active && answers[index] === null)"
            :class="{'fa-thumbs-up': answers[index], 'fa-thumbs-down': answers[index] === false, 'fa-question': answers[index] === null}"
            ></i>
        </div>
      </div>
    </template>

    <div class="youtube-popup-close" @click.stop="toggleYoutubePopup()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@mixin thumb-styles($selector) {
  #{$selector}::-webkit-slider-thumb {
    @content
  }
  #{$selector}::-moz-range-thumb {
    @content
  }
  #{$selector}::-ms-thumb {
    @content
  }
}

[youtube-popup-component] {
  $youtube-progress-bar-marker-size: 2rem;
  $bar-height: .375rem;

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: $black;
  z-index: $zindex-youtube-popup;
  height: 100vh;

  .next-icon {
    position: absolute;
    top: 50%;
    right: 5%;
  }

  .prev-icon {
    position: absolute;
    top: 50%;
    left: 5%;
  }

  .youtube-popup-prevent-action {
    background-color: transparent;
  }

  .video-progress-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: $bar-height;
    border-radius: .25rem;
  }

  .video-progress {
    width: 101%;
    height: 100%;
    position: absolute;
    top: 0;
    background: $primary;
  }

  $progress-icon-size: 2rem;
  .video-progress-icon {
    font-size: $progress-icon-size;
    top: -($progress-icon-size * 1.5);
    left: $progress-icon-size * 0.5;
    width: 100%;
    text-align: right;
    color: $white;
    text-shadow: 0 0 1px $black;

    &.paused {
      opacity: 0;
    }
  }
  
  .video-progress-background {
    width: 100%;
    height: 100%;
    background-color: $white;
    border: 1px solid $gray-200;
  }

  .youtube-popup-pause i {
    font-size: 5rem;
  }

  .youtube-popup-close {
    position: absolute;
    top: 0rem;
    right: 1rem;
    color: $white;
    cursor: pointer;
    font-size: 2rem;
  }

  .youtube-popup-video-container {
    /* Required video-16by9 */
    height: 100vh;
    position: relative;
  }

  .youtube-progress-bar-container {
    position: fixed;
    bottom: 35px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 75%;

    .youtube-progress-bar {
      width: 100%;
      appearance: none;
      height: $bar-height * 6;
      outline: none;
      position: absolute;
      top: -$bar-height * 3;
      left: 0;
      background-color: transparent;
      z-index: 1;

      @include thumb-styles("&") {
        appearance: none;
        width: $youtube-progress-bar-marker-size + .15rem;
        height: $youtube-progress-bar-marker-size + .15rem;
        border-radius: 100%;
        border: 1px solid transparent;
        background: transparent !important;
        cursor: pointer;
        position: relative;
        z-index: 3;
      }
    }

    &.youtube-progress-bar-interaction-inactive {
      display: none;
    }

    .youtube-progress-bar-chapter {
      position: absolute;
      top: unset;
      width: $youtube-progress-bar-marker-size;
      height: $youtube-progress-bar-marker-size;
      background-color: $primary;
      border: 1px solid $primary;
      color: $white;
      border-radius: 100%;
      cursor: pointer;
      z-index: 2;

      .far {
        font-size: $youtube-progress-bar-marker-size * 0.5;
      }

      &.active {
        // background-color: $gray-300;
        background-color: $white;
        border: 0 solid $primary;
        box-shadow: 0 0 0 $youtube-progress-bar-marker-size * 0.175 inset $primary;
      }

      &.correct {
        background-color: $green;
        border-color: $green;
      }

      &.wrong {
        background-color: $red;
        border-color: $red;
      }

      &.youtube-progress-bar-chapter-end {
        left: 100%;
      }
    }
  }

  @include media-breakpoint-down(xs) {

    .youtube-popup-video-container {
      &.video-16by9:not(.reverse-ar) {
        height: auto;
        padding-bottom: 56.25%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      /* Reverse aspect ratio to 9/16 when vcode_mobile is active. */
      &.video-16by9.reverse-ar {
        video, iframe {
          height: 177.78vw !important;
          width: 56.25vh !important;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {

    .youtube-progress-bar-container {

      .youtube-progress-bar {
        @include thumb-styles("&") {
          width: $youtube-progress-bar-marker-size * 0.75 + .115rem;
          height: $youtube-progress-bar-marker-size * 0.75 + .115rem;
        }
      }

      .youtube-progress-bar-chapter {
        width: $youtube-progress-bar-marker-size * 0.75;
        height: $youtube-progress-bar-marker-size * 0.75;
        .far {
          font-size: $youtube-progress-bar-marker-size * 0.30;
        }
      }
    }
  }
}
</style>
