<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { interactionMixin } from 'src/modules/interactions_module.js';

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  data: function() {
    return {};
  },
  computed: {
    userInteractions() {
      return this.getUserInteraction(this.containerContent, this.content.interaction);
    },
    gainedInteractions() {
      return this.userInteractions['outcome']['reward_name_to_counter'];
    },
    correct() {
      return this.userInteractions.data.answer_status;
    },
    points() {
      return this.getInteraction('point');
    },
    tickets() {
      return this.getInteraction('ticket');
    },
    interactionToShow() {
      let interaction = null;

      if (this.points) {
        interaction = {
          type: { singular: 'point', plural: 'points' },
          quantity: this.points
        };
      } else if (this.tickets) {
        interaction = {
          type: { singular: 'ticket', plural: 'tickets' },
          quantity: this.tickets
        };
      }

      return interaction;
    },
    feedbackText() {
      if (this.correct) {
        return this.ft('globals.trivia_interaction.feedback_success_text');
      } else {
        return this.ft('globals.trivia_interaction.feedback_wrong_text');
      }
    },
    gainText() {
      if (this.interactionToShow) {
        let type = "";

        if (this.interactionToShow.quantity == 1) {
          type = this.ft(`globals.${this.interactionToShow.type.singular}`);
        } else {
          type = this.ft(`globals.${this.interactionToShow.type.plural}`);
        }

        return this.ft('globals.gained_interactions', {
          type: type,
          quantity: this.interactionToShow.quantity
        });
      } else {
        return null;
      }
    },
    iconClass() {
      if (this.correct) {
        return ['fa-thumbs-up', 'text-success']
      } else {
        return ['fa-thumbs-down', 'text-danger']
      }
    }
  },
  methods: {
    getInteraction(interaction) {
      return this.gainedInteractions[interaction] || 0;
    }
  }
};
</script>

<template>
  <div class="fixed-top d-flex align-items-center justify-content-center mx-auto py-3 px-3 mt-4" interaction-feedback-alert>
    <div class="feedback-icon mr-3">
      <i class="fa-regular" :class="iconClass"></i>
    </div>
    <div>
      <h2 class="feedback-text mb-0">{{ feedbackText }}</h2>
      <p v-if="gainText" class="mb-0 small">{{ gainText }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$feedback-text-font-size: 1rem;
$feedback-icon-font-size: 1.5rem;
$feedback-width: 20rem;
$feedback-background-color: #F8F9FA;
$feedback-border-color: #DEE2E6;
$feedback-text-color: black;

[interaction-feedback-alert] {
  z-index: $zindex-feedback;
  width: $feedback-width;
  max-width: 90%;
  border-radius: 15rem;
  background-color: $feedback-background-color;
  border: 1px solid $feedback-border-color;


  .feedback-text {
    font-size: $feedback-text-font-size;
    font-weight: normal;
    color: $feedback-text-color;
  }

  i {
    font-size: $feedback-icon-font-size;
    color: green;
  }

}
</style>
