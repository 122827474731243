<template>
  <div :style="monitorStyle" class="ai-monitor h-100 bg-cover-center">
    <div v-if="!started" class="setup-container w-100 h-100 container-fluid">
      <div class="row row w-100 h-100vh">
        <div class="col-12 d-flex flex-column justify-content-center align-items-center">
          <h1 class="display-4">Monitor {{side}}</h1>
          <div v-if="!connected">
            <span>
              <i class="fa-solid fa-circle-notch fa-spin text-warning mr-3"></i>
              Connecting to mixer
            </span>
          </div>
          <div v-else>
            <div>
              <p>
                <i class="fa-solid fa-circle-check text-success mr-3"></i><span>Connected</span>
              </p>
            </div>
            <button @click="started=true" class="btn btn-outline-primary">Start monitor</button>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <div class="position-absolute-center">
        <f-video 
          v-if="hasBgVideo"
          :src="background.url" 
          class="bg-video h-100 w-100" 
          muted autoplay loop webkit-playsinline playsinline
        ></f-video>
      </div>
      <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
        <f-video
          v-if="video"
          ref-prefix="media"
          :src="video"
          class="position-relative h-100 w-100"
          preload="auto"
          webkit-playsinline playsinline loop
        ></f-video>
      </transition>
      <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
        <div v-if="image" class="h-100 p-3 image-container position-relative d-flex align-items-center justify-content-center">
          <img class="img-fluid rounded" :src="image">
        </div>
    </transition>
    </template>
  </div>
</template>

<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { ActionCableComponentsMixin } from 'src/modules/websocket_module.js';
import { broadcasting } from 'src/modules/services/ai-service';
import { v4 as uuidv4 } from 'uuid';
import FVideo from 'f-video';

export default {
  mixins: [fandomBasicMixin, ActionCableComponentsMixin],
  components: { FVideo },
  props: {
    side: {
      type: String,
      required: true,
      validator: (value) => (["dx","sx"].includes(value))
    },
    background: {
      type: Object
    }
  },
  data() {
    return {
      connected: false,
      started: false,
      activeStep: "",
      image: null,
      video: null,
      uuid: uuidv4()
    }
  },
  mounted() {
    this.subscribe(
      { channel: "ListenerChannel", room: "interview_mixer" },
      { 
        connected: this.handleConnected,
        received: this.handleInMessage 
      }
    );
  },
  methods: {
    handleConnected() {
      this.sendMixerMessage("connection", {connectionId: this.uuid});
    },
    handleCanPlay() {
      console.log("%cMONITOR", "background-color: yellow;", "can-play");
      this.$refs.mediafVideo.removeEventListener("canplaythrough", this.handleCanPlay);
      this.sendMixerMessage('video-ready');
    },
    handleInMessage(data) {
      switch (data.type) {
        case "confirm-connection":
          if (data.payload?.connectionId === this.uuid) {
            this.connected = true;
          }
          break;
        case "update-step":
          console.log("%cMONITOR", "background-color: yellow;", "update-step", data.payload);
          this.activeStep = data.payload.step;
          this.resetMedia();
          
          if (typeof data.payload.media !== 'undefined') {
            const type = data.payload.media.type;
            if (type === 'video') {
              this.video = data.payload.media.url;
            } else {
              this.image = data.payload.media.url;
            }
          } else {
            this.video = this.getVideo();
          }

          if (this.video) {
            Vue.nextTick(()=>this.$refs.mediafVideo.addEventListener("canplaythrough", this.handleCanPlay));
          }
          break;
        case "play-video":
          console.log("%cMONITOR", "background-color: yellow;", "play-video");
          this.$refs.mediafVideo?.play();
          break;
        default:
          break;
      }
    },
    sendMixerMessage(type, payload) {
      console.log("%cMONITOR", "background-color: yellow;", "Send message:", type);
      broadcasting({ room: "interview_mixer", type, payload: JSON.stringify(payload)});
    },
    getVideo() {
      const stepVideo = this.content.monitors[this.activeStep];
      if (stepVideo) {
        return stepVideo[this.side]?.url ?? stepVideo["main"]?.url ?? null;
      }

      return null;
    },
    resetMedia() {
      this.video = null;
      this.image = null;
    }
  },
  computed: {
    hasBgVideo() {
      return this.background?.url && !Fandom.isImage(this.background.url);
    },
    monitorStyle() {
      if (!this.hasBgVideo) {
        return {
          'background-image': Fandom.getBackgroundUrl(this.background.url)
        }
      }
      return {
        'background-color': "black"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.setup-container {
  background: var(--gray-light);
}
</style>