<template>
  <div class="position-fixed w-100 h-100" v-if="visible" loader-component>
    <div class="d-flex w-100 h-100 align-items-center justify-content-center">
      <slot>
        <i class="text-primary" :class="faIconClasses"></i>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    faIconClasses: {
      type: String,
      default: "fal fa-circle-notch fa-spin fa-3x"
    }
  },
  data() {
    return {
      visible: false
    };
  },
  mounted() {
    Fandom.onEvent("show-loader", () => {
      this.visible = true;
    });
    Fandom.onEvent("hide-loader", () => {
      this.visible = false;
    });
  }
};
</script>

<style lang="scss" scoped>
[loader-component] {
  z-index: 10000;
  background-color: rgba($dark, .15);
}
</style>
