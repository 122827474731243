<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { interactionMixin } from 'src/modules/interactions_module.js';
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  data: function () {
    return {
      filters: {
        month: null,
        year: null
      },
      years: [],
      months: {},
    };
  },
  mounted: function () {
    const d = new Date();
    this.filters.year = d.getFullYear();
    this.content.table.forEach(item => {
      if (item.date_column) {
        const d = new Date(item.date_column);
        let year = d.getFullYear();
        let month = d.getMonth();
        month = month < 9 ? `0${month + 1}` : `${month + 1}`;
        this.years.push(year);
        if (!this.months[year]) {
          this.months[year] = [month];
        } else {
          this.months[year].push(month);
        }
      }
    });
    this.years = [...new Set(this.years)];
    this.years.forEach((year) => {
      this.months[year] = [...new Set(this.months[year])];
    });
  },
  computed: {
    columnsMap() {
      let columnsMap = [false, false, false, false];
      this.content.table.forEach((item) => {
        columnsMap[0] = columnsMap[0] || Fandom.exists(item.text_column_1);
        columnsMap[1] = columnsMap[1] || Fandom.exists(item.text_column_2);
        columnsMap[2] = columnsMap[2] || Fandom.exists(item.text_column_3);
        columnsMap[3] = columnsMap[3] || Fandom.exists(item.attachment_column);
        columnsMap[4] = columnsMap[4] || Fandom.exists(item.date_column);
      });
      return columnsMap;
    },
    isInPageWithSidebar() {
      return this.containerContent.layout == "page-with-sidebar";
    },
    items() {
      let items = this.content.table;
      items = items.filter((item) => {
        if (item.date_column) {
          const d = new Date(item.date_column);
          let response = (d.getFullYear() == this.filters.year) || !this.filters.year;
          if (this.filters.month) {
            let month = d.getMonth();
            month = month < 9 ? `0${month + 1}` : `${month + 1}`;
            response = response && (month == this.filters.month);
          }
          return response;
        } else {
          return true;
        }
      });
      return items;
    },
    columnClasses() {
      const columnWidth = Fandom.exists(this.content.width) ? this.content.width : Fandom.retrieveFromGlobalState("one_col_text_width", 8);
      return [
        `col-lg-${columnWidth}`
      ];
    },
    visibleItems() {
      return this.items.filter(item => this.checkItemVisible(item));
    }
  },
  methods: { 
    checkItemVisible(item) {
      return item.text_column_1 || item.text_column_2 || item.text_column_3;
    },
    downloadInteractionHandler(item) {
      if (Fandom.exists(item.interaction)){
        let attachment = item.attachment_column;
        this.updateDownloadInteraction(this.containerContent, this.content, item.interaction, null, attachment.url);
      }
    },
    formattedDate(date) {
      const fDate = new Date(date);
      const month = fDate.getMonth() < 9 ? `0${fDate.getMonth() + 1}` : `${fDate.getMonth() + 1}`;
      const day = fDate.getDate() < 10 ? `0${fDate.getDate()}` : `${fDate.getDate()}`;
      return `${fDate.getFullYear()}/${month}/${day}`;
    },
    getMonthLiteral(number) {
      const months = {
        "it": ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
        "en": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
      };
      return months[globalState.lang][parseInt(number) - 1];
    }
  },
  watch: {
    'filters.year': function() {
      this.filters.month = "";
    }
  }
};
</script>

<template>
  <div :id="content.stripe_id" v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="contentType" :style="{'background-color': content.background_color}" table-simple-component>
    <div class="py-5 container">
      <div class="row justify-content-center">
        <div :class="columnClasses">
          <stripe-header v-if="content.title || content.description" align="left" class="px-0" :content="content"></stripe-header>
          <form class="form-inline my-3 justify-content-between" v-if="content.table[0] && content.table[0].date_column">
            {{ft("globals.filter_by")}}
            <div class="d-flex align-items-center">
              <div class="form-group mr-5">
                <label :for="`month-select-${contentId}`" class="mr-3">{{ft("globals.month")}}</label>
                <select class="form-control custom-select" :id="`month-select-${contentId}`" v-model="filters.month">
                  <option value="" selected>{{ft("globals.all")}}</option>
                  <option v-for="month in months[filters.year]" :value="month" :key="`month-${month}`">{{getMonthLiteral(month)}}</option>
                </select>
              </div>
              <div class="form-group">
                <label class="mr-3" :for="`year-select-${contentId}`">{{ft("globals.year")}}</label>
                <select class="form-control custom-select" :id="`year-select-${contentId}`" v-model="filters.year">
                  <option :value="null" selected>{{ft("globals.all")}}</option>
                  <option v-for="year in years" :key="`year-${year}`">{{year}}</option>
                </select>
              </div>
            </div>
          </form>
          <div class="table-responsive bg-white">
            <table class="table table-bordered mb-0" >
              <tbody>
                <tr v-for="(item, index) in visibleItems" :key="`item-${index}`">
                  <td v-if="columnsMap[4]" class="p-4 cell date">
                    {{formattedDate(item.date_column)}}
                  </td>
                  <td v-if="columnsMap[0]" class="p-4 cell" v-html="item.text_column_1"></td>
                  <td v-if="columnsMap[1]" class="p-4 cell" v-html="item.text_column_2"></td>
                  <td v-if="columnsMap[2]" class="p-4 cell" v-html="item.text_column_3"></td>
                  <td v-if="columnsMap[3]" align="middle" class="p-4 attachment-column cell">
                    <component v-if="item.attachment_column" :is="item.interaction ? 'span' : 'a'" :href="item.interaction ? 'javascript:void(0)' : item.attachment_column.url" :target="item.interaction ? '_self' :  '_blank'" class="cursor-pointer text-dark" @click="item.interaction ? downloadInteractionHandler(item) : null">
                      <i class="fal fa-download"></i>
                    </component>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> 
    </div> 
  </div>
</template>

<style lang="scss">
[table-simple-component] { 
  
  .anchor{
    display: block;
    height: calc(#{$header-height-sm} + #{$secondary-nav-height});
    margin-top:  calc(-#{$header-height-sm} - #{$secondary-nav-height});
    visibility: hidden;
  }

  .cell {
    vertical-align: middle;

    &.date {
      width: 8rem;
    }
  }

  .custom-select {
    height: 2rem;
    border-radius: 0;
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .attachment-column {
    width: 4rem;
  }

}
</style>