function generateToken(func, action) {
  grecaptcha.enterprise.ready(async () => {
    const token = await grecaptcha.enterprise.execute(globalState.pageModel.aux.recaptcha_enterprise, {action: action});
    func(token);
  });
}

function addRecaptchaInputInRef(recaptchaRef) {
  if (recaptchaRef) {
    const func = (e) => {
      const target = $(e.target);
      const action = target.attr("recaptcha-action");
      generateToken((token) => {
        e.target.removeEventListener("submit", func);
        target.append(`<input type="hidden" name="recaptcha_token" value=${token}>`);
        target.submit();
      }, action);
      e.preventDefault();
    };
    recaptchaRef.addEventListener("submit", func);
  }
}

export { generateToken, addRecaptchaInputInRef };