<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      show: false
    }
  },
  mounted() {
    Vue.nextTick(() => {
      Vue.set(this, 'show', true);
    });
  },
  watch: {
    content(newVal, oldVal) {
      if (!newVal) {
        this.close(oldVal.id || oldVal.$id);
      }
    }
  },
  methods: {
    close(contentId) {
      Vue.set(this, 'show', false);
      this.$emit('close', contentId);
    },
    closeDelayed() {
      setTimeout(() => {
        this.close(this.content.id || this.content.$id);
      }, 3000);
    }
  }
};
</script>

<template>
  <transition enter-active-class="animated fadeInUp" leave-active-class="animated fadeOutDown">
    <div v-if="show && !!content" v-easyadmin="easyadminId" :class="contentType" page-live-events-interaction-modal-component>
      <div class="container position-relative interaction-container">
        <div class="row align-items-center justify-content-center pt-5 pb-4 py-md-4">
          <div class="col-lg-8 text-center">
            <component
              :opt-container-content="containerContent"
              @interaction-updated="closeDelayed()"
              questionAlignment="center"
              :is="content.layout"
              :answerPaddingX="1"
              :answerColumns="6"
              :content="content"
              class="my-0"
              inherit-theme-type="dark"
              alignment="center"
            ></component>
          </div>
        </div>
        <i @click="close()" class="fal fa-lg fa-times p-3 p-md-4 cursor-pointer"></i>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
[page-live-events-interaction-modal-component] {
  z-index: $zindex-modal;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  left: 1.5rem;

  .interaction-container {
    border-radius: 15px;
    background-color: #333333;
    color: $white;
    overflow: hidden;
    width: 1100px;
    max-width: 100%;
  }

  .fa-times {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>