/**
 * Set attributes to element
 * @param {HTMLElement} element 
 * @param {Object} attributes 
 * @returns void
 */
const setAttributes = (element, attributes) => {
  Object.entries(attributes)
    .forEach(([key, value]) => element.setAttribute(key, value));
}

/**
 * Create an HTML element with attributes
 * @param {String} type 
 * @param {Object} attributes 
 * @returns HTMLElement
 */
const createElement = (type, attributes) => {
  const element = document.createElement(type);

  // Set all passed attributes
  if (typeof attributes === "object") {
    setAttributes(element, attributes);
  }

  return element;
}

/**
 * Remove all child nodes from parent
 * @param {HTMLElement} parent 
 * @returns void
 */
const removeAllChild = (parent) => {
  while (parent.firstChild) {
    parent.removeChild(parent.firstChild);
  }
}

const browser = {
  isIE: Boolean(window.document.documentMode),
  isEdge: window.navigator.userAgent.includes('Edge'),
  isWebkit: 'WebkitAppearance' in document.documentElement.style && !/Edge/.test(navigator.userAgent),
  isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
  isIPhone: /(iPhone|iPod)/gi.test(navigator.platform),
  isIos:
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) ||
    /(iPad|iPhone|iPod)/gi.test(navigator.platform),
  isTouch: (('ontouchstart' in window) ||(navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))
};

/**
 * Picture-in-picture support
 * Safari & Chrome only currently
 * @returns boolean
 */
const pip = () => {
  // Safari
  // https://developer.apple.com/documentation/webkitjs/adding_picture_in_picture_to_your_safari_media_controls
  if (typeof createElement('video').webkitSetPresentationMode === "function") {
    return true;
  }

  // Chrome
  // https://developers.google.com/web/updates/2018/10/watch-video-using-picture-in-picture
  if (document.pictureInPictureEnabled && !createElement('video').disablePictureInPicture) {
    return true;
  }

  return false;
}

/**
 * Load a js script
 * @param {String} id 
 * @param {String} src 
 */
const loadScript = (id, src) => {
  Fandom.executeOnce(id, () => {
    const tag = document.createElement("script");
    tag.src = src;
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  });
}

export {
  setAttributes,
  createElement,
  removeAllChild,
  browser,
  pip,
  loadScript
}