<template>
  <div countdown-component>
    <div class="countdown">
      <p class="countdown__label mb-0" :style="{fontSize: valueFontSize}">{{ time }}</p>
      <svg 
        class="countdown__svg" 
        :style="svgStyle"
        :key="`svg-${index}`">
        <circle ref="circle" :style="circleStyle" class="circle" cx="50%" cy="50%" :r="circleSize/2" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: 10
    },
    circleSize: {
      type: Number,
      default: 150
    },
    valueFontSize: {
      type: String,
      default: "14px"
    },
    emptyColor: {
      type: String,
      default: "#D8D8D8"
    },
    fullColor: {
      type: String,
      default: "#35363B"
    },
    intervalAudio: {
      type: [Object, HTMLAudioElement],
      required: false
    }
  },
  data: function() {
    return {
      time: parseInt(this.value),
      intervalId: null,
      index: 0,
      bannerAudioEl: this.intervalAudio
    }
  },
  mounted() {
    if (!(this.intervalAudio instanceof HTMLAudioElement) && this.intervalAudio?.url) {
      this.bannerAudioEl = new Audio(this.intervalAudio.url);
    }
    this.initCountdown();
  },
  beforeDestroy() {
    this.bannerAudioEl?.pause();
    this.bannerAudioEl = null;
  },
  methods: {
    initCountdown() {
      const circleEl = this.$refs.circle;
      circleEl.style.animation = `clock-animation ${this.time}s linear forwards`;
      this.intervalId = setInterval(this.updateTime, 1000);
    },
    updateTime() {
      if (this.time<=0) {
        clearInterval(this.intervalId);
        this.$emit("finished");
      } else {
        this.time--;
        this.bannerAudioEl?.play();
        this.$emit("input", this.time);
      }
    }
  },
  computed: {
    svgStyle() {
      return {
        width: `${this.circleSize}px`,
        height: `${this.circleSize}px`,
        background: this.fullColor
      }
    },
    circleStyle() {
      return {
        strokeWidth: `${this.circleSize}px`,
        "--stroke-dashoffset": Math.PI*this.circleSize,
        "--empty-color": this.emptyColor
      }
    }
  },
  watch: {
    value(newVal) {
      if (newVal != this.time) {
        this.time = parseInt(newVal);
        this.index++
        Vue.nextTick(()=>this.initCountdown())
      }
    }
  }
}
</script>

<style lang="scss">
[countdown-component] {
  --stroke-dashoffset: 471;

  .countdown {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &__label {
      position: absolute;
      color: $white;
      font-weight: 600;
    }

    &__svg {
      border-radius: 100%;

      .circle {
        fill: transparent;
        transform: rotate(-90deg);
        transform-origin: center;
        stroke: var(--empty-color);
        stroke-dasharray: var(--stroke-dashoffset);
        stroke-dashoffset: var(--stroke-dashoffset);
      }

      @keyframes clock-animation {
        0% {
          stroke-dashoffset: var(--stroke-dashoffset);
        }
        100% {
          stroke-dashoffset: 0;
          background: var(--empty-color);
        }
      }
    }
  }
}
</style>