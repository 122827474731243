<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js'
import { interactionMixin } from 'src/modules/interactions_module.js'

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  data() {
    return {
      // Marks whether the user has taken an action to activate the fallback or not
      missingUserAction: true
    };
  },
  methods: {
    doInteraction(answer) {
      const success = (_) => {
        if (answer.skip_to_second) {
          this.$emit("video-player-action-handler", { action: "seekTo", value: parseInt(answer.skip_to_second) });
          this.missingUserAction = false;
        }
      }
      this.updateVersusInteraction(this.containerContent, this.content, this.content.interaction, answer.$id, () => {}, success);
    },
  },
  beforeDestroy() {
    if (this.missingUserAction) {
      const userInteractionAnswerId = this.getUserInteractionAnswerId(this.containerContent, this.content.interaction);

      let defaultAnswer = this.content.answers.find(a => !!a.default) || this.content.answers[0];
      if (userInteractionAnswerId) {
        defaultAnswer = this.content.answers.find(a => a.$id == userInteractionAnswerId);
      }

      this.doInteraction(defaultAnswer);
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" interaction-gamebook-component>
    <div class="gamebook-container">
      <div class="row h-100 mx-0">
        <div class="col" v-for="answer in content.answers" @click="doInteraction(answer)" :style="{'background-color': answer.background_color}">
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[interaction-gamebook-component] {

  .gamebook-container {
    position: relative;
    width: 100vw;
    height: calc((9 / 16) * 100vw);
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-aspect-ratio: 16/9) {
    .gamebook-container {
      height: 100vh;
      width: calc((16 / 9) * 100vh);
    }
  }
}
</style>
