<script>
import { fandomBasicMixin, fandomAuthorizationMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomAuthorizationMixin],
  props: {
    comment: {
      type: Object,
      default() {
        return {};
      }
    },
    parentComment: {
      type: Object,
      default() {
        return {};
      }
    },
    reply: {
      type: Boolean,
      default: false
    },
    showDate: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      rawComment: {}
    }
  },
  mounted() {
    this.initComment();
  },
  computed: {
    canReply() {
      return this.reply && this.comment.ref_comment_id == null;
    }
  },
  methods: {
    initComment() {
      const userType = this.userType(this.comment.user_id)

      this.rawComment = {
        ...this.comment,
        username: this.getUsernameByUser(this.comment.user_id),
        time: this.getTime(this.comment.created_at),
        canComment: this.canComment(this.comment.user_id)
      }

      if (userType) {
        this.rawComment.userType = userType;
        this.rawComment.badgeStyle = {backgroundColor: userType.background_color};
      }

      if (this.parentComment && Object.values(this.parentComment).length > 0) {
        this.rawComment['parent'] = {
          username: this.getUsernameByUser(this.parentComment.user_id),
          text: this.parentComment.text,
          canComment: this.canComment(this.parentComment.user_id),
        }

        const parentUserType = this.userType(this.parentComment.user_id);
        if (parentUserType) {
          this.rawComment['parent'].userType = parentUserType;
          this.rawComment['parent'].badgeStyle = {backgroundColor: parentUserType.background_color};
        }
      }
    },
    toggle() {
      $(this.$refs["collapse"].$el).collapse("toggle");
    },
    getTime(date) {
      return (new Date(date)).toLocaleString([], {
        year: "numeric", month: "long", day: "numeric",
        hour: "numeric", minute: "numeric", second: "numeric"
      });
    },
    getUsernameByUser(userId) {
      const user = Fandom.getUserById(userId);
      if (user.anonymous) {
        return this.ft("globals.anonymous_username");
      } else {
        return user.first_name ? 
          `${user.first_name} ${user.last_name?.[0] ?? ''}` :
          user.username.split("@")[0];
      }
    }
  }
};
</script>

<template> 
  <div class="comment py-1 m-0" comment>
    <div class="position-relative" :class="{'pl-2 comment__with-parent': rawComment.parent}">
      <p v-if="rawComment.parent" class="parent-comment comment-preview mb-2 position-relative font-italic">
        <span v-if="rawComment.parent.userType" :style="rawComment.parent.badgeStyle" class="text-uppercase badge rounded-pill badge-light">
          <i v-if="rawComment.parent.userType.title_icon" :class="rawComment.parent.userType.title_icon"></i>{{rawComment.parent.userType.title}}
        </span>
        <span class="comment-preview__name font-weight-bold mb-0">{{rawComment.parent.username}}</span>
        <span class="comment-preview__text mb-0">{{rawComment.parent.text}}</span>
      </p>
      <div class="rounded mb-1">
        <p class="comment-preview mb-0">
          <i v-if="rawComment.parent" class="fas fa-reply fa-rotate-180 d-inline-block"></i>
          <span v-if="rawComment.userType" :style="rawComment.badgeStyle" class="text-uppercase badge rounded-pill badge-light">
            <i v-if="rawComment.userType.title_icon" :class="rawComment.userType.title_icon"></i>
            {{rawComment.userType.title}}
          </span>
          <span class="comment-preview__name font-weight-bold mb-0">{{rawComment.username}}</span>
          <span class="comment-preview__text mb-0">{{rawComment.text}}</span>
          <span 
            @click.prevent="$emit('reply-to-comment', rawComment)"
            v-if="canReply"
            class="reply-badge cursor-pointer text-uppercase badge badge-pill"
          >
            <i class="pr-1 fas fa-comment"></i>{{ft("comments.reply_button")}}
          </span>
        </p>
      </div>
      <div class="comment-date d-flex">
        <template v-if="showDate">
          <p class="mb-0"><small>{{rawComment.time}}</small></p>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[comment] {
  .comment {
    &__with-parent {
      &::before {
        content: "";
        width: 3px;
        background-color: #F2F2F3;
        border-radius: 5px;
        height: 100%;
        position: absolute;
        left: 0;
        opacity: .5;
      }

      .parent-comment {
        &::before {
          content: "";
          background: $white;
          border-radius: 5px;
          width: 3px;
          position: absolute;
          height: 100%;
          left: -8px;
        }
      }
    }

    &-preview {
      background-color: transparent;
      line-height: 1rem;
      word-break: break-all;

      .fa-reply {
        color: var(--theme-color);
        opacity: .7;
      }

      .reply-badge {
        color: #F2F2F3;
        background: #494B50;
        display: inline-flex;
        font-size: 10px;
      }

      &__name {
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
        font-size: $font-size-sm;
        line-height: 1rem;
        color: var(--theme-color);
        opacity: 0.75;
      }

      &__text {
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
        font-size: $font-size-sm;
        line-height: 1.1rem;
        color: var(--theme-color);
        word-break: break-word;
      }
    }

    .user-avatar {
      color: var(--theme-color);
    }
  }
}
</style>
