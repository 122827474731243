import { sendFandomAnalyticsEvent } from "src/modules/fandom_analytics_module.js";

const audiEventsBasicMixin = {
  methods: {
    trackingButtonUrl(event, buttonTracking, buttonEventCookie) {
      let trackingInfo = buttonTracking || this.content.button_tracking;
      let eventCookieInfo = buttonEventCookie || this.content.button_event_cookie;
      if (!!trackingInfo) {
        const trackingParams = trackingInfo.split(",");
        sendFandomAnalyticsEvent(trackingParams[0], trackingParams[1], trackingParams[2]);
      }

      if (!!eventCookieInfo) {
        document.cookie = `audi_events_user_tag=${eventCookieInfo}; path=/;`;
      }

      const target = $(event.currentTarget);
      const href = target.attr("href");

      if (href == "javascript:void(0)") {
        return false;
      }

      if (target.attr("target") == "_blank") {
        window.open(href, '_blank');
      } else {
        window.location.href = href;
      }
      return false;
    },
  }
};

export {
  audiEventsBasicMixin
};
