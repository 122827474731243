const BASE_URL = "/api/v5/ai";

const getCSRFHeader = () => {return {'X-CSRF-Token': $("meta[name=\"csrf-token\"]").attr("content")}}

const createChat = (cc_id, name) => {
  return fetch(Fandom.applyContextToUrl(`${BASE_URL}/mh/chat`), {
    method: 'POST',
    body: JSON.stringify({content_cache_id: cc_id, name}),
    headers: {
      'Content-Type': 'application/json',
      ...getCSRFHeader()
    }
  });
}

const startInterview = body => {
  return fetch(Fandom.applyContextToUrl(`${BASE_URL}/mh/start-interview`), {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      ...getCSRFHeader()
    }
  });
}

const endInterview = (chatName, userMessage) => {
  return fetch(Fandom.applyContextToUrl(`${BASE_URL}/mh/end-interview`), {
    method: 'POST',
    body: JSON.stringify({chat: chatName, user_message: userMessage}),
    headers: {
      'Content-Type': 'application/json',
      ...getCSRFHeader()
    }
  });
}

const transcribeAudio = body => {
  return fetch(Fandom.applyContextToUrl(`${BASE_URL}/mh/transcribe_audio`), {
    method: 'POST',
    body,
    headers: {...getCSRFHeader()}
  });
}

const fetchQuestion = body => {
  return fetch(Fandom.applyContextToUrl(`${BASE_URL}/mh/question`), {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {'Content-Type': 'application/json', ...getCSRFHeader()}
  });
}

const message = (chatId, runId) => {
  return fetch(Fandom.applyContextToUrl(`${BASE_URL}/${chatId}/message/${runId}`), {
    method: 'GET',
    headers: {'Content-Type': 'application/json', ...getCSRFHeader()}
  });
}

const synthesizeSpeech = (speechService="polly", text, settings, viseme=false) => {
  return fetch(Fandom.applyContextToUrl(`${BASE_URL}/chat/${speechService}/synthesize_speech`), {
    method: 'POST',
    body: JSON.stringify({ text, viseme, settings: JSON.stringify(settings) }),
    headers: {
      'Content-Type': 'application/json',
      ...getCSRFHeader()
    }
  })
}

const textToSpeech = (text, settings) => {
  return fetch(Fandom.applyContextToUrl(`${BASE_URL}/tts`), {
    method: 'POST',
    body: JSON.stringify({ text, settings: JSON.stringify(settings) }),
    headers: {
      'Content-Type': 'application/json',
      ...getCSRFHeader()
    }
  })
}

const textToVisemes = (text, settings) => {
  return fetch(Fandom.applyContextToUrl(`${BASE_URL}/ttv`), {
    method: 'POST',
    body: JSON.stringify({ text, settings: JSON.stringify(settings) }),
    headers: {
      'Content-Type': 'application/json',
      ...getCSRFHeader()
    }
  })
}

const audioToVisemes = (audio) => {
  const body = new FormData();
  body.append("audio", audio);
  return fetch(Fandom.applyContextToUrl(`${BASE_URL}/atv`), {
    method: 'POST',
    body,
    headers: getCSRFHeader()
  })
}

const broadcasting = body => {
  return fetch(Fandom.applyContextToUrl(`${BASE_URL}/chat/monitor_broadcasting`), {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {'Content-Type': 'application/json', ...getCSRFHeader()}
  });
}

const handleResponse = response => {
  if (response.ok) {
    return response.json();
  }
  throw new Error('Something went wrong');
}

export {
  createChat,
  startInterview,
  endInterview,
  transcribeAudio,
  fetchQuestion,
  message,
  synthesizeSpeech,
  textToSpeech,
  textToVisemes,
  audioToVisemes,
  broadcasting,
  handleResponse
}