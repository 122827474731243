import { get } from 'lodash';

const CONTROL_BAR_TIMEOUT = 3000;

export default {
  data: function() {
    return {
      showControls: false,
      showControlsTimeout: null,
      videoEl: null,
    }
  },
  mounted() {
    this.mouseMoveHandler();
    this.initVideoListeners();
    
    const controlBarEl = this.$refs['control-bar'];
    if (controlBarEl) {
      controlBarEl.addEventListener('mouseover', this.handleControlBarOver);
      controlBarEl.addEventListener('mouseleave', this.handleControlBarLeave);
    }
  },
  methods: {
    initVideoListeners() {
      this.videoEl = this.$refs['video'] || get($('iframe'), '0', null) || get($('video'), '0', null);
      if (this.videoEl) {
        this.videoEl.addEventListener('mousemove', this.mouseMoveHandler);
      }
    },
    mouseMoveHandler() {
      this.handleControlBarOver()
      this.handleControlBarLeave();
    },
    handleControlBarOver() {
      if (this.showControlsTimeout) {
        clearTimeout(this.showControlsTimeout);
      }
      this.showControls = true;
      this.$emit('control-bar:show');
    },
    handleControlBarLeave() {
      this.showControlsTimeout = setTimeout(() => {
        this.$emit('control-bar:hide');
        this.showControls = false;
      }, CONTROL_BAR_TIMEOUT);
    },
    videoReadyCallback() {
      if(this.videoEl) {
        return;
      }

      this.initVideoListeners();
    }
  }
}