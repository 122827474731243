<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { audiEventsBasicMixin } from '../../modules/audi_events_mixins_module.js';
import Intersect from 'vue-intersect';
import { get } from "lodash";
import postscribe from "postscribe"

const positionToAlignment = {
  left: "start",
  center: "center",
  right: "end"
};

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, audiEventsBasicMixin],
  components: {
  	Intersect
  },
  props: {
    buttonIds: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      countdownText: "",
      interval: null,
    };
  },
  mounted() {
    const finalDate = new Date(this.content.countdown_date);
    this.interval = setInterval(() => {      
      const initDate = new Date();
      if (initDate.getTime() > finalDate.getTime()) {
        clearInterval(this.interval);
        Vue.set(this, 'countdownText', "0g 0h 0m 0s");
      } else {
        const difference = finalDate.getTime() - initDate.getTime();
        const days = Math.floor(difference/(24*60*60*1000));
        const hours = Math.floor(difference/(60*60*1000)) % 24;
        const minutes = Math.floor(difference/(60*1000)) % 60;
        const seconds = Math.floor(difference/1000) % 60;
        Vue.set(this, 'countdownText', `${days}g ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 100);

    if(this.content.musement_widget_url) {
      Vue.nextTick(() => {
        const jsSrc = `${this.content.musement_widget_url}`;
        postscribe(`#m_widget_script`, `<script src="${jsSrc}"><\/script>`, {
          done: () => {}
        });
      });
    }
  },
  methods: {
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
    isUserRegistered: Fandom.isUserRegistered,
    scrollToBottom: Fandom.scrollToBottom,
    toggleVideo(status) {
    	if (status) {
    		this.video.muted = true;
    		this.video.play();
    	} else {
    		this.video.pause();
    	}
    },
    toggleVideoPopup() {
      Vue.set(globalState, "videoPopupContent", { content: this.content, containerContent: this.containerContent });
    }
  },
  computed: {
  	video() {
  		return $(this.$refs.video)[0];
  	},
    paddingClass() {
      const insetClass = this.content.boxed ? "inset" : "";
      return Fandom.exists(this.content.padding_y) ? `py-${this.content.padding_y}` : insetClass;
    },
  	backgroundVideoUrl() {
      const videoDesktop = get(this, "content.background_video.url", false);
      return get(globalState, "viewport.xs", false) ? get(this, "content.background_video_mobile.url", videoDesktop) : videoDesktop;
    },
    isVideoPresent() {
      return !!this.content.play;
    },
    anchorId() {
    	return `anchor-${this.easyadminId.replace("|", "_")}`
    },
    buttonBottom() {
      return this.content.button_position === "bottom";
    },
    contentClasses() {
      return [this.content.theme ? `${this.content.theme}-theme` : "", this.contentType];
    },
    textStyle() {
      return {
        "color": this.content.text_color
      };
    },
    alignment() {
      return this.content.text_align || "left";
    },
    textAlignment() {
      return `text-${this.alignment}`;
    },
    justifyWithAlignment() {
      const position = positionToAlignment[this.alignment];
      return `justify-content-${position}`;
    },
    alignWithAlignment() {
      const position = positionToAlignment[this.alignment];
      return `align-items-${position}`;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentClasses" v-if="isContentVisibleToUser()" :id="anchorId" stripe-one-col-media-component>
    <div id="m_widget_script" v-if="content.musement_widget_url"></div>
    <div :class="content.boxed ? 'container' : 'container-fluid'">
      <div class="row" :class="paddingClass">
        <div class="position-relative col-12">
        	<div class="position-absolute-center bg-cover-center inview_animate_zoom" :style="{'background-image': getContentResponsiveImageURL('background_image')}">
        	</div>
        	<intersect @enter="toggleVideo(true)" @leave="toggleVideo(false)">
      	  	<div v-if="backgroundVideoUrl" class="video-16by9 position-absolute-center h-100">
      	      <video loop muted webkit-playsinline playsinline autoplay ref="video">
      	        <source :src="expandUrl(backgroundVideoUrl)" type="video/mp4">
      	      </video>
      	    </div>
        	</intersect>
          <div class="position-absolute-center linear-gradient" v-if="!content.hide_gradient"></div>
           <div class="container">
            <div class="row content-container align-items-center"
              :class="[{'mh-100vh-header': !content.height}, justifyWithAlignment]"
              :style="[{'height': content.height}]"
            >
              <div
                class="col-12 col-md-8 col-lg-6"
                :class="textAlignment"
                :style="textStyle"
                >
              	<img v-if="content.icon" :src="expandUrl(content.icon.url)" class="mb-4 icon" />
                <p class="category font-weight-bold mb-4" :class="content.category_class" v-if="content.category">{{content.category}}</p>
                <component v-if="content.title" :is="content.title_tag || 'h2'" :class="content.title_class || 'h1'" v-html="content.title"></component>
                <component class="font-weight-normal" v-if="content.subtitle" :is="content.subtitle_tag || 'h3'" :class="content.subtitle_class || 'h2'" v-html="content.subtitle"></component>
                <component v-if="content.description" :is="content.description_tag || 'div'" class="description" v-html="content.description"></component>
                <buttons :button-ids="buttonIds" :content="content" :opt-container-content="containerContent" v-if="!buttonBottom"></buttons>
                <div :style="{'padding-bottom': !!content.countdown_date ? '8rem' : null}"></div>
                 <img v-if="content.info_logo" :src="content.info_logo.url" class="info-logo d-sm-none pt-4" />
              </div>
            </div>
          </div>
          <span class="position-absolute-center d-flex flex-column justify-content-center align-items-center text-white" v-if="isVideoPresent">
            <svg class="cursor-pointer" @click="toggleVideoPopup()">
              <use class="play-icon" xlink:href="#play-icon"></use>
            </svg>
          </span>
          <div v-if="!content.hide_next_arrow && !buttonBottom" class="next-container d-flex align-items-center justify-content-center py-3 col-12">
          	<div @click="scrollToBottom(anchorId, 80)"><i class="fal fa-chevron-down animated infinite pulse"></i></div>
          </div>
          <div v-if="content.countdown_date || content.buttons_description || buttonBottom" class="next-container d-flex align-items-center justify-content-center py-5 col-12 flex-column">
            <div class="countdown-container d-flex flex-column justify-content-center align-items-center" v-if="content.countdown_date">
              <span class="countdown-description">Starting in</span>
              <h3 class="font-weight-bold"> {{countdownText}}</h3>
            </div>
            <div class="text-center text-white font-weight-bold" v-if="content.buttons_description">{{content.buttons_description}}</div>
            <buttons v-if="buttonBottom" :content="content" :opt-container-content="containerContent" class="my-2"></buttons>
          </div>
          <img v-if="content.info_logo" :src="content.info_logo.url" class="info-logo position-absolute d-none d-sm-block" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

[stripe-one-col-media-component] {
	position: relative;
  color: $white;

  .play-icon {
    stroke: $white;
  }

  @include media-breakpoint-up(sm){
    .inset .mh-100vh-header {
      min-height: calc(100vh - #{$header-height-sm} - 10rem);
    }
  }

  .info-logo {
    width: 12.5rem;
    height: auto;
    right: 3rem;
    bottom: 2rem;
  }
	
	.next-container {
		font-size: 1.5rem;
		position: absolute;
    bottom: 0rem;
    left: 0;
	}

  .category {
    display: inline-block;
    text-transform: uppercase;
    font-family: 'Audi Extended';

    &.category_danger {
      border-radius: 5px;
      padding: 0.5rem 1.25rem;
      background-color: $red;
    }
  }

	.icon {
    width: 25rem;
    max-width: 60%;

    @include ie {
      height: 100%;
    }
  }

  .description {
    font-size: 1rem;

    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }
  }

  .countdown-container {
    height: 8rem;

    h3 {
      font-family: $font-family-headings;
    }

    .countdown-description {
      font-size: 1rem;
    }
  }

  .content-container {
    //@include media-breakpoint-up(md) {
      padding-top: 4.5rem;
      padding-bottom: 4.5rem;
    //}
    min-height: 600px;

    @include media-breakpoint-up(xl) {
      max-height: 1080px !important;
    }

    @include media-breakpoint-down(sm) {
      height: auto !important;
    }
  }
}
</style>
