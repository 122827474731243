
<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import { generateToken } from 'src/modules/recaptcha_v3_module.js';
import Intersect from 'vue-intersect';

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  components: {
    Intersect
  },
  data: function () {
    return {
      form: {
        errors: [],
        loading: false,
        progress: null,
        data: {},
      }
    };
  },
  methods: {
    isReadonly(field) {
      return field.readonly || this.isInteractionDone(this.containerContent, this.content.interaction);
    },
    getButtonTextFormatted () {
      if (this.isInteractionDone(this.containerContent, this.content.interaction)) {
        return this.ft("globals.form.next");
      } else if (this.form.loading) {
        return this.ft("globals.form.loading");
      } else {
        return this.ft("globals.form.save");
      }
    },
    getFieldInputName(fieldName) {
      return `user_upload_interaction[${fieldName}]`;
    },
    getFieldPlaceholder(field) {
      const placeholder = (typeof field.placeholder == "object") ? field.placeholder[globalState.lang] : field.placeholder;
      return placeholder;
    },
    getFieldLabel(field) {
      return (typeof field.label == "object") ? field.label[globalState.lang] : field.label;
    },
    onAttachmentChange(event, field) {
      Vue.set(this.form.data, field.name, event.target.files[0]);
    },
    submitSimpleForm(event) {
      const target = $(event.currentTarget);

      if (!this.form.loading) {

        if (this.isInteractionDone(this.containerContent, this.content.interaction)) {
          this.$emit("submission-success");
        } else {

          this.form.loading = true;
          this.form.errors = null;

          let submitForm = (token) => { 
            const data = this.getFormData();
            if (token)
              data.append("recaptcha_enterprise_token", token);

            Fandom.ajax(
              {
                processData: false,
                contentType: false,
                method: target.attr("method"),
                url: target.attr("action"),
                data: data,
                xhr: () => {
                  const xhr = new window.XMLHttpRequest();
                  xhr.upload.addEventListener("progress", (evt) => {
                    if (evt.lengthComputable) {
                      this.progress = evt.loaded / evt.total * 100;
                    }
                  }, false);
                  return xhr;
                },
                success: (data) => {
                  // Unused correct feedback
                  // this.form.success = data.success;
                  if (data.success) {
                    this.updateContentUserInfo(this.containerContent, this.content.interaction, data);
                    this.$emit("submission-success");
                  } else {
                    this.form.errors = data.errors;
                    this.$emit("submission-errors");
                  }
                }
              },
              () => {
                this.progress = null;
                this.form.loading = false;
              }
            );
          }
          if (globalState.pageModel.aux.recaptcha_enterprise) {
            generateToken(submitForm, "submit");
          } else {
            submitForm();
          }

        }
      }
    },
    mayPrecompileFormData() {
      if (this.content.precompile) {
        Fandom.ajax({
          method: "GET",
          data: {
            interaction_id: this.content.interaction.id,
            container_content_name: this.containerContent.name
          },
          url: "/api/v5/get_submission_init_data",
          success: (data) => {
            Object.keys(data).forEach(key => {
              Vue.set(this.form.data, key, data[key]);
            });
          }
        });
      }
    },
    getFormData () {
      const formData = new FormData();
      this.formFields.forEach(field => {
        const formDataValue = this.form.data[field.name];
        if (formDataValue && field.type != "textseparator") {
          formData.append(this.getFieldInputName(field.name), formDataValue);
        }
      });
      formData.append("interaction_id", this.content.interaction.id);
      formData.append("content_id", this.containerContent.id);

      return formData;
    },
    mayInitializeFields() {
      this.formFields.forEach((field) => {
        if (field.type == "hidden") {
          Vue.set(this.form.data, field.name, field.value);
        } else if (field.type == "dropdown") {
          // The dropdown input must be initialized to empty string to display the placeholder
          Vue.set(this.form.data, field.name, "");
        }
      });
    }
  },
  mounted() {
    this.mayInitializeFields();
  },
  computed: {
    formFields() {
      return JSON.parse(this.content.form_fields).fields;
    },
    isFormLoading() {
      return this.form.loading || (Fandom.exists(this.form.progress) && parseInt(this.form.progress) < 100);
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" page-live-event-survey-item-component>
    <intersect @enter.once="mayPrecompileFormData()">
      <form @submit.prevent="submitSimpleForm($event)" :action="'/upload_simple_form'" method="post" enctype="multipart/form-data">
        <div v-for="(field, index) in formFields" :key="index">
          <div v-if="field.type == 'dropdown'" class="form-group">
            <label v-if="getFieldLabel(field)" class="form-label font-weight-bold">{{getFieldLabel(field)}}</label>
            <select class="custom-select" :required="field.required" :readonly="isReadonly(field)" v-model="form.data[field.name]">
              <option value="" disabled selected>{{getFieldPlaceholder(field)}}</option>
              <option v-for="(option, optionIndex) in field.options" :key="`option-${optionIndex}`" :value="option.value">{{option.label}}</option>
            </select>
          </div>
          <div v-else-if="field.type == 'textarea'" class="form-group">
            <label v-if="getFieldLabel(field)" class="form-label font-weight-bold">{{getFieldLabel(field)}}</label>
            <textarea v-model="form.data[field.name]" :placeholder="getFieldPlaceholder(field)" class="form-control" rows="3" :maxlength="field.maxlength || 1000" :readonly="isReadonly(field)"></textarea>
          </div>
          <div v-else-if="field.type == 'radio'" class="form-group">
            <label v-if="getFieldLabel(field)" class="form-label font-weight-bold">{{getFieldLabel(field)}}</label>
            <div class="custom-control custom-radio" v-for="(option, optionIndex) in field.options" :key="`option-${optionIndex}`">
              <input type="radio" :id="`option-${contentId.replace('|', '-')}-${field.name}-${optionIndex}`" :value="option.value"  v-model="form.data[field.name]" class="custom-control-input" :disabled="isReadonly(field)">
              <label class="custom-control-label" :for="`option-${contentId.replace('|', '-')}-${field.name}-${optionIndex}`">{{getFieldLabel(option)}}</label>
            </div>
          </div>
          <div v-else-if="field.type == 'checkbox'" class="form-group form-check">
            <input type="checkbox" class="form-check-input" v-model="form.data[field.name]" :readonly="isReadonly(field)">
            <label class="form-check-label">{{getFieldLabel(field)}}</label>
          </div>
          <div v-else-if="field.type == 'attachment'" class="form-group">
            <label v-if="getFieldLabel(field)" class="form-label font-weight-bold">{{getFieldLabel(field)}}</label>
            <div class="custom-file">
              <input type="file" class="custom-file-input" @change="onAttachmentChange($event, field)">
              <label class="custom-file-label">{{getFieldPlaceholder(field)}}</label>
              <small class="form-text text-muted" v-if="form.data[field.name] && form.data[field.name].name">{{form.data[field.name].name}}</small>
            </div>
          </div>

          <p v-if="field.type == 'textseparator'">{{getFieldLabel(field)}}</p>
        </div>

        <div class="d-flex justify-content-center">
          <button type="submit" class="btn my-3" :class="content.button_class || 'btn-outline-primary'">
            <div class="d-flex align-items-center">{{getButtonTextFormatted()}}<i class="pl-2 fal fa-long-arrow-right"></i></div>
          </button>
        </div>

        <form-feedback :errors="form.errors" :success="form.success" :success-message="ft('globals.form.success')"></form-feedback>
      </form>
    
    </intersect>
  </div>
</template>

<style lang="scss" scoped>
[page-live-event-survey-item-component] {

  .btn {
    i {
      font-size: 1.55rem;
    }
  }

  .form-label {
    font-size: 1.25rem;
  }

}
</style>
