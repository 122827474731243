<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  watch: {
    'content.live_interactions'(newVal, oldVal) {
      if ((!newVal) && this.content.comments) {
        $("#list-chat-list").click();
      }
    },
    'content.active_tab'(newVal) {
      $(`#list-${newVal}-list`).click();
    }
  },
  methods: {
    bannerHandler(btn) {
      const url = btn.url;
      if (url.startsWith("#")) {
        $(url).click();
      } else {
        window.location.href = url;
      }
    }
  }
};
</script>

<template>
  <div class="container-lg d-flex flex-column live-event-sidebar">
    <div class="row">
      <div class="list-group col-12 flex-row px-0" id="list-tab" role="tablist">
        <a
          role="tab"
          href="#list-chat"
          data-toggle="list"
          id="list-chat-list"
          aria-controls="chat"
          v-if="content.comments"
          class="list-group-item list-group-item-action rounded-0 border-left-0 border-right-0 border-top-0 border-bottom flex-shrink-1 m-0"
          :class="{'active': !content.active_tab || content.active_tab === 'chat'}"
          >
          {{ft("page_live_event.qa.title")}}
        </a>
        <a
          role="tab"
          href="#list-poll"
          data-toggle="list"
          id="list-poll-list"
          aria-controls="poll"
          v-if="content.live_interactions"
          class="list-group-item list-group-item-action rounded-0 border-left-0 border-right-0 border-top-0 border-bottom flex-shrink-1 m-0"
          :class="{'active': !content.comments || content.active_tab === 'poll' }"
          >
          {{ft("page_live_event.interactions.title")}}
        </a>
        <a
          v-if="content.additional_tab"
          role="tab"
          :href="`#list-${content.additional_tab.label}`"
          data-toggle="list"
          :id="`list-${content.additional_tab.label}-list`"
          :aria-controls="content.additional_tab.label"
          class="list-group-item list-group-item-action rounded-0 border-left-0 border-right-0 border-top-0 border-bottom flex-shrink-1 m-0"
          :class="{'active': content.additional_tab.label === content.active_tab }"
          >
          {{content.additional_tab.title}}
        </a>
        <div class="w-100 h-100 d-flex align-items-center justify-content-end border-bottom">
          <a class="d-flex align-items-center deco-none d-lg-none" data-toggle="collapse" href="#nav-tabContent" role="button" aria-expanded="false" aria-controls="nav-tabContent">
            <i class="fal fa-lg fa-times px-4 close-icon"></i>
            <i class="far fa-lg fa-angle-down px-4 open-icon"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="row interactions-area flex-fill">
      <div class="tab-content col-12 collapse show px-lg-0" id="nav-tabContent">
        <div  
          class="tab-pane fade"
          id="list-chat"
          role="tabpanel"
          aria-labelledby="list-chat-list"
          :class="{'show active': !content.active_tab || content.active_tab === 'chat'}"
          v-if="content.comments"
        >
          <page-live-event-qa
            :content="content.comments"
            :opt-container-content="containerContent"
          ></page-live-event-qa>
          <div class="banner p-4 w-100 text-center my-4 bg-dark" v-if="content.banner">
            <div class="p-2">
              <div v-if="content.banner.title" class="mb-4 p-2 text-white">{{content.banner.title}}</div>
              <button
                class="btn w-100 btn-light"
                v-if="content.banner.button"
                @click="bannerHandler(content.banner.button)"
              >{{content.banner.button.title}}</button>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="list-poll"
          role="tabpanel"
          aria-labelledby="list-poll-list"
          :class="{'show active': !content.comments || content.active_tab === 'poll'}"
          v-if="content.live_interactions"
        >
          <div class="row" v-if="content.live_interactions.length > 0">
            <div class="col-12 py-2">
              <transition-group
                enter-active-class="animated fadeIn"
                leave-active-class="animated fadeOut"
                tag="div"
              >
                <div v-for="liveInteraction in content.live_interactions.slice().reverse()" :key="`interaction-${liveInteraction.interaction.id}`">
                  <component
                    class="my-3"
                    :is="liveInteraction.layout || 'interaction-versus'"
                    :content="liveInteraction"
                    :id="`anchor-${containerContent.id}-${liveInteraction.interaction.id}`"
                    :opt-container-content="containerContent"
                  ></component>
                </div>
              </transition-group>
            </div>
          </div>
        </div>
        <div
          v-if="content.additional_tab"
          class="tab-pane fade"
          :id="`list-${content.additional_tab.label}`"
          role="tabpanel"
          :aria-labelledby="`list-${content.additional_tab.label}-list`"
          :class="{'show active': content.additional_tab.label === content.active_tab}"
        >
          <div class="row" v-if="content.additional_tab.interactions && content.additional_tab.interactions.length > 0">
            <div class="col-12 py-2">
              <transition-group
                enter-active-class="animated fadeIn"
                leave-active-class="animated fadeOut"
                tag="div"
              >
                <div v-for="interaction in content.additional_tab.interactions.slice().reverse()" :key="`interaction-${interaction.interaction.id}`">
                  <component
                    class="my-3"
                    :is="interaction.layout || 'interaction-versus'"
                    :content="interaction"
                    :id="`anchor-${containerContent.id}-${interaction.interaction.id}`"
                    :opt-container-content="containerContent"
                  ></component>
                </div>
              </transition-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.deco-none {
  color: inherit !important; 
}

.live-event-sidebar {

  z-index: 1019;
  position: relative;
  color: var(--theme-color);
  border-bottom: 1px solid $gray-300;
  background-color: var(--theme-background);

  @include media-breakpoint-up(lg) {
    position: sticky;
    top: $header-height;
    height: calc(100vh - #{$header-height});
    border-bottom: 0;
  }
}

.list-group-item-action {
  width: auto !important;
  background-color: var(--theme-background);
  color: var(--theme-color);

  &.active {
    color: inherit;
    font-weight: 500;

    &:after {
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      position: absolute;
      border-bottom: 3px solid var(--theme-color) !important;
    }
  }
}

[data-toggle="collapse"] {
  &:not(.collapsed) > .open-icon,
  &.collapsed > .close-icon {
    display: none;
  }
}

.interactions-area {
  max-height: 100%;
  
  @include media-breakpoint-up(lg) {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  @include ie {
    height: 100%;
  }
}
</style>
