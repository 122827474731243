let polling = null;
let pollingIdToFunc = {};
let pollingIdToTime = {};
let pollingIsActive = false;

function addPolling(pollingId, func, pollingTime = 5000) {
  console.log(`%c${pollingId} polling\n\tstatus: added`, "color: #aaaaaa");
  pollingIdToFunc[pollingId] = func;
  pollingIdToTime[pollingId] = pollingTime;
  checkPolling();
}

function removePolling(pollingId) {
  console.log(`%c${pollingId} polling\n\tstatus: removed`, "color: #aaaaaa");
  delete pollingIdToFunc[pollingId];
  checkPolling();
}

function updatePollingTime(pollingId, pollingTime) {
  console.log(`%c${pollingId} polling\n\tstatus: updated`, "color: #aaaaaa");
  pollingIdToTime[pollingId] = pollingTime;
  checkPolling();
}

function checkPolling() {
  if(Object.keys(pollingIdToFunc).length > 0) {
    clearInterval(polling);
    polling = setInterval(() => {
      executePollingActions();
    }, minPollingTime());
  } else {
    if (polling) {
      clearInterval(polling);
      polling = null;
    } 
  }
}

function minPollingTime() {
  let result = null;
  for (const key in pollingIdToTime) {
    if (!result || pollingIdToTime[key] < result) {
      result = pollingIdToTime[key];
    } 
  }
  return result || 5000;
}

function executePollingActions() {
  for (const key in pollingIdToFunc) {
    console.log(`%c${key} polling\n\tstatus: in action\n\tdelay:`, "color: #aaaaaa", minPollingTime());
    pollingIdToFunc[key]();
  }
}

export {
  addPolling,
  removePolling,
  updatePollingTime
}