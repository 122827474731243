<script>
export default {
  props: {
    col: {
      type: Number,
      default: 8
    },
    content: Object,
    align: String
  },
  methods: {
    alignTextToJustify: Fandom.alignTextToJustify,
  },
  computed: {
    columnClasses() {
      const columnWidth = Fandom.exists(this.content.width) ? this.content.width : this.col;
      return [
        `col-lg-${columnWidth}`
      ];
    },
    isValid() {
      return this.content.title || 
             this.content.subtitle || 
             this.content.description;
    }
  }
};
</script>

<template>
  <div class="container" v-if="isValid" stripe-header-component>
    <div class="row" :class="`justify-content-md-${alignTextToJustify(align)}`">
      <div :class="[columnClasses, `text-${align ? align : 'center'}`]">
        <p class="category" v-if="content.category">{{content.category}}</p>
        <component v-if="content.title" :is="content.title_tag || 'h2'" class="h2">{{content.title}}</component>
        <component v-if="content.subtitle" :is="content.subtitle_tag || 'h3'" class="h2">{{content.subtitle}}</component>
        <component v-if="content.description" :is="content.description_tag || 'p'" v-html="content.description"></component>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[stripe-header-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);
}
</style>
