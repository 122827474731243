<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin]
};

</script>

<template>
  <div v-easyadmin="easyadminId" class="themed-item" :class="[contentType, paddingY]" style="theme" stripe-separator-component>
    <div class="container">
      <div class="row">
        <hr>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  hr {
    border-color: $gray-ccc;
    width: 100%;
  }
</style>
