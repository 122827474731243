function getFavouriteLists(successCallback) {
  Fandom.ajax({
    method: "POST",
    url: "/api/v5/favourite/get_lists",
    data: {},
    success: (data) => {
      if (data.success) {
        if (!globalState.pageModel.favouriteLists) {
          Vue.set(globalState.pageModel, "favouriteLists", {});
        }
        for (let list of data.lists) {
          globalState.pageModel.favouriteLists[list.id] = list.name;
        }
        if(successCallback) {
          successCallback(data);
        }
      }
    },
    error: (xhr, ajaxOptions, thrownError) => {}
  });
}

function getOrCreateList(name, source="", successCallback) {
  Fandom.ajax({
    method: "POST",
    url: "/api/v5/favourite/get_or_create_content_list",
    data: {name, source},
    success: (data) => {
      if (data.success) {
        if (!globalState.pageModel.favouriteLists) {
          Vue.set(globalState.pageModel, "favouriteLists", {});
        }

        globalState.pageModel.favouriteLists[data.favourite_list.id] = data.favourite_list.name;

        if (successCallback) {
          successCallback(data.favourite_list);
        }
      }
    },
    error: (xhr, ajaxOptions, thrownError) => {}
  });
}

function getContentLists(contentId, successCallback) {
  Fandom.ajax({
    method: "POST",
    url: "/api/v5/favourite/get_content_lists",
    data: {content_cache_id: contentId},
    success: (data) => {
      if (data.success) {
        if(successCallback) {
          successCallback(data);
        }
      }
    },
    error: (xhr, ajaxOptions, thrownError) => {}
  });
}

function createFavouriteList(listName, successCallback) {
  const component = this;
  Fandom.ajax({
    method: "POST",
    url: "/api/v5/favourite/create_list",
    data: { list_name: listName },
    success: (data) => {
      if (successCallback) {
        successCallback(data);
      }
    },
    error: (xhr, ajaxOptions, thrownError) => {}
  });
}

function editFavouritList(listId, listName, successCallback) {
  Fandom.ajax({
    method: "POST",
    url: "/api/v5/favourite/edit_list",
    data: { list_id: listId, list_name: listName },
    success: (data) => {
      successCallback(data);
    },
    error: (xhr, ajaxOptions, thrownError) => {}
  });
}

function deleteFavouriteList(listId, successCallback) {
  Fandom.ajax({
    method: "POST",
    url: "/api/v5/favourite/delete_list",
    data: { list_id: listId },
    success: (data) => {
      if (data.success) {
        Vue.delete(globalState.pageModel.favouriteLists, listId);
        successCallback(data);
      }
    },
    error: (xhr, ajaxOptions, thrownError) => {}
  });
}

function addOrRemoveContentFromFavouriteList(contentCacheId, listId) {
  Fandom.ajax({
    method: "POST",
    url: "/api/v5/favourite/add_or_remove_content_to_list",
    data: { content_cache_id: contentCacheId, list_id: listId },
    success: (data) => {
      if (data.success) {
        console.log(data);
      }
    },
    error: (xhr, ajaxOptions, thrownError) => {}
  });
}

function getContentsFromFavouriteList(listId, excludedContentIds, successCallback, favouriteContentsLayout) {
  Fandom.ajax({
    method: "POST",
    url: "/api/v5/favourite/get_favourite_contents",
    data: { list_id: listId, excluded_content_ids: excludedContentIds, layout: favouriteContentsLayout ? favouriteContentsLayout : "tile" },
    success: (data) => {
      if (successCallback){
        successCallback(data);
      }
    },
    error: (xhr, ajaxOptions, thrownError) => {}
  });
}

const favouriteInteractionMixin = {
  computed: {
    containerContentDefaultList() {
      return `container-${this.containerContent.title}-${this.containerContent.id}`.toLowerCase().replaceAll(' ','-').replace(/[^a-zA-Z0-9-]/g, "");
    },
    containerContentFavouriteListId() {
      const favouriteLists = globalState.pageModel.favouriteLists || {};
      return Object.keys(favouriteLists).find(key => favouriteLists[key] === this.containerContentDefaultList);
    }
  },
  methods: {
    getContentLists,
    getFavouriteLists,
    getOrCreateList,
    createFavouriteList,
    addOrRemoveContentFromFavouriteList,
    getContentsFromFavouriteList,
    editFavouritList,
    deleteFavouriteList,
    getOrCreateContainerContentList(source="") {
      if (typeof this.containerContentFavouriteListId === 'undefined') {
        this.getOrCreateList(this.containerContentDefaultList, source);
      }
    }
  }
};

export {
  favouriteInteractionMixin,
}
