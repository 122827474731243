<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { liveEventsMixin } from 'src/modules/live_events_module.js';
import { addPolling, removePolling } from 'src/modules/polling_module.js';
import { isInteractionDone } from "src/modules/interactions_module.js";
import Flickity from "flickity";

export default {
  mixins: [fandomBasicMixin, liveEventsMixin],
  data: function () {
    return {
      carousel: null,
      carouselIndex: 0,
      showSurveyModal: false,
      noticeEmitted: false,
    };
  },
  mounted() {
    if(this.content.active == true){
     this.emitSurveyNotice();
    }
  },
  watch: {
    'content.active'(newValue) {
       if(newValue == true) {
        this.emitSurveyNotice();
      } else {
        this.showSurveyModal = false;
        this.$emit("notice", { active: false });
        this.noticeEmitted = false;
      }
    },
    surveyDone(newValue, previousValue) {
      if (newValue) {
        this.$emit("notice", { active: false });
      }
    },
    showSurveyModal(newValue, previousValue) {
      if (newValue) {
        disableBodyScroll();
        Vue.nextTick(() => {
          this.carousel = new Flickity(this.$refs["survey-carousel"], {
            pageDots: false,
            prevNextButtons: false,
            draggable: false,
            initialIndex: this.surveyInitialIndex,
            accessibility: false
          });

          this.carouselIndex = this.surveyInitialIndex;
          this.carousel.on("change", (index) => {
            this.carouselIndex = Math.min(index, this.content.survey.length - 1);
          });

        });

      } else {
        clearAllBodyScrollLocks();
      }
    }
  },
  computed: {
    surveyInitialIndex() {
      let index = 0;
      this.content.survey.forEach((surveyStep) => {
        if (isInteractionDone(this.containerContent, surveyStep.interaction)) {
          index++;
        }
      });
      return index;
    },
    surveyDone() {
      let done = true;
      this.content.survey.forEach((surveyStep) => {
        done = done && isInteractionDone(this.containerContent, surveyStep.interaction);
      });
      return done;
    },
    // unused
    // surveyIndex() {
    //   return `${this.carouselIndex + 1} / ${this.content.survey.length}`;
    // },
    surveyPercentage() {
      if (this.surveyDone) {
        return 100;
      } else {
        return this.carouselIndex * 100 / this.content.survey.length;
      }
    }
  },
  methods: {
    emitSurveyNotice() {
      if(!this.noticeEmitted && !this.surveyDone){
        this.showSurveyModal = true;
        this.$emit("notice", { active: true });
        this.noticeEmitted = true;
      }
    },
    toggleSurvey() {
      this.showSurveyModal = !this.showSurveyModal;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" course-survey-carousel-component>
    <transition 
      enter-active-class="animated fadeIn faster" 
      leave-active-class="animated fadeOut faster" 
    >
      <div class="survey-modal__background" v-if="showSurveyModal"></div>
    </transition>
    <transition 
      enter-active-class="animated slideInDown faster" 
      leave-active-class="animated slideOutUp faster" 
    >
      <div class="survey-modal" v-if="showSurveyModal">
        <div class="survey-modal__content">
          <div class="d-flex flex-column justify-content-between">
            <div class="survey-modal__header w-100 d-flex justify-content-between">
              <div class="d-flex align-items-center"><h2 class="mb-0 p-3 h1">{{ft("globals.survey.title")}}</h2></div>
              <a href="javascript: void(0)" @click="toggleSurvey()" class="p-4"><i class="fal fa-times"></i></a>
            </div>
            <div class="w-100 survey-carousel py-5" ref="survey-carousel">
              <div v-for="(surveyContent, index) in content.survey" class="survey-item d-flex justify-content-center w-100">
                <div class="col-12 col-md-auto px-4">
                  <course-survey-carousel-item :content="surveyContent" :opt-container-content="containerContent" @submission-success="carousel.next()"></course-survey-carousel-item>  
                </div>
              </div>
              <!-- last slide -->
              <div class="w-100 h-100 d-flex justify-content-center align-items-center flex-column py-5 text-center survey-item__end">
                <div class="px-5">
                  <div class="my-4" v-html="ft('globals.survey.success')"></div>
                  <a href="javascript: void(0)" :class="containerContent.theme == 'dark' ? 'btn-outline-dark' : 'btn-outline-light'" class="btn my-4" @click="toggleSurvey()">{{ft("globals.survey.close")}}</a>
                </div>
              </div>
            </div>
            <div class="p-3 text-center">
              <div class="progress survey__progress mx-auto my-2">
                <div class="progress-bar" role="progressbar" :style="{'width': `${surveyPercentage}%`}"></div>
              </div>
              <p class="mb-0"><small><b>{{Math.floor(surveyPercentage)}}%</b> completato</small></p>
            </div>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<style lang="scss">
[course-survey-carousel-component] {

  .survey-modal__background {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    background-color: var(--theme-color);
    opacity: .75;
    z-index: 1090;
  }

  .survey-modal {
    padding: 3rem;
    z-index: 1090; 

    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    .survey-modal__content {
      border-radius: 0.5rem;
      overflow-y: scroll;
      height: 100%;
      width: 100%; 
      background-color: var(--theme-background);
      color: var(--theme-color);

      // > div {
      //   min-height: 100vh;
      // }
    }
  }

  .survey-modal__header a {
    color: var(--theme-color);
    font-size: 2rem;
  }

  .survey__progress {
    height: 10px;

    .progress-bar {
      background: rgb(222,16,45);
      background: linear-gradient(270deg, rgba(222,16,45,1) 0%, rgba(98,50,140,1) 100%);
    }
  } 

  [course-survey-carousel-item-component], .survey__progress {
    width: 600px;
    max-width: 100%;
  }

  @include media-breakpoint-down(xs) {
    
    .survey-modal {
      padding: 1.5rem;
    }
  }

    // for backdrop, because otherwise it will do something weird
  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: .75;}
  }

  @keyframes fadeOut {
    0% {opacity: .75;}
    100% {opacity: 0;}
  }
}
</style>