<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      contentFiltering: []
    }
  },
  mounted() {
    this.initFilters();
    if (this.content.$id) {
      console.log(`%Warning\n\tmessage: contents with layout 'grid-filter' should be standalone contents, in order to prevent depth conflicts`, "color: yellow");
    }
  },
  methods: {
    initFilters() {
      this.contentFiltering = Array.from(this.content.filters, () => []);
    },
    toggleFilter(index, filter) {
      const pos = this.contentFiltering[index].findIndex(f => f == filter);
      if (pos > -1) {
        this.contentFiltering[index].splice(pos,1);
      } else {
        this.contentFiltering[index].push(filter);
      }

      this.applyFilters();
    },
    toggleFiltersModal() {
      $(`#${this.content.name}-filters-modal`).modal('toggle');
    },
    reset() {
      this.initFilters();
      this.applyFilters();
    },
    removeAndApply(filter) {
      let index = 0;
      this.contentFiltering.forEach(filterGroup => {
        if (filterGroup.indexOf(filter) > -1) {
          this.toggleFilter(index, filter);
        }
        index++;
      });
    },
    applyFilters() {
      Fandom.emit('grid-filter-apply', {
        contentFiltering: this.contentFiltering,
        handlerContents: (this.content.handler_contents || "")
      });
    }
  },
  computed: {
    buttonClass() {
      return this.mainContent.theme == 'dark' ? 'btn-outline-white' : 'btn-outline-black';
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :id="`ref-${easyadminId}`" :class="[contentType, paddingY]" v-if="(content.filters || []).length > 0" stripe-filters-component>
    <div class="container title-section">
      <div class="d-flex align-items-center flex-wrap justify-content-center justify-content-md-between">
        <h2 class="h2 mb-md-0" v-if="content.title">{{content.title}}</h2>
        <button class="btn" :class="buttonClass" @click="toggleFiltersModal()">
          <svg class="filter-icon mr-2">
            <use xlink:href="#filter-icon"></use>
          </svg>
          {{ft('globals.filter')}}
        </button>
      </div>
      <div class="row my-4 text-left" v-if="contentFiltering.flat().length > 0">
        <div class="col">
          <div class="mx-n2">
            <label class="bg-black px-3 py-1 text-white mx-2 cursor-pointer" v-for="filter in contentFiltering.flat()" @click="removeAndApply(filter)">
              {{getContent(filter).title}} <i class="fal fa-times pl-1"></i>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade align-items-center justify-content-center" ref="filters-modal" :id="`${content.name}-filters-modal`">
      <div class="modal-dialog filters-modal modal-lg">
        <div class="modal-content rounded-0">
          <div class="modal-header border-bottom-0 position-absolute">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="modal-body container pb-sm-3 pb-md-4">
            <div class="filters-container px-3 px-md-4 mb-4 mb-md-5 pt-4">
              <div v-for="(filter, index) in content.filters" :key="index">
                <h4 class="category-title text-black border-bottom pb-2" :class="{'mt-4 pt-md-2': index > 0}">{{getContent(filter).title}}</h4>
                <div class="subfilters-container mx-n1">
                  <button
                    class="btn btn-gray-300 m-1 rounded-0 px-4 py-2"
                    v-for="(subfilter, subindex) in getContent(filter).children"
                    :class="{'active': (contentFiltering[index] || []).indexOf(subfilter) > -1, 'big': getContent(filter).main_category}"
                    :key="`subfilter-${index}-${subindex}`"
                    @click="toggleFilter(index,subfilter)"
                  >
                    {{getContent(subfilter).title}}
                  </button>
                </div>
              </div>
            </div>
            <div class="w-100 d-flex justify-content-center py-3">
              <button class="btn px-4 mr-2 btn-black" @click="toggleFiltersModal()">{{ft("globals.apply_selection")}}</button>
              <button class="btn btn-outline-black px-4" @click="reset()">{{ft("globals.reset_filters")}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[stripe-filters-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  .modal .btn.active {
    background-color: $black;
    color: $white;
  }

  .filter-icon {
    width: 1.25rem;
    height: 1.25rem;
    stroke: var(--theme-color);
    transition: all .25s;
  }

  .modal-header  {
    right: 0;
    z-index: 1;
  }

  .category-title {
    font-family: $font-family-headings;
    font-size: 1.25rem;
  }

  button.big {
    font-size: 18px;
  }

  .btn:hover .filter-icon {
    stroke: var(--theme-background);
  }

  .title-section {
    @include media-breakpoint-down(sm) {
      text-align: center;

      h2 {
        font-size: 1rem;
      }

      .btn {
        width: 100%;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .modal-lg {
      max-width: 700px;
    }
  }

  @include media-breakpoint-up(lg) {
    .modal-lg {
      max-width: 950px;
    }
  }
}
</style>