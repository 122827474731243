import { render, staticRenderFns } from "./course-product-streaming-basic.vue?vue&type=template&id=27de95bf"
import script from "./course-product-streaming-basic.vue?vue&type=script&lang=js"
export * from "./course-product-streaming-basic.vue?vue&type=script&lang=js"
import style0 from "./course-product-streaming-basic.vue?vue&type=style&index=0&id=27de95bf&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports