<template>
  <div class="player-controls-interactive pt-2">
    <button v-if="likeInteraction!==null" class="position-relative like-button player-button" title="like" @click="likes++">
      <i class="fas fa-heart position-relative">
        <interaction-like v-model="likes" :content="likeInteraction" :opt-container-content="containerContent"></interaction-like>
      </i>
    </button>
    <button v-if="groupIcons" class="group-button player-button" title="group" @click="$emit('toggle-right-content')">
      <i class="fa-solid fa-grid-2"></i>
    </button>
    <template v-else>
      <button v-if="hasQuiz && controls.includes('quiz')" :class="{'active': activeControls.includes('quiz')}" class="quiz-button player-button" title="quiz" @click="$emit('toggle-right-content', PLAYER_CONTENT.QUIZ)">
        <i class="far fa-comments-question-check"></i>
      </button>
      <button v-if="hasComments && controls.includes('comments')" :class="{'active': activeControls.includes('comments')}" class="comments-button player-button" title="comments" @click="$emit('toggle-comments')">
        <i class="fas" :class="activeControls.includes('comments') ? 'fa-comment-alt': 'fa-comment-alt-slash'">
          <span v-if="newCommentsCount" class="badge badge-pill">{{newCommentsCount}}</span>
        </i>
      </button>
      <button v-if="hasProducts && controls.includes('wishlist')" :class="{'active': activeControls.includes('wishlist')}" class="wishlist-button player-button" title="wishlist" @click="$emit('toggle-right-content', PLAYER_CONTENT.WISHLIST)">
        <i class="far fa-bookmark"></i>
      </button>
      <button v-if="hasProducts && controls.includes('products')" :class="{'active': activeControls.includes('products')}" class="products-button player-button" title="products" @click="$emit('toggle-right-content', PLAYER_CONTENT.PRODUCTS)">
        <i class="far fa-shopping-bag"></i>
      </button>
      <button v-if="hasInfo" :class="{'active': activeControls.includes('info')}" class="info-button player-button" title="info" @click="$emit('toggle-right-content', PLAYER_CONTENT.INFO)">
        <i class="far fa-circle-info"></i>
      </button>
    </template>
  </div>
</template>

<script>
import { PRESETS_TYPE, PLAYER_CONTENT, RATIO } from 'src/modules/player/constants';
import config from 'src/modules/player/config';
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { ActionCableComponentsMixin } from "src/modules/websocket_module.js";
import { InteractionLike } from 'interaction';

export default {
  mixins: [fandomBasicMixin, ActionCableComponentsMixin],
  components: { InteractionLike },
  props: {
    preset: {
      type: String,
      default: "default",
      validator: (value) => {
        return Object.values(PRESETS_TYPE).includes(value.toLowerCase())
      }
    },
    quiz: {
      type: Array,
      required: false,
      default: () => {return []}
    },
    activeControls: {
      type: Array, // array of PLAYER_CONTENT
      default: () => {return []}
    },
    groupIcons: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      likes: 0,
      PLAYER_CONTENT,
      controls: config.presets[this.preset],
      newCommentsCount: 0
    }
  },
  mounted() {
    if (this.hasComments) {
      const contentName = this.getContentName(this.optContainerContent.comments);
      Fandom.onEvent(`${this._uid}`, ({room, status, receivedData}) => {
        if (room !== contentName) {return;}
        if (status === 'received') {
          console.log('received', receivedData);
          if (!this.activeControls.includes(PLAYER_CONTENT.COMMENTS)) {
            this.newCommentsCount++;
          }
        }
      });

      this.subscribeWithEvent(this.optContainerContent.comments);
    }
  },
  computed: {
    likeInteraction() {
      return this.content?.like ?? null;
    },
    hasComments() {
      return Object.keys(this.optContainerContent?.comments ?? {}).length>0;
    },
    hasProducts() {
      return Object.keys(this.optContainerContent?.products ?? {}).length>0;
    },
    hasInfo() {
      return Object.keys(this.optContainerContent?.info ?? {}).length>0;
    },
    hasQuiz() {
      return this.quiz.length > 0;
    },
    ratio() {
      return this.content?.ratio ?? RATIO.HORIZONTAL;
    }
  },
  watch: {
    activeControls() {
      if (this.activeControls.includes(PLAYER_CONTENT.COMMENTS)) {
        this.newCommentsCount = 0;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.player-controls-interactive {
  .player-button {
    cursor: pointer;
    touch-action: manipulation;
    border: none;
    color: $white;
    margin: 7px 0;
    width: 40px;
    height: 40px;
    background: rgba(170, 170, 170, 0.5);
    mix-blend-mode: normal;
    backdrop-filter: blur(10px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active, &:hover {
      background-color: rgba(238, 238, 238, 0.8);
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .badge {
      position: absolute;
      background: $player-red-color;
      left: 0;
      top: 0;
      font-weight: 400;
      font-size: .5rem;
      padding: .4em .4em;
      min-width: 14px;
    }

    i {
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 16px;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, .5);
    }

    &:focus {
      outline: none;
    }
  }
}
</style>