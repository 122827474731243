<script>
import { get } from "lodash";

export default {
  props: {
    item: { required: true },
  },
  data() {
    return {
      content: this.item      
    };
  },
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl
  },
  computed: {
    thumbnail() {
      return get(this.content, "thumbnail.url", null);
    }
  }
};
</script>

<template>
  <div v-if="!content.searchText" class="p-2 d-flex align-items-center" stripe-search-autocomplete-item-component>
    <div class="mr-2">
     <div v-if="thumbnail" class="bg-cover-center item-thumbnail" :style="{'background-image': getBackgroundUrl(thumbnail)}">
      </div>
      <div class="item-thumbnail d-flex align-items-center justify-content-center" v-else>
        <i class="far fa-image fa-2x"></i>
      </div>
    </div>
    <div>    
      <p class="mb-0" v-html="content.title"></p>
      <p class="mb-0" v-html="content.headline"></p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$stripe-search-autocomplete-item-thumbnail-size: 5rem;

[stripe-search-autocomplete-item-component] {
  .item-thumbnail {
    width: $stripe-search-autocomplete-item-thumbnail-size;
    height: $stripe-search-autocomplete-item-thumbnail-size;
    background-color: $gray-300;
  }
}
</style>
