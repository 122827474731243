<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { isInteractionDone } from 'src/modules/interactions_module.js';
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin],
  props: ["propBookmark"],
  data() { 
    return {
      bookmark: this.propBookmark,
      unitNumber: 1
    } 
  },
  methods: {
    get: get,
    isInteractionDone: isInteractionDone,
    setBookmark(chapter, index) {
      if (chapter.$id != this.bookmark.$id) {
        this.bookmark = chapter;
        this.unitNumber = index + 1;
        this.$emit('set-bookmark', { chapter: chapter, unitNumber: this.unitNumber });
      }
    },
    isChapterActive(chapter) {
      return  this.bookmark.$id == chapter.$id;
    },
    isListActive(subchapters) {
      return subchapters.findIndex((subc) => this.isChapterActive(subc)) > -1;
    }
  }
};
</script>

<template>
  <div id="accordion" class="mh-100vh" course-sidebar-component>
    <div class="course-sidebar__header px-4 py-3 d-flex justify-content-between align-items-center">
      <span class="h2 mb-0">{{ft('ecommerce.content')}}</span>
      <a href="javascript: void(0)" @click="$emit('toggle-sidebar')" class="course-sidebar__close">
        <i class="fal fa-2x fa-times"></i>
      </a>
    </div>
    <div class="card px-3" v-for="(chapter, index) in content.chapters" :key="`chapter-${index}`">
      <div class="card-header py-1">
        <h3 class="mb-0">
          <button
            :aria-expanded="(index + 1 == unitNumber)"
            data-toggle="collapse"
            :aria-controls="`collapse${chapter.$id}`"
            :data-target="`#course-chapter-${chapter.$id}`"
            :class="{'collapsed': index + 1 != unitNumber}"
            class="btn btn-link w-100 course-index__item d-flex justify-content-between px-0 py-2 rounded-0 align-items-center"
          >
            <div class="text-left pr-2" :class="{'font-weight-normal': isListActive(chapter.chapters)}">{{chapter.chapter_title || chapter.title}}</div>
            <small><i class="far fa-chevron-up"></i></small>
          </button>
        </h3>
      </div>
      <div :id="`course-chapter-${chapter.$id}`" class="collapse" :class="{'show': (index + 1 == unitNumber)}" aria-labelledby="headingOne" data-parent="#accordion">
        <div class="card-body p-0">
          <ul v-if="chapter.chapters" class="list-unstyled">
            <li v-for="(subchapter, subindex) in chapter.chapters" class="px-3 py-2 d-flex" :key="`subchapter-${subindex}`" :class="{'course-index__item__active': isChapterActive(subchapter)}">
              <div class="course-sidebar__item-icon pr-3 pl-4">
                <i v-if="isChapterActive(subchapter)" class="text-white far fa-play-circle"></i>
                <i v-else-if="isInteractionDone(containerContent, get(subchapter, ['play', 'interaction'], false))" class="text-white fas fa-circle"></i>
                <i v-else class="text-white fal fa-circle"></i>
              </div>
              <a href="javascript: void(0)" class="course-index__item secondary" @click="setBookmark(subchapter, index)">{{subchapter.chapter_title || subchapter.title}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[course-sidebar-component] {
  background: #333333;

  .course-sidebar__header {
    color: $white;

    .course-sidebar__close {
      color: $white;
    }
  }

  .course-sidebar__item-icon {
    i { color: $white; }
  }

  .course-index__item__active {
    a {
      color: $white !important;
      font-weight: bold !important;
    }
  }

  .course-index__item {
    color: $white;
    text-transform: none;
    font-size: 1.125rem;
    font-weight: 300;
    
    .far.fa-chevron-up {
      transition: transform .25s;
    }

    &.collapsed .far.fa-chevron-up {
      transform: rotate(180deg);
    }

    &.secondary {
      font-size: 0.875rem;
    }
  }

  .card {
    border-radius: 0;
    border-bottom: none;
    background-color: transparent;
  }

  .card-header {
    border-bottom: none;
    border-radius: 0 !important;
    background-color: transparent;
    color: $black;
  }

  .card:not(:first-child) {
    border-top: none;
  }

}
</style>