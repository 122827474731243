<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin],
  data: function () {
    return {
      videoPlayer: {},
      countDownStopped: false
    };
  },
  watch: {
    "videoPlayer.firstPlay"(newVal, oldVal) {
      if (newVal) {
        const userTrackingContent = get(this.content, ['play', "user_tracking_content"], false);
        if (userTrackingContent) {
          Fandom.ajax({
            type: "POST",
            url: Fandom.applyContextToUrl("/api/v1/event_mkt_tracking"),
            data: {
              content_cache_id: this.containerContent.id,
              interaction_id: this.content.play.interaction.id
            },
            success: (data) => {
              console.log("%cfandom tracking\n\tfandom_action: " + (data.fandom_action || "none") + "\n\tmyaudi_action: " + (data.myaudi_action || "none"), "color: #aaaaaa");
            }
          });
        }
      }
    },
    "videoPlayer.end"(newValue, previousValue) {
      if (newValue) {
        this.countDownStopped = false;
      }
    }
  },
  methods: {
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" course-product-video-chapter-component>
    <div class="bg-black position-relative">
      <embed-advanced :content="content" 
                      :opt-container-content="containerContent"
                      @video-player-handler="videoPlayer = arguments[0]"
                      ref="embed-advanced"
      ></embed-advanced>
      <div class="d-flex justify-content-center align-items-center flex-column py-4 text-center text-white bg-black position-absolute-center" v-if="videoPlayer.end && !countDownStopped">
        <course-countdown
          :content="content"
          :opt-container-content="containerContent"
          :initalCountdown="3"
          @next-bookmark="$emit('next-bookmark')"
          @countdown-stopped="countDownStopped = true"
        >  
        </course-countdown>
      </div>
    </div>
    <stripe-one-col-text v-if="content.description" :content="content" :opt-container-content="containerContent" class="py-5"></stripe-one-col-text>
    <component :is="stripe.layout" :content="stripe" v-for="(stripe, index) in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
	</div>
</template>

<style lang="scss">
[course-product-video-chapter-component] {
}
</style>