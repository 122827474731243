<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    mayOpenModal() {
      if (this.bio) {
        $(this.$refs["modal"]).modal("show");
      }
    },
  },
  computed: {
    photo() {
      const url = get(this, ["content", "photo", "url"], false);
      return url ? { "background-image": `url(${url})` } : false;
    },
    name() {
      return get(this, ["content", "name"], false);
    },
    role() {
      return get(this, ["content", "role"], false);
    },
    bio() {
      return get(this, ["content", "bio"], false);
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType">
    <a class="text-decoration-none" href="javascript:void(0)" @click="mayOpenModal" :class="{'cursor-default': !bio}">
      <slot></slot>
    </a>
    <div ref="modal" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content bg-light text-dark">
          <div class="modal-header border-0 pb-0">
            <button type="button" class="close cursor-pointer" data-dismiss="modal" aria-label="Close">
              <i class="fal fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body px-4 pb-4 pt-0">
            <div class="bg-cover-center rounded-circle modal-photo mb-4" v-if="photo && name" :title="name" :style="photo"></div>
            <h2 v-if="name">{{name}}</h2>
            <h3 v-if="role">{{role}}</h3>
            <div v-if="bio" v-html="bio"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.cursor-default {
  cursor: default !important;
}

.modal-photo {
  width: 5rem;
  height: 5rem;
}
</style>
