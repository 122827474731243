<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { videoBasicMixin } from "src/modules/ivs_module.js";
import embedVideoMixin from "src/modules/embed_video_module.js";
import { merge, get } from 'lodash';

export default {
  mixins: [fandomBasicMixin, videoBasicMixin, embedVideoMixin],
  props: {
    controlBar: {
      type: Boolean,
      default: true
    },
    areaClickable: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      videoContent: this.getInitialVideoContent()
    };
  },
  watch: {
    videoPlayer: {
      handler(newValue) {
        this.$emit("video-player-handler", newValue);
      },
      deep: true
    },
    overvideoInteraction(newValue) {
      this.$emit("overvideo-interaction-handler", newValue);
    }
  },
  methods: {
    getInitialVideoContent() {
      const config = {
        autoplay: get(this.content, ['play', 'autoplay'], false)
      }
      return merge(this.content.play, config);
    }
  }
};
</script>

<template>
  <div embed-ivs-component>
    <video ref="video" :class="{'cursor-pointer': areaClickable}" @click="$emit('video-clicked')" playsinline :id="videoContentId"/>
    <div class="position-absolute-center text-white d-flex justify-content-center align-items-center" v-if="unsupportedBrowser">
      <h3 class="px-5 py-4 rounded-1 bg-dark">Please use a more recent browser</h3>
    </div>
    <div class="control-bar-container" ref="control-bar">
      <embed-video-control-bar
        :content="content"
        :control-bar="controlBar"
        :show-controls="showControls"
        :fullscreen="fullscreen"
        :video-player="videoPlayer"
        @toggle-fullscreen="$emit('toggle-fullscreen')"
        @toggle-pip="$emit('toggle-pip')"
        v-if="videoPlayer && videoPlayer._isReady && controlBar"
      ></embed-video-control-bar>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  [embed-ivs-component] {
    position: relative;

    ::v-deep i {
      font-size: 1.5rem;
    }
  }
</style>