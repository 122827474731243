<script>
import { fandomTranslate } from "src/modules/common_module";

export default {
  data() {
    return {
      show: false
    };
  },
  created() {
    this.show = this.isIE && !Fandom.readCookie('ie-banner');
  },
  methods: {
    ft: fandomTranslate,
    closeBanner() {
      const expire = new Date();
      expire.setHours(expire.getHours() + 2);
      Fandom.setCookie('ie-banner', 'hide', expire.toUTCString());
      this.show = false;
    }
  },
  computed: {
    title() {
      return this.ft('globals.ie_banner.title');
    },
    description() {
      return this.ft('globals.ie_banner.description');
    },
    buttonTitle() {
      return this.ft('globals.ie_banner.button_title');
    },
    isIE() {
      return !!window.document.documentMode;
    }
  }
};
</script>

<template>
  <div class="d-flex flex-column justify-content-center align-items-center py-5 px-4 bg-white border-top" v-if="show" ie-banner-component>
    <h5 class="text-center">{{title}}</h5>
    <button class="btn btn-outline-dark mt-4" @click="closeBanner">{{buttonTitle}}</button>
  </div>
</template>

<style lang="scss" scoped>
[ie-banner-component] {
  position: fixed;
  z-index: $zindex-fixed;
  bottom: 0;
  right: 0;
  left: 0;
}
</style>