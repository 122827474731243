<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  props: {
    alternativeAppendContents: Function,
    optContentName: String
  },
  data() {
    return {
      getContentsParams: {
        content_filtering: []
      }
    }
  },
  mounted() {
    Fandom.onEvent("grid-filter-apply", (data) => {
      if (data.handlerContents.indexOf(this.content.name) > -1) {
        this.getContentsParams.content_filtering = data.contentFiltering;
        Fandom.getContents(this.content.name, this.getContentsParams, () => {}, false);
      }
    });
  },
  methods: {
    appendContents() {
      const count = get(this.content, "contents_count", null);
      if (Fandom.exists(this.alternativeAppendContents)) {
        this.alternativeAppendContents(true);
      } else {
        Fandom.appendContents(this.content.name, null, count, this.getContentsParams);
      }
    },
    childLayout(child) {
      return this.getContent(child).layout;
    }
  },
  computed: {
    gridColumnSet() {
      const columnSet = {
        "1": "col-lg-4 col-md-6 col-12 p-sm-1",
        "2": "col-xl-2 col-lg-3 col-md-4 col-6 p-1",
        "3": "col-lg-4 col-md-6 col-12 p-2-5 px-4"
      };
      return columnSet[this.content.skin || "1"];
    },
    gridRowSet() {
      const rowSet = {
        "1": "mx-sm-n1",
        "2": "mx-n1",
        "3": "mx-sm-n2-5"
      };
      return rowSet[this.content.skin || "1"];
    },
    aspectRatio() {
      return get(this.content, "aspect_ratio", "ar-16by9");
    },
    gridSize() {
      return Fandom.exists(this.content.width) ? `col-md-${this.content.width}` : "";
    },
    buttonClass() {
      const theme = this.mainContent.theme || this.content.theme;
      return theme == 'dark' ? 'btn-white' : 'btn-black';
    }
  },
};

</script>

<template>
  <div 
    v-easyadmin="optContentName ? optContentName : easyadminId" 
    v-if="isContentVisibleToUser()" 
    :class="[contentType]" 
    :style="theme"
    :id="anchorId"
    class="py-4"
    grid-fixed-component
  >
    <stripe-header align="left" :content="content" class="pt-4"></stripe-header>
    <div :class="`container${content.boxed ? '' : '-fluid'}`">
      <div v-if="!content.children || content.children.length == 0" class="w-100 d-flex justify-content-center">
        <p class="text-uppercase text-muted">{{ft("globals.no_content")}}</p>
      </div>
      <div class="row justify-content-center" v-else>
        <div class="col-12" :class="gridSize">
          <div class="row" :class="gridRowSet">
            <div class="py-1" :class="gridColumnSet" v-for="(child, index) in content.children" :key="`tile-${index}`">
              <div class="w-100 position-relative" :class="aspectRatio">
                <component :is="childLayout(child)" :content="getContent(child)" :inherit-theme="content.theme"></component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="content.has_more_children" class="container my-4">
      <div class="row">
        <div class="col-sm-12 text-center">
          <button class="btn" :class="this.content.button_class || buttonClass" @click="appendContents()">{{ft("globals.load_more")}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[grid-fixed-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  .p-2-5 {
    padding: .75rem !important;

    @include media-breakpoint-down(xs) {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }

  @include media-breakpoint-up(sm) {
    .mx-sm-n2-5 {
      margin-left: -0.75rem;
      margin-right: -0.75rem;
    }
  }
}
</style>
