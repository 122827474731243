<template>
  <div class="container-fluid" player-banners-component>
    <div class="row">
      <div class="col-12 px-0">
        <div class="banner-container d-flex">
          <div 
            v-for="(banner, index) in banners"
            :key="`banner-${banner.id}`"
            :data-banner="`banner-${banner.id}`"
            :style="{'width: 0': banner.isNew}"
            class="banner mr-1"
            :class="[banner.class, {'last': VISIBLE_BANNERS==index+1, 'flashsale': !banner.countdownEnded && banner.hasActiveFlashsale}]"
            @click="bannerClick(banner)"
          >
            <template v-if="banner.showPreFlashsale">
              <div class="banner__content banner__content d-flex h-100">
                <div class="banner__image">
                  <div class="image d-flex justify-content-center align-items-center">
                    <i class="text-player-red-color fas fa-bolt fa-3x"></i>
                  </div>
                </div>
                <div class="banner__text pr-2 d-flex flex-column justify-content-center align-items-start">
                  <span class="text-uppercase fs-small pb-1">{{ ft('globals.live_shopping.flash_sale') }}</span>
                  <span class="text-player-red-color fs-xs">{{ ft('globals.live_shopping.start_soon') }}</span>
                </div>
                <div class="countdown bg-player-red-color d-flex flex-column justify-content-center align-items-center px-2">
                  <countdown
                    :value="banner.duration"
                    :intervalAudio="bannerCountdownAudio"
                    empty-color="#E74040"
                    full-color="#B71A2D"
                    :circle-size="34"
                    @finished="computeNewBanners"
                  ></countdown>
                </div>
              </div>
            </template>
            <template v-else-if="banner.type === PLAYER_CONTENT.PRODUCTS">
              <div class="banner__countdown" v-if="!banner.countdownEnded && banner.hasActiveFlashsale">
                <i class="fas fa-bolt pr-2"></i>
                <vue-countdown @end="handleCountdownEnd(index)" :time="banner.flashsaleDuration" :transform="transform">
                  <template #default="{minutes, seconds}">{{minutes}}:{{seconds}}</template>
                </vue-countdown>
              </div>
              <div class="banner__content d-flex h-100">
                <div class="banner__image bg-white">
                  <div class="image">
                    <div :style="`background-image: url('${banner.image.url}')`"></div>
                  </div>
                </div>
                <div class="banner__text px-2">
                  <span>{{ banner.showPreFlashsale }} {{ banner.title }}</span>
                </div>
                <div class="banner__more-info" v-if="banner.price || banner.discount_price">
                  <span v-if="banner.price" :class="banner.infoClass">{{banner.price}}</span>
                  <span v-if="banner.discount_price" class="font-weight-bold main-info">{{banner.discount_price}}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flashsaleMixin from 'src/modules/flashsale_module.js';
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module';
import { VISIBLE_BANNERS, BANNER_FLASHSALE_DEFAULT_DELTA_TIME } from 'src/modules/utils/constants';
import { PLAYER_CONTENT} from 'src/modules/player/constants';
import { addBanner, hideBanner, showBanners, expandBanner } from  'src/modules/player/animations';
import Countdown from 'countdown';
import VueCountdown from '@chenfengyuan/vue-countdown';
import { differenceBy, isEqual } from 'lodash';
import moment from 'moment';

export default {
  mixins: [fandomBasicMixin, flashsaleMixin],
  components: {Countdown, VueCountdown},
  props: {
    products: {
      type: Array
    },
    quizes: {
      type: Array
    },
    bannerAudio: {
      type: [Object, HTMLAudioElement],
      required: false
    },
    bannerCountdownAudio: {
      type: Object,
      required: false
    }
  },
  data: function() {
    return {
      PLAYER_CONTENT,
      VISIBLE_BANNERS,
      banners: [],
      bannerAudioEl: this.bannerAudio
    }
  },
  mounted() {
    if (!(this.bannerAudio instanceof HTMLAudioElement) && this.bannerAudio?.url) {
      this.bannerAudioEl = new Audio(this.bannerAudio.url);
    }
    this.banners = this.getBanners(true);
    Vue.nextTick(()=>showBanners());
  },
  methods: {
    getBanners(onMounted=false) {
      return this.products
        .filter(item => item.show_banner)
        .slice(-VISIBLE_BANNERS)
        .reverse()
        .map((product, index) => {
          const oldIndex = this.banners.findIndex(item => item.id === product.id);
          const isNew = onMounted ? false 
            : this.banners.length===0
              ? true
              : oldIndex === -1;

          const hasActiveFlashsale = this.hasActiveFlashsale(product);
          if (hasActiveFlashsale) {
            const startWithDelta = moment(product.flashsale_start).add(product?.flashsale_pre_countdown ?? BANNER_FLASHSALE_DEFAULT_DELTA_TIME, "s");
            const showPreFlashsale = moment().isBetween(product.flashsale_start, startWithDelta);

            if (showPreFlashsale) {
              return {
                type: PLAYER_CONTENT.PRODUCTS,
                showPreFlashsale,
                duration: Math.ceil(moment.duration(startWithDelta.diff(moment())).asSeconds()),
                isNew,
                class: 'preflashsale'
              };
            }
          }

          return {
            ...product,
            type: PLAYER_CONTENT.PRODUCTS,
            hasActiveFlashsale: this.hasActiveFlashsale(product),
            flashsaleDuration: this.flashsaleDuration(product),
            countdownEnded: false,
            infoClass: product.discount_price ? "text-player-grey line-through" : "main-info font-weight-bold",
            isNew,
            class: [{'small': index > 0}]
          };
        });
    },
    computeNewBanners() {
      const banners = this.getBanners();
      this.computeAnimation(banners);
    },
    handleCountdownEnd(index) {
      Vue.set(this.banners[index], "countdownEnded", true);
    },
    computeAnimation(newBanners) {
      const bannerToRemove = differenceBy(this.banners, newBanners, 'id');
      const query = bannerToRemove.map(banner => `[data-banner="banner-${banner.id}"]`)
      const targets = Array.from(document.querySelectorAll(query.length>0 ? query.join(',') : null));

      if (targets.length > 0) {
        const animation = hideBanner(targets);
        animation.finished.then(()=>{
          this.showBannerToAdd(newBanners);
        })
        animation.play();
      } else {
        this.showBannerToAdd(newBanners);
      }
    },
    showBannerToAdd(newBanners) {
      const bannerToAdd = differenceBy(newBanners, this.banners, 'id');
      this.banners = newBanners;
      if (bannerToAdd.length>0) {
        Vue.nextTick(() => {
          addBanner();
          this.bannerAudioEl?.play();
        })
      } else {
        Vue.nextTick(() => {expandBanner()});
      }
    },
    bannerClick(banner) {
      if (!banner.showPreFlashsale) {
        this.$emit('banner-clicked', banner);
      }
    }
  },
  watch: {
    products(newVal, oldVal) {
      if (newVal && !isEqual(newVal, oldVal)) {
        this.computeNewBanners();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
[player-banners-component] {

  --banner-width: 250px;
  --banner-height: 72px;

  @include media-breakpoint-down(lg) {
    --banner-width: 200px;
    --banner-height: 48px;
  }

  .banner {
    max-width: var(--banner-width);
    height: var(--banner-height);
    font-size: .75rem;
    line-height: 15px;
    letter-spacing: -0.5px;
    cursor: pointer;
    background-color: $white;
    border-radius: $player-border-radius-sm;
    position: relative;

    @include media-breakpoint-down(lg) {
      font-size: .625rem;
      line-height: 11px;
    }

    &__image {
      padding: 2px;
      border-bottom-left-radius: $player-border-radius-sm;
      border-top-left-radius: $player-border-radius-sm;

      .image {
        max-width: calc(var(--banner-height) - 4px);
        width: calc(var(--banner-height) - 4px);
        background: #fff;
        height: 100%;

        div {
          height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: $player-border-radius-sm;
        }
      }
    }

    &__text {
      flex: 1;
      color: $dark;
      font-weight: 600;
      display: flex;
      align-items: center;

      span {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__more-info {
      flex: 2;
      max-width: var(--banner-height);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #F2F2F3;
      color: $dark;
      padding: 0 .5rem;
      border-radius: $player-border-radius-sm;

      ::v-deep .line-through {
        text-decoration: line-through;
      }
    }

    &.small {
      width: var(--banner-height);
      max-width: var(--banner-height);

      .banner {
        &__content {
          flex-direction: column;
        }

        &__text {
          display: none;
        }

        &__image {
          flex: 2;
          border-top-right-radius: $player-border-radius-sm;
          overflow: hidden;
        }

        &__more-info {
          flex-direction: row;
          justify-content: center;
          padding: 0;
          background: $white;           

          ::v-deep span {
            &:not(.main-info) {
              display: none !important;
            }
          }
        }
      }
    }

    &.preflashsale {
      .countdown {
        border-bottom-right-radius: $player-border-radius-sm;
        border-top-right-radius: $player-border-radius-sm;
      }
    }

    &.flashsale {
      border: 1px solid $player-red-color;

      ::v-deep .main-info {
        color: $player-red-color;
      }
    }

    &__countdown {
      background: $player-red-color;
      color: $white;
      position: absolute;
      border: 1px solid $player-red-color;
      border-bottom-left-radius: $player-border-radius-sm;
      border-bottom-right-radius: $player-border-radius-sm;
      font-weight: 600;
      font-size: .875rem;
      line-height: 14px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: 2px 4px;
      bottom: -20px;
      left: -1px;
      height: 29px;
      width: var(--banner-height);

      @include media-breakpoint-down(lg) {
        font-size: .5rem;
        line-height: .5rem;
        bottom: -15px;
        height: 21px;
      }
    }
  }
}
</style>