const USER_TYPE_RULES = {
  CAN_COMMENT: "can_comment"
}

const SOCIAL_AUTH = {
  FACEBOOK: "facebook",
  GOOGLE: "google"
}

const VISIBLE_BANNERS = 3;

const BANNER_FLASHSALE_DEFAULT_DELTA_TIME = 20; // seconds

const QUIZ_TIMEOUT_TO_NEXT_QUESTION = 1500;

const QUIZ_TYPE = {
  CAROUSEL: "carousel",
  SIMPLE: "simple"
}

const FULLHD_LANDSCAPE = {
  maxBitrate: 6000,
  maxFramerate: 30,
  maxResolution: {
    width: 1920,
    height: 1080
  }
};

const FULLHD_PORTRAIT = {
  maxBitrate: 6000,
  maxFramerate: 30,
  maxResolution: {
    width: 1080,
    height: 1920
  }
};

const HD_LANDSCAPE = {
  maxBitrate: 3000,
  maxFramerate: 30,
  maxResolution: {
    width: 1280,
    height: 720
  }
};

const HD_PORTRAIT = {
  maxBitrate: 3000,
  maxFramerate: 30,
  maxResolution: {
    width: 720,
    height: 1280
  }
};

// TEXT TO SPEECH SERVICES
const TEXT_TO_SPEECH_SERVICES = {
  POLLY: "polly",
  ELEVEN_LAB: "eleven_lab",
  OPEN_AI: "open_ai"
}

const ACTIONS = {
  UNREAL: {
    SPEECH_END: "speechEnd",
    SPEECH_START: "speechStart",
    READY: "ready",
    METAHUMAN_LOADED: "metahuman_loaded",
    LANGUAGE_SWITCHED: "language_switched"
  },
  SITE: {
    TALK: "talk",
    THINK: "think",
    STOP: "stop",
    VIDEO: "video",
    ASK_MESSAGE: "ask_message",
    END_MESSAGE: "end_message",
    METAHUMAN_LOADED: "metahuman_loaded",
    SWITCH_LANGUAGE: "switch_language",
    RESTORE_AUDIO: "restore_audio", // unreal audio metahuman fallback
    START: "start" // unreal metahuman start
  }
}

const STEPS = {
  OFF: 'off',
  IDLE: 'idle',
  INTRO_VIDEO: 'intro_video',
  MH_ASK: 'mh_ask',
  USER_ASK: 'user_ask',
  AI_WAITING: "ai_waiting",
  MH_ANSWER: 'mh_answer',
  MH_GREETINGS: 'mh_greetings'
}

const SCENARIO = {
  IDLE: 'idle',
  CHAT: 'chat'
}

const CONVERSATION_TYPE = {
  INTERVIEW: 'interview',
  CHAT: 'chat'
}

export {
  USER_TYPE_RULES,
  SOCIAL_AUTH,
  VISIBLE_BANNERS,
  BANNER_FLASHSALE_DEFAULT_DELTA_TIME,
  QUIZ_TIMEOUT_TO_NEXT_QUESTION,
  QUIZ_TYPE,
  FULLHD_LANDSCAPE,
  FULLHD_PORTRAIT,
  HD_LANDSCAPE,
  HD_PORTRAIT,
  TEXT_TO_SPEECH_SERVICES,
  ACTIONS,
  STEPS,
  SCENARIO,
  CONVERSATION_TYPE
}