<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { audiEventsBasicMixin } from "../../modules/audi_events_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, audiEventsBasicMixin],
  computed: {
    category() {
      if (Fandom.exists(this.content.category)) {
        return this.content.category;
      } else {
        const decorator = Fandom.getDecorator(this.content);
        if (decorator) {
          return decorator.title;
        }
      }
      return false;
    },
    skin() {
      const value = this.content.skin || "1";
      const map = {
        "1": "col-sm-6",
        "2": "col-md-4 col-sm-6"
      }
      return map[value];
    }
  },
  methods: {
    expandUrl: Fandom.expandUrl,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getTarget: Fandom.getTarget,
  }
};
</script>

<template>
  <div 
    v-easyadmin="easyadminId" 
    :class="[contentType, paddingY]"
    class="px-0 themed-item"
    :style="theme"
    v-if="isContentVisibleToUser()" 
    list-keyopints-tile-component
    :id="content.name"
  >
    <div class="container" :id="content.stripe_id">
      <div class="row inset py-4">
        <div class="px-2 py-4" :class="skin" v-for="(keypoint, index) in content.keypoints" :key="`keypoints-${index}`">
          <div class="keypoint-image ar-1by1 w-100 bg-cover-center" :style="{ 'background-image': getBackgroundUrl(keypoint.image.url) }"></div>
          <div class="keypoint-text">
            <h2 class="keypoint-title pt-4 mt-2 mb-0">{{keypoint.title}}</h2>
            <p class="pt-3 pt-md-4 mt-md-2" v-html="keypoint.description"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[list-keyopints-tile-component] {

  .keypoint-title {
    font-size: 1.875rem;
    font-weight: normal;
  }

  @include media-breakpoint-up(md) {

    .keypoint-text {
      max-width: 80%;
    }
  }

}

</style>
