<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { merge } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      videoConfig: {
        controls: 0,
        autoplay: 1,
        interactive: 1
      }
    };
  },
  methods: {
    merge: merge
  },
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" course-product-video-popup-interactive-component>
  <stripe-one-col-media 
    :content="merge(content, { play: { videoConfig: videoConfig } } )" 
    :opt-container-content="containerContent">
  </stripe-one-col-media>
  <component :is="stripe.layout" :content="stripe" v-for="(stripe, index) in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
  </div>
</template>

<style lang="scss" scoped>
</style>
