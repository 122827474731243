import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { throttle } from "lodash";
import { ActionCableComponentsMixin } from "src/modules/websocket_module.js";
import { setForceKeepAlive } from "src/modules/fandom_analytics_module.js";
import Vue from 'vue';

const interactionsSuccessBlock = (data, polling = false) => {
  // Note: most of the callback work is done by the default getContents callback
  // Update main content
  const newContent = data.name_to_content[data.main_content_name];
  Vue.set(globalState.pageModel.name_to_content, data.main_content_name, newContent);
  // update name_to_content_user_info with cachedData
  setLiveInteractionsData(data, newContent);
  // if the successBlock is executed because of a polling and the interactive content is a shared content, we need to update also
  // the interaction contained in such a content
  if (polling && typeof newContent.interactive_content == 'string') {
    setLiveInteractionsData(data, Fandom.getContent(newContent.interactive_content));
  }
};

function setLiveInteractionsData(newPageModel, content) {
  // if (Fandom.exists(globalState.pageModel.name_to_content_user_info[content.name])) {
  //   Fandom.executeForEachExtraField(content, (key, value) => {
  //     if (key === "interaction" && value.id) {
  //       const interactionId = value.id;
  //       const isDeferred = get(newPageModel.name_to_content_user_info, [content.name, 'id_to_interaction', interactionId, 'deferred'], false);
  //       const cachedData = get(newPageModel.name_to_content_user_info, [content.name, 'id_to_interaction', interactionId, 'cached_data'], false);
  //       const contentUserInfo = get(globalState.pageModel.name_to_content_user_info, [content.name, 'id_to_interaction', interactionId], {});
  //       if (cachedData) {
  //         contentUserInfo.cached_data = cachedData;
  //         Object.keys(cachedData).forEach(key => {
  //           contentUserInfo[key] = cachedData[key];
  //         });
  //         contentUserInfo.deferred = true;
  //         if (!cachedData.updated_at) { delete contentUserInfo.updated_at }
  //       }
  //       // if (!isDeferred) {
  //       //   delete contentUserInfo.deferred
  //       //   delete contentUserInfo.updated_at
  //       // }
  //       Vue.set(globalState.pageModel.name_to_content_user_info[content.name].id_to_interaction, interactionId, contentUserInfo);
  //     }
  //   });
  // }
}

const liveEventsMixinV2 = {
  mixins: [ActionCableComponentsMixin],
  data() {
    return {
      disconnected: {},
      meetingContent: undefined
    }
  },
  created() {
    setLiveInteractionsData(globalState.pageModel, this.content);
    if (this.sharedInteractiveContent) {
      setLiveInteractionsData(globalState.pageModel, this.sharedInteractiveContent);
    }
  },
  mounted() {
    this.initSubscriptions();
    setForceKeepAlive(true);
  },
  methods: {
    initSubscriptions() {
      this.subscribe(
        { channel: "PublicChannel", room: this.content.name, lang: globalState.lang },
        {
          connected: () => {
            if (this.disconnected[this.content.name]) {
              this.getLastVersion(this.content);
              this.disconnected[this.content.name] = false;
            }
          },
          disconnected: () => {
            this.disconnected[this.content.name] = true;
          },
          received: (receivedData) => {
            Fandom.getContentsCallback(this.content.name, receivedData, interactionsSuccessBlock);
          }
        }
      );
      this.subscribe({ channel: "TrackingChannel", room: this.containerContent.name, lang: globalState.lang });

      if (this.sharedInteractiveContent) {
        this.subscribe(
          { channel: "PublicChannel", room: this.sharedInteractiveContent.name, lang: globalState.lang },
          {
            connected: () => {            
              if (this.disconnected[this.sharedInteractiveContent.name]) {
                this.getLastVersion(this.sharedInteractiveContent);
                this.disconnected[this.sharedInteractiveContent.name] = false;
              }
            },
            disconnected: () => {
              this.disconnected[this.sharedInteractiveContent.name] = true;
            },
            received: (receivedData) => {
              Fandom.getContentsCallback(this.sharedInteractiveContent.name, receivedData, interactionsSuccessBlock);
            }
          }
        );
      }
    },
    getLastVersion: throttle((content) => {
      Fandom.ajax({
        method: "GET",
        url: "/api/v5/get_content_last_version",
        data: {
          content_id: content.id,
          version: content.version
        },
        success: data => {
          if (data.new_version_available) {
            Fandom.getContentsCallback(content.name, data, interactionsSuccessBlock);
          }
        },
        error: err => {
          console.error(err);
        }
      });
    }, 1000)
  },
  computed: {
    /* return a record only if the interactive content is a shared content */
    sharedInteractiveContent() {
      if (typeof this.content.interactive_content == 'string') {
        return Fandom.getContent(this.content.interactive_content);
      } else {
        return false;
      }
    },
    linkedMeetings() {
      return this.content.children ? this.content.children.filter(c => Fandom.getContent(c).decorators["event-meeting"] === this.content.name) || [] : [];
    }
  }
};

const liveEventsModalMixin = {
  data() {
    return {
      showModal: false,
      preventModalOpen: false
    }
  },
  watch: {
    'content.active'(newVal) {
      if (newVal && !this.preventModalOpen) {
        this.openModal();
      } else {
        this.deactivateModal();
      }
    }
  },
  methods: {
    openModal() {
      disableBodyScroll();
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      clearAllBodyScrollLocks();
      this.$emit('close');
    },
    deactivateModal() {
      this.$emit('clearnotice', {id: this.content.id || this.content.$id});
      this.showModal = false;
      clearAllBodyScrollLocks();
    }
  },
  beforeDestroy() {
    this.deactivateModal();
  }
}

export {
  liveEventsMixinV2,
  liveEventsModalMixin
}
