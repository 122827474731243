import { createConsumer } from "@rails/actioncable";
import { get, throttle } from "lodash";

const ActionCableRootMixin = {
  data() {
    return {
      connection: null,
      listeners: {} // room: [listener_id_1, listener_id_2, ...]
    };
  },
  computed: {
    serverUrl() {
      const userToken = get(globalState, ["pageModel", "user", "jwt"], "");
      const metaTag = document.querySelector("meta[name=action-cable-url]");

      return `${metaTag.getAttribute("content")}?user_token=${userToken}`;
    },
    subscriptions() {
      return get(this.connection, ["subscriptions", "subscriptions"], []);
    }
  },
  methods: {
    connect() {
      this.connection = createConsumer(this.serverUrl);
    },
    subscribe(subscription, methods) {
      if (!this.connection) {
        this.connect();
      }
      return this.connection.subscriptions.create(subscription, methods);
    },
    notifyListeners(payload) {
      this.listeners[payload.room].forEach(id => {
        console.log('%cNOTIFY listener with id', "background-color: yellow;", id);
        Fandom.emit(`${id}`, payload)
      });
    },
    removeListenerId(id) {
      Object.keys(this.listeners).forEach(room => {
        this.listeners[room] = this.listeners[room].filter(item => item !== id);
      })
    }
  }
};

const ActionCableComponentsMixin = {
  data() {
    return {
      subscriptions: [],
      disconnected: {}
    };
  },
  beforeDestroy() {
    this.deleteSubscriptions();
  },
  methods: {
    subscribe(subscription, methods = {}) {
      subscription.tenant = globalState.pageModel.tenant.id;
      this.subscriptions.push(this.$root.subscribe(subscription, methods));
    },
    getContentName(content, containerContent=this.optContainerContent) {
      return !Fandom.exists(content.$id) ? containerContent.name : `${containerContent.name}|${content.$id}`;
    },
    subscribeWithEvent(content=this.content, containerContent=this.optContainerContent) {
      const room = this.getContentName(content, containerContent);
      const roomListener = this.$root.listeners[room];

      if (!roomListener) {
        this.$root.listeners[room] = [];
      }
      if (!this.$root.listeners[room].includes(this._uid)) 
        this.$root.listeners[room].push(this._uid);

      const index = this.$root.subscriptions.findIndex(item => item.identifier.includes(room));
      if (index !== -1) {
        console.log('%csubscribeWithEvent: ALREADY SUBSCRIBED', "background-color: yellow;", room, this.$options.name);
        Fandom.emit(`${this._uid}`, {room, status: 'connected'});
      } else {
        console.log('%csubscribeWithEvent: NOT SUBSCRIBED', "background-color: yellow;", room, this.$options.name);
        this.subscribe(
          { channel: "PublicChannel", room, lang: globalState.lang },
          {
            connected: () => {      
              Fandom.emit(`${this._uid}`, {room, status: 'connected'});
            },
            received: (receivedData) => {
              this.$root.notifyListeners({room, status: 'received', receivedData});
            }
          }
        )
      }
    },
    deleteSubscription(channel, room) {
      const subscription = this.subscriptions.find(subscription => {
        const identifier = JSON.parse(subscription.identifier);
        return identifier.channel === channel && identifier.room === room;
      });

      subscription.unsubscribe();

      // if subscribed with event
      if (this.subscriptions.length === 0) {
        Fandom.offEvent(`${this._uid}`);
        this.$root.removeListenerId(this._uid);
      }
    },
    deleteSubscriptions() {
      console.log('%cbefore destroy', "background-color: yellow;", this.contentName, this.$options.name);
      this.subscriptions.forEach(subscription => {
        subscription.unsubscribe();
      });
      // if subscribed with event
      Fandom.offEvent(`${this._uid}`);
      this.$root.removeListenerId(this._uid);
    },
    receivedChannelData(contentName, receivedData) {
      if (receivedData) {
        Fandom.getContentsCallback(contentName, receivedData, null, false, true);
      }
    },
    getContentLastVersion: throttle(function(content) {
      Fandom.ajax({
        method: "GET",
        url: "/api/v5/get_content_last_version",
        data: {
          content_id: content.id,
          version: content.version
        },
        success: data => {
          if (data.new_version_available) {
            this.receivedChannelData(content.name, data.new_content_version);
          }
        },
        error: err => {
          console.error(err);
        }
      });
    }, 1000)
  }
};

export {
  ActionCableRootMixin,
  ActionCableComponentsMixin
};
