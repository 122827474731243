<script>
import { get } from "lodash";

export default {
  data() {
    return {
      title: null,
      breadcrumb: []
    };
  },
  methods: {
    applyContextToUrl: Fandom.applyContextToUrl
  },
  computed: {
    current() {
      if (globalState.pageModel.seo && globalState.pageModel.seo.title) {
        return globalState.pageModel.seo.title;
      } else {
        const mainContentName = globalState.pageModel.main_content_name;
        return globalState.pageModel.name_to_content[mainContentName].title;
      }
    }
  },
  mounted() {
    for (const name of globalState.pageModel.breadcrumb) {
      const content = globalState.pageModel.name_to_content[name];
      const title = get(content, ["seo", "title"], content.title);
      if (Fandom.exists(title)) {
        this.breadcrumb.push({ title: title, url: content.slug });
      }
    }
  }
};
</script>

<template>
  <div class="d-flex align-items-center py-3" breadcrumb-component>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb p-0 m-0">
        <li class="breadcrumb-item"><a :href="applyContextToUrl('/')">Home</a></li>
        <li v-for="b in breadcrumb" class="breadcrumb-item active" aria-current="page">
          <template v-if="b.url">
            <a :href="applyContextToUrl(b.url)">{{b.title}}</a>
          </template>
          <template v-else>
            {{b.title}}
          </template>
        </li>
        <li class="breadcrumb-item" aria-current="page">{{current}}</li>
      </ol>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
[breadcrumb-component] {
  padding-left: 2.5rem;
  background-color: transparent;

  .breadcrumb {
    text-transform: uppercase;
    font-size: .75rem;

    *, .breadcrumb-item > a, .breadcrumb-item + .breadcrumb-item::before {
      color: $dark !important;
      font-weight: 300;
    }

    .breadcrumb-item:last-child {
      font-weight: 600;
      color: $dark !important;
    }
  }
}
</style>
